import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { featureKey } from './selectors';
import {
  CreateOrganizationBodyDto,
  EnrollmentBillingAddressDto,
  EnrollmentContactDto,
  EnrollmentCreditDataDto,
  EnrollmentDto,
  EnrollmentLicencesReqDto,
  EnrollmentShippingAddressDto,
  EnrollmentShortDto,
  GetOrganizationsResponseDto,
  GetOrganizationsUsersResponseDto,
  OrganizationDataDto,
  OrganizationDto,
  ShippingAddressDto,
  ShippingAddressRequestDto,
} from '../../../core/models/organization.model';
import { SortDirection } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';

export const OrganizationActions = createActionGroup({
  source: featureKey,
  events: {
    setSearchResult: props<{ organization: OrganizationDataDto }>(),
    resetSearchResult: emptyProps(),
    setSearchName: props<{ name: string | null }>(),
    setActionRequired: props<{ actionRequired: boolean }>(),
    setSearchStatus: props<{ status: string[] | null }>(),
    setSearchPage: props<{ page: number }>(),
    setSearchPageSize: props<{ pageSize: number }>(),
    setSearchSortOrder: props<{ sortOrder: SortDirection }>(),
    setSearchOrderBy: props<{ orderBy: string }>(),
    setOrganizations: props<{ response: GetOrganizationsResponseDto }>(),
    setOrganization: props<{ response: OrganizationDto | null }>(),
    getOrganizationIdFromParams: props<{ routerFn: () => Router; routeFn: () => ActivatedRoute }>(),
    clearOrganization: emptyProps(),
    setOrganizationUsers: props<{ response: GetOrganizationsUsersResponseDto }>(),
    getOrganizationUsersFail: emptyProps(),
    getOrganizationFail: emptyProps(),
    getOrganizations: emptyProps(),
    loadOrganizations: props<{
      page: number;
      sortOrder: SortDirection;
      name: string | null;
      orderBy: string | null;
      status: string[] | null;
      pageSize: number;
      actionRequired: boolean;
    }>(),
    getOrganizationsFail: emptyProps(),
    loadOrganization: emptyProps(),
    getOrganization: emptyProps(),
    loadOrganizationAddresses: emptyProps(),
    'Create Organization Account': props<{ body: CreateOrganizationBodyDto }>(),
    'Create Organization Account Success': props<{ orgId: number }>(),
    'Create Organization Account Fail': emptyProps(),
    'Create Organization Account Cancel': props<{ pristine: boolean }>(),
    'Ask Update Organization Account': props<{ id: number; status: string; orgAdminId?: number }>(),
    'Update Organization Account': props<{ id: number; status: string; orgAdminId?: number }>(),
    'Update Organization Account Success': emptyProps(),
    'Update Organization Account Fail': emptyProps(),
    getOrganizationShippingAddresses: props<{ id: number }>(),
    'Get Organization Shipping Addresses Success': props<{ response: ShippingAddressDto[] }>(),
    'Get Organization Shipping Addresses Fail': emptyProps(),
    'Create New Shipping Addresses': props<{ id: number; body: ShippingAddressRequestDto }>(),
    'Create New Shipping Addresses Success': props<{ id: number }>(),
    'Create New Shipping Addresses Fail': emptyProps(),
    'Approve Shipping Addresses': props<{ id: number; addressId: number }>(),
    'Approve Shipping Addresses Success': emptyProps(),
    'Approve Shipping Addresses Fail': emptyProps(),
    'Ask Decline Shipping Addresses': props<{ id: number; addressId: number }>(),
    'Decline Shipping Addresses': props<{ id: number; addressId: number }>(),
    'Decline Shipping Addresses Success': props<{ id: number }>(),
    'Decline Shipping Addresses Fail': emptyProps(),
    'Ask Delete Shipping Addresses': props<{ id: number; addressId: number }>(),
    'Delete Shipping Addresses': props<{ id: number; addressId: number }>(),
    'Delete Shipping Addresses Success': props<{ id: number }>(),
    'Delete Shipping Addresses Fail': emptyProps(),
    'Default Shipping Addresses': props<{ id: number; addressId: number }>(),
    'Default Shipping Addresses Success': props<{ id: number }>(),
    'Default Shipping Addresses Fail': emptyProps(),
    'Ask Deactivate Shipping Addresses': props<{ id: number; addressId: number }>(),
    'Deactivate Shipping Addresses': props<{ id: number; addressId: number }>(),
    'Deactivate Shipping Addresses Success': props<{ id: number }>(),
    'Deactivate Shipping Addresses Fail': emptyProps(),
    'Ask Activate Shipping Addresses': props<{ id: number; addressId: number }>(),
    'Activate Shipping Addresses': props<{ id: number; addressId: number }>(),
    'Activate Shipping Addresses Success': props<{ id: number }>(),
    'Activate Shipping Addresses Fail': emptyProps(),
  },
});

export const OrganizationEnrollmentActions = createActionGroup({
  source: featureKey,
  events: {
    createOrganizationEnrollmentAndUpdateBillTo: props<{ billTo: EnrollmentBillingAddressDto }>(),
    createOrganizationEnrollmentSuccess: props<{ enrollment: EnrollmentShortDto }>(),
    getOrganizationEnrollmentShort: emptyProps(),
    getOrganizationEnrollment: emptyProps(),
    getOrganizationEnrollmentSuccess: props<{ enrollment: EnrollmentShortDto }>(),
    getOrganizationEnrollmentById: props<{ enrollmentId: number }>(),
    getOrganizationEnrollmentByIdSuccess: props<{ enrollment: EnrollmentDto }>(),
    updateEnrollmentBillTo: props<{ billTo: EnrollmentBillingAddressDto }>(),
    updateEnrollmentShipTo: props<{ shipTo: EnrollmentShippingAddressDto }>(),
    updateEnrollmentLicences: props<{ licences: EnrollmentLicencesReqDto }>(),
    updateEnrollmentContacts: props<{ contacts: EnrollmentContactDto[] }>(),
    updateEnrollmentCreditData: props<{ creditData: EnrollmentCreditDataDto }>(),
    updateEnrollmentSuccess: emptyProps(),
    submitEnrollment: emptyProps(),
    submitEnrollmentSuccess: props<{ enrollment: EnrollmentDto }>(),
    approveEnrollment: emptyProps(),
    declineEnrollment: emptyProps(),
    clearEnrollmentData: emptyProps(),
  },
});
