import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { OrganizationsApiService } from '../../../services/organizations-api.service';
import { catchError, map, Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { OrganizationActions } from '../../../../organizations/store/actions';

export function createNpiValidator(
  apiService: OrganizationsApiService,
  store$: Store,
): AsyncValidatorFn {
  let prevValue: any = null;
  let prevError: ValidationErrors | null = null;
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    let changed = control.value !== prevValue;
    if (changed) {
      prevValue = control.value;
    }
    return !changed
      ? of(prevError)
      : apiService.searchByNpi(control.value).pipe(
          map((result) => {
            store$.dispatch(
              OrganizationActions.setSearchResult({
                organization: {
                  name: result.name,
                  npi: result.npi,
                  street: result.address.address,
                  city: result.address.city,
                  country: result.address.country,
                  state: result.address.state,
                  countryCode: result.address.countryCode,
                  phoneNumber: result.address.phone,
                  zip: result.address.postalCode.slice(0, 5),
                },
              }),
            );
            prevError = null;
            return null;
          }),
          catchError(({ error }) => {
            store$.dispatch(OrganizationActions.resetSearchResult());
            prevError = {
              custom: error?.message || 'Organization API service unavailable',
            };
            return of(prevError);
          }),
        );
  };
}
