<div class="app-blocked" automation-id="login-page">
  <app-logo></app-logo>
  <div class="app-blocked__card">
    <app-card>
      <div class="header">Log In</div>
      <div class="subheader">Your account has been temporary blocked</div>
      <div class="content">
        <p>You've tried to login too many times with an incorrect login or password.</p>
        <p class="card-inputs">Please try again in:</p>
        <p class="card-inputs">{{ timeLef$ | async }}</p>
        <p class="card-actions">
          <app-button
            automation-id="save-btn"
            [type]="btnTypes.Flat"
            label="Reset your password"
            htmlType="submit"
            class="app-login__card-submit"
            [size]="btnSizes.FullWidth"
            (onClick)="submit()"
          ></app-button>
        </p>
      </div>
    </app-card>
  </div>
</div>
