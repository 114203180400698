import { fedexMask, upcMask } from 'src/app/core/consts/masks';

export const prefixes = [
  {
    id: 1,
    name: 'Dr',
  },
  {
    id: 2,
    name: 'Mr',
  },
  {
    id: 3,
    name: 'Ms',
  },
];

export const shipVia = [
  { id: 'none', name: 'Not Specified', mask: fedexMask },
  { id: 'UPS', name: 'UPS', mask: upcMask },
  { id: 'FEDEX', name: 'FEDEX', mask: fedexMask },
];
