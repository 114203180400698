<div class="app-input" [matTooltip]="control.disabled || readonly ? control.value : ''">
  <mat-label *ngIf="label" class="app-input__label" [ngClass]="{ readonly: readonly }"
    >{{ label }}:
    <ng-container *ngIf="isRequired">*</ng-container>
  </mat-label>
  <mat-form-field
    appearance="outline"
    [ngClass]="{ 'mat-form-field-invalid': highlightAsError, readonly: readonly }"
  >
    <mat-icon *ngIf="controlType === 'search'" matPrefix class="app-input__icon prefix-icon">{{
      'search'
    }}</mat-icon>
    <input
      #inputElement
      [type]="type"
      matInput
      matNativeControl
      [formControl]="control"
      [maxLength]="maxLength"
      [readonly]="readonly"
      [mask]="mask"
      [prefix]="prefix"
      [placeholder]="placeholder"
      [name]="name"
      [id]="id"
      [autocomplete]="autocomplete"
      [patterns]="patterns"
      [validation]="validation"
      [min]="min"
      [max]="max"
      (change)="trimStart($event)"
    />
    <mat-icon
      *ngIf="controlType === 'password'"
      matSuffix
      class="app-input__icon"
      (click)="showHidePassword()"
      >{{ hide ? 'visibility' : 'visibility_off' }}</mat-icon
    >
  </mat-form-field>
  <mat-error class="app-input__error" *ngIf="showErrors && control?.invalid && control?.touched"
    >{{ getErrorMessage() }}
  </mat-error>
</div>
