<div class="app-radio">
  @if (label) {
    <span class="app-radio__label">
      {{ label }}:
      @if (isRequired) {
        *
      }
    </span>
  }
  <mat-radio-group
    aria-label="Select an option"
    [formControl]="control"
    [ngClass]="{ _vertical: orientation === 'vertical' }"
  >
    <mat-radio-button
      class="app-radio__button"
      [ngClass]="{ _multiline: isMultiline }"
      *ngFor="let option of values; let first = first"
      [value]="option[optionValue]"
    >
      <span (click)="$event.preventDefault()">{{ option[optionLabel] }}</span>
    </mat-radio-button>
  </mat-radio-group>
</div>
