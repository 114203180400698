<div class="app-create-organization" automation-id="app-create-organization">
  <div class="header" automation-id="title">Create New Organization Account</div>
  <div class="content app-create-organization__card-content">
    <app-stepper #cdkStepper automation-id="progress-stepper">
      <cdk-step label="1 NPI Validation">
        <div class="app-create-organization__step-content">
          <app-npi-validation automation-id="step-content"></app-npi-validation>
        </div>
        <ng-container [ngTemplateOutlet]="stepperActions"></ng-container>
      </cdk-step>
      <cdk-step label="2 Organization Details">
        <div class="app-create-organization__step-content">
          <app-personal-details-create-organization
            automation-id="step-content"
          ></app-personal-details-create-organization>
        </div>
        <ng-container [ngTemplateOutlet]="stepperActions"></ng-container>
      </cdk-step>
    </app-stepper>
  </div>
</div>

<!--todo move to stepper-->
<ng-template #stepperActions>
  <div automation-id="buttons-block" class="app-create-organization__step-actions">
    <div class="app-create-organization__step-cancel">
      <app-button
        automation-id="cancel-btn"
        [type]="btnTypes.Stroked"
        label="Cancel"
        (onClick)="cancel()"
      ></app-button>
    </div>
    <div
      *let="registering$ | async as registering"
      class="app-create-organization__step-navigation"
    >
      <app-button
        automation-id="back-btn"
        *ngIf="cdkStepper?.selectedIndex !== 0"
        [type]="btnTypes.Stroked"
        label="< Back"
        (onClick)="back()"
      ></app-button>
      <app-button
        automation-id="continue-btn"
        *ngIf="cdkStepper?.selectedIndex !== cdkStepper.steps.length - 1; else submitBtn"
        [type]="btnTypes.Flat"
        label="Continue"
        [disabled]="checkValidity(cdkStepper.selectedIndex, registering)"
        (onClick)="next(cdkStepper.selectedIndex)"
      ></app-button>
      <ng-template #submitBtn>
        <app-button
          automation-id="submit-btn"
          [type]="btnTypes.Flat"
          label="Submit"
          [disabled]="checkValidity(cdkStepper.selectedIndex, registering)"
          (onClick)="submit()"
        ></app-button>
      </ng-template>
    </div>
  </div>
</ng-template>
