import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { catchError, Observable } from 'rxjs';
import { environment } from 'src/environment/environment';
import { inject } from '@angular/core';

export class ApiService {
  public baseUrl = environment.baseUrl;
  http = inject(HttpClient);

  private setHeaders(): HttpHeaders {
    // todo Установите глобальные заголовки здесь
    return new HttpHeaders().set('Content-Type', 'application/json');
  }

  protected get<T>(path: string, params?: HttpParams): Observable<T> {
    return this.http
      .get<T>(this.baseUrl + path, { headers: this.setHeaders(), params })
      .pipe(catchError(this.formatErrors));
  }

  protected put<T>(path: string, body: Object = {}): Observable<T> {
    return this.http
      .put<T>(this.baseUrl + path, JSON.stringify(body), { headers: this.setHeaders() })
      .pipe(catchError(this.formatErrors));
  }

  protected patch<T>(path: string, body: Object = {}): Observable<T> {
    return this.http
      .patch<T>(this.baseUrl + path, JSON.stringify(body), { headers: this.setHeaders() })
      .pipe(catchError(this.formatErrors));
  }

  protected post<T>(path: string, body: Object = {}): Observable<T> {
    return this.http
      .post<T>(this.baseUrl + path, JSON.stringify(body), { headers: this.setHeaders() })
      .pipe(catchError(this.formatErrors));
  }

  protected postFormData<T>(path: string, body: Object = {}): Observable<T> {
    return this.http.post<T>(this.baseUrl + path, body).pipe(catchError(this.formatErrors));
  }

  protected putFormData<T>(path: string, body: Object = {}): Observable<T> {
    return this.http.put<T>(this.baseUrl + path, body).pipe(catchError(this.formatErrors));
  }

  protected delete<T>(path: string): Observable<T> {
    return this.http
      .delete<T>(this.baseUrl + path, { headers: this.setHeaders() })
      .pipe(catchError(this.formatErrors));
  }

  protected getFullResponse<T>(
    path: string,
    params?: HttpParams,
    responseType?: any,
  ): Observable<HttpResponse<T>> {
    return this.http
      .get<T>(this.baseUrl + path, {
        headers: this.setHeaders(),
        params,
        responseType,
        observe: 'response',
      })
      .pipe(catchError(this.formatErrors));
  }

  protected getText<T>(path: string, params?: HttpParams): Observable<T> {
    return this.http
      .get<T>(this.baseUrl + path, { responseType: 'text' as 'json', params })
      .pipe(catchError(this.formatErrors));
  }

  private formatErrors(error: any): Observable<never> {
    // todo Логика обработки ошибок
    throw error;
  }
}
