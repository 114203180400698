import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionsService } from 'src/app/core/services/permissions.service';
import { AppState } from 'src/app/core/store/models/state';
import { Store } from '@ngrx/store';
import { selectUser } from 'src/app/core/store/selectors/selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: '[appHasPermission]',
  standalone: true,
})
export class HasPermissionDirective implements OnInit {
  private currentPermission = '';

  @Input() set appHasPermission(permission: string) {
    this.currentPermission = permission;
  }
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionsService: PermissionsService,
    private store: Store<AppState>,
  ) {}

  ngOnInit() {
    this.store
      .select(selectUser)
      .pipe(untilDestroyed(this))
      .subscribe((user) => {
        if (user) {
          if (this.permissionsService.hasPermission(this.currentPermission, user.role)) {
            this.viewContainer.createEmbeddedView(this.templateRef);
          } else {
            this.viewContainer.clear();
          }
        }
      });
  }
}
