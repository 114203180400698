import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AppKeyValueLabels } from 'src/app/core/models/common-components.model';

@Component({
  selector: 'app-key-value-block',
  templateUrl: './app-key-value-block.component.html',
  styleUrl: './app-key-value-block.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppKeyValueBlockComponent {
  @Input() title?: string;
  @Input() source: any;
  @Input() labels!: AppKeyValueLabels[];
}
