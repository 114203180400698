<ng-container *ngIf="organization$ | async as organization">
  <ng-container *ngIf="user$ | async as user">
    <ng-container *let="enrollmentShort$ | async as enrollmentShort">
      <div class="app-organization-account">
        <div class="app-organization-account__title">
          <span>Organization Account</span>
          @if (
            (user.role === ROLES.ORG_ADMIN &&
              (organization.status === OrganizationStatusDto.PENDING ||
                organization.status === OrganizationStatusDto.ONBOARDING)) ||
            enrollmentShort
          ) {
            <ng-container *appIsDeactivatedOrganizationUser>
              <app-button
                *appHasPermission="PERMISSIONS.enrollment.canView"
                [type]="btnTypes.Flat"
                [disabled]="enrollBtnDisabled(enrollmentShort, organization, user)"
                [label]="getEnrollmentLabel(enrollmentShort,  user)"
                (onClick)="goToEnrollment()"
              ></app-button>
            </ng-container>
          }
        </div>
        <div class="app-organization-account__content">
          <div class="column">
            <app-organization-summary></app-organization-summary>
          </div>

          <div class="column">
            <app-shipping-address-list></app-shipping-address-list>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
