import { Injectable } from '@angular/core';
import { ROLE_PERMISSIONS, ROLES } from 'src/app/core/consts/roles-and-permissions';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  hasPermission(permission: string, role: ROLES): boolean {
    return ROLE_PERMISSIONS[role].includes(permission);
  }
}
