import { Injectable, OnDestroy } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { filter } from 'rxjs';
import { untilDestroyed } from '@ngneat/until-destroy';

@Injectable({
  providedIn: 'root',
})
export class FormUtilsService {
  constructor() {}

  setupControlSpaceCheck(
    control: AbstractControl,
    controlName: string,
    lastProcessedValues: { [key: string]: string },
    componentInstance: OnDestroy,
  ) {
    control.valueChanges
      .pipe(
        filter((value) => value !== lastProcessedValues[controlName]),
        untilDestroyed(componentInstance),
      )
      .subscribe((value) => {
        const newValue = value.replace(/\s+/g, '');
        lastProcessedValues[controlName] = newValue;
        control.setValue(newValue, { emitEvent: false });
      });
  }
}
