<div automation-id="app-npi-validation" class="app-npi-validation">
  <div class="app-npi-validation__form" [formGroup]="form">
    <div *let="searchResult$ | async" class="app-npi-validation__form-npi">
      <app-input
        automation-id="npi-field"
        formControlName="npi"
        label="NPI Number"
        [mask]="npiMask"
      ></app-input>
      @if (this.form.controls.npi.hasError('custom')) {
        <mat-error class="npi-error" automation-id="npi-field-error">{{
          this.form.controls.npi.getError('custom')
        }}</mat-error>
      }
    </div>
  </div>
</div>
