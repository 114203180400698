import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { Observable } from 'rxjs';
import {
  AppointmentDto,
  AppointmentsOverlappingDtoRes,
  AppointmentsReminderDtoRes,
  DeleteAppointmentRequestBodyDto,
  DispenseAppointmentRequestBodyDto,
  GetAppointmentResponseDto,
  GetProspectAppointmentResponseDto,
  RescheduleAppointmentRequestBodyDto,
  ScheduleAppointmentRequestBodyDto,
  ScheduleAppointmentResponseDto,
} from 'src/app/core/models/appointment.model';
import { ApiService } from 'src/app/core/services/api.service';
import { format } from 'date-fns/format';
import { GetPatientsResponseDto } from '../../../core/models/patients.model';

@Injectable({
  providedIn: 'root',
})
export class AppointmentsApiService extends ApiService {
  getAppointments$(
    orgId: number,
    page = 1,
    sortOrder: SortDirection = 'desc',
    orderBy: string | null,
    status: string[] | null,
    patientsIds: string[] | null,
    dateFrom: Date | null,
    dateTo: Date | null,
    limit = 20,
  ): Observable<GetAppointmentResponseDto> {
    const data = {
      page: page || 1,
      sortOrder: sortOrder || 'desc',
      orderBy: orderBy || 'id',
      limit,
    };

    let httpParams = new HttpParams();
    for (let key in data) {
      let k = key as keyof typeof data;
      httpParams = httpParams.append(key, data[k]);
    }

    if (status) {
      for (let s of status) {
        httpParams = httpParams.append('status', s);
      }
    }

    if (patientsIds) {
      for (let s of patientsIds) {
        httpParams = httpParams.append('patientsIds', s);
      }
    }

    if (dateFrom) {
      httpParams = httpParams.append('fromDate', format(dateFrom, 'yyyy-M-d'));
    }

    if (dateTo) {
      httpParams = httpParams.append('toDate', format(dateTo, 'yyyy-M-d'));
    }

    return this.get(`/organizations/${orgId}/appointments`, httpParams);
  }

  getProspectAppointments$(
    orgId: number,
    page = 1,
    patientsIds: string[] | null = null,
    limit = 2,
  ): Observable<GetProspectAppointmentResponseDto> {
    const data = {
      page: page || 1,
      limit,
    };

    let httpParams = new HttpParams();
    for (let key in data) {
      let k = key as keyof typeof data;
      httpParams = httpParams.append(key, data[k]);
    }
    if (patientsIds) {
      for (let s of patientsIds) {
        httpParams = httpParams.append('patientsIds', s);
      }
    }

    return this.get(`/organizations/${orgId}/patients/prospect-appointments`, httpParams);
  }

  scheduleAppointment$(
    orgId: number,
    body: ScheduleAppointmentRequestBodyDto,
  ): Observable<ScheduleAppointmentResponseDto> {
    return this.post(`/organizations/${orgId}/appointments`, body);
  }

  rescheduleAppointment$(
    orgId: number,
    body: RescheduleAppointmentRequestBodyDto,
  ): Observable<void> {
    return this.patch(`/organizations/${orgId}/appointments/${body.id}/reschedule`, {
      visitDate: body.visitDate,
      locationId: body.locationId,
    });
  }

  dispenseAppointment$(
    orgId: number,
    body: DispenseAppointmentRequestBodyDto,
  ): Observable<AppointmentDto> {
    return this.patch(`/organizations/${orgId}/appointments/${body.id}/dispense`, {
      visitDate: body.visitDate,
      locationId: body.locationId,
    });
  }

  deleteAppointment$(
    orgId: number,
    body: DeleteAppointmentRequestBodyDto,
  ): Observable<AppointmentDto> {
    return this.delete(`/organizations/${orgId}/appointments/${body.id}`);
  }

  getAppointmentPatients$(
    orgId: number,
    excludeUpcomingAppointments = false,
  ): Observable<GetPatientsResponseDto> {
    let httpParams = new HttpParams();
    if (excludeUpcomingAppointments) {
      httpParams = httpParams.set('excludeUpcomingAppointments', 'true');
    }
    httpParams = httpParams.set('page', 0);

    return this.get(`/organizations/${orgId}/patients`, httpParams);
  }

  countAppointments$(orgId: number): Observable<number> {
    return this.get(`/organizations/${orgId}/appointments/count`);
  }

  checkForReminder$(orgId: number, patientId: number): Observable<AppointmentsReminderDtoRes> {
    const httpParams = new HttpParams().set('patientId', patientId);
    return this.get(`/organizations/${orgId}/appointments/reminder`, httpParams);
  }

  checkForScheduledAppointments$(
    orgId: number,
    patientId: number,
    date: Date,
    appointmentId?: number,
  ): Observable<AppointmentsOverlappingDtoRes> {
    let httpParams = new HttpParams()
      .set('patientId', patientId)
      .set('scheduleDate', format(date, 'yyyy-M-d'));
    if (appointmentId) {
      httpParams = httpParams.set('appointmentId', appointmentId);
    }
    return this.get(`/organizations/${orgId}/appointments/overlapping`, httpParams);
  }
}
