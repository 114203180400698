<ng-container *ngIf="user$ | async as user">
  <div class="app-header" automation-id="header-component">
    <div class="app-header__left-side">
      <img
        automation-id="logo"
        src="assets/logo.png"
        class="app-header__logo"
        alt="Cipla logo"
        (click)="toHomePage(user)"
      />
      <div class="app-header__breadcrumbs">
        <app-breadcrumbs></app-breadcrumbs>
      </div>
    </div>
    <div class="app-header__right-side">
      <div class="app-header__profile" [matMenuTriggerFor]="menu" automation-id="menu-btn">
        {{ user.firstName[0] + user.lastName[0] }}
      </div>
      <div class="app-header__role">
        <div
          class="app-header__role-point"
          [ngClass]="{ _admin: user.role === ROLES.ADMIN || user.role === ROLES.SUPER_ADMIN }"
        ></div>
        {{ user.role | roleToLabel }}
      </div>
    </div>
  </div>

  <mat-menu #menu="matMenu">
    <button
      mat-menu-item
      disabled
      [matTooltip]="user.firstName + ' ' + user.lastName"
      automation-id="user-name-btn"
    >
      <span class="user-name">{{ user.firstName + ' ' + user.lastName }}</span>
    </button>
    <hr class="divider" />
    <ng-container *let="organization$ | async as organization">
      <button
        *ngIf="showProfileBtn(user, organization)"
        automation-id="profile-btn"
        mat-menu-item
        (click)="navigateToProfile(user)"
      >
        <span>Profile</span>
      </button>
    </ng-container>
    <button mat-menu-item (click)="logout()" automation-id="logout-btn">
      <span>Logout</span>
    </button>
  </mat-menu>
</ng-container>
