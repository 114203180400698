<div class="app-personal-details" automation-id="app-personal-details">
  <div class="app-personal-details__title">Contact Person</div>
  <div class="app-personal-details__form" [formGroup]="form">
    <div class="app-personal-details__form-row">
      <app-input
        automation-id="first-name-field"
        class="app-personal-details__form-control"
        label="First Name"
        formControlName="firstName"
      ></app-input>
      <app-input
        automation-id="last-name-field"
        class="app-personal-details__form-control"
        label="Last Name"
        formControlName="lastName"
      ></app-input>
    </div>
    <div class="app-personal-details__form-row">
      <app-input
        *let="email$ | async"
        automation-id="email-field"
        class="app-personal-details__form-control"
        label="Login Email"
        formControlName="email"
        [maxLength]="128"
      ></app-input>
      <app-input
        automation-id="phone-field"
        class="app-personal-details__form-control"
        label="Mobile Phone Number"
        formControlName="phoneNumber"
        [mask]="phoneMask"
        [prefix]="prefix"
      ></app-input>
    </div>
    <div class="app-personal-details__form-single-row">
      <app-textarea
        automation-id="message-field"
        label="Message"
        formControlName="message"
        [maxlength]="256"
      ></app-textarea>
    </div>
  </div>
</div>
