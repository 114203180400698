import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AppKeyValueLabels } from 'src/app/core/models/common-components.model';

@Component({
  selector: 'app-island',
  templateUrl: './app-island.component.html',
  styleUrl: './app-island.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class AppIslandComponent {
  @Input() hover?: boolean = undefined;
  @Input() title?: string;
  @Input() source: any;
  @Input() labels!: AppKeyValueLabels[];
}
