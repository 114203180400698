import { PatientMessagingType } from '../../modules/patients/utils/patients.utils';

export enum PatientStatusDto {
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
}

export enum GenderDto {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  UNDEFINED = 'UNDEFINED',
}

export enum ConsentStatusDto {
  ON_HOLD = 'ON_HOLD',
  OPTED_IN = 'OPTED_IN',
  OPTED_OUT = 'OPTED_OUT',
}

export interface PatientDto {
  gender: GenderDto;
  status: PatientStatusDto;
  consentStatus: ConsentStatusDto;
  id: number;
  emrEhrNumber: string | null;
  organizationId: number;
  firstName: string;
  lastName: string;
  fullName?: string;
  dateOfBirth: string;
  street: string | null;
  state: string | null;
  city: string | null;
  zip: string | null;
  email: string;
  mobilePhoneNumber: string;
  phoneNumber: string | null;
  createdAt: string;
  updatedAt: string;
  obtainedDate: string | null;
}

export interface AddPatientReqDto {
  gender: GendersEnum;
  emrEhrNumber?: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  street?: string;
  state?: string;
  city?: string;
  zip?: string;
  email: string;
  phoneNumber?: string;
  mobilePhoneNumber: string;
}

export interface AddPatientResDto {
  message: string;
  patientId: number;
}

export enum GendersEnum {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  UNDEFINED = 'UNDEFINED',
}

export interface GetPatientsResponseDto {
  total: number;
  page: number;
  pageSize: number;
  items: PatientDto[];
}

export interface GetPatientConsentTokenResponseDto {
  consentToken: string;
}

export interface UpdatePatientConsentStatusRequestDto {
  consentStatus: ConsentStatusDto;
  consentToken: string;
}

export interface SendPatientNotificationRequestDto {
  messagingType: PatientMessagingType;
  requestedUserId: number;
}
