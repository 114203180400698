import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SharedModule } from '../../../../shared/shared.module';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrl: './privacy-policy.component.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SharedModule],
})
export class PrivacyPolicyComponent {
  email = 'globalprivacy@cipla.com';
  public mailToLink = `mailto:${this.email}`;
  constructor(private dialogRef: MatDialog) {}

  close() {
    this.dialogRef.closeAll();
  }
}
