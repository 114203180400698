<div class="page" automation-id="app-organizations">
  <div class="header">
    <div class="filters">
      <app-multi-select
        *let="status$ | async"
        automation-id="status-field"
        class="status-filter"
        label="Status"
        [formControl]="statusFilter"
        [values]="items"
        optionIcon="icon"
        optionIconColor="iconColor"
        optionValue="id"
        (onSelect)="statusSelect($event)"
      >
      </app-multi-select>

      <ng-container *let="searchByName$ | async">
        <app-input
          *let="name$ | async"
          automation-id="name-field"
          class="search-filter"
          placeholder="Search by name"
          [formControl]="searchName"
          type="search"
          controlType="search"
        ></app-input>
      </ng-container>

      <div *let="actionRequiredInit$ | async" class="action-required">
        <app-checkbox *let="actionRequired$ | async" [formControl]="actionRequiredFilter">Action required</app-checkbox>
      </div>
    </div>

    <app-button
      automation-id="create-btn"
      icon="add"
      class="add-btn"
      [type]="btnTypes.Flat"
      label="Add Organization"
      [size]="btnSizes.FullHeight"
      (onClick)="addOrganization()"
    ></app-button>
  </div>

  <app-organizations-list></app-organizations-list>
</div>
