<div class="app-registration" automation-id="registration-page">
  <app-logo></app-logo>
  <div class="app-registration__card">
    <app-card>
      <div class="header" automation-id="title">
        Setup your account by completing the Organization Account Form
      </div>
      <div class="subheader" automation-id="subtitle">The required fields are marked with *</div>
      <div class="content app-registration__card-content">
        <app-stepper #cdkStepper automation-id="progress-stepper">
          <cdk-step label="1 NPI Validation">
            <div class="app-registration__step-content">
              <div class="app-registration__step-info">
                <ng-container [ngTemplateOutlet]="policyAlert"></ng-container>
              </div>
              <app-npi-validation automation-id="step-content"></app-npi-validation>
            </div>
            <ng-container [ngTemplateOutlet]="stepperActions"></ng-container>
          </cdk-step>
          <cdk-step label="2 Organization Details">
            <div class="app-registration__step-content">
              <div class="app-registration__step-info">
                <ng-container [ngTemplateOutlet]="policyAlert"></ng-container>
              </div>
              <app-organization-details automation-id="step-content"></app-organization-details>
            </div>
            <ng-container [ngTemplateOutlet]="stepperActions"></ng-container>
          </cdk-step>
          <cdk-step label="3 Personal Details">
            <div class="app-registration__step-content">
              <div class="app-registration__step-info">
                <ng-container [ngTemplateOutlet]="policyAlert"></ng-container>
              </div>
              <app-personal-details automation-id="step-content"></app-personal-details>
              <div class="app-registration__step-alert">
                <app-alert [type]="alertTypes.Info" [showIcon]="false">
                  The Organization Admin role will be automatically assigned to the designated
                  Contact Person
                </app-alert>
              </div>
            </div>
            <ng-container [ngTemplateOutlet]="stepperActions"></ng-container>
          </cdk-step>
        </app-stepper>
      </div>
    </app-card>
  </div>
</div>

<ng-template #policyAlert>
  <app-alert automation-id="alert" [type]="alertTypes.Info">
    This LeuPRO Logix solution was created by Cipla Inc. and it is designed to provide self-service
    access to healthcare professionals looking for medical information about Cipla products. It is
    intended for healthcare professionals only. Use and access of this solution are subject to the
    terms and conditions set out in our
    <a (click)="navigateToPrivacyPage()">Legal Statement</a> and
    <a (click)="navigateToPrivacyPage()">Privacy Notice</a>.
  </app-alert>
</ng-template>

<!--todo move to stepper-->
<ng-template #stepperActions>
  <div automation-id="buttons-block" class="app-registration__step-actions">
    <div class="app-registration__step-cancel">
      <app-button
        automation-id="cancel-btn"
        [type]="btnTypes.Stroked"
        label="Cancel"
        (onClick)="cancel()"
      ></app-button>
    </div>
    <div *let="registering$ | async as registering" class="app-registration__step-navigation">
      <app-button
        automation-id="back-btn"
        *ngIf="cdkStepper?.selectedIndex !== 0"
        [type]="btnTypes.Stroked"
        label="< Back"
        (onClick)="back()"
      ></app-button>
      <app-button
        automation-id="continue-btn"
        *ngIf="cdkStepper?.selectedIndex !== cdkStepper.steps.length - 1; else submitBtn"
        [type]="btnTypes.Flat"
        label="Continue"
        [disabled]="checkValidity(cdkStepper.selectedIndex, registering)"
        (onClick)="next(cdkStepper.selectedIndex)"
      ></app-button>
      <ng-template #submitBtn>
        <app-button
          automation-id="submit-btn"
          [type]="btnTypes.Flat"
          label="Submit"
          [disabled]="checkValidity(cdkStepper.selectedIndex, registering)"
          (onClick)="submit()"
        ></app-button>
      </ng-template>
    </div>
  </div>
</ng-template>
