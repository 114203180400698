import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AppButtonSize, AppButtonType } from '../../../../core/models/common-components.model';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { filter, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { OrganizationActions } from '../../store/actions';
import { fromOrganizations } from '../../store/selectors';
import { isPresent } from '../../../../core/utils/isPresent';
import { first } from 'rxjs/operators';
import { allStates } from '../../../../core/consts/states';
import { OrganizationStatusFactory } from '../../../../core/factories/organization-status.factory';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ALL_DROPDOWN_ITEM } from 'src/app/core/utils/orders.util';
import { AppRoutes, OrganizationsRoutes } from 'src/app/core/consts/navigation.const';

@UntilDestroy()
@Component({
  selector: 'app-organizations',
  templateUrl: './organizations.component.html',
  styleUrl: './organizations.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationsComponent implements OnInit {
  protected readonly btnTypes = AppButtonType;
  protected readonly btnSizes = AppButtonSize;

  searchName = new FormControl<string>('', { nonNullable: true });

  items = [ALL_DROPDOWN_ITEM, ...this.organizationStatusFactory.getAllItemsAsArray()];
  statusFilter = new FormControl<string[]>([ALL_DROPDOWN_ITEM.id]);
  actionRequiredFilter = new FormControl<boolean>(false, { nonNullable: true });

  name$ = this.store$.select(fromOrganizations.selectSearchName).pipe(
    first(),
    filter(isPresent),
    tap((name) => this.searchName.setValue(name, { emitEvent: false })),
  );

  status$ = this.store$.select(fromOrganizations.selectSearchStatus).pipe(
    first(),
    filter(isPresent),
    tap((status) => this.statusFilter.setValue(status, { emitEvent: false })),
  );

  actionRequiredInit$ = this.store$.select(fromOrganizations.selectActionRequired).pipe(
    first(),
    tap((value) => this.actionRequiredFilter.setValue(value, { emitEvent: false })),
  );

  actionRequired$ = this.actionRequiredFilter.valueChanges.pipe(
    tap((actionRequired) => {
      this.store$.dispatch(OrganizationActions.setActionRequired({ actionRequired }));
    }),
  );

  searchByName$ = this.searchName.valueChanges.pipe(
    tap((name) => {
      this.store$.dispatch(OrganizationActions.setSearchName({ name: name.trimStart() }));
      this.store$.dispatch(OrganizationActions.setSearchPage({ page: 1 }));
      this.searchName.setValue(name.trimStart(), { emitEvent: false });
    }),
  );

  constructor(
    private readonly router: Router,
    private readonly store$: Store,
    private readonly organizationStatusFactory: OrganizationStatusFactory,
  ) {}

  addOrganization() {
    this.router.navigate([`${AppRoutes.ORGANIZATIONS}/${OrganizationsRoutes.CREATE}`]);
  }

  ngOnInit(): void {
    this.store$.dispatch(OrganizationActions.getOrganizations());
  }

  protected readonly states = allStates;

  statusSelect(status: string[]): void {
    this.store$.dispatch(OrganizationActions.setSearchStatus({ status }));
  }
}
