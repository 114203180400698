import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppInputComponent } from 'src/app/shared/components/app-input/app-input.component';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { AngularMaterialModule } from 'src/app/modules/angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppCardComponent } from './components/app-card/app-card.component';
import { AppDialogComponent } from './components/app-dialog/app-dialog.component';
import { AppStepperComponent } from './components/app-stepper/app-stepper.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { AppAlertComponent } from './components/app-alert/app-alert.component';
import { AppCheckboxComponent } from './components/app-checkbox/app-checkbox.component';
import { AppKeyValueBlockComponent } from './components/app-key-value-block/app-key-value-block.component';
import { AppTextareaComponent } from './components/app-textarea/app-textarea.component';
import { AppDropdownComponent } from './components/app-dropdown/app-dropdown.component';
import { AppRadioComponent } from './components/app-radio/app-radio.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { AppStatusComponent } from './components/app-status/app-status.component';
import { AppSlideToggleComponent } from './components/app-slide-toggle/app-slide-toggle.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SafeHtmlPipe } from '../core/pipes/safe-html.pipe';
import { AppMultiSelectComponent } from './components/app-dropdown/app-multi-select/app-multi-select.component';
import { AppLabelComponent } from './components/app-label/app-label.component';
import { AppAddressStatusComponent } from './components/app-address-status/app-address-status.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AppNativeCheckboxComponent } from 'src/app/shared/components/app-native-checkbox/app-native-checkbox.component';
import { AppInlineDialogComponent } from './components/app-inline-dialog/app-inline-dialog.component';

@NgModule({
  declarations: [
    AppInputComponent,
    AppButtonComponent,
    AppCardComponent,
    AppDialogComponent,
    AppStepperComponent,
    AppAlertComponent,
    AppCheckboxComponent,
    AppKeyValueBlockComponent,
    AppTextareaComponent,
    AppDropdownComponent,
    AppRadioComponent,
    AppStatusComponent,
    AppSlideToggleComponent,
    AppMultiSelectComponent,
    AppLabelComponent,
    AppAddressStatusComponent,
    AppNativeCheckboxComponent,
    AppInlineDialogComponent,
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    CdkStepperModule,
    NgxMaskDirective,
    NgxMaskPipe,
    MatSlideToggleModule,
    SafeHtmlPipe,
    FormsModule,
    MatTooltipModule,
  ],
  exports: [
    AppInputComponent,
    AppButtonComponent,
    AppCardComponent,
    AppStepperComponent,
    AppAlertComponent,
    AppCheckboxComponent,
    AppKeyValueBlockComponent,
    AppTextareaComponent,
    AppDropdownComponent,
    AppRadioComponent,
    AppStatusComponent,
    AppSlideToggleComponent,
    AppMultiSelectComponent,
    AppMultiSelectComponent,
    AppLabelComponent,
    AppAddressStatusComponent,
    AppNativeCheckboxComponent,
    AppInlineDialogComponent,
  ],
  providers: [provideNgxMask()],
})
export class SharedModule {}
