import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store/models/state';
import { map, Observable, take } from 'rxjs';
import { selectUser } from 'src/app/core/store/selectors/selectors';
import { PermissionsService } from 'src/app/core/services/permissions.service';
import { AppRoutes } from 'src/app/core/consts/navigation.const';

@Injectable({ providedIn: 'root' })
export class PermissionGuard implements CanActivate {
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private permissionsService: PermissionsService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const requiredPermission = route.data['requiredPermission'];
    return this.store.select(selectUser).pipe(
      map((user) => {
        if (
          user &&
          user.role &&
          this.permissionsService.hasPermission(requiredPermission, user.role)
        ) {
          return true;
        } else {
          this.router.navigate([
            user ? `${AppRoutes.ORGANIZATIONS}/${user.organizationId}` : AppRoutes.ORGANIZATIONS,
          ]);
          return false;
        }
      }),
      take(1),
    );
  }
}
