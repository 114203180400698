import { AddressStatusDto } from '../models/organization.model';

export function getAddressColorByStatus(
  status: AddressStatusDto | string | undefined,
  isDefault = false,
) {
  switch (status) {
    case AddressStatusDto.ACTIVE: {
      return isDefault ? '#000000' : '#255CA3';
    }

    case AddressStatusDto.DELETED: {
      return '#000000';
    }
    case AddressStatusDto.DEACTIVATED: {
      return '#00000026';
    }
    case AddressStatusDto.DECLINED: {
      return '#00000026';
    }
    default: {
      return '#000000';
    }
  }
}
