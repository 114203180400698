import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AppRoutes } from 'src/app/core/consts/navigation.const';

@Injectable()
export class ForbiddenInterceptor implements HttpInterceptor {
  private urlsToInclude: string[] = ['/organizations'];

  constructor(private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          this.urlsToInclude.some((url) => req.url.includes(url)) &&
          error.status === 403
        ) {
          this.router.navigate([AppRoutes.LOGIN]);
        }
        return throwError(() => error);
      }),
    );
  }
}
