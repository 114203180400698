<div>
  <h1>Terms of Use</h1>
  <p>
    These Terms of Use (“Terms of Use”) govern the terms and conditions of the use by the individual
    user (“User”) of the right to use (a) www.leuprologix.com ( the “Website”); and (b) other
    content (that includes without limitation news, webcasts, reference tools and applications,
    sponsored programming, personalized content, continuing medical education, communication tools
    and discussion board) and updates thereto made available by Cipla USA, Inc. (“Cipla”)
    (collectively referred to as, “Cipla Information”). By accessing, using and browsing this
    Information, it is deemed that the User has read, understood and accepted, without limitation or
    qualification, the terms and conditions set out in the Terms of Use herein. Although accessible
    in other parts of the world, the Information is intended for access and use by U.S residents
    only.
  </p>
  <p>
    <strong>Use of Information and Restrictions.</strong> User may not use, copy, modify, or
    transfer the Information, or any part thereof, in whole or in part, except as expressly provided
    in these Terms of Use. User may not reverse engineer, disassemble, decompile, or translate the
    Information, or otherwise attempt to derive the source code of the Information, or authorize any
    third party to do any of the foregoing. The User is prohibited from developing, selling or
    distributing applications that are capable of launching, being launched from, or are otherwise
    integrated with, the Information without the express written consent of Cipla. User shall not
    rent, lease, loan, resell for profit, distribute, or sublicense the Information, or any part
    thereof. The User shall not alter any Information, post or transmit any unlawful, threatening,
    libelous, defamatory, obscene, scandalous, inflammatory, or profane material that could
    constitute or encourage conduct that would be considered a criminal offense, give rise to a
    civil liability or otherwise violate any law. Cipla will cooperate with any law enforcement
    authorities or court order requesting or directing Cipla to disclose the identity of anyone
    posting such information or materials.
  </p>
  <p>
    <strong>Ownership.</strong> The information that appears on the Website, including Cipla
    Information, is the property of Cipla and others and is protected by copyright and/or other
    intellectual property laws. The Cipla Information is only licensed to the User for use only
    under the terms of these Terms of Use, and Cipla reserves all rights not expressly granted to
    User. Any information, including protected health information (PHI), as defined under applicable
    law, entered into the Website by the User shall be owned and protected in accordance with a
    Business Associate Agreement or other agreement as entered into between the User and Cipla for
    the purposes of the provision of services by Cipla.
  </p>
  <p>
    <strong>Warranty; Disclaimer.</strong> The Cipla Information is provided to the User “as is”.
    Except as expressly provided under these Terms of Use, Cipla and its affiliates, agents and
    licensors: (a) cannot and do not warrant the accuracy, completeness, currency or
    non-infringement of the Information provided hereunder; and (b) expressly disclaim all
    warranties and conditions, express, implied or statutory, including without limitation the
    implied warranties or conditions of merchantability, fitness for a particular purpose, and
    non-infringement. The User’s reliance upon the Cipla Information is solely at the User’s own
    risk. Cipla does not assume any liability or responsibility for damage or injury (including
    death) to the User, other persons or property arising from any use of the Cipla Information,
    material, idea or instruction contained in the Information.
  </p>
  <p>
    Cipla Information concerning the Products. Products listed on the Website may not be available
    in all countries or may be available under a different brand name, in different strengths, or
    for different indications. Many of the products listed are available only by prescription
    through your local health care professional. Except as expressly stated and agreed upon in
    advance by Cipla, no director, employee, agent, or representative of Cipla, its subsidiaries and
    affiliates are engaged in rendering medical advice, diagnosis, treatment or other medical
    services that in any way create a physician-patient relationship.
  </p>
  <p>
    <strong>No Advice.</strong> Nothing contained in the Cipla Information should be construed as
    medical, legal, investment, financial or other advice. Without limitation, nothing contained in
    the Cipla Information should replace medical advice, medical visits, or recommendations from
    health care providers.
  </p>
  <p>
    <strong>Medical Disclaimer.</strong> The Cipla Information does not endorse drugs, diagnose
    patients, or recommend therapy. The Information is for use by Healthcare Professionals only for
    an academic purpose; and any personal material submitted into this Information is hypothetical
    and is used only for academic purposes and not for any type of professional treatment. Cipla
    does not assume any responsibility for any aspect of healthcare administered or not administered
    with the aid of the Cipla Information. The Information is not intended to be a substitute for
    professional advice for any patients and is not intended to replace personal consultation with a
    qualified physician, pharmacist, and healthcare professional or academic faculty responsible for
    the training of the User. In the event that the User has, or suspects to have a medical problem
    or condition, the User is bound to contact a qualified healthcare provider immediately. The User
    shall not disregard medical advice or delay in seeking such advice because of something the User
    has read or accessed on this Information.
  </p>
  <p>
    <strong>Linking to Us:</strong> The User must first seek written permission from Cipla if the
    User intends to use the Cipla Information or incorporate portions of the Cipla Information into
    a different site. The User may not link to Cipla nor use Cipla products or Cipla Information for
    the publication or promotion of illegal, obscene, or offensive content, or for the purposes of
    causing harm to the reputation of Cipla, its business or its products and services.
  </p>
  <p>
    <strong>Access to Our Services.</strong> Cipla’s services, including full access to its Website
    and products, require multi-factor authentication for each individual User. Users are strictly
    prohibited from sharing their unique usernames, passwords, or other authentication information
    provided by Cipla with any other User. Cipla disclaims any liability for any unauthorized access
    to any of Cipla’s services or due to User’s sharing passwords or authentication information.
  </p>
  <p>
    <strong>User Information:</strong> Notwithstanding the User’s use of Cipla’s Website to enter,
    upload or convey confidential patient information in accordance with these Terms, any other type
    of communication or material that the User transmits to or posts by electronic mail or
    otherwise, including any data, questions, comments, suggestions (“User Information”)to Cipla’s
    Website, is and will be treated as non-confidential and non-proprietary information and shall
    become the property of Cipla. Cipla, or any its affiliates, may store or use the User
    Information for any purpose whatsoever, including but not limited to, reproduction, disclosure,
    transmission, publication, broadcast or further posting, without any compensation to the User.
    The User also expressly acknowledges that Cipla, or any of its affiliates, are free to store,
    use any ideas, concepts, know-how or techniques contained in any User Information, for any
    purpose whatsoever, including but not limited to, developing, manufacturing and marketing
    products, without any compensation to the User. In case of a change in control of Cipla, Cipla
    reserves the right to transfer the User Information to the new party.
  </p>
  <p>
    If the User leaves this Website and goes to another website, different terms apply and Cipla has
    no responsibility or liability for any content on those sites.
  </p>
  <p>
    <strong>Confidentiality of PHI.</strong> To the extent User enters information about any
    patient, including EMR/HER ID numbers, User understands that such information may be considered
    PHI and may be protected under law. User understands and agrees to treat all such information
    confidentially and in accordance with any Business Associate Agreement that has been executed
    between Cipla and User.
  </p>
  <p>
    <strong>Indemnity, Assumption of Risk.</strong> The User assumes all risk for selection and use
    of the Cipla Information and content provided thereon. Although care has been taken to ensure
    completeness and accuracy of the content, Cipla shall not be responsible for any errors,
    misstatements, inaccuracies or omissions regarding content delivered through the Information or
    any delays in or interruptions of such delivery.
  </p>
  <p>
    <strong>The User acknowledges that Cipla:</strong> (a) has no control of or responsibility for
    the User’s use of the Cipla Information or content provided herein, (b) has no knowledge of the
    specific or unique circumstances under which the Cipla Information or content provided thereon
    may be used by the User, (c) undertakes no obligation to supplement or update content of the
    Cipla Information, and (d) has no liability to any person for any data or material, including
    personal and identifiable material, input on the Information by persons other than Cipla.
  </p>
  <p>
    Cipla shall not be liable to any person (including but not limited to the User and persons
    treated by or on behalf of the User) for, and the User agrees to indemnify and hold Cipla, its
    affiliates, directors, employees and agents, harmless from any claims, lawsuits, proceedings,
    costs, attorneys’ fees, damages or other losses (collectively, “Losses”) arising out of or
    relating to (a) the User’s use of the Cipla Information or content provided thereon or any
    equipment furnished in connection therewith, (b) User’s misuse unauthorized disclosure of any
    confidential information, including PHI, that User may enter into Cipla’s Website or products,
    and (b) any data or material input on the service by User, in all cases including but not
    limited to losses for tort, personal injury, medical malpractice or product liability. Further,
    without limiting the foregoing, in no event shall Cipla be liable for any special, incidental,
    consequential, or indirect damages, including damages for loss of profits, loss of business,
    even if Cipla has been advised of the possibility of such damages.
  </p>
  <p>
    <strong>Limitation of Liability.</strong> Neither Cipla nor its affiliates, agents or licensors
    shall be liable under any claim, demand or action arising out of or relating to the User’s use
    of the Information, nor Cipla’s performance of (or failure to perform) any obligation under
    these Terms of Use, nor for direct, special, incidental or consequential damages, including,
    without limitation, damages due to lost profits or business interruption, or other damages, even
    if Cipla, its affiliates, agents or licensors have been advised of the possibility of such loss
    or damages and whether or not such loss or damages is/are foreseeable and notwithstanding the
    failure of essential purpose of any limited remedy. In no event shall Cipla, its affiliates,
    agents or licensors be liable to the User under these Terms of Use for any reason whatsoever.
  </p>
  <p>General.</p>
  <p>
    11.1&#xa0;<strong>Governing Law and Dispute Resolution.</strong>&#xa0;These Terms of Use and the
    legal relations between the parties arising hereunder shall be governed by and interpreted
    exclusively in accordance with the laws of Delaware. Any dispute, controversy or claim arising
    out of or relating to these Terms of Use shall be settled exclusively by reference to courts of
    the State of Delaware. By using this website, the User agrees to personal jurisdiction and venue
    in the states and federal courts in Wilmington, Delaware and waives any objection to such
    jurisdiction and venue. EXCEPT WHERE PROHIBITED BY LAW, THE USER EXPRESSLY WAIVES TRIAL BY JURY
    IN ANY JUDICIAL PROCEEDING INVOLVING ANY DISPUTE, CONTROVERSY, OR CLAIM ARISING OUT OF OR
    RELATING TO THE INFORMATION.
  </p>
  <p>
    11.2&#xa0;<strong>Amendment.</strong>&#xa0;Cipla shall be entitled to, at any time, without
    prior written notice to the User, modify these Terms of Use. The User is bound by any such
    revisions and should therefore periodically visit this page to review the most updated Terms of
    Use to which the User is bound.
  </p>
  <p>
    11.3&#xa0;<strong>Term and Termination.</strong>&#xa0;These Terms of Use shall apply until the
    earlier of (a) the termination of the agreement between Cipla and User in accordance with the
    terms thereof and (b) the termination of services for cause in accordance with the terms hereof.
    The rights and obligations of the Parties which, by their nature, should survive termination of
    these Terms, shall survive such termination or expiration.
  </p>
  <p>
    Upon Cipla’s or User’s knowledge of a material breach of these Terms of Use by the other Party,
    the non-breaching Party shall notify the breaching Party of such material breach. If such
    material breach is not cured within 60 days of the breaching Party’s receipt of such notice (or
    within such longer period as the non-breaching Party may agree), or if cure is not possible, the
    non-breaching Party may terminate the related Cipla services on written notice to the breaching
    Party.
  </p>
  <p>
    11.3&#xa0;<strong>Notices and Take Down Procedures.</strong>&#xa0;Any notices to Cipla shall be
    in writing and delivered personally or by internationally reputed courier service to: 9100 S.
    Dadeland Blvd., Suite 1500, Miami, FL 33156, United States of America. If the User has any
    queries in respect of the Information or believes that the Information (or any part thereof)
    infringe any copyright, the User may request removal of those materials (or access thereto) from
    the Information contacting Cipla at
    <a [href]="mailToLink">{{ email }}</a>
    provided that such User shall provide Cipla with (a) details of the copyrighted work that is
    alleged to be infringed, (b) its location, (c) its URL (d) A statement that the User has a good
    faith belief that the complained of use of the materials is not authorized by the copyright
    owner, its agent, or the law, (e) A statement that the information that the User has supplied is
    accurate, and indicating that such statement is “under penalty of perjury” (f) A signature or
    the electronic equivalent from the copyright holder or authorized representative (g) or any
    other pertinent information that will allow Cipla to locate and confirm the authenticity of the
    material.
  </p>
  <p>
    11.5&#xa0;<strong>Severability.</strong>&#xa0;If any part of these Terms of Use is for any
    reason found to be unenforceable, all other parts nevertheless remain enforceable as long as a
    party’s rights under these Terms of Use are not materially affected. If any part of these Terms
    of Use is held by a court or other tribunal of competent jurisdiction to be void or
    unenforceable, such provisions will be limited or eliminated to the minimum extent necessary and
    replaced with a valid provision that best embodies the intent of these Terms of Use so that
    these Terms of Use will remain in full effect.
  </p>
</div>
