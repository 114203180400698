import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AppKeyValueLabels } from 'src/app/core/models/common-components.model';
import { FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { fromOrganizations } from '../../../../organizations/store/selectors';
import { filter, tap } from 'rxjs';
import { allStates } from '../../../../../core/consts/states';
import { OrganizationDataDto } from '../../../../../core/models/organization.model';
import { formatStringToPhone } from '../../../../../core/utils/formatStringToPhone';
import { convertFullAddress } from '../../../../../core/utils/convertFullAddress';
import { phoneNumberPrefix, phonePrefixMask, zipCodeMask } from 'src/app/core/consts/masks';

@Component({
  selector: 'app-organization-details',
  templateUrl: './organization-details.component.html',
  styleUrl: './organization-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationDetailsComponent {
  states = allStates;
  organizationDataInputMode = [
    { id: 1, name: 'I certify that the provided information is true and accurate' },
    { id: 2, name: `I would like to enter my organization's information manually` },
  ];
  public form = this.fb.group({
    npi: this.fb.control(
      {
        value: '',
        disabled: true,
      },
      {
        validators: [Validators.required],
        nonNullable: true,
      },
    ),
    name: this.fb.control<string>('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    phoneNumber: this.fb.control<string>('', {
      validators: [Validators.required, Validators.minLength(10)],
      nonNullable: true,
    }),
    street: this.fb.control<string>('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    city: this.fb.control<string>('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    state: this.fb.control<string>('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    countryCode: this.fb.control<string>('US', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    country: this.fb.control<string>('United States', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    zip: this.fb.control<string>('', {
      validators: [Validators.required, Validators.minLength(5)],
      nonNullable: true,
    }),
  });
  mode = this.fb.control(this.organizationDataInputMode[0].id);

  public labelsDataMock: AppKeyValueLabels[] = [
    {
      label: 'NPI Number',
      field: 'npi',
    },
    {
      label: 'Organization Name',
      field: 'name',
    },
    {
      label: 'Address',
      field: 'address',
    },
    {
      label: 'Phone',
      field: 'phone',
    },
  ];

  postalCodeMask = zipCodeMask;
  prefix = phoneNumberPrefix;
  phoneMask = phonePrefixMask;
  searchResult!: OrganizationDataDto;

  searchResult$ = this.store$.select(fromOrganizations.selectLastOrganizationSearchResult).pipe(
    filter((value) => Boolean(value)),
    tap((value) => {
      if (value) {
        this.form.patchValue(value);
        this.searchResult = value;
      }
    }),
  );

  organizationDataMockValue(searchResult: OrganizationDataDto) {
    return {
      npi: searchResult.npi,
      name: searchResult.name,
      address: convertFullAddress({ ...searchResult }),
      phone: formatStringToPhone(searchResult.phoneNumber),
    };
  }

  constructor(
    private fb: FormBuilder,
    private readonly store$: Store,
  ) {}
}
