export enum AppButtonType {
  Basic = 'basic',
  Raised = 'raised',
  Stroked = 'stroked',
  Flat = 'flat',
  Icon = 'icon',
  Fab = 'fab',
  Rounded = 'rounded',
}

export enum AppButtonSize {
  Basic = 'basic',
  FullWidth = 'fullWidth',
  Small = 'small',
  ExtraSmall = 'extraSmall',
  FullHeight = 'fullHeight',
}

export enum AppLabelColors {
  ORANGE = 'orange',
  RED = 'red',
  BLUE = 'blue',
  GREY = 'grey',
}

export interface DialogData {
  title?: string;
  content: string;
  actions: string[];
  disableClose?: boolean;
}

export enum AppAlertType {
  Info = 'info',
  Error = 'error',
  Warning = 'warning',
}

export interface AppKeyValueLabels {
  label: string;
  field: string;
}

export interface DropDownItem {
  id: string;
  name: string;
}

export interface TwoLinesDropDownItem {
  id: string;
  name: string;
  secondLine: string;
}

export interface DropDownItemNumber {
  label: string;
  value: number;
}

export interface RequestCallReqDto {
  name: string;
  phoneNumber: string;
  subject?: string | null;
}

export interface FileFormControlValue {
  file: File | Blob | null | undefined | string;
  id?: number;
}
