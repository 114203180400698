import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './components/login/login.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { ResetComponent } from './components/reset/reset.component';
import { AuthRoutingModule } from 'src/app/modules/auth/auth-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LogoComponent } from './components/logo/logo.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { AppStepperComponent } from 'src/app/shared/components/app-stepper/app-stepper.component';
import { NpiValidationComponent } from './components/registration/npi-validation/npi-validation.component';
import { OrganizationDetailsComponent } from './components/registration/organization-details/organization-details.component';
import { PersonalDetailsComponent } from './components/registration/personal-details/personal-details.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { LetDirective } from '../../core/utils/let-directive';
import { MfaVerificationComponent } from 'src/app/modules/auth/components/mfa-verification/mfa-verification.component';
import { HasPermissionDirective } from 'src/app/core/directives/has-permission.directive';
import { BlockedAccountComponent } from './components/blocked-account/blocked-account.component';

@NgModule({
  declarations: [
    LoginComponent,
    RegistrationComponent,
    ResetComponent,
    LogoComponent,
    NpiValidationComponent,
    OrganizationDetailsComponent,
    PersonalDetailsComponent,
    ChangePasswordComponent,
    MfaVerificationComponent,
    BlockedAccountComponent,
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    CdkStepperModule,
    MatFormFieldModule,
    MatIconModule,
    LetDirective,
    HasPermissionDirective,
  ],
  providers: [AppStepperComponent],
})
export class AuthModule {}
