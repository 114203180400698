<div class="app-get-support">
  <div class="app-get-support__title">
    <span>Get Support</span>
    <mat-icon class="material-icons-outlined" (click)="close()">close</mat-icon>
  </div>
  <div class="app-get-support__subtitle">
    Please provide your details below and the support team will contact you soon
  </div>
  <div class="app-get-support__content" [formGroup]="form">
    <div class="app-get-support__content-control">
      <app-input formControlName="name" label="Full Name"></app-input>
    </div>
    <div class="app-get-support__content-control">
      <app-input
        formControlName="phoneNumber"
        label="Phone number"
        [mask]="phonePrefixMask"
        [prefix]="phoneNumberPrefix"
      ></app-input>
    </div>
    <div class="app-get-support__content-control">
      <app-textarea formControlName="subject" label="Type your request subject"></app-textarea>
    </div>
  </div>
  <div class="app-get-support__actions">
    <app-button
      [type]="btnTypes.Flat"
      label="Request Call"
      class="app-get-support__submit"
      [disabled]="form.invalid"
      [size]="btnSizes.FullWidth"
      (onClick)="requestCall()"
    ></app-button>
  </div>
</div>
