<div>
  <h1>PRIVACY NOTICE</h1>
  <p>
    Cipla USA, Inc. and its North American affiliates (collectively, “Cipla”) is providing this
    notice (“Privacy Notice”) to explain our online information practices and the choices you can
    make about the way your information is collected and used. This Privacy Notice applies to the
    use and collection of data collected on our website, www.cipla.com (the “Site”) and or directly
    from you or your healthcare provider with your consent. As used in this Privacy Notice, “we,”
    “our” and “us” means Cipla, and its related companies and affiliates.
  </p>
  <p>
    <strong>Sources of Personal Information We Collect Directly From You</strong>
  </p>
  <p>
    We may collect and process Personal Information through our Site, including through automated
    means as further described in the “Cookies and Online Tracking” section of this Privacy Notice.
    We may also collect Personal Information that you voluntarily choose to give us by sending us an
    email, contacting us by telephone, filling out a survey, or entering Personal Information on our
    Site.
  </p>
  <p>Specifically, we may collect the following Personal Information about you:</p>
  <ul>
    <li>
      <strong>Identifiers:</strong> such as first and last name, signature, email address, telephone
      number, unique online identifiers, IP address
    </li>
    <li>
      <strong>Demographic Information:</strong> Protected Classification Characteristics: age and
      medical conditions
    </li>
    <li>
      <strong>Health Information:</strong> such as medical condition and treatment and appointments
      with healthcare providers
    </li>
    <li>
      <strong>Content of communications:</strong> such as information you post on our Sites or
      social media pages and information you provide through communications with us
    </li>
    <li>
      <strong>Internet or other similar network activity:</strong> such as device information,
      browser information, browsing history, search history, information on a consumer's interaction
      with a website, application, or advertisement
    </li>
    <li>
      <strong>Professional or employment-related information:</strong> such as your employment
      history collected when you apply for employment with us
    </li>
    <li>
      <strong>Education information:</strong> such as educational background when you apply for
      employment with us
    </li>
    <li>
      <strong>Geolocation data:</strong> such as information about where your device is located when
      you visit our Site or use our services
    </li>
    <li>
      <strong>Inferences drawn from other Personal Information:</strong> such as a profile
      reflecting a person's online or content preferences
    </li>
    <li>
      <strong>Sensitive Personal Information:</strong> certain of the information listed in the
      categories above constitutes sensitive Personal Information including health information,
      social security number, and racial or ethnic origin. We do not use sensitive Personal
      Information for purposes of inferring characteristics about a consumer.
    </li>
  </ul>
  <p>
    We may also maintain certain Personal Information that has been de-identified and is therefore
    no longer Personal Information. We commit not to attempt to reidentify such information.
  </p>
  <p><strong>Patient Information Collected Through LeuPROLogix</strong></p>

  <p>
    If you are a patient and your healthcare provider uses our proprietary web-based application,
    LeuPROLogix for appointment scheduling and patient engagement management, your protected health
    information (PHI) may be processed on the LeuPROLogix platform pursuant to Cipla’s business
    associate agreement with the healthcare provider. Please contact your healthcare provider for
    more information about how your PHI is processed and your privacy rights under applicable laws.
  </p>
  <p>
    <strong>Use of Personal Information</strong>
  </p>
  <p>
    We will only use your personal data for specified purposes as provided in this Privacy Notice or
    as otherwise disclosed to you, including for one or more of the following purposes:
  </p>
  <ul>
    <li>To fulfil a contract we have with you, or</li>
    <li>If we have a legal duty to use your data for a particular reason, or</li>
    <li>When you consent to it, or</li>
    <li>When it is in our legitimate interests.</li>
  </ul>
  <p>
    Legitimate interests are our business or commercial reasons for using your data, but even so, we
    will not unfairly put our legitimate interests above what is best for you. The use of your
    information is subject to the Privacy Notice in effect at the time of our use. Cipla uses
    information provided to us for our general business use. This may include the following
    purposes:
  </p>
  <ul>
    <li>To respond to your requests;</li>
    <li>To provide services to you, including through your healthcare provider;</li>
    <li>
      To send communications to you about our or our affiliates’ current services, new services or
      promotions that we are developing, and opportunities that may be available to you;
    </li>
    <li>To alert you to new features or enhancements to our services;</li>
    <li>
      To communicate with you about job or career opportunities about which you have inquired;
    </li>
    <li>To ensure that our Site and our services function in an effective manner for you;</li>
    <li>To measure or understand the effectiveness of advertising and outreach;</li>
    <li>
      Legal obligations: We may be required to use and retain Personal Information for legal and
      compliance reasons, such as the prevention, detection, or investigation of a crime; loss
      prevention; or fraud. We may also use Personal Information to meet our internal and external
      audit requirements, information security purposes, and as we otherwise believe to be necessary
      or appropriate;
    </li>
    <li>Under applicable law, which may include laws outside your country of residence;</li>
    <li>
      To respond to requests from courts, law enforcement agencies, regulatory agencies, and other
      public and government authorities, which may include such authorities outside your country of
      residence.
    </li>
  </ul>
  <p>
    We endeavor to collect only such information that is reasonably necessary to perform services
    for you or to respond to your inquiries. You are responsible for ensuring that the information
    you provide directly to us is accurate, complete and current; we will take reasonable steps to
    ensure that the information we collect and use is relevant to its intended use.
  </p>
  <p><strong>Sharing of Personal Information</strong></p>
  <p>We may disclose your Personal Information to the following third parties:</p>
  <ul>
    <li>
      <strong>Third Party Service Providers.</strong> We may share Personal Information with third
      parties who provide us with services, such as those who assist us with technology, data
      analysis, pharmacovigilance, or marketing. We do not share information with unaffiliated third
      parties for their own marketing purposes.
    </li>
    <li>
      <strong>Business Partners.</strong> We may share Personal Information with business partners
      with whom we collaborate regarding our investigational products and programs, such as
      scientific advisors, joint venture partners and strategic collaboration partners, specialty
      pharmacies, insurers, manufacturers, and healthcare professionals.
    </li>
    <li>
      <strong>Acquirers or Assignees.</strong> In the event of a reorganization, merger, sale, joint
      venture, assignment, transfer or other disposition of all or any portion of Cipla or its
      assets, we may transfer any and all Personal Information that we collect to the acquiring
      party or assignee.
    </li>
    <li>
      <strong>Entities Entitled Under Law.</strong> We may disclose Personal Information as we
      believe appropriate in the following circumstances: (i) when required by applicable law,
      including laws outside your country of residence; (ii) to comply with legal process; (iii) to
      respond to requests from public and government authorities; (iv) to enforce the terms and
      conditions for use of the Sites, including this Policy; (v) to protect and defend our rights
      and property; (vi) to protect the interests of Cipla or others; and (vii) to permit us to
      pursue available remedies or limit the damages that we may sustain. We have no obligation to
      notify you of such disclosures except as required by applicable law.
    </li>
  </ul>
  <p>
    Cipla does not sell Personal Information for monetary value, but certain of our digital
    advertising activities may constitute a “sale” or “sharing” of Personal Information for
    cross-context behavioral advertising with our third party advertising partners, as those terms
    are defined under certain state privacy laws. The categories of Personal Information that may be
    shared in this way include Identifiers, Internet or Other Similar Network Activity, and
    Geolocation Data. See further information below in the section titled “State Privacy Rights.”
    Cipla does not knowingly sell any Personal Information of children under the age of 16.
  </p>
  <p><strong>Cookies and Online Tracking</strong></p>
  <p>
    The Site may automatically collect some information about your use of the Site, some of which
    may be Personal Information (e.g., your IP address). This data is collected via browser
    navigational tools, such as cookies, local shared objects (“flash cookies”), pixel tags, and
    similar technologies that are employed on the Sites.
  </p>
  <p>
    Cookies are small data files that a website stores on the hard drive of the device through which
    a website user connects to the website. You can set your browser to accept all cookies, to
    reject all cookies, or to notify you whenever a cookie is offered so that you can decide each
    time whether to accept it. However, refusing a cookie may in some cases preclude you from using,
    or negatively affect the display or function of, a website or certain areas or features of a
    website. To learn more about cookies, please visit
    <a [href]="'https://allaboutcookies.org/'">http://www.allaboutcookies.org</a>.
  </p>
  <p>
    By visiting the Site, you are deemed to unambiguously agree to its use of any cookies that you
    do not disable. For information regarding state opt-out rights, please see the “Your Rights and
    Choices” section below.
  </p>
  <p>
    We use performance and analytics firms including but not limited to Google Analytics and Google
    Search Console, which employ cookies and similar technologies on the Sites, so that we can
    compile statistics about our daily number of Site visitors, specific page requests, or other
    patterns of use on the Sites. To opt-out of Google Analytics, please visit:
    <a [href]="'https://tools.google.com/dlpage/gaoptout'"
      >https://tools.google.com/dlpage/gaoptout</a
    >. The Sites currently do not respond to a “Do Not Track” browser signal from a visitor’s
    computer or other connecting device.
  </p>
  <p><strong>Adverse Event Reporting</strong></p>
  <p>
    Please visit
    <a [href]="'https://www.cipla.com/dsafe.html'">https://www.cipla.com/dsafe.html</a> to access
    Cipla’s global pharmacovigilance privacy policy and learn how we collect and use personal data
    to help us fulfill our pharmacovigilance obligations.
  </p>
  <p><strong>Opting Out of Marketing Communications</strong></p>
  <p>
    Cipla does not support “spamming", which is defined as sending unsolicited emails, usually of a
    commercial nature, in large numbers and repeatedly to individuals with whom the sender has had
    no previous contact or who have declined to receive such communications. In accordance with your
    preferences or interests communicated to us, Cipla may send periodic emails to you to the email
    address provided by you through our Site during registration. You may choose to opt out of
    receiving such emails through a link included in the footer of any of the emails you receive
    from us.
  </p>
  <p><strong>Surveys</strong></p>
  <p>
    We may provide you the opportunity to participate in surveys on our Site. If you participate, we
    will request certain personally identifiable information from you. Participation in these
    surveys is completely voluntary and you therefore have a choice whether or not to disclose this
    information. The requested information typically includes contact information (such as name and
    email address), and demographic and/or health information (such as zip code and health condition
    information).
  </p>
  <p><strong>Other Websites</strong></p>
  <p>
    This Privacy Notice applies only to Cipla's website. Cipla may provide links to other websites,
    which we believe may be of interest to you. Cipla is not responsible for the content on such
    third-party website, your access to such website links, security of Personal Information that
    you provide or any information collected by that website. The risk of accessing such websites is
    solely yours. Please visit the third-party website privacy policy to receive information about
    how that website and its operator collect and process Personal Information about you.
  </p>
  <p><strong>Protecting Your Personal Information</strong></p>
  <p>
    Cipla will maintain adequate technical and organizational security measures to protect your
    personally identifiable information. As a policy, Cipla secures each web page that collects
    Personal Information; however, the confidentiality of Personal Information transmitted over the
    Internet will not be guaranteed. We urge you to exercise caution when transmitting Personal
    Information over the Internet.
  </p>
  <p><strong>Retention of Your Personal Information</strong></p>
  <p>
    Cipla will maintain your Personal Information as long as is reasonably deemed required for
    legal, contractual, or business purposes. During these periods, we will take appropriate steps
    to ensure that the privacy of your Personal Information is maintained.
  </p>
  <p><strong>Special Note Regarding Website Use for Children</strong></p>
  <p>
    We do not knowingly collect or solicit Personal Information from anyone under the age of 13.
    This website is not intended or designed to attract children. If you are under 13, please do not
    send any Personal Information about yourself to us. If you believe that we might have any
    information from or about a child under 13, please contact us at
    <a [href]="'https://www.ciplausa.com/contact-us'">https://www.ciplausa.com/contact-us</a> and we
    will delete such information.
  </p>
  <p><strong>Location Services</strong></p>
  <p>
    Some services on this Site may make use of location-based data. If you allow these services, we
    may collect information about the Wi-Fi routers closest to you or the cell IDs of the towers
    closest to you. We may use this information to provide the requested service or to provide you
    content based upon your location. Although we may use or collect geo-location data, we are not
    capable of assisting in an emergency.
  </p>
  <p><strong>Your Rights and Choices</strong></p>
  <p>
    Depending on your residence, the rights available to you may differ in some respects. Cipla will
    respond to any rights and requests in accordance with local legal regulations. In some cases,
    state laws require us to verify the identity of the individual submitting a request regarding
    their Personal Information before providing a substantive response to the request. Consistent
    with those requirements, and because we take the privacy and security of your Personal
    Information seriously, we will verify your identity by asking you to provide certain information
    about yourself. Once your identity is verified, we will work to provide you with your requested
    information in a timely manner. If you choose to empower an “authorized agent” to submit
    requests on your behalf, we will require the authorized agent to have a written authorization
    confirming that authority. We will not discriminate against you for exercising your privacy
    rights.
  </p>
  <p>
    The California Consumer Privacy Act of 2018 (“CCPA”) provides California residents with certain
    rights with respect to their Personal Information. If you are a California resident, you may
    exercise the following rights regarding your Personal Information, subject to certain exceptions
    and limitations:
  </p>
  <ul>
    <li>
      The right to know the categories and specific pieces of Personal Information we collect, use,
      disclose about you, the categories of sources from which we collected your Personal
      Information, our purposes for collecting or selling your Personal Information, the categories
      of your Personal Information that we have disclosed for a business purpose, and the categories
      of third parties with which we have sold, shared, or disclosed your Personal Information.
    </li>
    <li>
      The right to request that we delete the Personal Information we have collected from you or
      maintain about you.
    </li>
    <li>
      The right to request that we correct the Personal Information we have collected from you or
      maintain about you.
    </li>
    <li>
      The right to opt-out of the sale or sharing of your Personal Information for purposes of
      cross-context behavioral advertising.
    </li>
    <li>
      California’s Shine the Light Law also permits California residents to request certain
      information regarding our disclosure of their Personal Information to third parties for their
      direct marketing purposes.
    </li>
  </ul>
  <p>
    In addition, residents of Connecticut are entitled to exercise the following rights with respect
    to your consumer health data, subject to certain exceptions:
  </p>
  <ul>
    <li>
      The rights to access, data portability, deletion, and correction of their consumer health
      data.
    </li>
    <li>The right to opt-out of targeted advertising based on consumer health data.</li>
    <li>
      The right to appeal any decision we make regarding your request, and to contact the Attorney
      General to submit a complaint.
    </li>
  </ul>
  <p>
    For further information regarding our handling of consumer health data, and the privacy rights
    of Washington and Nevada residents, please review our Consumer Health Data Privacy Policy. For
    methods to exercise your privacy rights, please see the “Contacting Us” section below. To
    exercise the right to opt-out of sale or sharing of Personal Information or use of Personal
    Information for targeted advertising, please click on the following link: Do Not Sell or Share
    My Personal Information. Additionally, depending on your state of residence, you may be able to
    communicate your opt-out request by broadcasting the Global Privacy Control (“GPC”) signal from
    your browser. You can learn more about the GPC signal here. Please note that if you use the GPC
    signal, your request to opt-out will only apply to the browser from which you broadcast the
    signal.
  </p>
  <p><strong>Changes to this Privacy Notice</strong></p>
  <p>
    We reserve the right to amend this Privacy Notice at our discretion. When changes are made to
    this Privacy Notice we will post the updated policy and the date the last revisions were made.
    We encourage you to periodically review this page for the most current Privacy Notice.
  </p>
  <p><strong>Feedback</strong></p>
  <p>
    Any complaints or concerns with regards to the processing of Personal Information provided by
    you or breach of these terms must be reported to
    <a [href]="mailToLink">{{ email }}</a>
  </p>
  <p><strong>Contact Us</strong></p>
  <p>
    If you have questions or comments regarding this privacy notice, please contact us at
    +1-888-912-9604, or at:
    <a [href]="'https://www.ciplausa.com/contact-us'">https://www.ciplausa.com/contact-us</a>.
  </p>
</div>
