<div
  *let="userNotification$ | async"
  automation-id="app-shipping-address-list"
  class="app-shipping-address-list"
>
  <ng-container *let="organization$ | async as organization">
    <div class="app-shipping-address-list__title">Ship-To Address Book</div>
    <div *ngIf="organization" class="app-shipping-address-list__addresses">
      <ng-container *ngFor="let address of addresses$ | async">
        <div class="island">
          <app-island
            [hover]="true"
            (click)="viewAddress(address)"
            [class.disabled]="address.status === AddressStatusDto.DEACTIVATED"
          >
            <div class="header">
              <div class="title">{{ address.customerName }}</div>

              <app-address-status
                class="status"
                [status]="address.status"
                [default]="address.isDefault"
              ></app-address-status>
            </div>
            <div class="content">{{ convertAddress(address) }}</div>
            <div class="buttons">
              @if (!address.isDefault && address.status === AddressStatusDto.ACTIVE) {
                <button
                  *appHasPermission="PERMISSIONS.organizationAccount.canDeactivateShipping"
                  mat-icon-button
                  [matMenuTriggerFor]="menu"
                  (click)="$event.stopPropagation(); $event.preventDefault()"
                >
                  <mat-icon>more_horiz</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button
                    mat-menu-item
                    *appHasPermission="PERMISSIONS.organizationAccount.canSetDefaultShipping"
                    (click)="setDefault(address, organization)"
                  >
                    <span>Set Default</span>
                  </button>
                  <button
                    *appHasPermission="PERMISSIONS.organizationAccount.canDeactivateShipping"
                    mat-menu-item
                    [disabled]="!canDeactivate()"
                    (click)="deactivate(address, organization)"
                  >
                    <span>Deactivate</span>
                  </button>
                  <button
                    mat-menu-item
                    *appHasPermission="PERMISSIONS.organizationAccount.canDeleteShipping"
                    [disabled]="!canDelete(address)"
                    (click)="delete(address, organization)"
                  >
                    <span class="delete">Delete</span>
                  </button>
                </mat-menu>
              }
              @if (!address.isDefault && address.status === AddressStatusDto.DEACTIVATED) {
                <button
                  *appHasPermission="PERMISSIONS.organizationAccount.canActivateShipping"
                  mat-icon-button
                  [matMenuTriggerFor]="menu"
                  (click)="$event.stopPropagation(); $event.preventDefault()"
                >
                  <mat-icon>more_horiz</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="activate(address, organization)">
                    <span>Activate</span>
                  </button>
                  <button
                    mat-menu-item
                    *appHasPermission="PERMISSIONS.organizationAccount.canDeleteShipping"
                    [disabled]="!canDelete(address)"
                    (click)="delete(address, organization)"
                  >
                    <span class="delete">Delete</span>
                  </button>
                </mat-menu>
              }
            </div>
          </app-island>
        </div>
      </ng-container>
    </div>

    @if (organization && organization.status === OrganizationStatusDto.ACTIVE) {
      <div class="app-shipping-address-list__add-address-btn">
        <ng-container *appIsDeactivatedOrganizationUser>
          <app-button
            *appHasPermission="PERMISSIONS.organizationAccount.canAddShipping"
            [type]="btnTypes.Basic"
            label="Request to Add New Ship-To"
            automation-id="add-address-btn"
            icon="add"
            [size]="btnSizes.Basic"
            (click)="addNewShippingAddress()"
          ></app-button>
        </ng-container>
      </div>
    }

    @if ((addresses$ | async)?.length === 0) {
      <app-island class="app-shipping-address-list__island">
        <div class="content">Please add at least 1 valid address to complete account setup</div>
      </app-island>
    }
  </ng-container>
</div>
