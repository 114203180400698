export const AppRoutes = {
  LOGIN: 'login',
  ORGANIZATIONS: 'organizations',
  ORGANIZATIONS_ORDERS: 'organizations-orders',
  ORDERS: 'orders',
  ORGANIZATIONS_INVENTORY: 'organizations-inventory',
  INVENTORY: 'inventory',
  STAFF: 'staff',
  SUPPORT: 'support',
  REPORTS: 'reports',
  PATIENTS: 'patients',
  CONSENT_FORM: 'consent-form',
  RETURN_GOODS_POLICY: 'return-goods-policy',
  CONSUMER_HEALTH_PRIVACY_POLICY: 'consumer-health-privacy-policy',
  STANDARD_PURCHASE_TERMS: 'standard-purchase-terms',
  TERMS_OF_USE: 'terms-of-use',
  PRIVACY_POLICY: 'privacy-policy',
  ADMINS_LIST: 'admins-list',
};

export const OrganizationsRoutes = {
  CREATE: 'create',
  ENROLLMENT: 'enrollment',
};

export const OrganizationOrdersRoutes = {
  CREATE: 'create',
};

export const StaffRoutes = {
  ADD: 'add',
  EDIT: 'edit',
  VIEW: 'view',
};

export const AuthRoutes = {
  BLOCKED: 'blocked',
  RESET_PASSWORD: 'reset-password',
  REGISTRATION: 'registration',
  SET_PASSWORD: 'set-password',
  MFA_VERIFICATION: 'mfa',
};

export const PatientsRoutes = {
  ADD: 'add',
  EDIT: 'edit',
  VIEW: 'view',
};
