@if (form) {
  <div class="app-enrollment-bill-to" [formGroup]="form">
    <div *ngIf="form.enabled" class="app-enrollment-bill-to__control">
      <app-checkbox formControlName="sameAsOrganization">Same as organization details</app-checkbox>
    </div>
    <div class="app-enrollment-bill-to__control">
      <app-input label="Customer Name" formControlName="customerName" [maxLength]="156"></app-input>
    </div>
    <div class="app-enrollment-bill-to__control">
      <app-input label="Street Name" formControlName="street" [maxLength]="256"></app-input>
    </div>
    <div class="app-enrollment-bill-to__row">
      <div class="app-enrollment-bill-to__control">
        <app-input label="City" formControlName="city" [maxLength]="156"></app-input>
      </div>
      <div class="app-enrollment-bill-to__control">
        <app-dropdown
          label="State"
          formControlName="state"
          showAbbreviation="id"
          optionValue="id"
          [values]="states"
        ></app-dropdown>
      </div>
      <div class="app-enrollment-bill-to__control">
        <app-input label="Zip Code" formControlName="zip" [mask]="zipCodeMask"></app-input>
      </div>
    </div>
    <div class="app-enrollment-bill-to__row">
      <div class="app-enrollment-bill-to__control">
        <app-input
          label="Account Payable Email"
          formControlName="email"
          [maxLength]="128"
        ></app-input>
      </div>
      <div class="app-enrollment-bill-to__control">
        <app-input label="Phone" formControlName="phoneNumber" [mask]="phoneMask"></app-input>
      </div>
      <div class="app-enrollment-bill-to__control">
        <app-input label="Fax" formControlName="fax" [mask]="faxMask"></app-input>
      </div>
    </div>
  </div>
}
