import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SharedModule } from '../../../../shared/shared.module';

@Component({
  selector: 'app-consumer-health-privacy-policy',
  templateUrl: './consumer-health-privacy-policy.component.html',
  styleUrl: './consumer-health-privacy-policy.component.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SharedModule],
})
export class ConsumerHealthPrivacyPolicyComponent {
  email = 'ciplaUSA@cipla.com';
  public mailToLink = `mailto:${this.email}`;

  constructor(private dialogRef: MatDialog) {}

  close() {
    this.dialogRef.closeAll();
  }
}
