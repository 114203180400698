export enum ROLES {
  SUPER_ADMIN = 'SUPER_ADMIN', // developer
  ADMIN = 'ADMIN', // Cipla admin
  ORG_ADMIN = 'ORG_ADMIN',
  ORG_USER = 'ORG_USER',
}

export const PERMISSIONS = {
  loginAndPassword: 'loginAndPassword:all',
  adminSpace: 'adminSpace:all',
  adminsList: 'adminsList:all',
  support: {
    canViewCiplaAdminGuide: 'ciplaAdminGuide:view',
    canViewOrgAdminGuide: 'orgAdminGuide:view',
    canViewUserGuide: 'userGuide:view',
  },
  organizationAccount: {
    canView: 'organizationAccount:view',
    canActivateDeactivate: 'organizationAccount:activate',
    canChangeAdmin: 'organizationAccount:changeAdmin',
    canAddShipping: 'organizationAccount:addShipping',
    canApproveShipping: 'organizationAccount:approveShipping',
    canSetDefaultShipping: 'organizationAccount:setDefaultShipping',
    canDeleteShipping: 'organizationAccount:deleteShipping',
    canDeclineShipping: 'organizationAccount:DeclineShipping',
    canActivateShipping: 'organizationAccount:activateShipping',
    canDeactivateShipping: 'organizationAccount:deactivateShipping',
  },
  organizationUsers: {
    canView: 'organizationUsers:view',
    canAdd: 'organizationUsers:add',
    canActivateDeactivate: 'organizationUsers:activate',
    canViewDetails: 'organizationUsers:viewDetails',
    canEdit: 'organizationUsers:edit',
    canResendInvite: 'organizationUsers:canResendInvite',
    canCancelInvite: 'organizationUsers:canCancelInvite',
  },
  organizationOrders: {
    canAdd: 'organizationOrders:add',
    canViewHistory: 'organizationOrders:viewHistory',
    canViewHistoryActionDelete: 'organizationOrders:viewHistory:actionDelete',
    canViewHistoryActionConfirm: 'organizationOrders:viewHistory:actionConfirm',
    canCancel: 'organizationOrders:cancel',
    canUpdateStatus: 'organizationOrders:updateStatus',
  },
  inventoryManagement: {
    canView: 'inventoryManagement:view',
    canAddItem: 'inventoryManagement:addItem',
    canViewAll: 'inventoryManagement:canViewAll',
  },
  organizationReports: {
    canView: 'organizationReports:view',
  },
  orders: {
    canView: 'orders:view',
  },
  organizations: {
    canView: 'organizations:view',
  },
  enrollment: {
    canView: 'enrollment:view',
    canEdit: 'enrollment:edit',
  },
  patients: {
    canView: 'patients:view',
    canAdd: 'patients:add',
    canEdit: 'patients:edit',
    canViewOne: 'patients:viewOne',
    canGenerateLink: 'patients:generateLink',
  },
};

export const ROLE_PERMISSIONS: { [key in ROLES]: Array<string> } = {
  [ROLES.SUPER_ADMIN]: [
    PERMISSIONS.support.canViewCiplaAdminGuide,
    PERMISSIONS.loginAndPassword,
    PERMISSIONS.adminSpace,
    PERMISSIONS.organizationAccount.canView,
    PERMISSIONS.organizationAccount.canActivateDeactivate,
    PERMISSIONS.organizationAccount.canAddShipping,
    PERMISSIONS.organizationAccount.canDeleteShipping,
    PERMISSIONS.organizationAccount.canSetDefaultShipping,
    PERMISSIONS.organizationAccount.canApproveShipping,
    PERMISSIONS.organizationAccount.canDeclineShipping,
    PERMISSIONS.organizationAccount.canActivateShipping,
    PERMISSIONS.organizationAccount.canDeactivateShipping,
    PERMISSIONS.organizationAccount.canChangeAdmin,
    PERMISSIONS.organizationUsers.canAdd,
    PERMISSIONS.organizationUsers.canActivateDeactivate,
    PERMISSIONS.organizationUsers.canEdit,
    PERMISSIONS.organizationUsers.canView,
    PERMISSIONS.organizationUsers.canViewDetails,
    PERMISSIONS.organizationUsers.canResendInvite,
    PERMISSIONS.organizationUsers.canCancelInvite,
    PERMISSIONS.organizationOrders.canCancel,
    PERMISSIONS.organizationOrders.canViewHistory,
    PERMISSIONS.organizationOrders.canUpdateStatus,
    PERMISSIONS.inventoryManagement.canView,
    PERMISSIONS.inventoryManagement.canViewAll,
    PERMISSIONS.organizationReports.canView,
    PERMISSIONS.organizations.canView,
    PERMISSIONS.orders.canView,
    PERMISSIONS.enrollment.canView,
    PERMISSIONS.patients.canView,
    PERMISSIONS.patients.canAdd,
    PERMISSIONS.patients.canEdit,
    PERMISSIONS.patients.canViewOne,
    PERMISSIONS.patients.canGenerateLink,
    PERMISSIONS.adminsList,
  ],
  [ROLES.ADMIN]: [
    PERMISSIONS.support.canViewCiplaAdminGuide,
    PERMISSIONS.loginAndPassword,
    PERMISSIONS.adminSpace,
    PERMISSIONS.organizationAccount.canView,
    PERMISSIONS.organizationAccount.canActivateDeactivate,
    PERMISSIONS.organizationAccount.canApproveShipping,
    PERMISSIONS.organizationAccount.canDeclineShipping,
    PERMISSIONS.organizationAccount.canChangeAdmin,
    PERMISSIONS.organizationUsers.canAdd,
    PERMISSIONS.organizationUsers.canActivateDeactivate,
    PERMISSIONS.organizationUsers.canEdit,
    PERMISSIONS.organizationUsers.canView,
    PERMISSIONS.organizationUsers.canViewDetails,
    PERMISSIONS.organizationUsers.canResendInvite,
    PERMISSIONS.organizationUsers.canCancelInvite,
    PERMISSIONS.organizationOrders.canCancel,
    PERMISSIONS.organizationOrders.canViewHistory,
    PERMISSIONS.organizationOrders.canUpdateStatus,
    PERMISSIONS.inventoryManagement.canViewAll,
    PERMISSIONS.organizationReports.canView,
    PERMISSIONS.organizations.canView,
    PERMISSIONS.orders.canView,
    PERMISSIONS.enrollment.canView,
    PERMISSIONS.patients.canView,
    PERMISSIONS.patients.canViewOne,
  ],
  [ROLES.ORG_ADMIN]: [
    PERMISSIONS.support.canViewOrgAdminGuide,
    PERMISSIONS.loginAndPassword,
    PERMISSIONS.organizationAccount.canView,
    PERMISSIONS.organizationAccount.canAddShipping,
    PERMISSIONS.organizationAccount.canDeleteShipping,
    PERMISSIONS.organizationAccount.canSetDefaultShipping,
    PERMISSIONS.organizationAccount.canActivateShipping,
    PERMISSIONS.organizationAccount.canDeactivateShipping,
    PERMISSIONS.organizationUsers.canAdd,
    PERMISSIONS.organizationUsers.canActivateDeactivate,
    PERMISSIONS.organizationUsers.canEdit,
    PERMISSIONS.organizationUsers.canView,
    PERMISSIONS.organizationUsers.canViewDetails,
    PERMISSIONS.organizationUsers.canResendInvite,
    PERMISSIONS.organizationUsers.canCancelInvite,
    PERMISSIONS.organizationOrders.canAdd,
    PERMISSIONS.organizationOrders.canCancel,
    PERMISSIONS.organizationOrders.canViewHistory,
    PERMISSIONS.organizationOrders.canViewHistoryActionDelete,
    PERMISSIONS.organizationOrders.canViewHistoryActionConfirm,
    PERMISSIONS.inventoryManagement.canView,
    PERMISSIONS.inventoryManagement.canAddItem,
    PERMISSIONS.organizationReports.canView,
    PERMISSIONS.enrollment.canView,
    PERMISSIONS.enrollment.canEdit,
    PERMISSIONS.patients.canView,
    PERMISSIONS.patients.canAdd,
    PERMISSIONS.patients.canEdit,
    PERMISSIONS.patients.canViewOne,
    PERMISSIONS.patients.canGenerateLink,
  ],
  [ROLES.ORG_USER]: [
    PERMISSIONS.support.canViewUserGuide,
    PERMISSIONS.loginAndPassword,
    PERMISSIONS.organizationAccount.canView,
    PERMISSIONS.organizationUsers.canViewDetails,
    PERMISSIONS.organizationOrders.canAdd,
    PERMISSIONS.organizationOrders.canCancel,
    PERMISSIONS.organizationOrders.canViewHistory,
    PERMISSIONS.organizationOrders.canViewHistoryActionDelete,
    PERMISSIONS.organizationOrders.canViewHistoryActionConfirm,
    PERMISSIONS.inventoryManagement.canView,
    PERMISSIONS.inventoryManagement.canAddItem,
    PERMISSIONS.patients.canView,
    PERMISSIONS.patients.canViewOne,
  ],
};
