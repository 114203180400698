import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Statuses } from 'src/app/core/consts/statuses';
import { BaseFactory } from '../../../core/factories/base.factory';

@Component({
  selector: 'app-status',
  templateUrl: './app-status.component.html',
  styleUrl: './app-status.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppStatusComponent {
  @Input() status!: Statuses | string;
  @Input() factory?: BaseFactory;

  public statuses = Statuses;

  get statusText(): string | undefined {
    return this.factory ? this.factory.getById(this.status).name : this.status?.toLowerCase();
  }

  get isGreen(): boolean | undefined {
    return (
      this.status === this.statuses.SUBMITTED ||
      this.status === this.statuses.ACTIVE ||
      this.status === this.statuses.OPENED ||
      this.status === this.statuses.OPTED_IN
    );
  }

  get isBlue(): boolean | undefined {
    return (
      this.status === this.statuses.PENDING ||
      this.status === this.statuses.ENROLLMENT ||
      this.status === this.statuses.IN_PROCESS
    );
  }
  get isLightBlue(): boolean | undefined {
    return (
      this.status === this.statuses.DELIVERED ||
      this.status === this.statuses.ONBOARDING ||
      this.status === this.statuses.ON_HOLD
    );
  }

  get isGray(): boolean | undefined {
    return (
      this.status === this.statuses.DISABLED ||
      this.status === this.statuses.DEACTIVATED ||
      this.status === this.statuses.CANCELLED
    );
  }

  get isRed(): boolean | undefined {
    return this.status === this.statuses.OPTED_OUT;
  }
}
