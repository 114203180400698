import { Injectable } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class ValidationErrorsService {
  constructor() {}

  public getControlErrorMessage(errors: ValidationErrors): string {
    if (errors['required']) {
      return 'Please fill in the required field(s)';
    }
    if (errors['emailFormat']) {
      return 'Wrong data format';
    }
    if (errors['custom']) {
      return '';
    }
    if (errors['passwordMismatch']) {
      return 'The password confirmation does not match';
    }
    if (errors['emailTaken']) {
      return 'This email is already taken';
    }
    return 'Wrong data format';
  }
}
