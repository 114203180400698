import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { AppAlertType, AppButtonType } from 'src/app/core/models/common-components.model';
import { Router } from '@angular/router';
import { CdkStepper } from '@angular/cdk/stepper';
import { NpiValidationComponent } from 'src/app/modules/auth/components/registration/npi-validation/npi-validation.component';
import { PersonalDetailsComponent } from 'src/app/modules/auth/components/registration/personal-details/personal-details.component';
import { FormGroup } from '@angular/forms';
import { OrganizationDetailsComponent } from './organization-details/organization-details.component';
import { Store } from '@ngrx/store';
import { registerActions } from '../../../../core/store/actions/actions';
import { selectRegistering } from '../../../../core/store/selectors/selectors';
import { formatPhoneToString } from '../../../../core/utils/formatPhoneToString';
import { MatDialog } from '@angular/material/dialog';
import { PrivacyPolicyComponent } from '../../../static-pages/components/privacy-policy/privacy-policy.component';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrl: './registration.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegistrationComponent {
  @ViewChild('cdkStepper') stepper!: CdkStepper;
  @ViewChild(NpiValidationComponent) npiValidationComponent!: NpiValidationComponent;
  @ViewChild(OrganizationDetailsComponent)
  organizationDetailsComponent!: OrganizationDetailsComponent;
  @ViewChild(PersonalDetailsComponent) personalDetailsComponent!: PersonalDetailsComponent;
  public alertTypes = AppAlertType;
  public btnTypes = AppButtonType;
  registering$ = this.store$.select(selectRegistering);

  constructor(
    private router: Router,
    private readonly store$: Store,
    private dialog: MatDialog,
  ) {}

  public checkValidity(selectedIndex: number, registering: boolean | null): boolean {
    switch (selectedIndex) {
      case 0: {
        return !this.isFormValid(this.npiValidationComponent?.form);
      }
      case 1: {
        return (
          this.organizationDetailsComponent.mode.value === 2 &&
          !this.isFormValid(this.organizationDetailsComponent?.form)
        );
      }
      case 2: {
        return registering || !this.isFormValid(this.personalDetailsComponent?.form);
      }
      default: {
        return false;
      }
    }
  }

  public cancel(): void {
    const pristine =
      this.npiValidationComponent?.form.pristine &&
      this.organizationDetailsComponent?.form.pristine &&
      this.personalDetailsComponent?.form.pristine;

    this.store$.dispatch(registerActions.registerCancel({ pristine }));
  }

  public back(): void {
    if (this.stepper?.selected) this.stepper.selected.completed = false;
    this.stepper.previous();
  }

  public next(selectedIndex: number): void {
    if (selectedIndex === 0) {
      this.npiValidationComponent.form.disable();
    }

    if (selectedIndex === 1) {
      this.organizationDetailsComponent.mode.disable();
    }
    if (this.stepper?.selected) this.stepper.selected.completed = true;
    this.stepper.next();
  }

  public submit(): void {
    const organization =
      this.organizationDetailsComponent.mode.value === 1
        ? this.organizationDetailsComponent.searchResult
        : this.organizationDetailsComponent.form.getRawValue();

    const user = this.personalDetailsComponent.form.getRawValue();

    this.store$.dispatch(
      registerActions.register({
        body: {
          user: {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            mobilePhoneNumber: formatPhoneToString(user.phoneNumber),
          },
          isNpiValidated: this.organizationDetailsComponent.mode.value === 1,
          message: user.message,
          organization: {
            ...organization,
            phoneNumber: formatPhoneToString(organization.phoneNumber),
          },
        },
      }),
    );
  }

  private isFormValid(form?: FormGroup): boolean {
    if (!form) {
      return false;
    }
    return form.disabled || (form.status !== 'PENDING' && form.valid);
  }

  navigateToPrivacyPage() {
    this.dialog.open(PrivacyPolicyComponent, {
      autoFocus: false,
      maxHeight: '90vh',
    });
  }
}
