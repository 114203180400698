<div
  *ngIf="searchResult$ | async as searchResult"
  class="app-organization-details"
  automation-id="app-organization-details"
  [formGroup]="form"
>
  @switch (mode.value) {
    @case (1) {
      <div class="app-organization-details__form">
        <app-key-value-block
          automation-id="key-value-block"
          class="app-organization-details__form-single-row"
          title="Organization Details"
          [source]="organizationDataMockValue(searchResult)"
          [labels]="labelsDataMock"
        ></app-key-value-block>
      </div>
    }
    @case (2) {
      <div automation-id="input-form" class="app-organization-details__form">
        <div automation-id="title" class="mat-subtitle-2">Organization Details</div>
        <div class="app-organization-details__form-row">
          <app-input
            automation-id="npi-field"
            class="app-organization-details__form-control"
            label="NPI Number"
            [maxLength]="10"
            formControlName="npi"
          ></app-input>
          <app-input
            automation-id="name-field"
            class="app-organization-details__form-control"
            label="Organization Name(LBN, DBA)"
            formControlName="name"
          ></app-input>
        </div>
        <div automation-id="address-title" class="mat-subtitle-2">Registered Address</div>
        <div class="app-organization-details__form-single-row">
          <app-input
            automation-id="address-field"
            formControlName="street"
            label="Street Address"
          ></app-input>
        </div>
        <div class="app-organization-details__form-row">
          <app-input
            automation-id="city-field"
            class="app-organization-details__form-control"
            label="City"
            formControlName="city"
          ></app-input>
          <app-dropdown
            automation-id="state-field"
            class="app-organization-details__form-control"
            label="State"
            formControlName="state"
            [values]="states"
            showAbbreviation="id"
            optionValue="id"
          >
          </app-dropdown>
        </div>
        <div class="app-organization-details__form-row">
          <app-input
            automation-id="postal-code-field"
            class="app-organization-details__form-control"
            label="ZIP (Postal) Code"
            formControlName="zip"
            [mask]="postalCodeMask"
          ></app-input>
          <app-input
            automation-id="phone-field"
            class="app-organization-details__form-control"
            label="Phone Number"
            formControlName="phoneNumber"
            [mask]="phoneMask"
            [prefix]="prefix"
          ></app-input>
        </div>
      </div>
    }
  }

  <div [ngClass]="{ hidden: mode.disabled }" class="app-organization-details__form">
    <div automation-id="radio-block-title" class="mat-subtitle-2">
      Please review the entered information before finalizing your submission
    </div>
    <app-radio
      automation-id="radio-block"
      [values]="organizationDataInputMode"
      [formControl]="mode"
    ></app-radio>
  </div>
</div>
