<div class="app-reset" automation-id="reset-password">
  <app-logo></app-logo>
  <div class="app-reset__card">
    <app-card>
      <div class="header">Reset Your Password</div>
      <div class="subheader">
        Enter your registered email address below, and we will send you a password reset link along
        with instructions to reset your password
      </div>
      <div class="content app-reset__card-content">
        <div class="app-reset__card-inputs" [formGroup]="form">
          <app-input
            automation-id="email-field"
            label="Email"
            formControlName="email"
            class="app-reset__card-email"
            [maxLength]="128"
          ></app-input>
        </div>
        <div class="app-reset__card-actions">
          <div class="app-reset__card-submit">
            <app-button
              automation-id="submit-btn"
              [type]="btnTypes.Flat"
              label="Submit"
              [disabled]="form.invalid"
              [size]="btnSizes.FullWidth"
              (onClick)="submit()"
            ></app-button>
          </div>
          <div class="app-reset__card-back">
            <app-button
              automation-id="back-btn"
              [type]="btnTypes.Stroked"
              label="Back to Login"
              [size]="btnSizes.FullWidth"
              routerLink="/login"
            ></app-button>
          </div>
        </div>
      </div>
    </app-card>
  </div>
</div>
