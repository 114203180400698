import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SharedModule } from '../../../../shared/shared.module';
import { customerData } from '../../../../core/consts/customer-data';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrl: './terms-of-use.component.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SharedModule],
})
export class TermsOfUseComponent {
  email = 'ciplaUSA@cipla.com';
  public mailToLink = `mailto:${this.email}`;
  constructor(private dialogRef: MatDialog) {}

  close() {
    this.dialogRef.closeAll();
  }

  protected readonly customerData = customerData;
}
