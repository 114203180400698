import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { allStates } from '../../../../../core/consts/states';
import { faxMask, phoneMask, zipCodeMask } from 'src/app/core/consts/masks';
import { AppAlertType } from '../../../../../core/models/common-components.model';
import { shipVia } from 'src/app/core/consts/form-values';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-enrollment-ship-to',
  templateUrl: './enrollment-ship-to.component.html',
  styleUrl: './enrollment-ship-to.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, SharedModule],
})
export class EnrollmentShipToComponent implements OnInit {
  @Input() form: FormGroup | undefined;
  protected readonly states = allStates;
  protected readonly zipCodeMask = zipCodeMask;
  protected readonly phoneMask = phoneMask;
  protected readonly faxMask = faxMask;
  protected readonly alertTypes = AppAlertType;
  protected readonly shipViaValue = shipVia;

  ngOnInit() {
    this.form
      ?.get('shippingProvider')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((value) => {
        const accountNumberControl = this.form?.get('accountNumber');
        accountNumberControl?.reset();
        if (value && value !== this.shipViaValue[0].id) {
          accountNumberControl?.enable();
        } else {
          accountNumberControl?.disable();
        }
      });
  }

  getAccountNumberMask(): string {
    return (
      this.shipViaValue.find((item) => item.id === this.form?.get('shippingProvider')?.value)
        ?.mask || ''
    );
  }
}
