import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import {
  AppAlertType,
  AppButtonSize,
  AppButtonType,
} from 'src/app/core/models/common-components.model';
import { ActivatedRoute, Router } from '@angular/router';
import { AppState } from 'src/app/core/store/models/state';
import { Store } from '@ngrx/store';
import { loginActions, logoutAction } from 'src/app/core/store/actions/actions';
import * as CustomValidators from 'src/app/core/utils/validators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { selectLoginError } from 'src/app/core/store/selectors/selectors';
import { filter, take } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { AppDialogComponent } from 'src/app/shared/components/app-dialog/app-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FormUtilsService } from 'src/app/core/services/form-utils.service';
import { PrivacyPolicyComponent } from '../../../static-pages/components/privacy-policy/privacy-policy.component';
import { AppInputComponent } from 'src/app/shared/components/app-input/app-input.component';
import { AutofillMonitor } from '@angular/cdk/text-field';
import { AppRoutes, AuthRoutes } from 'src/app/core/consts/navigation.const';
import { customerData } from '../../../../core/consts/customer-data';
import { compareExpDate } from '../../../../core/utils/compare-exp-date';
import { GetSupportComponent } from '../../../../core/components/get-support/get-support.component';
import { TermsOfUseComponent } from '../../../static-pages/components/terms-of-use/terms-of-use.component';

@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('emailInput') emailInput: AppInputComponent | undefined;
  @ViewChild('passwordInput') passwordInput: AppInputComponent | undefined;
  public btnTypes = AppButtonType;
  public btnSizes = AppButtonSize;
  public beError: HttpErrorResponse | null | undefined;
  public form = this.fb.group({
    email: [
      '',
      [Validators.required, Validators.maxLength(128), CustomValidators.emailFormatValidator()],
    ],
    password: ['', [Validators.required, Validators.maxLength(128)]],
  });
  public alertTypes = AppAlertType;
  public lastProcessedValues: { [key: string]: string } = {};
  public autofilled = {
    email: false,
    password: false,
  };

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private store: Store<AppState>,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private formUtilsService: FormUtilsService,
    private autofillMonitor: AutofillMonitor,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.store.dispatch(logoutAction());
    this.route.queryParamMap.pipe(untilDestroyed(this)).subscribe((params) => {
      const email = params.get('email');
      if (email) {
        this.form.get('email')?.setValue(email);
      }
      if (compareExpDate(params.get('exp'))) {
        this.navigateToLoginPage();
      }
    });
    this.store
      .select(selectLoginError)
      .pipe(
        filter((error) => !!error),
        untilDestroyed(this),
      )
      .subscribe((error) => {
        this.beError = error;
        this.form.setErrors({ invalidCredentials: true });
        this.cdr.markForCheck();
      });
    this.form.valueChanges.pipe(take(1), untilDestroyed(this)).subscribe(() => {
      this.autofilled.email = false;
      this.autofilled.password = false;
    });
    this.checkForSpaces();
  }

  ngAfterViewInit() {
    this.autofillMonitor.monitor(this.emailInput?.inputElement?.nativeElement).subscribe((e) => {
      if (e.isAutofilled) {
        this.autofilled.email = true;
      }
    });

    this.autofillMonitor.monitor(this.passwordInput?.inputElement?.nativeElement).subscribe((e) => {
      if (e.isAutofilled) {
        this.autofilled.password = true;
      }
    });
  }

  public submit(): void {
    this.beError = null;
    this.store.dispatch(
      loginActions.login({
        body: {
          email: this.form.value.email!,
          password: this.form.value.password!,
        },
      }),
    );
  }

  public registration(): void {
    this.router.navigate([`${AppRoutes.LOGIN}/${AuthRoutes.REGISTRATION}`]);
  }

  getSupport(): void {
    this.dialog.open(GetSupportComponent, {
      autoFocus: false,
      width: '392px',
      position: {
        bottom: '24px',
        right: '24px',
      },
    });
  }

  private showInactiveUserDialog(): void {
    // todo define when open it when backend ready
    this.dialog.open(AppDialogComponent, {
      data: {
        title: 'Your account is no longer active',
        content: `Your account is no longer active. Please contact customer support [admin email address] for assistance`,
        actions: ['Ok'],
      },
    });
  }

  private checkForSpaces(): void {
    const emailControl = this.form.get('email') as AbstractControl;
    const passwordControl = this.form.get('password') as AbstractControl;
    this.formUtilsService.setupControlSpaceCheck(
      emailControl,
      'email',
      this.lastProcessedValues,
      this,
    );
    this.formUtilsService.setupControlSpaceCheck(
      passwordControl,
      'password',
      this.lastProcessedValues,
      this,
    );
  }

  navigateToTermsOfUsePage() {
    this.dialog.open(TermsOfUseComponent, {
      autoFocus: false,
      maxHeight: '90vh',
    });
  }

  navigateToPrivacyPage() {
    this.dialog.open(PrivacyPolicyComponent, {
      autoFocus: false,
      maxHeight: '90vh',
    });
  }

  ngOnDestroy() {
    this.autofillMonitor.stopMonitoring(this.emailInput?.inputElement?.nativeElement);
    this.autofillMonitor.stopMonitoring(this.passwordInput?.inputElement?.nativeElement);
  }

  navigateToLoginPage() {
    const dialog = this.dialog.open(AppDialogComponent, {
      data: {
        title: 'The temporary password has expired',
        content: `Please, contact customer support at <a href="mailto:${customerData.email}">${customerData.email}</a>`,
        actions: ['Ok'],
      },
    });

    dialog
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.router.navigate([AppRoutes.LOGIN]);
      });
  }
}
