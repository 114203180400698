import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store/models/state';
import { map, Observable, take } from 'rxjs';
import { selectMfaData } from 'src/app/core/store/selectors/selectors';
import { AppRoutes } from 'src/app/core/consts/navigation.const';

@Injectable({ providedIn: 'root' })
export class MfaGuard implements CanActivate {
  constructor(
    private store: Store<AppState>,
    private router: Router,
  ) {}
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store.select(selectMfaData).pipe(
      map((mfaData) => {
        if (mfaData) {
          return true;
        } else {
          this.router.navigate([AppRoutes.LOGIN]);
          return false;
        }
      }),
      take(1),
    );
  }
}
