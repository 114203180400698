import { ChangeDetectionStrategy, Component, TemplateRef } from '@angular/core';
import { CdkStepper } from '@angular/cdk/stepper';

@Component({
  selector: 'app-stepper',
  templateUrl: './app-stepper.component.html',
  styleUrl: './app-stepper.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: CdkStepper, useExisting: AppStepperComponent }
  ]
})
export class AppStepperComponent extends CdkStepper {
  get selectedContent(): TemplateRef<any> | null {
    return this.selected?.content ?? null;
  }
  onClick(index: number): void {
    this.selectedIndex = index;
  }
}
