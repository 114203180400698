import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ValidationErrorsService } from 'src/app/shared/services/validation-errors.service';

@UntilDestroy()
@Component({
  selector: 'app-input',
  templateUrl: './app-input.component.html',
  styleUrl: './app-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppInputComponent implements ControlValueAccessor, OnInit {
  @Input() type: string = 'text';
  @Input() controlType: string = 'text';
  @Input() label: string | undefined;
  @Input() showErrors?: boolean = true;
  @Input() maxLength?: number = 256;
  @Input() readonly = false;
  @Input() mask = '';
  @Input() validation = false;
  @Input() patterns: any = '';
  @Input() prefix = '';
  @Input() placeholder: string = '';
  @Input() highlightAsError?: boolean = false;
  @Input() name: string = '';
  @Input() id: string = '';
  @Input() autocomplete?: string = '';
  @Input() min?: number;
  @Input() max?: number;
  @ViewChild('inputElement') inputElement: ElementRef | undefined;

  public hide: boolean = false;

  get control(): FormControl {
    return this.ngControl?.control as FormControl;
  }

  get isRequired(): boolean {
    const validator = this.control?.validator;
    if (typeof validator === 'function') {
      const result = validator({} as AbstractControl);
      return !!result && result.hasOwnProperty('required');
    }
    return false;
  }

  trimStart(event: Event) {
    if (event.target) {
      const input = event.target as HTMLInputElement;
      this.control.setValue(input.value.trimStart(), {
        emitEvent: false,
      });
    }
  }

  constructor(
    @Self() @Optional() public ngControl: NgControl,
    private validationErrorsService: ValidationErrorsService,
    private cdr: ChangeDetectorRef,
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {}

  public showHidePassword(): void {
    this.hide = !this.hide;
    if (this.type === 'password') {
      this.type = 'text';
    } else {
      this.type = 'password';
    }
  }

  public getErrorMessage(): string {
    if (this.control?.invalid && this.control?.errors) {
      return this.validationErrorsService.getControlErrorMessage(this.control.errors);
    }
    return '';
  }

  onChange: any = (value: string) => {};
  onTouched: any = () => {};

  public registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public writeValue(value: any): void {}

  public checkForChanges(): void {
    this.cdr.markForCheck();
  }
}
