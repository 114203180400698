import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Inject, Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { DialogsActions } from './actions';
import { MatDialog } from '@angular/material/dialog';
import { AppDialogComponent } from '../../../shared/components/app-dialog/app-dialog.component';
import { WINDOW } from '@ng-web-apis/common';

@Injectable()
export class FeatureEffects {
  private isDialogOpen = false;

  showDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DialogsActions.showDialog),
        tap(({ data, callback }) => {
          if (this.isDialogOpen) return;
          this.isDialogOpen = true;
          this.window.scroll({ top: 0 });
          const ref = this.dialog.open(AppDialogComponent, {
            data,
            disableClose: data.disableClose,
          });

          ref.afterClosed().subscribe((result) => {
            this.isDialogOpen = false;
            if (callback) {
              callback(result);
            }
          });
        }),
      ),
    { dispatch: false },
  );
  constructor(
    private readonly actions$: Actions,
    public dialog: MatDialog,
    @Inject(WINDOW) readonly window: Window,
  ) {}
}
