import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AppButtonSize, AppButtonType } from '../../../../core/models/common-components.model';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store/models/state';
import { forgotPasswordActions, registerActions } from 'src/app/core/store/actions/actions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { confirmPasswordValidator, customPasswordValidator } from 'src/app/core/utils/validators';
import { Title } from '@angular/platform-browser';
import { FormUtilsService } from 'src/app/core/services/form-utils.service';
import { selectLoginFirstData } from 'src/app/core/store/selectors/selectors';
import { AppRoutes } from 'src/app/core/consts/navigation.const';
import { AppDialogComponent } from '../../../../shared/components/app-dialog/app-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { compareExpDate } from '../../../../core/utils/compare-exp-date';
import { GetSupportComponent } from '../../../../core/components/get-support/get-support.component';

interface PasswordRuleModel {
  id: number;
  label: string;
  errorName: string;
}

@UntilDestroy()
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  public btnTypes = AppButtonType;
  public btnSizes = AppButtonSize;
  public rules: PasswordRuleModel[] = [
    {
      id: 0,
      label: 'One lowercase character',
      errorName: 'lowercaseRequired',
    },
    {
      id: 1,
      label: 'One uppercase character',
      errorName: 'uppercaseRequired',
    },
    {
      id: 2,
      label: 'One digit',
      errorName: 'digitRequired',
    },
    {
      id: 3,
      label: '8 characters minimum',
      errorName: 'minLengthRequired',
    },
    {
      id: 4,
      label: 'Password must contain a special symbol',
      errorName: 'specialCharRequired',
    },
  ];
  public email: string | undefined;
  public code: string | undefined;

  public form = this.fb.group({
    password: ['', [Validators.required, customPasswordValidator()]],
    passwordConfirm: ['', [Validators.required]],
    email: [''],
  });
  public lastProcessedValues: { [key: string]: string } = {};

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private store: Store<AppState>,
    private titleService: Title,
    private route: ActivatedRoute,
    private formUtilsService: FormUtilsService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle('LeuPRO Logix | Set Password');
    this.route.queryParamMap.pipe(untilDestroyed(this)).subscribe((params) => {
      if (params.get('email') && params.get('code')) {
        this.email = params.get('email')!;
        this.code = params.get('code')!;
        this.form.get('email')?.setValue(this.email);
        this.cdr.markForCheck();
        if (compareExpDate(params.get('exp'))) {
          this.navigateToLoginPage();
        }
      }
    });
    this.store
      .select(selectLoginFirstData)
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        if (data?.metadata?.userAttributes?.email) {
          this.email = data.metadata.userAttributes.email;
          this.form.get('email')?.setValue(this.email);
          this.cdr.markForCheck();
        }
      });
    this.setValidators();
    this.checkForSpaces();
    this.form
      .get('password')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe(() => {
        this.form.get('passwordConfirm')?.updateValueAndValidity();
      });
  }

  submit(): void {
    if (this.email && this.code) {
      this.store.dispatch(
        forgotPasswordActions.forgotPasswordConfirm({
          body: { email: this.email, code: this.code, newPassword: this.form.value.password! },
        }),
      );
    } else {
      this.store.dispatch(
        registerActions.registerConfirm({
          body: {
            newPassword: this.form.value.password!,
          },
        }),
      );
    }
  }

  cancel(): void {
    this.router.navigate([AppRoutes.LOGIN]);
  }

  getSupport(): void {
    this.dialog.open(GetSupportComponent, {
      autoFocus: false,
      width: '392px',
      position: {
        bottom: '24px',
        right: '24px',
      },
    });
  }

  private setValidators(): void {
    const passwordControl = this.form.get('password') as AbstractControl;
    this.form
      .get('passwordConfirm')
      ?.setValidators([Validators.required, confirmPasswordValidator(passwordControl)]);
  }

  private checkForSpaces(): void {
    const passwordConfirmControl = this.form.get('passwordConfirm') as AbstractControl;
    const passwordControl = this.form.get('password') as AbstractControl;
    this.formUtilsService.setupControlSpaceCheck(
      passwordConfirmControl,
      'passwordConfirm',
      this.lastProcessedValues,
      this,
    );
    this.formUtilsService.setupControlSpaceCheck(
      passwordControl,
      'password',
      this.lastProcessedValues,
      this,
    );
  }

  ngOnDestroy() {}

  navigateToLoginPage() {
    const dialog = this.dialog.open(AppDialogComponent, {
      data: {
        title: 'The password recovery link has expired',
        content: `You can restart the password recovery process by clicking on 'Forgot password' at the login page`,
        actions: ['Ok'],
      },
    });

    dialog
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.router.navigate([AppRoutes.LOGIN]);
      });
  }
}
