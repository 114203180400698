import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { deaNumberMask, resaleCertificateMask } from '../../../../../core/consts/masks';
import { SharedModule } from 'src/app/shared/shared.module';
import { AppFileInputComponent } from 'src/app/shared/components/app-file-input/app-file-input.component';
import { AppDatepickerComponent } from 'src/app/shared/components/app-datepicker/app-datepicker.component';
import { FileFormControlValue } from '../../../../../core/models/common-components.model';
import { fromOrganizations, selectOrganizationEnrollment } from '../../../store/selectors';
import { filter, switchMap, take, withLatestFrom } from 'rxjs';
import { isPresent } from '../../../../../core/utils/isPresent';
import { Store } from '@ngrx/store';
import { OrganizationsApiService } from '../../../../auth/services/organizations-api.service';

@Component({
  selector: 'app-enrollment-regulatory-info',
  templateUrl: './enrollment-regulatory-info.component.html',
  styleUrl: './enrollment-regulatory-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    AppFileInputComponent,
    AppDatepickerComponent,
  ],
})
export class EnrollmentRegulatoryInfoComponent {
  @Input() form: FormGroup | undefined;
  protected readonly deaNumberMask = deaNumberMask;
  protected readonly resaleCertificateMask = resaleCertificateMask;
  public today = new Date();

  constructor(
    private store: Store,
    private organizationsApiService: OrganizationsApiService,
  ) {}

  public getFormGroup(key: 'stateLicence' | 'dea' | 'taxInformation'): FormGroup {
    return this.form?.get(key) as FormGroup;
  }
  public getFileFormControl(key: 'stateLicence' | 'dea' | 'taxInformation'): FormControl {
    switch (key) {
      case 'stateLicence': {
        return this.form?.get(`${key}.stateLicenceFile`) as FormControl;
      }
      case 'dea': {
        return this.form?.get(`${key}.deaLicenceFile`) as FormControl;
      }
      case 'taxInformation': {
        return this.form?.get(`${key}.taxLicenceFile`) as FormControl;
      }
    }
  }

  downloadFile(value: FileFormControlValue): void {
    if (value.id) {
      this.store
        .select(fromOrganizations.selectOrganization)
        .pipe(
          filter(isPresent),
          take(1),
          withLatestFrom(this.store.select(selectOrganizationEnrollment).pipe(filter(isPresent))),
          switchMap(([organization, enrollment]) =>
            this.organizationsApiService.getEnrollmentLicenceFile$(
              organization.id,
              enrollment.id,
              value.id!,
            ),
          ),
        )
        .subscribe((link) => {
          const a = document.createElement('a');
          a.href = link;
          a.download = value.file as string;
          a.click();
          window.URL.revokeObjectURL(link);
        });
    }
  }
}
