import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { patientConsentValues } from 'src/app/core/utils/patients.utils';
import { AppButtonType } from '../../models/common-components.model';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PatientConsentFormService } from 'src/app/core/components/patient-consent-form/patient-consent-form.service';
import { PatientsApiService } from 'src/app/modules/patients/services/patients-api.service';
import { ConsentStatusDto } from 'src/app/core/models/patients.model';
import { catchError, of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AppDialogComponent } from '../../../shared/components/app-dialog/app-dialog.component';
import { ConsumerHealthPrivacyPolicyComponent } from '../../../modules/static-pages/components/consumer-health-privacy-policy/consumer-health-privacy-policy.component';

interface DecodedToken {
  orgId: number;
  patientId: number;
  orgName: string;
}

@UntilDestroy()
@Component({
  selector: 'app-patient-consent-form',
  templateUrl: './patient-consent-form.component.html',
  styleUrl: './patient-consent-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, SharedModule],
})
export class PatientConsentFormComponent implements OnInit {
  protected readonly btnTypes = AppButtonType;
  private consentToken: string;
  protected decodedToken: DecodedToken;
  protected submitted: boolean = false;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private patientConsentFormService: PatientConsentFormService,
    private patientsApiService: PatientsApiService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private readonly router: Router,
  ) {}

  ngOnInit() {
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
      this.consentToken = params['consentToken'];
      if (this.consentToken) {
        this.decodedToken = this.patientConsentFormService.decodeToken(this.consentToken);
      }
    });
  }

  cancel(): void {
    this.dialog.open(AppDialogComponent, {
      data: {
        title: 'The updates will not be saved and you have not provided your consent.',
        content: ``,
        actions: ['Ok'],
      },
    });
  }

  submit(): void {
    this.submitted = true;
    if (this.consentToken && this.decodedToken) {
      this.patientsApiService
        .updatePatientConsentStatus$(this.decodedToken.orgId, this.decodedToken.patientId, {
          consentStatus: ConsentStatusDto.OPTED_IN,
          consentToken: this.consentToken,
        })
        .pipe(
          catchError((error) => {
            this.submitted = false;
            this.dialog.open(AppDialogComponent, {
              data: {
                title: error.error.message ?? 'Patients API is not available',
                content: ``,
                actions: ['Close'],
              },
            });
            this.cdr.markForCheck();
            return of(null);
          }),
        )
        .subscribe((res) => {
          if (res) {
            this.dialog.open(AppDialogComponent, {
              data: {
                title: 'Thank you, your consent has been successfully submitted',
                content: ``,
                actions: ['Close'],
              },
            });
          }
        });
    }
  }

  openConsumerPolicy(): void {
    this.dialog.open(ConsumerHealthPrivacyPolicyComponent, {
      autoFocus: false,
      maxHeight: '90vh',
    });
  }
}
