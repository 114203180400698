<div class="app-card">
  <div class="mat-headline-6 app-card__header">
    <ng-content select=".header"></ng-content>
  </div>
  <div>
    <ng-content select=".subheader"></ng-content>
  </div>
  <div class="app-card__content">
    <ng-content select=".content"></ng-content>
  </div>
</div>

