<div class="common-table">
  <table
    mat-table
    [dataSource]="(data$ | async) || []"
    matSort
    matSortActive="id"
    matSortDisableClear
    matSortDirection="desc"
  >
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>ID</th>
      <td mat-cell *matCellDef="let row">{{ row.id }}</td>
    </ng-container>

    <ng-container matColumnDef="npi">
      <th mat-header-cell *matHeaderCellDef>NPI Number</th>
      <td mat-cell *matCellDef="let row">{{ row.npi }}</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Organization Name</th>
      <td mat-cell *matCellDef="let row" [matTooltip]="row.name">
        <span>{{ row.name }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="admin">
      <th mat-header-cell *matHeaderCellDef>Organization Admin</th>
      <td mat-cell *matCellDef="let row" [matTooltip]="row.admin">{{ row.admin }}</td>
    </ng-container>

    <ng-container matColumnDef="userCount">
      <th mat-header-cell *matHeaderCellDef>Number of users</th>
      <td mat-cell *matCellDef="let row">{{ row.userCount }}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let row">
        <app-status [status]="row.status" [factory]="organizationStatusFactory"></app-status>
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let row">
        <div class="container-horizontal">
          <mat-icon
            automation-id="navigate-to-account-btn"
            matSuffix
            class="table-action-icon material-icons-outlined"
            [class.required]="row.actionRequired"
            (click)="navigateToAccount(row.id)"
            matTooltip="View organization details"
            >{{ row.actionRequired ? 'info' : 'visibility' }}</mat-icon
          >
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr *matNoDataRow>
      <td colspan="7">
        <div class="no-results-row">
          <div class="no-results-content">
            <mat-icon matPrefix>search_off</mat-icon>No organization found
          </div>
        </div>
      </td>
    </tr>
  </table>
  <mat-paginator
    [length]="resultsLength$ | async"
    [pageSize]="pageSize$ | async"
    [pageIndex]="pageIndex$ | async"
    [pageSizeOptions]="[10, 20, 30, 40, 50]"
    (page)="handlePageEvent($event)"
  ></mat-paginator>
</div>
