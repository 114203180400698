export const zipCodeMask = '00000';
export const phoneMask = '(000) 000-0000';
export const phonePrefixMask = ' (000) 000-0000';
export const faxMask = '(000) 000-0000';

export const dunsNumberMask = '000000000';
export const feinNumberMask = '000000000';

export const deaNumberMask = 'AAAAAAAAA';
export const resaleCertificateMask = 'AAAAAAAAAAAAAAAA';

export const accountNumberMaks = 'A{34}';
export const npiMask = '0000000000';

export const phoneNumberPrefix = '+1';

export const fedexMask = '000000000';
export const upcMask = '000000';

export const mfaCodeMask = '000000';
