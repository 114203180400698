import {
  EnrollmentDto,
  EnrollmentShortDto,
  GetOrganizationsUsersResponseDto,
  OrganizationDataDto,
  OrganizationDto,
  ShippingAddressDto,
} from '../../../core/models/organization.model';
import { SortDirection } from '@angular/material/sort';

export interface FeatureState {
  lastOrganizationSearch: OrganizationDataDto | null;
  registering: boolean;
  page: number;
  pageSize: number;
  total: number;
  sortOrder: SortDirection;
  actionRequired: boolean;
  name: string | null;
  status: string[] | null;
  orderBy: string;
  organizations: OrganizationDataDto[] | null;
  organization: OrganizationDto | null;
  organizationUsers: GetOrganizationsUsersResponseDto | null;
  organizationShippingAddress: ShippingAddressDto[] | null;
  shippingAddressSaving: boolean;
  loadingOrganizations: boolean;
  loadingOrganization: boolean;
  loadingAddresses: boolean;
  enrollmentShortSummary: EnrollmentShortDto | null;
  enrollment: EnrollmentDto | null;
}

export const initialState: FeatureState = {
  lastOrganizationSearch: null,
  registering: false,
  name: null,
  status: null,
  page: 1,
  pageSize: 20,
  total: 0,
  sortOrder: 'desc',
  orderBy: 'id',
  actionRequired: false,
  organizations: null,
  organizationUsers: null,
  organizationShippingAddress: null,
  loadingOrganizations: false,
  organization: null,
  loadingOrganization: false,
  loadingAddresses: false,
  shippingAddressSaving: false,
  enrollmentShortSummary: null,
  enrollment: null,
};
