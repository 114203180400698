import { createReducer, on } from '@ngrx/store';
import {
  adminsListActions,
  badgesActions,
  loggedInSuccessAction,
  loginActions,
  logoutAction,
  mfaActions,
  refreshTokenActions,
  registerActions,
} from 'src/app/core/store/actions/actions';
import { initialAppState } from 'src/app/core/store/models/state';

export const reducer = createReducer(
  initialAppState,
  on(loginActions.login, (state) => ({ ...state, loginError: null })),
  on(loginActions.loginSuccess, (state, { res }) => ({ ...state, loginData: res })),
  on(mfaActions.verifyCodeSuccess, (state, { res }) => ({
    ...state,
    user: res.user,
    tokens: res.tokens,
    isLoggedIn: true,
  })),
  on(loginActions.loginFailure, (state, { error }) => ({ ...state, loginError: error })),
  on(loginActions.loginFirstSuccess, (state, { res }) => ({ ...state, firstLoginData: res })),
  on(registerActions.registerConfirmSuccess, (state, { res }) => ({
    ...state,
    loginData: res,
  })),
  on(registerActions.register, (state, {}) => ({
    ...state,
    registering: true,
  })),
  on(registerActions.registerSuccess, (state, {}) => ({
    ...state,
    registering: false,
  })),
  on(registerActions.registerFail, (state, {}) => ({
    ...state,
    registering: false,
  })),
  on(refreshTokenActions.refreshTokenSuccess, (state, { res }) => ({
    ...state,
    user: res.user,
    tokens: res.tokens,
    isLoggedIn: true,
  })),
  on(refreshTokenActions.autoRefreshTokenSuccess, (state, { res }) => ({
    ...state,
    tokens: res.tokens,
  })),
  on(logoutAction, (state) => ({
    ...state,
    tokens: null,
    user: null,
    firstLoginData: null,
    isLoggedIn: false,
  })),
  on(loggedInSuccessAction, (state) => ({ ...state, isLoggedIn: true })),
  on(badgesActions.setOrdersNotificationsCount, (state, { count }) => ({
    ...state,
    organizationOrdersNotificationsCount: count,
  })),
  on(badgesActions.setCiplaAdminOrdersNotificationsCount, (state, { count }) => ({
    ...state,
    ciplaAdminOrdersNotificationsCount: count,
  })),
  on(badgesActions.setAppointmentsNotificationsCount, (state, { count }) => ({
    ...state,
    organizationAppointmentsNotificationsCount: count,
  })),
  on(adminsListActions.getAdminsListSuccess, (state, { admins }) => ({
    ...state,
    admins,
  })),
);
