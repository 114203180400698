@if (form) {
  <div class="app-enrollment-regulatory-info" [formGroup]="form">
    <div class="app-enrollment-regulatory-info__block" [formGroup]="getFormGroup('stateLicence')">
      <div class="app-enrollment-regulatory-info__title">State License</div>
      <div class="app-enrollment-regulatory-info__row">
        <div class="app-enrollment-regulatory-info__control">
          <app-input
            label="State License Number"
            formControlName="stateLicenceNumber"
            [maxLength]="256"
          ></app-input>
        </div>
        <div class="app-enrollment-regulatory-info__control">
          <app-input label="Type" formControlName="stateLicenceType" [maxLength]="256"></app-input>
        </div>
        <div class="app-enrollment-regulatory-info__control">
          <app-datepicker
            label="Expiration Date"
            formControlName="stateLicenceExpDate"
            [min]="today"
          ></app-datepicker>
        </div>
      </div>
      <div class="app-enrollment-regulatory-info__control">
        <app-file-input
          requiredFileName="State License"
          [control]="getFileFormControl('stateLicence')"
          [maxFileSizeInMB]="5"
          (onDownload)="downloadFile($event)"
        ></app-file-input>
      </div>
    </div>
    <div class="app-enrollment-regulatory-info__block" [formGroup]="getFormGroup('dea')">
      <div class="app-enrollment-regulatory-info__title">Drug Enforcement Administration/DEA</div>
      <div class="app-enrollment-regulatory-info__row">
        <div class="app-enrollment-regulatory-info__control">
          <app-input
            label="DEA Number"
            formControlName="deaNumber"
            [mask]="deaNumberMask"
          ></app-input>
        </div>
        <div class="app-enrollment-regulatory-info__control">
          <app-input label="Schedule" formControlName="deaSchedule" [maxLength]="256"></app-input>
        </div>
        <div class="app-enrollment-regulatory-info__control">
          <app-datepicker
            label="Expiration Date"
            formControlName="deaExpDate"
            [min]="today"
          ></app-datepicker>
        </div>
      </div>
      <div class="app-enrollment-regulatory-info__control">
        <app-file-input
          requiredFileName="DEA License"
          [control]="getFileFormControl('dea')"
          [maxFileSizeInMB]="5"
          (onDownload)="downloadFile($event)"
        ></app-file-input>
      </div>
    </div>
    <div class="app-enrollment-regulatory-info__block" [formGroup]="getFormGroup('taxInformation')">
      <div class="app-enrollment-regulatory-info__title">Tax Information</div>
      <div class="app-enrollment-regulatory-info__row">
        <div class="app-enrollment-regulatory-info__control">
          <app-input
            label="Resale or Tax Exemption Certificate No."
            formControlName="taxNumber"
            [mask]="resaleCertificateMask"
          ></app-input>
        </div>
        <div class="app-enrollment-regulatory-info__control">
          <app-datepicker
            label="Expiration Date"
            formControlName="taxExpDate"
            [min]="today"
          ></app-datepicker>
        </div>
      </div>
      <div class="app-enrollment-regulatory-info__control">
        <app-file-input
          requiredFileName="Resale Certificate / Tax Exemption Certificate"
          [control]="getFileFormControl('taxInformation')"
          [maxFileSizeInMB]="5"
          (onDownload)="downloadFile($event)"
        ></app-file-input>
      </div>
    </div>
  </div>
}
