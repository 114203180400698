import { ApiService } from 'src/app/core/services/api.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AddPatientReqDto,
  AddPatientResDto,
  GetPatientConsentTokenResponseDto,
  GetPatientsResponseDto,
  PatientDto,
  SendPatientNotificationRequestDto,
  UpdatePatientConsentStatusRequestDto,
} from 'src/app/core/models/patients.model';
import { HttpParams } from '@angular/common/http';
import { SortDirection } from '@angular/material/sort';

@Injectable({
  providedIn: 'root',
})
export class PatientsApiService extends ApiService {
  addPatient$(organizationId: number, patient: AddPatientReqDto): Observable<AddPatientResDto> {
    return this.post(`/organizations/${organizationId}/patients`, patient);
  }

  getPatients$(
    organizationId: number,
    excludeUpcomingAppointments: boolean = false,
    page = 1,
    sortOrder: SortDirection = 'asc',
    orderBy: string | null,
    limit = 20,
    statuses: string[] | null,
    name: string | null,
  ): Observable<GetPatientsResponseDto> {
    const data = {
      page: page || 1,
      sortOrder: sortOrder || 'desc',
      orderBy: orderBy || 'id',
      limit,
    };

    let httpParams = new HttpParams();

    for (let key in data) {
      let k = key as keyof typeof data;
      httpParams = httpParams.append(key, data[k]);
    }

    if (excludeUpcomingAppointments) {
      httpParams = httpParams.append('excludeUpcomingAppointments', excludeUpcomingAppointments);
    }

    if (statuses && statuses.length > 0) {
      statuses.forEach((status) => {
        httpParams = httpParams.append('consentStatus', status);
      });
    }

    if (name) {
      httpParams = httpParams.append('searchQuery', name);
    }

    return this.get(`/organizations/${organizationId}/patients`, httpParams);
  }

  getPatient$(organizationId: number, patientId: number): Observable<PatientDto> {
    return this.get(`/organizations/${organizationId}/patients/${patientId}`);
  }

  editPatient$(
    organizationId: number,
    patient: AddPatientReqDto,
    patientId: number,
  ): Observable<AddPatientResDto> {
    return this.put(`/organizations/${organizationId}/patients/${patientId}`, patient);
  }

  getConsentToken$(
    organizationId: number,
    patientId: number,
  ): Observable<GetPatientConsentTokenResponseDto> {
    return this.get(`/organizations/${organizationId}/patients/${patientId}/token`);
  }

  updatePatientConsentStatus$(
    organizationId: number,
    patientId: number,
    body: UpdatePatientConsentStatusRequestDto,
  ): Observable<AddPatientResDto> {
    return this.patch(`/organizations/${organizationId}/patients/${patientId}`, body);
  }

  sendPatientNotification$(
    organizationId: number,
    patientId: number,
    body: SendPatientNotificationRequestDto,
  ): Observable<AddPatientResDto> {
    return this.patch(`/organizations/${organizationId}/patients/${patientId}/users`, body);
  }
}
