import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  AppButtonSize,
  AppButtonType,
  DialogData,
} from '../../../../core/models/common-components.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlmostThereComponent } from '../../../organizations/components/almost-there/almost-there.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../../shared/shared.module';
import { Router } from '@angular/router';
import { AppRoutes, AuthRoutes } from 'src/app/core/consts/navigation.const';

@UntilDestroy()
@Component({
  selector: 'app-reset-password-link-expired',
  templateUrl: './reset-password-link-expired.component.html',
  styleUrl: './reset-password-link-expired.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, SharedModule],
})
export class ResetPasswordLinkExpiredComponent {
  public btnTypes = AppButtonType;
  public btnSizes = AppButtonSize;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogRef: MatDialogRef<AlmostThereComponent>,
    private readonly router: Router,
  ) {}

  public close(): void {
    this.router.navigate([AppRoutes.LOGIN]);
    this.dialogRef.close();
  }

  public navigateToForgetPassword(): void {
    this.router.navigate([`${AppRoutes.LOGIN}/${AuthRoutes.RESET_PASSWORD}`]);
    this.dialogRef.close();
  }
}
