import { createActionGroup, props } from '@ngrx/store';
import { featureKey } from './selectors';
import { MatSnackBarConfig } from '@angular/material/snack-bar';

export const NotificationsActions = createActionGroup({
  source: featureKey,
  events: {
    showNotification: props<{ message: string; action?: string; config?: MatSnackBarConfig }>(),
  },
});
