import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SpinnerService {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  get increased() {
    return this.loadingSubject.value;
  }

  public increase() {
    this.loadingSubject.next(true);
  }

  public decrease() {
    setTimeout(() => {
      this.loadingSubject.next(false);
    }, 500);
  }
}
