import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { catchError, map, Observable, of } from 'rxjs';
import {
  FirstLoginResModel,
  ForgotPassConfirmReqDto,
  ForgotPassReqModel,
  LoginReqModel,
  LoginResDto,
  MfaLoginResDto,
  PassChangeReqModel,
  RegisterConfirmReqDto,
  RegistrationBodyDto,
  UserDto,
} from 'src/app/core/models/auth.model';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store/models/state';
import { refreshTokenActions } from 'src/app/core/store/actions/actions';
import { RequestCallReqDto } from '../../../core/models/common-components.model';
import { AddUserReqDto } from '../../../core/models/staff.model';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService extends ApiService {
  constructor(private store: Store<AppState>) {
    super();
  }
  register$(body: RegistrationBodyDto): Observable<void> {
    return this.post('/auth/register', body);
  }

  login$(body: LoginReqModel): Observable<MfaLoginResDto | FirstLoginResModel> {
    return this.post<MfaLoginResDto | FirstLoginResModel>('/auth/login', body);
  }

  refresh$(): Observable<LoginResDto | null> {
    const refreshToken = localStorage.getItem('refreshToken');
    return refreshToken
      ? this.post<LoginResDto>('/auth/refresh', { refreshToken }).pipe(
          map((res) => {
            this.store.dispatch(refreshTokenActions.refreshTokenSuccess({ res }));
            return res;
          }),
          catchError((error) => {
            this.store.dispatch(refreshTokenActions.refreshTokenFailure({ error }));
            return of(null);
          }),
        )
      : of(null);
  }
  refreshToken$(): Observable<LoginResDto | null> {
    const refreshToken = localStorage.getItem('refreshToken');
    return refreshToken
      ? this.post<LoginResDto>('/auth/refresh', { refreshToken }).pipe(
          map((res) => {
            this.store.dispatch(refreshTokenActions.autoRefreshTokenSuccess({ res }));
            return res;
          }),
          catchError((error) => {
            this.store.dispatch(refreshTokenActions.refreshTokenFailure({ error }));
            return of(null);
          }),
        )
      : of(null);
  }

  forgotPass$(body: ForgotPassReqModel): Observable<void> {
    return this.post('/auth/password/forgot', body);
  }

  forgotPassConfirm$(body: ForgotPassConfirmReqDto): Observable<void> {
    return this.post('/auth/password/forgot/confirm', body);
  }

  registerConfirm$(body: RegisterConfirmReqDto): Observable<MfaLoginResDto> {
    return this.post<MfaLoginResDto>('/auth/register/confirm', body);
  }

  passChange$(body: PassChangeReqModel): Observable<void> {
    return this.post('/auth/password/change', body);
  }

  resendInvite$(email: string): Observable<void> {
    return this.post('/auth/password/resend', { email });
  }

  cancelInvite$(email: string): Observable<void> {
    return this.post('/auth/register/cancel', { email });
  }

  logout$(accessToken: string): Observable<void> {
    return this.post('/auth/logout', { accessToken });
  }

  mfaVerify$(code: string, session: string, cognitoId: string): Observable<LoginResDto> {
    return this.post('/auth/mfa/confirm', { code, session, cognitoId });
  }

  requestCall$(body: RequestCallReqDto): Observable<void> {
    return this.post('/support', body);
  }

  getAdminsList$(): Observable<UserDto[]> {
    return this.get('/admins');
  }

  addCiplaAdmin$(body: AddUserReqDto): Observable<void> {
    return this.post('/admins', body);
  }

  deleteCiplaAdmin$(id: number): Observable<void> {
    return this.delete(`/admins/${id}`);
  }
}
