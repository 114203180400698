import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { UserDto } from 'src/app/core/models/auth.model';
import { selectIsLoggedIn, selectUser } from 'src/app/core/store/selectors/selectors';
import { Title } from '@angular/platform-browser';
import { PERMISSIONS, ROLES } from 'src/app/core/consts/roles-and-permissions';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OrganizationActions } from './modules/organizations/store/actions';
import { badgesActions } from './core/store/actions/actions';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  public user$: Observable<UserDto | null> | undefined;
  public isLoggedIn$: Observable<boolean | null> | undefined;
  public PERMISSIONS = PERMISSIONS;
  public isSpinnerShown$: Observable<boolean>;
  private urlsToExcludeTopMenu: string[] = [
    '/',
    '/login',
    '/organizations',
    '/organizations-inventory',
    '/organizations-orders',
    '/support',
    '/organizations/create',
    '/admins-list',
  ];

  constructor(
    private titleService: Title,
    private spinnerService: SpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private store$: Store,
  ) {
    this.isSpinnerShown$ = this.spinnerService.loading$;
  }
  ngOnInit() {
    this.user$ = this.store$.select(selectUser);
    this.isLoggedIn$ = this.store$.select(selectIsLoggedIn);
    this.titleService.setTitle('LeuPRO Logix');
    this.store$.dispatch(badgesActions.showDeliveryConfirmationRequired());
    this.store$.dispatch(
      OrganizationActions.getOrganizationIdFromParams({
        routeFn: () => this.route,
        routerFn: () => this.router,
      }),
    );
  }

  isTopMenuShown(user: UserDto | null): boolean {
    if (user?.role === ROLES.ADMIN || user?.role === ROLES.SUPER_ADMIN) {
      return !this.urlsToExcludeTopMenu.includes(this.router.url);
    }
    return true;
  }
}
