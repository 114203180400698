import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function emailFormatValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const email = control.value;
    if (!email) {
      return null;
    }

    const [prefix, domain] = email.split('@');

    if (
      !prefix ||
      prefix.length > 64 ||
      /[^a-zA-Z0-9!#$%'*+\-/=?^_`{|}~.]/.test(prefix) ||
      prefix.startsWith('.') ||
      prefix.endsWith('.') ||
      prefix.includes('..')
    ) {
      return { emailFormat: true };
    }

    if (
      !domain ||
      domain.length > 63 ||
      /[^a-zA-Z0-9\-.]/.test(domain) ||
      !/\.[a-zA-Z]{2,}$/.test(domain) ||
      domain.includes('..')
    ) {
      return { emailFormat: true };
    }

    return null;
  };
}

export function customPasswordValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return null;
    }

    const errors: ValidationErrors = {};
    if (!/[a-z]/.test(value)) {
      errors['lowercaseRequired'] = true;
    }
    if (!/[A-Z]/.test(value)) {
      errors['uppercaseRequired'] = true;
    }
    if (!/\d/.test(value)) {
      errors['digitRequired'] = true;
    }
    if (!/[\!\@\#\$\%\^\&\*\(\)\_\+\-\=\[\]\{\}\;\:\'\"\\|\,\<\.\>\/\?]/.test(value)) {
      errors['specialCharRequired'] = true;
    }
    if (value.length < 8) {
      errors['minLengthRequired'] = true;
    }

    return Object.keys(errors).length === 0 ? null : errors;
  };
}

export function confirmPasswordValidator(passwordControl: AbstractControl): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const password = passwordControl.value;
    const confirmPassword = control.value;
    if (!password || !confirmPassword) {
      return null;
    }
    return password === confirmPassword ? null : { passwordMismatch: true };
  };
}

export function maxFileSize(maxSize: number = 20000000): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    const maxSizeErr = value && value.size > maxSize;
    if (!maxSizeErr) {
      return null;
    }
    return !maxSizeErr ? null : { custom: true };
  };
}
