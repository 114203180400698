import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PasswordService {
  private password: string | null = null;

  setPassword(newPassword: string) {
    this.password = newPassword;
  }

  getPassword(): string | null {
    return this.password;
  }

  clearPassword() {
    this.password = null;
  }

  isConditionMet(condition: boolean): boolean {
    return condition;
  }
}
