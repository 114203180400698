import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  AppAlertType,
  AppButtonSize,
  AppButtonType,
} from 'src/app/core/models/common-components.model';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppRoutes, AuthRoutes } from 'src/app/core/consts/navigation.const';
import { BehaviorSubject } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-blocked-account',
  templateUrl: './blocked-account.component.html',
  styleUrl: './blocked-account.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlockedAccountComponent implements OnInit {
  public btnTypes = AppButtonType;
  public btnSizes = AppButtonSize;

  public alertTypes = AppAlertType;

  timeLef$ = new BehaviorSubject('-');

  exp: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.route.queryParamMap.pipe(untilDestroyed(this)).subscribe((params) => {
      if (params.get('exp')) {
        this.exp = +params.get('exp')!;
        this.timeLef$.next(msToTime(this.exp - Date.now()));
      }
    });

    setInterval(() => {
      this.timeLef$.next(msToTime(this.exp - Date.now()));
    }, 1000);
  }

  public submit(): void {
    this.router.navigate([`${AppRoutes.LOGIN}/${AuthRoutes.RESET_PASSWORD}`]);
  }
}
function msToTime(duration: number) {
  let milliseconds = Math.floor((duration % 1000) / 100),
    seconds: string | number = Math.floor((duration / 1000) % 60),
    minutes: string | number = Math.floor((duration / (1000 * 60)) % 60),
    hours: string | number = Math.floor((duration / (1000 * 60 * 60)) % 24);

  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;

  return hours + ':' + minutes + ':' + seconds;
}
