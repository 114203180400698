<div class="app-mfa-verification" automation-id="mfa-verification">
  <app-logo></app-logo>
  <div class="app-mfa-verification__card">
    <app-card>
      <div class="header">Verify Your Identity</div>
      <div class="subheader">Enter a verification code sent by the text message to:</div>
      <div class="content app-mfa-verification__card-content" [formGroup]="form">
        <div class="app-mfa-verification__card-inputs">
          <app-input formControlName="phoneNumber"></app-input>
          <a class="app-mfa-verification__card-update-number" (click)="updateNumber()"
            >Update your phone number</a
          >
          <div class="app-mfa-verification__card-code-valid">
            This code is valid for 15 minutes.
          </div>
          <div *ngIf="showTimer; else resendButton" class="app-mfa-verification__card-resend-text">
            Resend code in {{ timerValue }}s
          </div>
          <ng-template #resendButton>
            <div class="app-mfa-verification__card-resend">
              <app-button
                [type]="btnTypes.Basic"
                label="Resend Code"
                [size]="btnSizes.Basic"
                (click)="resendCode()"
              ></app-button>
            </div>
          </ng-template>

          <app-input
            automation-id="mfa-code"
            label="Enter the 6-digit code"
            formControlName="code"
            [mask]="mfaCodeMask"
          ></app-input>
        </div>
        <div class="app-mfa-verification__card-actions">
          <app-button
            automation-id="mfa-verify-btn"
            [type]="btnTypes.Flat"
            label="Verify"
            class="app-mfa-verification__card-submit"
            [disabled]="form.invalid"
            [size]="btnSizes.FullWidth"
            (onClick)="verify()"
          ></app-button>
          <app-button
            automation-id="mfa-cancel-btn"
            [type]="btnTypes.Stroked"
            label="Back to Login"
            class="app-mfa-verification__card-cancel"
            [size]="btnSizes.FullWidth"
            routerLink="/login"
          ></app-button>
          <a class="app-mfa-verification__card-need-help" (click)="getSupport()">
            <mat-icon class="material-icons-outlined">help_outline</mat-icon>
            Get Support
          </a>
        </div>
      </div>
    </app-card>
  </div>
</div>
