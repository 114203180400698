<div class="app-add-admin">
  <div class="title">Add Cipla Admin</div>
  @if (form) {
    <div class="content" [formGroup]="form">
      <app-input class="control" label="First Name" formControlName="firstName"></app-input>
      <app-input class="control" label="Last Name" formControlName="lastName"></app-input>
      <app-input
        class="control"
        label="Email"
        formControlName="email"
        [maxLength]="128"
      ></app-input>
      <app-input
        class="control"
        label="Mobile Phone Number"
        formControlName="phoneNumber"
        [mask]="phoneMask"
        [prefix]="prefix"
      ></app-input>
    </div>
  }

  <div class="actions">
    <app-button
      class="cancel-btn"
      [type]="btnTypes.Stroked"
      [size]="btnSizes.FullHeight"
      label="Cancel"
      (onClick)="cancel()"
    ></app-button>
    <app-button
      [size]="btnSizes.FullHeight"
      [type]="btnTypes.Flat"
      label="Submit"
      [disabled]="form.invalid"
      (onClick)="submit()"
    ></app-button>
  </div>
</div>
