import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { finalize, Observable } from 'rxjs';
import { SpinnerService } from 'src/app/core/services/spinner.service';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
  // add URL if no need to show spinner
  private urlsToIgnore: string[] = ['/auth/refresh', '/count'];
  constructor(private spinnerService: SpinnerService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.urlsToIgnore.find((url) => req.url.includes(url))) {
      if (!this.spinnerService.increased) {
        this.spinnerService.increase();
      }
    }

    return next.handle(req).pipe(
      finalize(() => {
        if (!this.urlsToIgnore.find((url) => req.url.includes(url))) {
          if (this.spinnerService.increased) {
            this.spinnerService.decrease();
          }
        }
      }),
    );
  }
}
