import { createReducer, on } from '@ngrx/store';
import { initialState } from './state';
import { DialogsActions } from './actions';

export const featureReducer = createReducer(
  initialState,
  on(DialogsActions.setRef, (state, { ref }) => ({
    ...state,
    ref,
  })),
);
