import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  Optional,
  Self,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { ValidationErrorsService } from 'src/app/shared/services/validation-errors.service';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgIf } from '@angular/common';
import { SharedModule } from '../../shared.module';
import { NgxMaskDirective } from 'ngx-mask';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-datepicker',
  templateUrl: './app-datepicker.component.html',
  styleUrl: './app-datepicker.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatDatepickerModule,
    MatInputModule,
    NgIf,
    ReactiveFormsModule,
    SharedModule,
    NgxMaskDirective,
    MatIconModule,
    MatButtonModule,
  ],
})
export class AppDatepickerComponent implements OnChanges, ControlValueAccessor {
  @Input() label: string | undefined = 'Choose a date';
  @Input() min: Date | undefined | null;
  @Input() max: Date | undefined | null;
  @Input() readonly = false;
  @Input() filter!: (d: Date | null) => boolean;
  @Input() placeholder = 'MM/DD/YYYY';

  get isRequired(): boolean {
    const validator = this.control.validator ? this.control.validator({} as AbstractControl) : null;
    return validator && validator['required'];
  }

  get control(): FormControl {
    return this.ngControl?.control as FormControl;
  }

  constructor(
    @Self() @Optional() public ngControl: NgControl,
    private validationErrorsService: ValidationErrorsService,
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['min'] || changes['max']) {
      this.filter = (d: Date | null) => {
        const targetDate = d?.getTime() || 0;
        let isAfterMin = true;
        let isBeforeMax = true;

        if (this.min) {
          const min = this.min.setHours(0, 0, 0, 0);
          isAfterMin = targetDate > new Date(min).getTime() - 1;
        }

        if (this.max) {
          const max = this.max.setHours(23, 59, 59, 0);
          isBeforeMax = targetDate < new Date(max).getTime() + 1;
        }

        return isAfterMin && isBeforeMax;
      };
    }
  }

  onChange: any = (value: string) => {};
  onTouched: any = () => {};

  clearControl() {}

  public registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public writeValue(value: any): void {}
}
