<div class="app-dropdown">
  <div class="app-dropdown__label">
    {{ label }}:
    <ng-container *ngIf="isRequired">*</ng-container>
  </div>
  <mat-form-field class="app-dropdown__field">
    <mat-select
      [formControl]="control"
      value=""
      placeholder="-Select-"
      (selectionChange)="selectedValue($event)"
      multiple
    >
      <mat-select-trigger class="trigger">
        {{
          emptyValuesPlaceholder && values.length === 1
            ? emptyValuesPlaceholder
            : selectedData?.text
        }}
      </mat-select-trigger>
      <mat-option *ngFor="let option of values" [value]="option[optionValue]"
        ><mat-icon *ngIf="optionIcon" [style.color]="option[optionIconColor]" class="smallIcon">{{
          option[optionIcon]
        }}</mat-icon>
        <div class="option-content">
          <div class="leftContent">
            {{
              emptyValuesPlaceholder && values.length === 1
                ? emptyValuesPlaceholder
                : option[optionLabel]
            }}<span *ngIf="optionLabel2"> {{ option[optionLabel2] }}</span>
            <span *ngIf="showAbbreviation"> ({{ option[showAbbreviation] }}) </span>
            <span
              *ngIf="optionLabelSecondLine && option[optionLabelSecondLine]"
              class="app-dropdown__field__secondLine"
            >
              <br />
              {{ option[optionLabelSecondLine] }}</span
            >
          </div>

          <div *ngIf="option[optionLabelRightContent]" class="rightContent">
            {{ option[optionLabelRightContent] }}
          </div>
        </div>
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-error class="app-dropdown__error" *ngIf="control?.invalid && control?.touched">
    {{ getErrorMessage() }}
  </mat-error>
</div>
