import { UserDataDto, UserDto } from './auth.model';
import { FileFormControlValue } from './common-components.model';

export interface OrganizationDataDto {
  npi: string;
  name: string;
  phoneNumber: string;
  street: string;
  city: string;
  state: string;
  countryCode: string;
  country: string;
  zip: string;
  actionRequired?: boolean;
}

export interface CreateOrganizationBodyDto {
  user?: UserDataDto;
  organization: OrganizationDataDto;
}

export interface UpdateOrganizationBodyDto {
  status: string;
  orgAdminId?: number;
}

export interface CreateOrganizationResponseDto {
  message: string;
  orgId: number;
}

export enum OrganizationStatusDto {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  ONBOARDING = 'ONBOARDING',
  DEACTIVATED = 'DEACTIVATED',
  ENROLLMENT = 'ENROLLMENT',
}

export interface GetOrganizationsResponseDto {
  total: number;
  page: number;
  pageSize: number;
  items: GetOrganizationsDto[];
}

export interface ShippingAddressDto {
  unitsInHand: number;
  availableUnits: number;
  id: number;
  status: AddressStatusDto;
  accountNumber: string;
  shippingProvider: string;
  customerName: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  email: string;
  phoneNumber: string;
  fax: string;
  organizationId: number;
  isDefault: boolean;
  updatedAt: string;
  createdAt: string;
  licences?: LicenseDto[];
}

export interface LicenseDto {
  id: number;
  name: string;
  stateNumber: string | null;
  stateType: string | null;
  deaNumber: string | null;
  deaSchedule: string | null;
  expirationDate: string;
  file: string;
  taxCertNumber: string | null;
  shippingAddressId: number;
  createdAt: string;
  updatedAt: string;
}

export interface ShippingAddressRequestDto {
  stateLicenceFile: Blob | File | null | undefined | string;
  deaLicenceFile: Blob | File | null | undefined | string;
  taxLicenceFile: Blob | File | null | undefined | string;
  accountNumber?: string;
  shippingProvider?: string;
  customerName: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  email: string;
  phoneNumber: string;
  // fax: string;
  stateLicenceNumber: string;
  stateLicenceType?: string;
  stateLicenceExpDate: string;
  deaNumber: string;
  deaSchedule?: string;
  deaExpDate: string;
  taxNumber: string;
  taxExpDate: string;
}

export interface ShippingAddressFormLicencesDto {
  stateLicenceFile: FileFormControlValue;
  deaLicenceFile: FileFormControlValue;
  taxLicenceFile: FileFormControlValue;
  stateLicenceNumber: string;
  stateLicenceType?: string;
  stateLicenceExpDate: string;
  deaNumber: string;
  deaSchedule?: string;
  deaExpDate: string;
  taxNumber: string;
  taxExpDate: string;
}

export interface OrganizationDto {
  status: OrganizationStatusDto;
  id: number;
  name: string;
  npi: string;
  country: string;
  countryCode: string;
  phoneNumber: string;
  city: string;
  state: string;
  zip: string;
  street: string;
  createdAt: string;
  updatedAt: string;
  adminId: number;
}

export interface GetOrganizationsUsersResponseDto {
  items: UserDto[];
  total: number;
  page: number;
  pageSize: number;
}

export interface GetOrganizationsDto {
  status: OrganizationStatusDto;
  id: number;
  name: string;
  npi: string;
  country: string;
  countryCode: string;
  phoneNumber: string;
  city: string;
  state: string;
  zip: string;
  street: string;
  createdAt: string;
  updatedAt: string;
  userCount: number;
  admin?: string;
}

export enum AddressStatusDto {
  ACTIVE = 'ACTIVE',
  IN_REVIEW = 'IN_REVIEW',
  DECLINED = 'DECLINED',
  DELETED = 'DELETED',
  DEACTIVATED = 'DEACTIVATED',
}

export interface EnrollmentShortDto {
  billToId?: number;
  creditDataId?: number;
  id: number;
  organization: OrganizationDto;
  organizationId: number;
  shipToId: number;
  status: EnrollmentStatusDto;
  updatedAt: string;
  createdAt: string;
}

export interface EnrollmentDto {
  status: EnrollmentStatusDto;
  id: number;
  organizationId: number;
  billTo?: EnrollmentBillingAddressDto;
  shipTo?: EnrollmentShippingAddressDto;
  contacts?: EnrollmentContactDto[];
  creditData?: EnrollmentCreditDataDto;
  licences: EnrollmentLicenceDto[];
  createdAt: string;
  updatedAt: string;
}

export interface EnrollmentUpdateDto {
  billTo?: EnrollmentBillingAddressDto;
  shipTo?: EnrollmentShippingAddressDto;
  contacts?: EnrollmentContactDto[];
  creditData?: EnrollmentCreditDataDto;
}

export enum EnrollmentStatusDto {
  IN_PROGRESS = 'IN_PROGRESS',
  IN_REVIEW = 'IN_REVIEW',
  DECLINED = 'DECLINED',
  APPROVED = 'APPROVED',
}

export interface EnrollmentContactDto {
  contactType: EnrollmentContactType;
  email: string;
  name: string;
  phoneNumber: string;
  cellPhoneNumber: string | null;
  fax: string | null;
}

export enum EnrollmentContactType {
  PURCHASING = 'PURCHASING',
  ACCOUNTS_PAYABLE = 'ACCOUNTS_PAYABLE',
}

export interface EnrollmentBillingAddressDto {
  customerName: string;
  state: string;
  zip: string;
  city: string;
  street: string;
  email: string;
  phoneNumber: string;
  fax: string | null;
}

export interface EnrollmentShippingAddressDto {
  accountNumber?: string;
  shippingProvider?: string;
  customerName: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  email: string;
  phoneNumber: string;
  fax?: string;
}

export interface EnrollmentCreditDataDto {
  companyName: string;
  dunsNo?: string;
  registeredSince: string;
  registeredAddress: string;
  cityStateZip: string;
  accountReceivableEmail: string;
  phone: string;
  fax?: string;
  natureOfBusiness: string;
  fEinNo: string;
  natureOfEntity: string;
  registeredAddressBusiness: string;
  cityStateZipBusiness: string;
  isBankruptcyInitiated: boolean;
  bankName: string;
  bankAddress: string;
  typeOfAccount: string;
  accountNo: string;
  bankContactPerson: string;
  bankPhone: string;
  references: EnrollmentReferenceDto[];
}

export interface EnrollmentReferenceDto {
  companyName: string;
  accountNo: string;
  bankAddress: string;
  cityStateZip: string;
  customerServiceEmail: string;
  phone: string;
  fax?: string;
}

export interface EnrollmentLicenceDto {
  id: number;
  name: EnrollmentLicenceName;
  stateNumber: string;
  stateType: string;
  deaNumber: string;
  deaSchedule: string;
  expirationDate: string;
  file: string;
  taxCertNumber: string;
  shippingAddressId: number;
  enrollmentId: number;
  createdAt: string;
  updatedAt: string;
}

export enum EnrollmentLicenceName {
  STATE = 'STATE',
  DEA = 'DEA',
  TAX = 'TAX',
}

export interface EnrollmentLicencesReqDto {
  stateLicenceFile: Blob | File | null | undefined | string;
  deaLicenceFile: Blob | File | null | undefined | string;
  taxLicenceFile: Blob | File | null | undefined | string;
  stateLicenceNumber: string;
  stateLicenceType?: string | null;
  stateLicenceExpDate: string;
  deaNumber: string;
  deaSchedule?: string | null;
  deaExpDate: string;
  taxNumber: string;
  taxExpDate: string;
}
