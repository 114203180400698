import { createReducer, on } from '@ngrx/store';
import { OrganizationActions, OrganizationEnrollmentActions } from './actions';
import { initialState } from './state';
import { SortDirection } from '@angular/material/sort';

export const featureReducer = createReducer(
  initialState,
  on(OrganizationActions.setSearchResult, (state, { organization }) => ({
    ...state,
    lastOrganizationSearch: organization,
  })),
  on(OrganizationActions.createOrganizationAccount, (state, {}) => ({
    ...state,
    registering: true,
  })),
  on(OrganizationActions.createOrganizationAccountFail, (state, {}) => ({
    ...state,
    registering: false,
  })),
  on(OrganizationActions.createOrganizationAccountSuccess, (state, {}) => ({
    ...state,
    registering: false,
  })),
  on(OrganizationActions.setSearchName, (state, { name }) => ({
    ...state,
    name,
  })),
  on(OrganizationActions.setSearchStatus, (state, { status }) => ({
    ...state,
    page: 1,
    status,
  })),
  on(OrganizationActions.setSearchPage, (state, { page }) => ({
    ...state,
    page,
  })),
  on(OrganizationActions.setSearchPageSize, (state, { pageSize }) => ({
    ...state,
    pageSize,
  })),
  on(OrganizationActions.setSearchSortOrder, (state, { sortOrder }) => ({
    ...state,
    sortOrder,
  })),
  on(OrganizationActions.setSearchOrderBy, (state, { orderBy }) => ({
    ...state,
    orderBy,
  })),
  on(OrganizationActions.setActionRequired, (state, { actionRequired }) => ({
    ...state,
    actionRequired,
  })),
  on(OrganizationActions.setOrganizations, (state, { response }) => ({
    ...state,
    organizations: response.items,
    total: response.total,
    pageSize: response.pageSize,
    loadingOrganizations: false,
  })),
  on(OrganizationActions.getOrganizations, (state, {}) => ({
    ...state,
    name: null,
    status: null,
    page: 1,
    total: 0,
    sortOrder: 'desc' as SortDirection,
    orderBy: 'id',
  })),
  on(OrganizationActions.loadOrganizations, (state, {}) => ({
    ...state,
    loadingOrganizations: true,
  })),
  on(OrganizationActions.getOrganizationsFail, (state, {}) => ({
    ...state,
    loadingOrganizations: false,
  })),
  on(OrganizationActions.setOrganization, (state, { response }) => ({
    ...state,
    organization: response,
    loadingOrganization: false,
  })),
  on(OrganizationActions.clearOrganization, (state) => ({
    ...state,
    organization: null,
  })),
  on(OrganizationActions.getOrganization, (state, {}) => ({
    ...state,
    organizationUsers: null,
    organizationShippingAddress: null,
    loadingOrganization: true,
  })),
  on(OrganizationActions.updateOrganizationAccount, (state, {}) => ({
    ...state,
    loadingOrganization: true,
  })),
  on(OrganizationActions.updateOrganizationAccountSuccess, (state, {}) => ({
    ...state,
    loadingOrganization: true,
  })),
  on(OrganizationActions.getOrganizationFail, (state, {}) => ({
    ...state,
    loadingOrganization: false,
  })),
  on(OrganizationActions.setOrganizationUsers, (state, { response }) => ({
    ...state,
    organizationUsers: response,
  })),
  on(OrganizationActions.getOrganizationShippingAddressesSuccess, (state, { response }) => ({
    ...state,
    organizationShippingAddress: response,
  })),

  on(OrganizationActions.loadOrganizationAddresses, (state) => ({
    ...state,
    loadingAddresses: true,
  })),

  on(OrganizationActions.getOrganizationShippingAddressesFail, (state) => ({
    ...state,
    loadingAddresses: false,
  })),
  on(OrganizationActions.getOrganizationShippingAddressesSuccess, (state) => ({
    ...state,
    loadingAddresses: false,
  })),

  on(OrganizationActions.createNewShippingAddresses, (state) => ({
    ...state,
    shippingAddressSaving: true,
  })),

  on(OrganizationActions.createNewShippingAddressesSuccess, (state) => ({
    ...state,
    shippingAddressSaving: false,
  })),
  on(OrganizationActions.createNewShippingAddressesFail, (state) => ({
    ...state,
    shippingAddressSaving: false,
  })),
  on(OrganizationEnrollmentActions.getOrganizationEnrollmentSuccess, (state, { enrollment }) => ({
    ...state,
    enrollmentShortSummary: enrollment,
  })),
  on(
    OrganizationEnrollmentActions.createOrganizationEnrollmentSuccess,
    (state, { enrollment }) => ({
      ...state,
      enrollmentShortSummary: enrollment,
    }),
  ),
  on(
    OrganizationEnrollmentActions.getOrganizationEnrollmentByIdSuccess,
    (state, { enrollment }) => ({
      ...state,
      enrollment,
    }),
  ),
  on(OrganizationEnrollmentActions.submitEnrollmentSuccess, (state, { enrollment }) => ({
    ...state,
    enrollment,
  })),
  on(OrganizationEnrollmentActions.clearEnrollmentData, (state) => ({
    ...state,
    enrollment: null,
    enrollmentShortSummary: null,
  })),
);
