import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AngularMaterialModule } from '../../../modules/angular-material.module';
import { AppButtonSize, AppButtonType } from '../../models/common-components.model';
import { SharedModule } from '../../../shared/shared.module';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { phoneNumberPrefix, phonePrefixMask } from '../../consts/masks';
import { Store } from '@ngrx/store';
import { requestCallAction } from '../../store/actions/actions';
import { formatPhoneToString } from '../../utils/formatPhoneToString';

@Component({
  selector: 'app-get-support',
  templateUrl: './get-support.component.html',
  styleUrl: './get-support.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, AngularMaterialModule, SharedModule, ReactiveFormsModule],
})
export class GetSupportComponent {
  protected readonly btnSizes = AppButtonSize;
  protected readonly btnTypes = AppButtonType;
  protected readonly phonePrefixMask = phonePrefixMask;
  protected readonly phoneNumberPrefix = phoneNumberPrefix;

  form = this.fb.group({
    name: this.fb.control('', [Validators.required]),
    phoneNumber: this.fb.control('', [Validators.required]),
    subject: this.fb.control(''),
  });
  constructor(
    private dialogRef: MatDialogRef<GetSupportComponent>,
    private fb: FormBuilder,
    private store: Store,
  ) {}

  close(): void {
    this.dialogRef.close();
  }

  requestCall(): void {
    const formValue = this.form.value;
    this.store.dispatch(
      requestCallAction({
        body: {
          name: formValue.name!,
          phoneNumber: formatPhoneToString(formValue.phoneNumber!),
          subject: formValue.subject,
        },
      }),
    );
    this.dialogRef.close();
  }
}
