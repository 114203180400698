<div class="app-enrollment">
  <div class="app-enrollment__header">Enrollment</div>
  <div class="app-enrollment__content">
    <app-stepper #cdkStepper>
      <cdk-step label="1 Bill-to">
        <div class="app-enrollment__step">
          <app-enrollment-bill-to [form]="getStepForm('billTo')"></app-enrollment-bill-to>
          <ng-container [ngTemplateOutlet]="stepperActions"></ng-container>
        </div>
      </cdk-step>
      <cdk-step label="2 Ship-to">
        <div class="app-enrollment__step">
          <app-enrollment-ship-to [form]="getStepForm('shipTo')"></app-enrollment-ship-to>
          <ng-container [ngTemplateOutlet]="stepperActions"></ng-container>
        </div>
      </cdk-step>
      <cdk-step label="3 Regulatory Info">
        <div class="app-enrollment__step">
          <app-enrollment-regulatory-info
            [form]="getStepForm('regulatoryInfo')"
          ></app-enrollment-regulatory-info>
          <ng-container [ngTemplateOutlet]="stepperActions"></ng-container>
        </div>
      </cdk-step>
      <cdk-step label="4 Key Contacts">
        <div class="app-enrollment__step">
          <app-enrollment-key-contacts
            [form]="getStepForm('contacts')"
          ></app-enrollment-key-contacts>
          <ng-container [ngTemplateOutlet]="stepperActions"></ng-container>
        </div>
      </cdk-step>
      <cdk-step label="5 Credit Application">
        <div class="app-enrollment__step">
          <app-enrollment-credit-application
            [form]="getStepForm('creditData')"
          ></app-enrollment-credit-application>
          <ng-container [ngTemplateOutlet]="stepperActions"></ng-container>
        </div>
      </cdk-step>
    </app-stepper>
  </div>
</div>
<ng-template #stepperActions>
  <div class="app-enrollment__step-actions">
    <app-button
      *ngIf="cdkStepper?.selectedIndex !== 0"
      [type]="btnTypes.Stroked"
      label="< Back"
      (onClick)="back()"
    ></app-button>
    <div class="app-enrollment__step-navigation">
      @if (
        user &&
        (user.role === ROLES.ADMIN || user.role === ROLES.SUPER_ADMIN) &&
        enrollment?.status === EnrollmentStatusDto.IN_REVIEW &&
        cdkStepper?.selectedIndex === cdkStepper.steps.length - 1 &&
        (organization$ | async)?.status !== OrganizationStatusDto.DEACTIVATED
      ) {
        <div class="app-enrollment__step-decline-approve">
          <app-button [type]="btnTypes.Stroked" label="Decline" (onClick)="decline()"></app-button>
        </div>
        <div class="app-enrollment__step-decline-approve">
          <app-button [type]="btnTypes.Flat" label="Approve" (onClick)="approve()"></app-button>
        </div>
      }
      <app-button
        *ngIf="cdkStepper?.selectedIndex !== cdkStepper.steps.length - 1; else submitBtn"
        [type]="btnTypes.Flat"
        [disabled]="isActionDisabled(cdkStepper.selectedIndex)"
        label="Next"
        (onClick)="next(cdkStepper.selectedIndex)"
      ></app-button>
      <ng-template #submitBtn>
        <app-button
          *ngIf="
            (!enrollment?.status || enrollment?.status === EnrollmentStatusDto.IN_PROGRESS) &&
              user?.role !== ROLES.SUPER_ADMIN &&
              user?.role !== ROLES.ADMIN;
            else closeBtn
          "
          [type]="btnTypes.Flat"
          label="Submit"
          (onClick)="submit()"
          [disabled]="isActionDisabled(cdkStepper.selectedIndex)"
        ></app-button>
      </ng-template>
      <ng-template #closeBtn>
        <app-button [type]="btnTypes.Stroked" label="Close" (onClick)="close()"></app-button>
      </ng-template>
    </div>
  </div>
</ng-template>
