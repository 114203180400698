import { ROLES } from '../consts/roles-and-permissions';

export enum OrderPriorityDto {
  REGULAR_GROUND = 'REGULAR_GROUND',
  TWO_DAYS_AIR = 'TWO_DAYS_AIR',
  NEXT_DAY_AIR = 'NEXT_DAY_AIR',
}

export enum OrderStatusDto {
  SUBMITTED = 'SUBMITTED',
  IN_PROCESS = 'IN_PROCESS',
  DELIVERED = 'DELIVERED',
  CANCELLED = 'CANCELLED',
}

export interface CreateOrderBodyDto {
  quantity: number;
  priority: OrderPriorityDto;
  shippingAddressId: number;
}

export interface CreateOrderResponseDto {
  orderId: number;
  message: string;
}

export interface ConfirmOrderBodyDto {
  quantity: number;
  deliveryComment?: string;
}

export interface ChangeStatusBodyDto {
  status: OrderStatusDto;
}

export interface OrderDto {
  id: number;
  status: OrderStatusDto;
  quantity: number;
  confirmedQuantity: number | null;
  deliveryComment?: string | null;
  priority: OrderPriorityDto;
  expectedDelivery: string;
  confirmedDate: string | null;
  organizationId: number;
  shippingAddressId: number;
  userId: number;
  confirmUserId: number | null;
  createdAt: string;
  updatedAt: string;
  processedDate: string | null;
}

export interface OrderShippingAddressDto {
  id: number;
  street: string;
  state: string;
  city: string;
  zip: string;
  customerName: string;
}

export interface OrderOrganizationDto {
  id: number;
  name: string;
}

export interface OrderUserDto {
  id: number;
  firstName: string;
  lastName: string;
  role: ROLES;
  namePrefix: string | null;
}

export interface GetOrdersOrderDto extends OrderDto {
  shippingAddress: OrderShippingAddressDto;
  organization: OrderOrganizationDto;
  user: OrderUserDto;
  confirmUser: OrderUserDto | null;
}

export interface GetOrdersResponseDto {
  total: number;
  page: number;
  pageSize: number;
  items: GetOrdersOrderDto[];
}

export interface DownloadOrdersRes {
  blob: Blob | null;
  filename: string;
}
