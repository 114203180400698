<div class="app-island" [attr.hovered]="hover">
  <div class="app-island__left-block">
    <div class="app-island__header">
      <ng-content select=".header"></ng-content>
    </div>
    <div class="app-island__content">
      <ng-content select=".content"></ng-content>
    </div>
  </div>

  <div class="app-island__buttons">
    <ng-content select=".buttons"></ng-content>
  </div>
</div>
