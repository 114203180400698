<div class="app-login" automation-id="login-page">
  <app-logo></app-logo>
  <div class="app-login__card">
    <app-card>
      <div class="header">Log In</div>
      <div class="subheader">Please login using your email and password</div>
      <div class="content app-login__card-content" [formGroup]="form">
        <div class="app-login__card-inputs">
          <div class="app-login__card-email">
            <app-input
              #emailInput
              label="Email"
              formControlName="email"
              id="email"
              name="email"
              type="email"
              [maxLength]="128"
              [highlightAsError]="form.invalid && form.hasError('invalidCredentials')"
              automation-id="email-field"
            ></app-input>
          </div>
          <div class="app-login__card-password">
            <app-input
              #passwordInput
              label="Password"
              formControlName="password"
              id="password"
              name="password"
              type="password"
              controlType="password"
              [maxLength]="128"
              [highlightAsError]="form.invalid && form.hasError('invalidCredentials')"
              automation-id="password-field"
            ></app-input>
          </div>
          <a
            automation-id="reset-password"
            routerLink="/login/reset-password"
            class="app-login__card-reset"
          >
            Forgot your password?
          </a>
          <div
            class="app-login__card-be-error"
            *ngIf="form.invalid && form.hasError('invalidCredentials')"
          >
            <app-alert automation-id="login-error" [type]="alertTypes.Error">{{
              beError?.error?.message
            }}</app-alert>
          </div>
        </div>
        <div class="app-login__card-actions">
          <app-button
            automation-id="save-btn"
            [type]="btnTypes.Flat"
            label="Submit"
            htmlType="submit"
            class="app-login__card-submit"
            [disabled]="
              form.invalid &&
              (!autofilled.email || !autofilled.password) &&
              !form.hasError('invalidCredentials')
            "
            [size]="btnSizes.FullWidth"
            (onClick)="submit()"
          ></app-button>
          <div class="body-small app-login__card-policies">
            By clicking Submit button, you agree to LeuPRO Logix
            <a
              (click)="
                $event.preventDefault(); $event.stopImmediatePropagation(); navigateToPrivacyPage()
              "
              style="white-space: nowrap"
              >Privacy Notice</a
            >
            &
            <a
              (click)="
                $event.preventDefault();
                $event.stopImmediatePropagation();
                navigateToTermsOfUsePage()
              "
              style="white-space: nowrap"
              >Terms of Use</a
            >
          </div>
          <div class="body-small app-login__card-partner">Don't have an account yet?</div>
          <app-button
            automation-id="registration-btn"
            [type]="btnTypes.Stroked"
            label="Create New Account"
            class="app-login__card-registration"
            [size]="btnSizes.FullWidth"
            (onClick)="registration()"
          ></app-button>
          <a class="app-login__card-need-help" (click)="getSupport()">
            <mat-icon class="material-icons-outlined">help_outline</mat-icon>
            Get Support
          </a>
        </div>
      </div>
    </app-card>
  </div>
</div>
