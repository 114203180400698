<div class="app-change-password" automation-id="change-password">
  <app-logo></app-logo>
  <div class="app-change-password__card">
    <app-card>
      <div class="header">Set a New Password</div>
      <div class="subheader">
        You are setting up a new password for your account on LeuPRO Logix.
      </div>
      <div class="content app-change-password__card-content">
        <form class="app-change-password__card-inputs" [formGroup]="form" (ngSubmit)="submit()">
          <!--fix for browsers password managers -->
          @if (email) {
            <input
              [hidden]="true"
              type="email"
              id="email"
              name="email"
              autocomplete="email"
              formControlName="email"
            />
          }
          <div class="app-change-password__card-password">
            <app-input
              automation-id="password"
              label="New Password"
              formControlName="password"
              name="new-passsword"
              id="new-passsword"
              autocomplete="new-password"
              type="password"
              controlType="password"
              [maxLength]="128"
              [highlightAsError]="form.get('passwordConfirm')?.hasError('passwordMismatch')"
            ></app-input>
          </div>
          <div class="app-change-password__card-password-confirm">
            <app-input
              automation-id="confirm-password"
              label="Confirm Password"
              formControlName="passwordConfirm"
              name="confirm-password"
              id="confirm-password"
              autocomplete="off"
              type="password"
              controlType="password"
              [maxLength]="128"
            ></app-input>
          </div>
          <div class="app-change-password__card-actions">
            <app-button
              automation-id="submit-btn"
              [type]="btnTypes.Flat"
              label="Submit"
              class="app-change-password__card-submit"
              [disabled]="form.invalid"
              [size]="btnSizes.FullWidth"
              htmlType="submit"
            ></app-button>
            <div class="app-change-password__card-rules" automation-id="rules">
              <div class="app-change-password__card-rule" *ngFor="let rule of rules">
                <mat-icon
                  class="app-change-password__card-rule-icon not-done"
                  *ngIf="
                    form.get('password')?.hasError(rule.errorName) || !form.get('password')?.value;
                    else done
                  "
                  >close
                </mat-icon>
                <ng-template #done>
                  <mat-icon class="app-change-password__card-rule-icon done">done</mat-icon>
                </ng-template>
                <span>{{ rule.label }}</span>
              </div>
            </div>
            <app-button
              automation-id="cancel-btn"
              [type]="btnTypes.Stroked"
              label="Cancel"
              class="app-change-password__card-cancel"
              [size]="btnSizes.FullWidth"
              (onClick)="cancel()"
            ></app-button>
          </div>
          <a class="app-change-password__card-need-help" (click)="getSupport()">
            <mat-icon class="material-icons-outlined">help_outline</mat-icon>
            Get Support
          </a>
        </form>
      </div>
    </app-card>
  </div>
</div>
