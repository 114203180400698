import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-native-checkbox',
  templateUrl: './app-native-checkbox.component.html',
  styleUrl: './app-native-checkbox.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppNativeCheckboxComponent {
  @Input() label: string | undefined;
  @Input() checked: boolean | undefined;
  @Input() indeterminate: boolean | undefined;
  @Input() disabled: boolean | undefined;
  @Output() onChange = new EventEmitter<Event>();
  @Output() onClick = new EventEmitter<Event>();

  constructor() {}

  public change(event: Event): void {
    this.onChange.emit(event);
  }

  public click(event: Event): void {
    this.onClick.emit(event);
  }
}
