import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatOptionModule } from '@angular/material/core';
import { AppIslandComponent } from '../../../../shared/components/app-island/app-island.component';
import { AppButtonSize, AppButtonType } from '../../../../core/models/common-components.model';
import { SharedModule } from '../../../../shared/shared.module';
import { Store } from '@ngrx/store';
import {
  fromOrganizations,
  selectOrganization,
  selectOrganizationEnrollmentShort,
} from '../../store/selectors';
import { OrganizationActions } from '../../store/actions';
import {
  AddressStatusDto,
  EnrollmentStatusDto,
  OrganizationDto,
  OrganizationStatusDto,
  ShippingAddressDto,
} from '../../../../core/models/organization.model';
import { convertFullAddress } from '../../../../core/utils/convertFullAddress';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { combineLatest, filter, map, Observable, tap, withLatestFrom } from 'rxjs';
import { isPresent } from '../../../../core/utils/isPresent';
import { HasPermissionDirective } from '../../../../core/directives/has-permission.directive';
import { PERMISSIONS, ROLES } from 'src/app/core/consts/roles-and-permissions';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { AddNewShippingAddressComponent } from '../add-new-shipping-address/add-new-shipping-address.component';
import { AlmostThereComponent } from '../almost-there/almost-there.component';
import { LetDirective } from '../../../../core/utils/let-directive';
import { IsDeactivatedOrganizationUserDirective } from 'src/app/core/directives/is-deactivated-organization-user.directive';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { selectUser } from 'src/app/core/store/selectors/selectors';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { OrganizationsRoutes } from 'src/app/core/consts/navigation.const';

@Component({
  selector: 'app-shipping-address-list',
  templateUrl: './shipping-address-list.component.html',
  styleUrl: './shipping-address-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatOptionModule,
    AppIslandComponent,
    SharedModule,
    MatProgressSpinnerModule,
    HasPermissionDirective,
    LetDirective,
    IsDeactivatedOrganizationUserDirective,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
  ],
})
export class ShippingAddressListComponent {
  protected readonly PERMISSIONS = PERMISSIONS;
  protected readonly isDefaultBtnIconColor = `#71CE62`;
  protected readonly deleteBtnIconColor = `#C70D0D`;

  AddressStatusDto = AddressStatusDto;

  userNotification$ = combineLatest([
    this.store$.select(selectOrganizationEnrollmentShort),
    this.store$.select(selectOrganization).pipe(filter(isPresent)),
  ]).pipe(
    withLatestFrom(
      this.store$.select(selectUser).pipe(
        filter(isPresent),
        filter((user) => user.role === ROLES.ORG_ADMIN),
      ),
    ),
    tap(([[enrollment, organization], user]) => {
      if (
        (!enrollment || enrollment?.status === EnrollmentStatusDto.IN_PROGRESS) &&
        organization.status === OrganizationStatusDto.ONBOARDING
      ) {
        const ref = this.dialog.open(AlmostThereComponent, {
          data: {
            actions: ["Let's start!"],
          },
        });
        ref
          .afterClosed()
          .subscribe((result) =>
            this.router.navigate([OrganizationsRoutes.ENROLLMENT], { relativeTo: this.route }),
          );
      }
    }),
    first(),
  );

  addresses$: Observable<Array<ShippingAddressDto>> = this.store$
    .select(fromOrganizations.selectOrganizationShippingAddress)
    .pipe(
      filter(isPresent),
      map((addresses) =>
        addresses.filter((address) => address.status !== AddressStatusDto.DELETED),
      ),
      map((addresses) => {
        return [...addresses].sort((a, b) => {
          return a.id - b.id;
        });
      }),
      map((addresses) => {
        const isDefaultIndex = addresses.findIndex((el) => el.isDefault);
        if (isDefaultIndex >= 0) {
          const isDefaultElement = addresses.splice(isDefaultIndex, 1);
          return [...isDefaultElement, ...addresses];
        }
        return [...addresses];
      }),
    );

  organization$ = this.store$.select(fromOrganizations.selectOrganization);
  loadingAddresses$ = this.store$.select(fromOrganizations.selectLoadingAddresses);

  protected readonly btnTypes = AppButtonType;
  protected readonly btnSizes = AppButtonSize;
  protected readonly OrganizationStatusDto = OrganizationStatusDto;
  constructor(
    private readonly store$: Store,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
  ) {}
  addNewShippingAddress() {
    this.dialog.open(AddNewShippingAddressComponent, {
      autoFocus: false,
      maxHeight: '90vh',
      disableClose: true,
      closeOnNavigation: false,
    });
  }
  convertAddress(address: ShippingAddressDto) {
    return convertFullAddress({ ...address });
  }

  setDefault(address: ShippingAddressDto, organization: OrganizationDto) {
    this.store$.dispatch(
      OrganizationActions.defaultShippingAddresses({ id: organization.id, addressId: address.id }),
    );
  }
  delete(address: ShippingAddressDto, organization: OrganizationDto) {
    this.store$.dispatch(
      OrganizationActions.askDeleteShippingAddresses({
        id: organization.id,
        addressId: address.id,
      }),
    );
  }

  viewAddress(address: ShippingAddressDto) {
    if (address.status === AddressStatusDto.DEACTIVATED) {
      return;
    }
    this.dialog.open(AddNewShippingAddressComponent, {
      autoFocus: false,
      maxHeight: '90vh',
      data: address,
    });
  }

  canDeactivate(): boolean {
    return true;
  }

  canDelete(address: ShippingAddressDto): boolean {
    return address.unitsInHand <= 0;
  }

  activate(address: ShippingAddressDto, organization: OrganizationDto) {
    this.store$.dispatch(
      OrganizationActions.askActivateShippingAddresses({
        id: organization.id,
        addressId: address.id,
      }),
    );
  }

  deactivate(address: ShippingAddressDto, organization: OrganizationDto) {
    this.store$.dispatch(
      OrganizationActions.askDeactivateShippingAddresses({
        id: organization.id,
        addressId: address.id,
      }),
    );
  }
}
