@if (form) {
  <div class="app-enrollment-key-contacts" [formGroup]="form">
    <div class="app-enrollment-key-contacts__block" formArrayName="contactsArray">
      @for (group of getFormArray().controls; track group; let i = $index) {
        <div class="app-enrollment-key-contacts__block" [formGroupName]="i">
          <div class="app-enrollment-key-contacts__title">{{ getContactTitle(group) }}</div>
          <div class="app-enrollment-key-contacts__row">
            <div class="app-enrollment-key-contacts__control">
              <app-input label="Name/Title" formControlName="name" [maxLength]="256"></app-input>
            </div>
            <div class="app-enrollment-key-contacts__control">
              <app-input label="Email" formControlName="email" [maxLength]="128"></app-input>
            </div>
          </div>
          <div class="app-enrollment-key-contacts__row">
            <div class="app-enrollment-key-contacts__control">
              <app-input label="Phone" formControlName="phoneNumber" [mask]="phoneMask"></app-input>
            </div>
            <div class="app-enrollment-key-contacts__control">
              <app-input
                label="Cellphone"
                formControlName="cellPhoneNumber"
                [mask]="phoneMask"
              ></app-input>
            </div>
            <div class="app-enrollment-key-contacts__control">
              <app-input label="Fax" formControlName="fax" [mask]="faxMask"></app-input>
            </div>
          </div>
        </div>
      }
    </div>
  </div>
}
