import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store/models/state';
import { filter, Observable } from 'rxjs';
import { UserDto } from 'src/app/core/models/auth.model';
import {
  selectCiplaAdminOrdersNotificationsCount,
  selectUser,
} from 'src/app/core/store/selectors/selectors';
import { ROLES } from 'src/app/core/consts/roles-and-permissions';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isPresent } from '../../utils/isPresent';
import { AppRoutes, OrganizationsRoutes } from 'src/app/core/consts/navigation.const';

interface MenuItem {
  icon: string;
  label: string;
  roles?: string[];
  route?: string;
  highlightForRoutes?: string[];
  counter?: number;
}

@UntilDestroy()
@Component({
  selector: 'app-left-side-menu',
  templateUrl: './left-side-menu.component.html',
  styleUrl: './left-side-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeftSideMenuComponent implements OnInit {
  private homeMenuLabel: string = 'Home';
  private ordersMenuLabel: string = 'Orders';

  public menuItems: MenuItem[] = [
    {
      icon: 'home',
      label: this.homeMenuLabel,
      roles: [ROLES.ORG_ADMIN, ROLES.ORG_USER],
    },
    {
      icon: 'corporate_fare',
      label: 'Organizations',
      roles: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
      route: `/${AppRoutes.ORGANIZATIONS}`,
      highlightForRoutes: [`/${AppRoutes.ORGANIZATIONS}/${OrganizationsRoutes.CREATE}`],
    },
    {
      icon: 'shopping_basket',
      label: this.ordersMenuLabel,
      roles: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
      route: `/${AppRoutes.ORGANIZATIONS_ORDERS}`,
    },
    {
      icon: 'inventory',
      label: 'Inventory',
      roles: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
      route: `/${AppRoutes.ORGANIZATIONS_INVENTORY}`,
    },
    {
      icon: 'help_outline',
      label: 'Support',
      roles: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ORG_ADMIN, ROLES.ORG_USER],
      route: `/${AppRoutes.SUPPORT}`,
    },
    {
      icon: 'admin_panel_settings',
      label: 'Admins List',
      roles: [ROLES.SUPER_ADMIN],
      route: `/${AppRoutes.ADMINS_LIST}`,
    },
  ];
  public selectedItemIndex = 0;
  public orderMenuIndex = this.menuItems.findIndex((item) => item.label === this.ordersMenuLabel);
  public homeMenuIndex = this.menuItems.findIndex((item) => item.label === this.homeMenuLabel);
  public ciplaAdminOrdersNotificationsCount$: Observable<number | null> | undefined;
  public user: UserDto | null | undefined;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.store
      .select(selectUser)
      .pipe(untilDestroyed(this))
      .subscribe((user) => {
        this.user = user;
        this.initHomeMenuRoute();
      });

    this.ciplaAdminOrdersNotificationsCount$ = this.store.select(
      selectCiplaAdminOrdersNotificationsCount,
    );
    this.checkSelected(this.router.url);
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        untilDestroyed(this),
      )
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.checkSelected(event.url);
          this.cdr.markForCheck();
        }
      });
    this.fetchData();
  }

  initHomeMenuRoute(): void {
    const orgId = this.user?.organizationId;

    if (orgId) {
      this.menuItems[this.homeMenuIndex].route = `/${AppRoutes.ORGANIZATIONS}/${orgId}`;
    }
  }

  fetchData(): void {
    this.store
      .select(selectCiplaAdminOrdersNotificationsCount)
      .pipe(untilDestroyed(this), filter(isPresent))
      .subscribe((count) => {
        this.menuItems[this.orderMenuIndex].counter = count;
        this.cdr.markForCheck();
      });
  }

  public selectItem(i: number): void {
    this.selectedItemIndex = i;

    if (this.menuItems[i].route) {
      this.router.navigate([this.menuItems[i].route]);
    }
  }

  private checkSelected(route: string): void {
    if (this.isOrgUserHome(route)) {
      this.selectedItemIndex = 0;
    } else {
      this.selectedItemIndex = this.menuItems.findIndex(
        (item) => item.route === route || item.highlightForRoutes?.includes(route),
      );
    }
  }

  private isOrgUserHome(route: string): boolean {
    return (
      (this.user?.role === ROLES.ORG_ADMIN || this.user?.role === ROLES.ORG_USER) &&
      route === `/${AppRoutes.ORGANIZATIONS}/${this.user.organizationId}`
    );
  }
}
