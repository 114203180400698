<div class="app-add-new-shipping-address">
  @if (this.data) {
    @if (this.data.status === 'IN_REVIEW') {
      <div automation-id="title" class="title">Please Review Ship-To Information</div>

      <div automation-id="subtitle" class="subtitle">
        <app-alert [type]="alertTypes.Info">
          Please note that Cipla USA will verify and approve the provided shipping address and
          regulatory information
        </app-alert>
      </div>
    } @else {
      <div automation-id="title" class="title">Shipping Address</div>
    }
  } @else {
    <div automation-id="title" class="title">Request to Add New Ship-To</div>
    <div automation-id="subtitle" class="subtitle">
      <app-alert [type]="alertTypes.Info">
        Please note that Cipla USA will verify and approve the provided shipping address and
        regulatory information
      </app-alert>
    </div>
  }

  <div automation-id="content" class="content">
    <div *let="submit$ | async" class="form" [formGroup]="form" enctype="multipart/form-data">
      <div class="form-row">
        <app-dropdown
          *let="shippingProvider$ | async"
          automation-id="ship-via-field"
          class="form-control"
          label="Ship via"
          formControlName="shippingProvider"
          [values]="via"
        ></app-dropdown>
        <app-input
          automation-id="account-number-field"
          class="form-control"
          label="Account Number"
          formControlName="accountNumber"
          [mask]="viaMask"
        ></app-input>
      </div>
      <div class="form-row">
        <app-input
          automation-id="customer-name-field"
          class="form-control"
          label="Customer Name"
          formControlName="customerName"
          [maxLength]="256"
        ></app-input>
        <app-input
          automation-id="street-field"
          class="form-control"
          label="Street"
          formControlName="street"
          [maxLength]="256"
        ></app-input>
      </div>
      <div class="form-row">
        <app-input
          automation-id="city-field"
          class="form-control"
          label="City"
          formControlName="city"
          [maxLength]="256"
        ></app-input>
        <app-dropdown
          automation-id="state-field"
          class="form-control"
          label="State"
          formControlName="state"
          [values]="states"
          showAbbreviation="id"
          optionValue="id"
        >
        </app-dropdown>
      </div>
      <div class="form-row">
        <app-input
          automation-id="postal-code-field"
          class="form-control"
          label="ZIP (Postal) Code"
          formControlName="zip"
          [maxLength]="5"
          [mask]="zipCodeMask"
        ></app-input>
        <app-input
          automation-id="email-field"
          class="form-control"
          label="Email"
          formControlName="email"
          [maxLength]="128"
        ></app-input>
      </div>
      <div class="form-row">
        <app-input
          automation-id="phone-field"
          class="form-control"
          label="Phone Number"
          formControlName="phoneNumber"
          [prefix]="prefix"
          [mask]="phoneMask"
        ></app-input>
      </div>

      <div class="license-title">State License</div>
      <div class="form-row">
        <app-input
          automation-id="license-number-field"
          class="form-control"
          label="State License Number"
          formControlName="stateLicenceNumber"
          [maxLength]="256"
        ></app-input>
        <app-input
          automation-id="state-licence-type-field"
          class="form-control"
          label="Type"
          formControlName="stateLicenceType"
          [maxLength]="256"
        ></app-input>
        <app-datepicker
          automation-id="state-licence-exp-date-field"
          class="form-control"
          label="Expiration Date"
          formControlName="stateLicenceExpDate"
          [filter]="afterTodayFilter"
          [readonly]="true"
        ></app-datepicker>
      </div>
      <div class="form-row">
        <app-file-input
          [requiredFileName]="'State License'"
          [control]="form.controls.stateLicenceFile"
          (onDownload)="downloadFile($event)"
        ></app-file-input>
      </div>

      <div class="license-title">Drug Enforcement Administration/DEA</div>
      <div class="form-row">
        <app-input
          automation-id="dea-number-field"
          class="form-control"
          label="DEA Number"
          formControlName="deaNumber"
          [maxLength]="9"
          [mask]="deaNumberMask"
        ></app-input>
        <app-input
          automation-id="dea-schedule-field"
          class="form-control"
          label="Schedule"
          formControlName="deaSchedule"
          [maxLength]="256"
        ></app-input>
        <app-datepicker
          automation-id="dea-exp-date-field"
          class="form-control"
          label="Expiration Date"
          formControlName="deaExpDate"
          [filter]="afterTodayFilter"
          [readonly]="true"
        ></app-datepicker>
      </div>
      <div class="form-row">
        <app-file-input
          [requiredFileName]="'DEA License'"
          [control]="form.controls.deaLicenceFile"
          (onDownload)="downloadFile($event)"
        ></app-file-input>
      </div>

      <ng-container *ngIf="enableTaxInformation">
        <div class="license-title">Tax Information</div>
        <div class="form-row">
          <app-input
            automation-id="dea-number-field"
            class="form-control"
            label="Resale Certificate No./Tax Exemption Certificate No."
            formControlName="taxNumber"
            [maxLength]="16"
            [mask]="resaleCertificateMask"
          ></app-input>
          <app-datepicker
            automation-id="dea-exp-date-field"
            class="form-control"
            label="Expiration Date"
            formControlName="taxExpDate"
            [filter]="afterTodayFilter"
            [readonly]="true"
          ></app-datepicker>
        </div>
        <div class="form-row">
          <app-file-input
            [requiredFileName]="'Resale Certificate / Tax Exemption Certificate'"
            [control]="form.controls.taxLicenceFile"
            (onDownload)="downloadFile($event)"
          ></app-file-input>
        </div>
      </ng-container>

      <div
        *ngIf="organization$ | async as organization"
        automation-id="buttons-block"
        class="form-row buttons-block"
      >
        <ng-container *ngIf="data?.status === AddressStatusDto.IN_REVIEW">
          <app-button
            *appHasPermission="PERMISSIONS.organizationAccount.canApproveShipping"
            [type]="btnTypes.Flat"
            label="Approve"
            class="approve-btn"
            [size]="btnSizes.Basic"
            (click)="approve(data, organization)"
          ></app-button>
          <app-button
            *appHasPermission="PERMISSIONS.organizationAccount.canDeclineShipping"
            [type]="btnTypes.Basic"
            label="Decline"
            class="set-default-btn"
            [size]="btnSizes.Basic"
            (click)="decline(data, organization)"
          ></app-button>
        </ng-container>

        <ng-container *ngIf="!data">
          <ng-container *let="sewShippingAddressSaving$ | async as sewShippingAddressSaving">
            <app-button
              *appHasPermission="PERMISSIONS.organizationAccount.canAddShipping"
              automation-id="submit-btn"
              [type]="btnTypes.Flat"
              label="Submit"
              [disabled]="form.invalid || !!sewShippingAddressSaving"
              (click)="submit(organization.id, sewShippingAddressSaving)"
            ></app-button>
          </ng-container>
        </ng-container>

        <app-button
          automation-id="cancel-btn"
          [type]="btnTypes.Stroked"
          label="Close"
          (onClick)="cancel()"
        ></app-button>
      </div>
    </div>
  </div>
  <div automation-id="actions" class="actions">
    <div style="display: none" cdkFocusInitial></div>
  </div>
</div>
