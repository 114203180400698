import { AfterViewInit, ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { CommonModule } from '@angular/common';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Store } from '@ngrx/store';
import { fromOrganizations } from '../../store/selectors';
import { LetDirective } from '../../../../core/utils/let-directive';
import { OrganizationActions } from '../../store/actions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppButtonSize, AppButtonType } from '../../../../core/models/common-components.model';
import { SharedModule } from '../../../../shared/shared.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { map } from 'rxjs';
import { OrganizationStatusFactory } from '../../../../core/factories/organization-status.factory';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RoleToLabelPipe } from '../../../../core/pipes/role-to-label.pipe';
import { AppRoutes } from 'src/app/core/consts/navigation.const';

@UntilDestroy()
@Component({
  selector: 'app-organizations-list',
  templateUrl: './organizations-list.component.html',
  styleUrl: './organizations-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatTableModule,
    CommonModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    LetDirective,
    SharedModule,
    MatFormFieldModule,
    MatIconModule,
    MatTooltipModule,
    RoleToLabelPipe,
  ],
})
export class OrganizationsListComponent implements AfterViewInit {
  displayedColumns: string[] = ['id', 'npi', 'name', 'admin', 'userCount', 'status', 'action'];

  data$ = this.store$.select(fromOrganizations.selectOrganizations);
  resultsLength$ = this.store$.select(fromOrganizations.selectTotal);
  pageSize$ = this.store$.select(fromOrganizations.selectPageSize);
  pageIndex$ = this.store$.select(fromOrganizations.selectPage).pipe(
    map((page) => {
      return page - 1;
    }),
  );
  isLoadingResults$ = this.store$.select(fromOrganizations.selectOrganizationsLoading);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private readonly store$: Store,
    private readonly router: Router,
    readonly organizationStatusFactory: OrganizationStatusFactory,
  ) {}

  ngAfterViewInit(): void {
    this.sort.sortChange.pipe(untilDestroyed(this)).subscribe((sort) => {
      this.store$.dispatch(OrganizationActions.setSearchOrderBy({ orderBy: sort.active }));
      this.store$.dispatch(OrganizationActions.setSearchSortOrder({ sortOrder: sort.direction }));
    });

    this.paginator.page.pipe(untilDestroyed(this)).subscribe((page) => {
      this.store$.dispatch(OrganizationActions.setSearchPage({ page: page.pageIndex + 1 }));
    });
  }

  protected readonly btnTypes = AppButtonType;
  protected readonly btnSizes = AppButtonSize;

  navigateToAccount(id: number) {
    this.router.navigate([AppRoutes.ORGANIZATIONS, id]);
  }

  getStatusName(status: string) {
    return this.organizationStatusFactory.getById(status).name;
  }

  handlePageEvent({ pageSize }: PageEvent) {
    this.store$.dispatch(OrganizationActions.setSearchPageSize({ pageSize }));
  }
}
