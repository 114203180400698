export enum EnrollmentContactType {
  PURCHASING = 'PURCHASING',
  ACCOUNTS_PAYABLE = 'ACCOUNTS_PAYABLE',
}

export const naturesOfEntity = [
  { id: 'Sole Proprietorship', name: 'Sole Proprietorship' },
  { id: 'Partnership', name: `Partnership` },
  { id: 'Corporation', name: `Corporation` },
  { id: 'LLC', name: `LLC` },
  { id: 'Others', name: `Others` },
];
