import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CdkStepper, CdkStepperModule } from '@angular/cdk/stepper';
import { SharedModule } from 'src/app/shared/shared.module';
import { EnrollmentBillToComponent } from 'src/app/modules/organizations/components/enrollment/enrollment-bill-to/enrollment-bill-to.component';
import { EnrollmentShipToComponent } from 'src/app/modules/organizations/components/enrollment/enrollment-ship-to/enrollment-ship-to.component';
import {
  AppButtonType,
  FileFormControlValue,
} from '../../../../core/models/common-components.model';
import { CommonModule, Location } from '@angular/common';
import { EnrollmentRegulatoryInfoComponent } from 'src/app/modules/organizations/components/enrollment/enrollment-regulatory-info/enrollment-regulatory-info.component';
import { EnrollmentKeyContactsComponent } from 'src/app/modules/organizations/components/enrollment/enrollment-key-contacts/enrollment-key-contacts.component';
import { EnrollmentCreditApplicationComponent } from 'src/app/modules/organizations/components/enrollment/enrollment-credit-application/enrollment-credit-application.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { emailFormatValidator, maxFileSize } from 'src/app/core/utils/validators';
import { filter } from 'rxjs';
import { Store } from '@ngrx/store';
import { OrganizationEnrollmentActions } from 'src/app/modules/organizations/store/actions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  selectOrganization,
  selectOrganizationEnrollment,
} from 'src/app/modules/organizations/store/selectors';
import { isPresent } from 'src/app/core/utils/isPresent';
import {
  EnrollmentContactType,
  naturesOfEntity,
} from 'src/app/modules/organizations/models/enrollment.model';
import {
  EnrollmentBillingAddressDto,
  EnrollmentContactDto,
  EnrollmentCreditDataDto,
  EnrollmentDto,
  EnrollmentLicenceName,
  EnrollmentLicencesReqDto,
  EnrollmentReferenceDto,
  EnrollmentShippingAddressDto,
  EnrollmentStatusDto,
  OrganizationStatusDto,
} from 'src/app/core/models/organization.model';
import { format } from 'date-fns/format';
import { selectUser } from 'src/app/core/store/selectors/selectors';
import { UserDto } from 'src/app/core/models/auth.model';
import { ROLES } from 'src/app/core/consts/roles-and-permissions';
import { DialogsActions } from 'src/app/modules/dialogs/store/actions';

@UntilDestroy()
@Component({
  selector: 'app-enrollment',
  templateUrl: './enrollment.component.html',
  styleUrl: './enrollment.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    CdkStepperModule,
    SharedModule,
    EnrollmentBillToComponent,
    EnrollmentShipToComponent,
    EnrollmentRegulatoryInfoComponent,
    EnrollmentKeyContactsComponent,
    EnrollmentCreditApplicationComponent,
  ],
})
export class EnrollmentComponent implements OnInit, OnDestroy {
  @ViewChild('cdkStepper') stepper!: CdkStepper;
  public form = this.fb.group({
    billTo: this.fb.group({
      sameAsOrganization: this.fb.control<boolean>(false),
      customerName: this.fb.control<string>('', {
        validators: Validators.required,
      }),
      street: this.fb.control<string>('', { validators: Validators.required }),
      city: this.fb.control<string>('', { validators: Validators.required }),
      state: this.fb.control<string>('', { validators: Validators.required }),
      zip: this.fb.control<string>('', { validators: Validators.required }),
      email: this.fb.control<string>('', {
        validators: [Validators.required, emailFormatValidator()],
      }),
      phoneNumber: this.fb.control<string>('', {
        validators: [Validators.required],
      }),
      fax: this.fb.control<string>(''),
    }),
    shipTo: this.fb.group({
      shippingProvider: this.fb.control<string>(''),
      accountNumber: this.fb.control<string>({ value: '', disabled: true }),
      customerName: this.fb.control<string>('', { validators: [Validators.required] }),
      street: this.fb.control<string>('', { validators: Validators.required }),
      city: this.fb.control<string>('', { validators: Validators.required }),
      state: this.fb.control<string>('', { validators: Validators.required }),
      zip: this.fb.control<string>('', { validators: Validators.required }),
      email: this.fb.control<string>('', {
        validators: [Validators.required, emailFormatValidator()],
      }),
      phoneNumber: this.fb.control<string>('', { validators: [Validators.required] }),
      fax: this.fb.control<string>(''),
    }),
    regulatoryInfo: this.fb.group({
      stateLicence: this.fb.group({
        stateLicenceNumber: this.fb.control<string>('', { validators: [Validators.required] }),
        stateLicenceType: this.fb.control<string>(''),
        stateLicenceExpDate: this.fb.control<Date | null>(null, {
          validators: [Validators.required],
        }),
        stateLicenceFile: this.fb.control<FileFormControlValue | null>(null, {
          validators: [Validators.required, maxFileSize(5000000)],
        }),
      }),
      dea: this.fb.group({
        deaNumber: this.fb.control<string>('', { validators: [Validators.required] }),
        deaSchedule: this.fb.control<string>(''),
        deaExpDate: this.fb.control<Date | null>(null, { validators: [Validators.required] }),
        deaLicenceFile: this.fb.control<FileFormControlValue | null>(null, {
          validators: [Validators.required, maxFileSize(5000000)],
        }),
      }),
      taxInformation: this.fb.group({
        taxNumber: this.fb.control<string>('', { validators: [Validators.required] }),
        taxExpDate: this.fb.control<Date | null>(null, { validators: [Validators.required] }),
        taxLicenceFile: this.fb.control<FileFormControlValue | null>(null, {
          validators: [Validators.required, maxFileSize(5000000)],
        }),
      }),
    }),
    contacts: this.fb.group({
      contactsArray: this.fb.array([
        this.initContactFormGroup(EnrollmentContactType.PURCHASING),
        this.initContactFormGroup(EnrollmentContactType.ACCOUNTS_PAYABLE),
      ]),
    }),
    creditData: this.fb.group({
      businessContactInformation: this.fb.group({
        companyName: this.fb.control<string>('', { validators: [Validators.required] }),
        dunsNo: this.fb.control<string>(''), // 9 digits
        registeredSince: this.fb.control<Date | null>(null, { validators: Validators.required }),
        registeredAddress: this.fb.control<string>('', { validators: [Validators.required] }),
        cityStateZip: this.fb.control<string>('', { validators: [Validators.required] }),
        accountReceivableEmail: this.fb.control<string>('', {
          validators: [Validators.required, emailFormatValidator()],
        }),
        phone: this.fb.control<string>('', { validators: [Validators.required] }),
        fax: this.fb.control<string>(''),
        natureOfBusiness: this.fb.control<string>('', { validators: [Validators.required] }),
        fEinNo: this.fb.control<string>('', { validators: [Validators.required] }),
        natureOfEntity: this.fb.control<string | null>(null, { validators: [Validators.required] }),
        specifyOthers: this.fb.control<string>({ value: '', disabled: true }, {}),
      }),
      businessCreditInformation: this.fb.group({
        registeredAddressBusiness: this.fb.control<string>('', {
          validators: [Validators.required],
        }),
        cityStateZipBusiness: this.fb.control<string>('', { validators: [Validators.required] }),
        isBankruptcyInitiated: this.fb.control<boolean | null>(null, {
          validators: [Validators.required],
        }),
      }),
      bankReference: this.fb.group({
        bankName: this.fb.control<string>('', { validators: [Validators.required] }),
        bankAddress: this.fb.control<string>('', { validators: [Validators.required] }),
        typeOfAccount: this.fb.control<string>('', { validators: [Validators.required] }),
        accountNo: this.fb.control<string>('', { validators: [Validators.required] }),
        bankContactPerson: this.fb.control<string>('', { validators: [Validators.required] }),
        bankPhone: this.fb.control<string>('', { validators: [Validators.required] }),
      }),
      businessReferenceInformation: this.fb.group({
        references: this.fb.array([
          this.initReferenceFormGroup(),
          this.initReferenceFormGroup(),
          this.initReferenceFormGroup(),
        ]),
      }),
      confirmation: this.fb.control<boolean>(false, {
        validators: [Validators.requiredTrue],
      }),
    }),
  });
  public enrollment: EnrollmentDto | undefined;
  public user: UserDto | undefined;
  public organization$ = this.store.select(selectOrganization);
  protected readonly btnTypes = AppButtonType;
  protected readonly EnrollmentStatusDto = EnrollmentStatusDto;
  protected readonly ROLES = ROLES;
  protected readonly OrganizationStatusDto = OrganizationStatusDto;

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private location: Location,
  ) {}

  ngOnInit() {
    this.store.dispatch(OrganizationEnrollmentActions.getOrganizationEnrollment());
    this.store
      .select(selectOrganizationEnrollment)
      .pipe(filter(isPresent), untilDestroyed(this))
      .subscribe((enrollment) => {
        this.enrollment = enrollment;
        this.stepper?.reset();
        if (
          enrollment.billTo ||
          enrollment.shipTo ||
          enrollment.contacts ||
          enrollment.creditData
        ) {
          this.patchFormValue(enrollment);
        }

        if (enrollment.status !== EnrollmentStatusDto.IN_PROGRESS) {
          this.form.disable({ emitEvent: false });
        }

        setTimeout(() => {
          this.form.markAsPristine();
        });
      });
    this.store
      .select(selectUser)
      .pipe(filter(isPresent), untilDestroyed(this))
      .subscribe((user) => {
        this.user = user;
        if (user.role === ROLES.ADMIN || user.role === ROLES.SUPER_ADMIN) {
          this.form.disable({ emitEvent: false });
        }
      });
  }

  getStepForm(
    stepName: 'billTo' | 'shipTo' | 'regulatoryInfo' | 'contacts' | 'creditData',
  ): FormGroup {
    return this.form.get(stepName) as FormGroup;
  }

  submit(): void {
    const formData = { ...this.form.value };
    const specifyOthers = formData.creditData?.businessContactInformation?.specifyOthers;
    delete formData.creditData?.businessContactInformation?.['specifyOthers'];
    const creditData = {
      ...formData.creditData?.businessContactInformation,
      ...formData.creditData?.businessCreditInformation,
      ...formData.creditData?.bankReference,
      ...formData.creditData?.businessReferenceInformation,
      registeredSince: format(
        formData.creditData?.businessContactInformation?.registeredSince!,
        'yyyy-M-d',
      ),
      natureOfEntity: specifyOthers
        ? specifyOthers
        : formData.creditData?.businessContactInformation?.natureOfEntity!,
      isBankruptcyInitiated:
        !!formData.creditData?.businessCreditInformation?.isBankruptcyInitiated,
      references: formData?.creditData?.businessReferenceInformation
        ?.references as EnrollmentReferenceDto[],
    } as EnrollmentCreditDataDto;
    this.form.disable();
    this.store.dispatch(
      OrganizationEnrollmentActions.updateEnrollmentCreditData({
        creditData: creditData,
      }),
    );
  }

  close(): void {
    this.location.back();
  }

  approve(): void {
    this.store.dispatch(OrganizationEnrollmentActions.approveEnrollment());
  }

  decline(): void {
    this.store.dispatch(
      DialogsActions.showDialog({
        data: {
          actions: ['Close', 'Yes, Decline'],
          content:
            'Please note, that the entered data will permanently be removed from the Organization account. Are you sure you want to continue?',
          title: 'You are about to decline the Enrollment Form',
        },
        callback: (action) => {
          if (action === 'Yes, Decline') {
            this.store.dispatch(OrganizationEnrollmentActions.declineEnrollment());
          }
        },
      }),
    );
  }

  next(currentStep: number): void {
    const formData = { ...this.form.value };
    switch (currentStep) {
      case 0: {
        if (this.form.get('billTo')?.dirty && this.form.get('billTo')?.touched) {
          delete formData.billTo?.['sameAsOrganization'];
          this.store.dispatch(
            OrganizationEnrollmentActions.createOrganizationEnrollmentAndUpdateBillTo({
              billTo: formData.billTo as EnrollmentBillingAddressDto,
            }),
          );
          this.form.controls.billTo.markAsPristine();
        }
        break;
      }
      case 1: {
        if (this.form.get('shipTo')?.dirty && this.form.get('shipTo')?.touched) {
          this.store.dispatch(
            OrganizationEnrollmentActions.updateEnrollmentShipTo({
              shipTo: formData.shipTo as EnrollmentShippingAddressDto,
            }),
          );
          this.form.controls.shipTo.markAsPristine();
        }
        break;
      }
      case 2: {
        if (this.form.get('regulatoryInfo')?.dirty && this.form.get('regulatoryInfo')?.touched) {
          this.store.dispatch(
            OrganizationEnrollmentActions.updateEnrollmentLicences({
              licences: this.mapFormToLicences(),
            }),
          );
          this.form.controls.regulatoryInfo.markAsPristine();
        }
        break;
      }
      case 3: {
        if (this.form.get('contacts')?.dirty && this.form.get('contacts')?.touched) {
          this.store.dispatch(
            OrganizationEnrollmentActions.updateEnrollmentContacts({
              contacts: formData.contacts?.contactsArray as EnrollmentContactDto[],
            }),
          );
          this.form.controls.contacts.markAsPristine();
        }
        break;
      }
      default: {
        break;
      }
    }
    if (this.stepper?.selected) {
      this.stepper.selected.completed = true;
    }
    this.stepper.next();
  }

  back(): void {
    if (this.stepper?.selected) this.stepper.selected.completed = false;
    this.stepper.previous();
  }

  isActionDisabled(currentStep: number): boolean {
    switch (currentStep) {
      case 0: {
        return !!this.form.get('billTo')?.invalid;
      }
      case 1: {
        return !!this.form.get('shipTo')?.invalid;
      }
      case 2: {
        return !!this.form.get('regulatoryInfo')?.invalid;
      }
      case 3: {
        return !!this.form.get('contacts')?.invalid;
      }
      case 4: {
        return !!this.form.get('creditData')?.invalid;
      }
    }
    return true;
  }

  canDeactivateEnrollment(): boolean {
    console.log(this.form);
    return this.form.pristine || this.form.disabled;
  }

  private initContactFormGroup(contactType: EnrollmentContactType) {
    return this.fb.group({
      contactType: this.fb.control(contactType),
      name: this.fb.control<string>('', { validators: [Validators.required] }),
      email: this.fb.control<string>('', {
        validators: [Validators.required, emailFormatValidator()],
      }),
      phoneNumber: this.fb.control<string>('', { validators: [Validators.required] }),
      cellPhoneNumber: this.fb.control<string>(''),
      fax: this.fb.control<string>(''),
    });
  }

  private initReferenceFormGroup(): FormGroup {
    return this.fb.group({
      companyName: this.fb.control<string>(''),
      accountNo: this.fb.control<string>(''),
      bankAddress: this.fb.control<string>(''),
      cityStateZip: this.fb.control<string>(''),
      customerServiceEmail: this.fb.control<string>('', { validators: emailFormatValidator() }),
      phone: this.fb.control<string>(''),
      fax: this.fb.control<string>(''),
    });
  }

  private patchFormValue(enrollment: EnrollmentDto): void {
    if (enrollment.billTo) {
      const billTo = {
        customerName: enrollment.billTo.customerName,
        state: enrollment.billTo.state,
        zip: enrollment.billTo.zip,
        city: enrollment.billTo.city,
        street: enrollment.billTo.street,
        email: enrollment.billTo.email,
        phoneNumber: enrollment.billTo.phoneNumber,
        fax: enrollment.billTo.fax,
        sameAsOrganization: false,
      };
      this.form.get('billTo')?.patchValue(billTo, { emitEvent: false });
    }
    if (enrollment.shipTo) {
      const shipTo = {
        accountNumber: enrollment.shipTo.accountNumber ?? null,
        shippingProvider: enrollment.shipTo.shippingProvider ?? null,
        customerName: enrollment.shipTo.customerName ?? null,
        street: enrollment.shipTo.street ?? null,
        city: enrollment.shipTo.city ?? null,
        state: enrollment.shipTo.state ?? null,
        zip: enrollment.shipTo.zip ?? null,
        email: enrollment.shipTo.email ?? null,
        phoneNumber: enrollment.shipTo.phoneNumber ?? null,
        fax: enrollment.shipTo.fax ?? null,
      };
      if (enrollment.shipTo.accountNumber) {
        this.form.get('shipTo.accountNumber')?.enable({ emitEvent: false });
      }
      this.form.get('shipTo')?.patchValue(shipTo, { emitEvent: false });
    }
    if (enrollment.licences?.length) {
      const licenceItem = enrollment.licences.find(
        (licence) => licence.name === EnrollmentLicenceName.STATE,
      );
      const stateLicence = {
        stateLicenceNumber: licenceItem?.stateNumber ?? null,
        stateLicenceType: licenceItem?.stateType ?? null,
        stateLicenceExpDate: licenceItem?.expirationDate
          ? new Date(licenceItem.expirationDate)
          : null,
        stateLicenceFile: {
          file: licenceItem?.file ?? null,
          id: licenceItem?.id,
        },
      };
      const deaItem = enrollment.licences.find(
        (licence) => licence.name === EnrollmentLicenceName.DEA,
      );
      const dea = {
        deaNumber: deaItem?.deaNumber ?? null,
        deaSchedule: deaItem?.deaSchedule ?? null,
        deaExpDate: deaItem?.expirationDate ? new Date(deaItem.expirationDate) : null,
        deaLicenceFile: {
          file: deaItem?.file ?? null,
          id: deaItem?.id,
        },
      };
      const taxItem = enrollment.licences.find(
        (licence) => licence.name === EnrollmentLicenceName.TAX,
      );
      const taxInformation = {
        taxNumber: taxItem?.taxCertNumber ?? null,
        taxExpDate: taxItem?.expirationDate ? new Date(taxItem.expirationDate) : null,
        taxLicenceFile: {
          file: taxItem?.file,
          id: taxItem?.id,
        },
      };
      this.form
        .get('regulatoryInfo')
        ?.patchValue({ stateLicence, dea, taxInformation }, { emitEvent: false });
    }
    if (enrollment.contacts?.length) {
      this.form
        .get('contacts.contactsArray')
        ?.patchValue(enrollment.contacts, { emitEvent: false });
    }
    if (enrollment.creditData) {
      const natureOfEntity =
        naturesOfEntity.find((entity) => entity.id === enrollment.creditData?.natureOfEntity)?.id ||
        'Others';
      if (natureOfEntity === 'Others') {
        this.form
          .get('creditData.businessContactInformation.specifyOthers')
          ?.enable({ emitEvent: false });
      }
      const creditData = {
        businessContactInformation: {
          companyName: enrollment.creditData.companyName,
          dunsNo: enrollment.creditData.dunsNo ?? null,
          registeredSince: enrollment.creditData.registeredSince
            ? new Date(enrollment.creditData.registeredSince)
            : null,
          registeredAddress: enrollment.creditData.registeredAddress,
          cityStateZip: enrollment.creditData.cityStateZip,
          accountReceivableEmail: enrollment.creditData.accountReceivableEmail,
          phone: enrollment.creditData.phone,
          fax: enrollment.creditData.fax ?? null,
          natureOfBusiness: enrollment.creditData.natureOfBusiness,
          fEinNo: enrollment.creditData.fEinNo,
          natureOfEntity: natureOfEntity,
          specifyOthers: natureOfEntity === 'Others' ? enrollment.creditData?.natureOfEntity : null,
        },
        businessCreditInformation: {
          registeredAddressBusiness: enrollment.creditData.registeredAddressBusiness,
          cityStateZipBusiness: enrollment.creditData.cityStateZipBusiness,
          isBankruptcyInitiated: enrollment.creditData.isBankruptcyInitiated,
        },
        bankReference: {
          bankName: enrollment.creditData.bankName,
          bankAddress: enrollment.creditData.bankAddress,
          typeOfAccount: enrollment.creditData.typeOfAccount,
          accountNo: enrollment.creditData.accountNo,
          bankContactPerson: enrollment.creditData.bankContactPerson,
          bankPhone: enrollment.creditData.bankPhone,
        },
        businessReferenceInformation: {
          references: enrollment.creditData.references,
        },
        confirmation: true,
      };
      this.form.get('creditData')?.patchValue(creditData, { emitEvent: false });
    }
  }

  private mapFormToLicences(): EnrollmentLicencesReqDto {
    const formData = { ...this.form.value };
    return {
      deaExpDate: format(formData.regulatoryInfo?.dea?.deaExpDate!, 'yyyy-M-d'),
      deaLicenceFile: formData.regulatoryInfo?.dea?.deaLicenceFile?.file,
      deaNumber: formData.regulatoryInfo?.dea?.deaNumber!,
      deaSchedule: formData.regulatoryInfo?.dea?.deaSchedule,
      stateLicenceExpDate: format(
        formData.regulatoryInfo?.stateLicence?.stateLicenceExpDate!,
        'yyyy-M-d',
      ),
      stateLicenceFile: formData.regulatoryInfo?.stateLicence?.stateLicenceFile?.file,
      stateLicenceNumber: formData.regulatoryInfo?.stateLicence?.stateLicenceNumber!,
      stateLicenceType: formData.regulatoryInfo?.stateLicence?.stateLicenceType,
      taxExpDate: format(formData.regulatoryInfo?.taxInformation?.taxExpDate!, 'yyyy-M-d'),
      taxLicenceFile: formData.regulatoryInfo?.taxInformation?.taxLicenceFile?.file,
      taxNumber: formData.regulatoryInfo?.taxInformation?.taxNumber!,
    };
  }

  ngOnDestroy() {}
}
