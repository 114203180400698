import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { ValidationErrorsService } from 'src/app/shared/services/validation-errors.service';

@Component({
  selector: 'app-dropdown',
  templateUrl: './app-dropdown.component.html',
  styleUrl: './app-dropdown.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppDropdownComponent implements ControlValueAccessor {
  @Input() label: string | undefined;
  @Input() labelPostfixIcon: string | undefined;
  @Input() labelPostfixIconColor: string | undefined;
  @Input() labelPostfixIconTooltip: string | undefined;

  @Input() values: any[] = [];
  @Input() optionIcon: string = '';
  @Input() optionIconColor: string = '';
  @Input() optionLabel: string = 'name';
  @Input() optionLabel2: string = '';

  @Input() optionLabelFn: (value: any) => string;
  @Input() optionLabelSecondLine: string = '';
  @Input() optionLabelRightContent: string = '';
  @Input() optionValue: string = 'id';
  @Input() showAbbreviation: string = '';
  @Input() displayExtraSection: boolean = false;
  @Input() optionDisabled: string = 'disabled';
  @Input() defaultPlaceholder: string = '-Select-';
  @Input() emptyListPlaceholder: string = '-Select-';

  @Output() onSelect: EventEmitter<MatSelectChange> = new EventEmitter<MatSelectChange>();
  @Output() onSelectExtraSection: EventEmitter<void> = new EventEmitter<void>();
  @Output() labelPostfixIconClick = new EventEmitter<void>();

  selectedData:
    | {
        value: any;
        text: string;
        icon?: string;
        iconColor?: string;
        optionLabel2?: string;
        showAbbreviation?: string;
      }
    | undefined;

  get mapperProvided() {
    return !!this.optionLabelFn;
  }

  get mapperPlaceholder() {
    return this.values.length ? this.defaultPlaceholder : this.emptyListPlaceholder;
  }

  get control(): FormControl {
    return this.ngControl?.control as FormControl;
  }
  get isRequired(): boolean {
    const validator = this.control.validator ? this.control.validator({} as AbstractControl) : null;
    return validator && validator['required'];
  }

  constructor(
    @Self() @Optional() public ngControl: NgControl,
    private validationErrorsService: ValidationErrorsService,
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  clickOption(event: any) {
    this.selectedValue({ value: event[this.optionValue] } as MatSelectChange);
  }

  selectedValue(event: MatSelectChange) {
    this.selectedData = {
      value: event.value,
      text:
        this.mapperProvided && event.value
          ? this.optionLabelFn(
              this.values.find((item: any) => {
                return item[this.optionValue] === event.value;
              }),
            )
          : this.values.find((item: any) => {
              return item[this.optionValue] === event.value;
            })?.[this.optionLabel],
      icon: this.values.find((item: any) => {
        return item[this.optionValue] === event.value;
      })?.[this.optionIcon],
      iconColor: this.values.find((item: any) => {
        return item[this.optionValue] === event.value;
      })?.[this.optionIconColor],
      optionLabel2: this.values.find((item: any) => {
        return item[this.optionValue] === event.value;
      })?.[this.optionLabel2],
      showAbbreviation: this.values.find((item: any) => {
        return item[this.optionValue] === event.value;
      })?.[this.showAbbreviation],
    };
    this.onSelect.emit(event);
  }

  public getErrorMessage(): string {
    if (this.control?.invalid && this.control?.errors) {
      return this.validationErrorsService.getControlErrorMessage(this.control.errors);
    }
    return '';
  }

  onChange: any = (value: string) => {};
  onTouched: any = () => {};

  selectedExtraSection() {
    this.onSelectExtraSection.emit();
  }

  public registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public writeValue(value: any): void {
    this.selectedData = {
      value: value,
      text:
        this.mapperProvided && value
          ? this.optionLabelFn(
              this.values.find((item: any) => {
                return item[this.optionValue] === value;
              }),
            )
          : this.values.find((item: any) => {
              return item[this.optionValue] === value;
            })?.[this.optionLabel],
      icon: this.values.find((item: any) => {
        return item[this.optionValue] === value;
      })?.[this.optionIcon],
      iconColor: this.values.find((item: any) => {
        return item[this.optionValue] === value;
      })?.[this.optionIconColor],
      optionLabel2: this.values.find((item: any) => {
        return item[this.optionValue] === value;
      })?.[this.optionLabel2],
      showAbbreviation: this.values.find((item: any) => {
        return item[this.optionValue] === value;
      })?.[this.showAbbreviation],
    };
  }
}
