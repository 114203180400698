import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AppButtonSize, AppButtonType } from 'src/app/core/models/common-components.model';
import { AddressStatusDto } from 'src/app/core/models/organization.model';
import { getAddressColorByStatus } from '../../../core/utils/getAddressColorByStatus';

@Component({
  selector: 'app-address-status',
  templateUrl: './app-address-status.component.html',
  styleUrl: './app-address-status.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppAddressStatusComponent {
  @Input() status: AddressStatusDto | string | undefined;
  @Input() default: boolean | undefined;

  protected readonly btnTypes = AppButtonType;
  protected readonly btnSizes = AppButtonSize;

  get statusText(): string | undefined {
    if (this.default) {
      return 'Default';
    }

    switch (this.status) {
      case AddressStatusDto.ACTIVE: {
        return 'Approved';
      }
      case AddressStatusDto.IN_REVIEW: {
        return 'In Review';
      }
      case AddressStatusDto.DEACTIVATED: {
        return 'Deactivated';
      }
    }

    return this.status?.toLowerCase();
  }

  get getColorByStatus() {
    return getAddressColorByStatus(this.status, this.default);
  }
}
