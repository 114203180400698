import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { combineLatestWith, debounceTime, filter } from 'rxjs';
import { selectUser } from 'src/app/core/store/selectors/selectors';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store/models/state';
import { UserDto } from 'src/app/core/models/auth.model';
import { ROLES } from 'src/app/core/consts/roles-and-permissions';
import { selectOrganization } from 'src/app/modules/organizations/store/selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppRoutes, OrganizationsRoutes } from 'src/app/core/consts/navigation.const';

export interface Breadcrumb {
  label: string;
  icon?: string;
  url: string;
}
@UntilDestroy()
@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrl: './breadcrumbs.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent implements OnInit {
  public breadcrumbs: Breadcrumb[] = [];
  private previousUrl: string = '';
  private user: UserDto | undefined;
  private organizationId: string | undefined;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private store: Store<AppState>,
  ) {}

  ngOnInit() {
    this.breadcrumbs = this.createBreadcrumbs(this.activatedRoute.root);
    this.cdr.detectChanges();
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        combineLatestWith(this.store.select(selectUser), this.store.select(selectOrganization)),
        untilDestroyed(this),
        debounceTime(100),
      )
      .subscribe(([event, user, organization]) => {
        if (event instanceof NavigationEnd && user) {
          this.user = user;
          if (this.user?.organizationId) {
            this.organizationId = `${this.user.organizationId}`;
          } else if (organization?.id) {
            this.organizationId = `${organization.id}`;
          }
          this.breadcrumbs = this.createBreadcrumbs(this.activatedRoute.root);
          this.checkLastRouteForOrgId(event.url);
        }
        this.cdr.markForCheck();
      });
  }

  public goTo(breadcrumb: Breadcrumb): void {
    this.router.navigate([breadcrumb.url]);
  }

  private createBreadcrumbs(
    route: ActivatedRoute,
    parentUrl: string = '',
    breadcrumbs: Breadcrumb[] = [],
  ): Breadcrumb[] {
    if (route.routeConfig && route.routeConfig.data && route.routeConfig.data['breadcrumb']) {
      const routeURL: string = route.snapshot.url.map((segment) => segment.path).join('/');
      const fullPath = parentUrl ? `${parentUrl}/${routeURL}` : routeURL;

      // todo change logic with correct permissions
      if (
        (this.previousUrl === '' ||
          this.user?.role === ROLES.ORG_ADMIN ||
          this.user?.role === ROLES.ORG_USER) &&
        parentUrl.includes(AppRoutes.ORGANIZATIONS) &&
        fullPath !== `${AppRoutes.ORGANIZATIONS}/${OrganizationsRoutes.CREATE}`
      ) {
        breadcrumbs = [];
        breadcrumbs.push({
          label: 'Organization Account',
          url: `/${AppRoutes.ORGANIZATIONS}/${this.organizationId}`,
          icon: 'home',
        });
        if (
          breadcrumbs[breadcrumbs.length - 1]?.label !== route.routeConfig.data['breadcrumb'].label
        ) {
          breadcrumbs.push({
            label: route.routeConfig.data['breadcrumb'].label,
            icon: route.routeConfig.data['breadcrumb'].icon,
            url: fullPath,
          });
        }

        return breadcrumbs;
      }

      if (this.isRouteActive(route)) {
        breadcrumbs.push({
          label: route.routeConfig.data['breadcrumb'].label,
          icon: route.routeConfig.data['breadcrumb'].icon,
          url: fullPath,
        });
      }
    }

    if (route.firstChild) {
      return this.createBreadcrumbs(
        route.firstChild,
        (parentUrl ? parentUrl + '/' : '') +
          route.snapshot.url.map((segment) => segment.path).join('/'),
        breadcrumbs,
      );
    } else {
      // Логика для обработки специальных случаев
      const organizationChildCrumb = breadcrumbs.find((breadcrumb) =>
        ['staff/', 'orders', 'inventory', 'reports/', 'patients/'].some((path) =>
          breadcrumb.url.startsWith(path),
        ),
      );
      if (organizationChildCrumb) {
        breadcrumbs.unshift({
          label: 'Organization Account',
          url: `/${AppRoutes.ORGANIZATIONS}/${this.organizationId}`,
          icon: 'home',
        });
      }

      return breadcrumbs;
    }
  }

  private isRouteActive(route: ActivatedRoute): boolean {
    const currentRoute: ActivatedRoute = this.getCurrentRoute(this.activatedRoute.root);
    return currentRoute === route || currentRoute.pathFromRoot.includes(route);
  }

  private getCurrentRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

  private checkLastRouteForOrgId(url: string): void {
    const regex = /^\/organizations\/(\d+)$/;
    const match = url.match(regex);
    if (match) {
      this.organizationId = match[1];
    }
  }
}
