@if (isSpinnerShown$ | async) {
  <div class="app-spinner">
    <mat-spinner></mat-spinner>
  </div>
}
<ng-container *let="(user$ | async) && (isLoggedIn$ | async) as loggedIn">
  <div
    [ngClass]="{
      'app-container': loggedIn,
      'app-login-content': !loggedIn
    }"
  >
    @if (loggedIn) {
      <div class="app-container__header">
        <app-header></app-header>
      </div>
    }
    <div class="app-content-container" [ngClass]="{ _loggedIn: loggedIn }">
      @if (loggedIn) {
        <div class="app-content-container__left-menu">
          <app-left-side-menu></app-left-side-menu>
        </div>
      }
      <div
        class="app-content"
        *let="loggedIn && isTopMenuShown(user$ | async) as topMenuShown"
        [ngClass]="{ _topMenuShown: topMenuShown }"
      >
        @if (topMenuShown) {
          <div class="app-content__top-menu">
            <app-top-menu></app-top-menu>
          </div>
        }
        <div  class="app-content__router">
          <router-outlet></router-outlet>
        </div>

      </div>
    </div>
    @if (loggedIn) {
      <div class="app-footer">
        <app-footer></app-footer>
      </div>
    }
  </div>
</ng-container>
