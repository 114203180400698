import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AppButtonSize, AppButtonType } from '../../../../core/models/common-components.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { mfaCodeMask, phoneNumberPrefix } from 'src/app/core/consts/masks';
import { Store } from '@ngrx/store';
import { DialogsActions } from 'src/app/modules/dialogs/store/actions';
import { mfaActions } from 'src/app/core/store/actions/actions';
import { selectMfaData } from 'src/app/core/store/selectors/selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, map, take, timer } from 'rxjs';
import { isPresent } from 'src/app/core/utils/isPresent';
import { Router } from '@angular/router';
import { AppRoutes } from 'src/app/core/consts/navigation.const';
import { Title } from '@angular/platform-browser';
import { GetSupportComponent } from '../../../../core/components/get-support/get-support.component';
import { MatDialog } from '@angular/material/dialog';

@UntilDestroy()
@Component({
  selector: 'app-mfa-verification',
  templateUrl: './mfa-verification.component.html',
  styleUrl: './mfa-verification.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MfaVerificationComponent implements OnInit {
  protected readonly btnTypes = AppButtonType;
  protected readonly btnSizes = AppButtonSize;
  protected readonly adminEmail = 'admin@admin.com';
  protected readonly emailSubject = encodeURIComponent('Request for MFA Phone number update');
  protected readonly mfaCodeMask = mfaCodeMask;
  protected readonly prefix = phoneNumberPrefix;
  public form: FormGroup = this.fb.group({
    phoneNumber: [{ value: null, disabled: true }],
    code: [null, [Validators.required, Validators.minLength(6)]],
  });
  public timerValue = 60;
  public showTimer = false;
  public timerRunning = false;

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private router: Router,
    private titleService: Title,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.titleService.setTitle('LeuPRO Logix | MFA');
    this.store
      .select(selectMfaData)
      .pipe(filter(isPresent), untilDestroyed(this))
      .subscribe((mfaData) => {
        this.form.get('phoneNumber')?.setValue(mfaData.metadata.phoneNumber);
      });
    this.startTimer();
  }

  verify(): void {
    if (this.form.get('code')?.value) {
      this.store.dispatch(mfaActions.verifyCode({ code: this.form.get('code')?.value }));
    }
  }

  resendCode(): void {
    this.store.dispatch(mfaActions.resendCode());
    this.startTimer();
  }

  updateNumber(): void {
    this.store.dispatch(
      DialogsActions.showDialog({
        data: {
          actions: ['Cancel', 'Continue'],
          content:
            `To update your phone number for MFA please contact the ${this.adminEmail}.` +
            'For security reasons, please ensure to use your registered email address (email used to login) to email customer support.',
        },
        callback: (action) => {
          if (action === 'Continue') {
            const mailToLink = `mailto:${this.adminEmail}?subject=${this.emailSubject}`;
            this.router.navigate([AppRoutes.LOGIN]);
            window.location.href = mailToLink;
          }
          if (action === 'Cancel') {
            this.router.navigate([AppRoutes.LOGIN]);
          }
        },
      }),
    );
  }

  getSupport(): void {
    this.dialog.open(GetSupportComponent, {
      autoFocus: false,
      width: '392px',
      position: {
        bottom: '24px',
        right: '24px',
      },
    });
  }

  private startTimer() {
    if (!this.timerRunning) {
      this.showTimer = true;
      this.timerValue = 60;
      this.timerRunning = true;

      const timer$ = timer(0, 1000).pipe(
        take(this.timerValue + 1),
        map((time) => 60 - time),
        untilDestroyed(this),
      );

      timer$.subscribe((val) => {
        this.timerValue = val;
        if (this.timerValue <= 0) {
          this.showTimer = false;
          this.timerRunning = false;
        }
        this.cdr.markForCheck();
      });
    }
  }
}
