import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { SharedModule } from '../../../../../shared/shared.module';
import { OrganizationsApiService } from '../../../../auth/services/organizations-api.service';
import { createNpiValidator } from '../../../../auth/components/registration/npi-validation/npi-validator';
import { Actions, ofType } from '@ngrx/effects';
import { OrganizationActions } from '../../../store/actions';
import { tap } from 'rxjs';
import { CommonModule } from '@angular/common';
import { LetDirective } from '../../../../../core/utils/let-directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { npiMask } from 'src/app/core/consts/masks';

@Component({
  selector: 'app-npi-validation',
  templateUrl: './npi-validation.component.html',
  styleUrl: './npi-validation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule, ReactiveFormsModule, CommonModule, LetDirective, MatFormFieldModule],
})
export class NpiValidationComponent {
  public form = this.fb.group({
    npi: this.fb.control(null, {
      validators: [Validators.required, Validators.minLength(10)],
      asyncValidators: [createNpiValidator(this.apiService, this.store$)],
    }),
  });

  npiMask = npiMask;

  searchResult$ = this.actions$.pipe(ofType(OrganizationActions.resetSearchResult)).pipe(
    tap(() => {
      this.cdr.detectChanges();
    }),
  );

  constructor(
    private fb: FormBuilder,
    private readonly apiService: OrganizationsApiService,
    private readonly store$: Store,
    private readonly actions$: Actions,
    private readonly cdr: ChangeDetectorRef,
  ) {}
}
