<div class="app-key-value-block">
  <div  automation-id="title" class="app-key-value-block__title" *ngIf="title">
    {{title}}
  </div>
  <div class="app-key-value-block__content">
    <div *ngFor="let label of labels; let i = index" class="app-key-value-block__content-row">
      <div class="app-key-value-block__content-key">{{label.label}}:</div>
      <div class="app-key-value-block__content-value">{{source[label.field]}}</div>
    </div>
  </div>
</div>
