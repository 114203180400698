<div class="app-button" [ngClass]="{}">
  <button
    class="app-button__button"
    mat-button
    [disabled]="disabled"
    [ngClass]="{
      'mat-mdc-button': type === 'basic',
      'mdc-button--outlined mat-mdc-outlined-button': type === 'stroked',
      'mdc-button--unelevated mat-mdc-unelevated-button': type === 'flat' || type === 'rounded',
      'custom-color-ONBOARDING': color === 'ONBOARDING',
      'custom-color-PENDING': color === 'PENDING',
      'custom-color-ACTIVE': color === 'ACTIVE',
      'custom-color-DEACTIVATED': color === 'DEACTIVATED',
      'custom-color-ERROR': color === 'ERROR',
      'full-width': size === 'fullWidth',
      'full-height': size === 'fullHeight',
      extraSmall: size === 'extraSmall',
      rounded: type === 'rounded'
    }"
    (click)="click()"
    [type]="htmlType"
    [style.color]="labelColor"
  >
    <mat-icon *ngIf="icon" [style.color]="iconColor">{{ icon }}</mat-icon>
    @if (label) {
      {{ label }}
    }
  </button>
</div>
