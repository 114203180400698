<div class="app-admins-list">
  <div class="app-admins-list__title">
    <div>Cipla Admins List</div>
    <app-button
      [type]="btnTypes.Flat"
      class="add-btn"
      label="+ Add Cipla Admin"
      (onClick)="addAdmin()"
    ></app-button>
  </div>
  <div class="app-admins-list__content">
    <app-admins-table></app-admins-table>
  </div>
</div>
