import { ChangeDetectionStrategy, Component, Input, Optional, Self } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NgControl } from '@angular/forms';

@Component({
  selector: 'app-radio',
  templateUrl: './app-radio.component.html',
  styleUrl: './app-radio.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppRadioComponent implements ControlValueAccessor {
  @Input() values!: any[];
  @Input() optionLabel: string = 'name';
  @Input() optionValue: string = 'id';
  @Input() label?: string | undefined;
  @Input() isMultiline?: boolean = false;
  @Input() orientation?: 'vertical' | 'horizontal';

  get control(): FormControl {
    return this.ngControl?.control as FormControl;
  }

  constructor(@Self() @Optional() public ngControl: NgControl) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  get isRequired(): boolean {
    const validator = this.control.validator ? this.control.validator({} as AbstractControl) : null;
    return validator && validator['required'];
  }

  onChange: any = (value: string) => {};
  onTouched: any = () => {};

  public registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public writeValue(value: any): void {}
}
