<div
  *ngIf="searchResult$ | async as searchResult"
  class="app-organization-summary"
  automation-id="app-organization-summary"
>
  <div
    automation-id="key-value-block"
    class="key-value-block"
    *let="organizationDataMockValue(searchResult) as data"
  >
    <div automation-id="title" class="key-value-block__title">Organization Profile</div>
    <div class="key-value-block__content">
      <div class="key-value-block__content-row">
        <div class="key-value-block__content-key">Account Status:</div>
        <div class="key-value-block__content-value">
          <app-status class="org-status" [status]="data.status" [factory]="organizationStatusFactory"></app-status>
        </div>
      </div>
      <div class="key-value-block__content-row">
        <div class="key-value-block__content-key">Organization Name:</div>
        <div class="key-value-block__content-value">{{ data.name }}</div>
      </div>
      <div class="key-value-block__content-row">
        <div class="key-value-block__content-key">Address:</div>
        <div class="key-value-block__content-value">{{ data.address }}</div>
      </div>
      <div class="key-value-block__content-row">
        <div class="key-value-block__content-key">Phone:</div>
        <div class="key-value-block__content-value">{{ data.phone }}</div>
      </div>
    </div>
  </div>

  <div *let="user$ | async" class="app-organization-summary__bottom-controls" [formGroup]="form">
    <div class="settings-title">Settings</div>
    <div class="row">
      <app-dropdown
        automation-id="status-field"
        class="account-status"
        label="Account Status"
        formControlName="status"
        [values]="items"
        optionIcon="icon"
        optionIconColor="iconColor"
        optionValue="id"
      >
      </app-dropdown>

      <app-dropdown
        *ngIf="users$ | async as users"
        automation-id="admin-field"
        class="account-admin"
        label="Organization Admin"
        formControlName="adminId"
        [values]="users.items"
        optionValue="id"
        optionLabel="firstName"
        optionLabel2="lastName"
      >
      </app-dropdown>
    </div>
    <div class="row" *appHasPermission="PERMISSIONS.organizationAccount.canChangeAdmin">
      <app-button automation-id="cancel-btn" label="Cancel" (click)="cancel()"></app-button>

      <app-button
        *let="loading$ | async as loading"
        automation-id="submit-btn"
        label="Save"
        [type]="btnTypes.Flat"
        [disabled]="form.pristine || !!loading"
        (onClick)="save(searchResult)"
      ></app-button>
    </div>
  </div>
</div>
