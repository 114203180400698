import {
  FirstLoginResModel,
  MfaLoginResDto,
  TokensModel,
  UserDto,
} from 'src/app/core/models/auth.model';
import { HttpErrorResponse } from '@angular/common/http';

export interface AppState {
  user: UserDto | null;
  tokens: TokensModel | null;
  loginError: HttpErrorResponse | null;
  loginData: MfaLoginResDto | null;
  firstLoginData: FirstLoginResModel | null;
  registering: boolean;
  isLoggedIn: boolean;
  ciplaAdminOrdersNotificationsCount: number | null;
  organizationOrdersNotificationsCount: number | null;
  organizationAppointmentsNotificationsCount: number | null;
  admins: UserDto[] | null;
}

export const initialAppState: AppState = {
  user: null,
  tokens: null,
  loginError: null,
  loginData: null,
  firstLoginData: null,
  registering: false,
  isLoggedIn: false,
  ciplaAdminOrdersNotificationsCount: null,
  organizationOrdersNotificationsCount: null,
  organizationAppointmentsNotificationsCount: null,
  admins: null,
};
