import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-slide-toggle',
  templateUrl: './app-slide-toggle.component.html',
  styleUrl: './app-slide-toggle.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppSlideToggleComponent {
  @Input() label: string | undefined;
  @Input() checked: boolean = true;
  @Input() disabled: boolean = false;
  @Output() onChange: EventEmitter<MatSlideToggleChange> = new EventEmitter<MatSlideToggleChange>();

  constructor(private cdr: ChangeDetectorRef) {}

  sliderChange(event: MatSlideToggleChange) {
    this.onChange.emit(event);
  }

  public runChangeDetection(): void {
    this.cdr.markForCheck();
  }
}
