import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AppButtonSize, AppButtonType } from '../../../models/common-components.model';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import * as CustomValidators from '../../../utils/validators';
import { phoneNumberPrefix, phonePrefixMask } from '../../../consts/masks';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { adminsListActions } from '../../../store/actions/actions';
import { AddUserReqDto } from '../../../models/staff.model';
import { formatPhoneToString } from '../../../utils/formatPhoneToString';
import { SharedModule } from '../../../../shared/shared.module';

@Component({
  selector: 'app-add-admin',
  templateUrl: './add-admin.component.html',
  styleUrl: './add-admin.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule, ReactiveFormsModule],
})
export class AddAdminComponent {
  protected readonly phoneMask = phonePrefixMask;
  protected readonly prefix = phoneNumberPrefix;
  protected readonly btnSizes = AppButtonSize;
  protected readonly btnTypes = AppButtonType;
  public form = this.fb.group({
    firstName: this.fb.control('', { validators: [Validators.required], nonNullable: true }),
    lastName: this.fb.control('', { validators: [Validators.required], nonNullable: true }),
    email: this.fb.control('', {
      validators: [
        Validators.required,
        Validators.maxLength(128),
        CustomValidators.emailFormatValidator(),
      ],
      nonNullable: true,
    }),
    phoneNumber: this.fb.control('', {
      nonNullable: true,
      validators: [Validators.minLength(10), Validators.required],
    }),
  });

  constructor(
    private fb: FormBuilder,
    private readonly dialogRef: MatDialogRef<AddAdminComponent>,
    private store: Store,
  ) {}

  cancel(): void {
    this.dialogRef.close();
  }

  submit(): void {
    const formValues = this.form.value;
    const body: AddUserReqDto = {
      firstName: formValues.firstName!,
      lastName: formValues.lastName!,
      email: formValues.email!,
      mobilePhoneNumber: formatPhoneToString(formValues.phoneNumber!),
    };
    this.store.dispatch(adminsListActions.addCiplaAdmin({ body }));
    this.dialogRef.close();
  }
}
