import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { NotificationsActions } from './actions';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class FeatureEffects {
  showNotification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NotificationsActions.showNotification),
        tap(({ config, message, action }) => {
          this.snackBar.open(message, action, {
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
          });
        }),
      ),
    { dispatch: false },
  );
  constructor(
    private readonly actions$: Actions,
    private readonly snackBar: MatSnackBar,
  ) {}
}
