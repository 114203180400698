import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

interface InlineDialogData {
  message: string;
}

@Component({
  selector: 'app-app-inline-dialog',
  templateUrl: './app-inline-dialog.component.html',
  styleUrl: './app-inline-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppInlineDialogComponent {
  constructor(
    private snackBarRef: MatSnackBarRef<AppInlineDialogComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: InlineDialogData,
  ) {}

  close(): void {
    this.snackBarRef.dismiss();
  }
}
