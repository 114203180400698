import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap, take } from 'rxjs';
import { environment } from 'src/environment/environment';
import { AppState } from 'src/app/core/store/models/state';
import { Store } from '@ngrx/store';
import { selectTokens } from 'src/app/core/store/selectors/selectors';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private store: Store<AppState>) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isApiUrl = req.url.startsWith(environment.baseUrl);
    return this.store.select(selectTokens).pipe(
      take(1),
      switchMap((tokens) => {
        if (tokens?.idToken && isApiUrl) {
          req = req.clone({
            setHeaders: { Authorization: `Bearer ${tokens.idToken}` },
          });
        }
        return next.handle(req);
      }),
    );
  }
}
