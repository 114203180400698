import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ActivatedRoute, Router } from '@angular/router';
import { fromOrganizations, selectOrganizationEnrollmentShort } from '../../store/selectors';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { LetDirective } from '../../../../core/utils/let-directive';
import { SharedModule } from '../../../../shared/shared.module';
import { AuthModule } from '../../../auth/auth.module';
import { OrganizationSummaryComponent } from '../organization-summary/organization-summary.component';
import { ShippingAddressListComponent } from '../shipping-address-list/shipping-address-list.component';
import { PERMISSIONS, ROLES } from '../../../../core/consts/roles-and-permissions';
import { HasPermissionDirective } from '../../../../core/directives/has-permission.directive';
import { OrganizationActions, OrganizationEnrollmentActions } from '../../store/actions';
import { AppButtonType } from '../../../../core/models/common-components.model';
import {
  EnrollmentShortDto,
  EnrollmentStatusDto,
  OrganizationDto,
  OrganizationStatusDto,
} from 'src/app/core/models/organization.model';
import { selectUser } from 'src/app/core/store/selectors/selectors';
import { IsDeactivatedOrganizationUserDirective } from 'src/app/core/directives/is-deactivated-organization-user.directive';
import { OrganizationsRoutes } from 'src/app/core/consts/navigation.const';
import { UserDto } from '../../../../core/models/auth.model';

@UntilDestroy()
@Component({
  selector: 'app-organization-account',
  templateUrl: './organization-account.component.html',
  styleUrl: './organization-account.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    CdkStepperModule,
    LetDirective,
    SharedModule,
    AuthModule,
    OrganizationSummaryComponent,
    ShippingAddressListComponent,
    HasPermissionDirective,
    IsDeactivatedOrganizationUserDirective,
  ],
})
export class OrganizationAccountComponent implements OnInit {
  public enrollmentShort$ = this.store$.select(selectOrganizationEnrollmentShort);
  public user$ = this.store$.select(selectUser);
  public organization$ = this.store$.select(fromOrganizations.selectOrganization);

  protected readonly PERMISSIONS = PERMISSIONS;
  protected readonly btnTypes = AppButtonType;
  protected readonly ROLES = ROLES;

  constructor(
    private readonly store$: Store,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.store$.dispatch(OrganizationActions.loadOrganization());
    this.store$.dispatch(OrganizationEnrollmentActions.getOrganizationEnrollmentShort());
  }

  goToEnrollment(): void {
    this.router.navigate([OrganizationsRoutes.ENROLLMENT], { relativeTo: this.route });
  }

  enrollBtnDisabled(
    enrollmentShort: EnrollmentShortDto | null,
    organization: OrganizationDto,
    user: UserDto,
  ) {
    return (
      organization?.status === OrganizationStatusDto.PENDING ||
      ((user.role === ROLES.ADMIN || user?.role === ROLES.SUPER_ADMIN) &&
        (enrollmentShort?.status === EnrollmentStatusDto.IN_PROGRESS || !enrollmentShort))
    );
  }

  public getEnrollmentLabel(enrollmentShort: EnrollmentShortDto | null, user: UserDto): string {
    if (enrollmentShort) {
      if (
        enrollmentShort.status === EnrollmentStatusDto.IN_REVIEW ||
        enrollmentShort.status === EnrollmentStatusDto.APPROVED ||
        user.role === ROLES.ADMIN ||
        user.role === ROLES.SUPER_ADMIN
      ) {
        return 'View Enrollment';
      }
      if (enrollmentShort.status === EnrollmentStatusDto.IN_PROGRESS && enrollmentShort.billToId) {
        return 'Complete Enrollment';
      }
    }
    return 'Enroll now';
  }

  protected readonly OrganizationStatusDto = OrganizationStatusDto;
  protected readonly EnrollmentStatusDto = EnrollmentStatusDto;
}
