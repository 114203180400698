import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { Observable } from 'rxjs';
import {
  ShippingAddressDto,
  ShippingAddressRequestDto,
} from '../../../core/models/organization.model';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ShippingAddressesApiService extends ApiService {
  getOrganizationAddresses$(
    id: string,
    statuses: string[] = ['ACTIVE', 'IN_REVIEW', 'DEACTIVATED', 'DELETED'],
  ): Observable<ShippingAddressDto[]> {
    let httpParams = new HttpParams();

    if (statuses) {
      for (let status of statuses) {
        httpParams = httpParams.append('status', status);
      }
    }

    httpParams = httpParams.append('availableUnits', true);
    httpParams = httpParams.append('unitsInHand', true);

    return this.get(`/organizations/${id}/addresses`, httpParams);
  }

  createOrganizationAddress$(
    id: number,
    body: ShippingAddressRequestDto,
  ): Observable<ShippingAddressDto> {
    const formData = new FormData();
    let key: keyof typeof body;

    for (key in body) {
      const value = body[key];
      if (value) {
        formData.append(key, value);
      }
    }
    return this.postFormData(`/organizations/${id}/addresses`, formData);
  }

  approveOrganizationAddress$(id: number, addressId: number): Observable<null> {
    return this.patch(`/organizations/${id}/addresses/${addressId}/approve`);
  }

  declineOrganizationAddress$(id: number, addressId: number): Observable<null> {
    return this.patch(`/organizations/${id}/addresses/${addressId}/decline`);
  }

  deleteOrganizationAddress$(id: number, addressId: number): Observable<ShippingAddressDto> {
    return this.delete(`/organizations/${id}/addresses/${addressId}`);
  }

  defaultOrganizationAddress$(id: number, addressId: number): Observable<ShippingAddressDto> {
    return this.patch(`/organizations/${id}/addresses/${addressId}/default`);
  }

  activateOrganizationAddress$(id: number, addressId: number): Observable<null> {
    return this.patch(`/organizations/${id}/addresses/${addressId}/activate`);
  }

  deactivateOrganizationAddress$(id: number, addressId: number): Observable<null> {
    return this.patch(`/organizations/${id}/addresses/${addressId}/deactivate`);
  }

  getShippingFile$(id: number, addressId: number, fileId: number): Observable<string> {
    return this.getText(`/organizations/${id}/addresses/${addressId}/licences/${fileId}`);
  }
}
