import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AppButtonSize, AppButtonType } from '../../models/common-components.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { loginActions, startLogoutAction } from '../../store/actions/actions';
import { SharedModule } from '../../../shared/shared.module';
import { BehaviorSubject, filter, tap } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { LetDirective } from '../../utils/let-directive';

interface MenuItem {
  icon: string;
  label: string;
  roles?: string[];
  route: string;
  counter?: number;
  disabledForStatuses?: string[];
}

@UntilDestroy()
@Component({
  selector: 'app-auto-log-out',
  templateUrl: './auto-log-out.component.html',
  styleUrl: './auto-log-out.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule, AsyncPipe, LetDirective],
})
export class AutoLogOutComponent implements OnInit {
  protected readonly btnSizes = AppButtonSize;
  protected readonly btnTypes = AppButtonType;

  remainingTime$ = new BehaviorSubject(60);

  logout$ = this.remainingTime$.pipe(
    filter((time) => !time),
    tap((time) => {
      this.store$.dispatch(startLogoutAction());
      this.dialog.closeAll();
    }),
  );

  constructor(
    private readonly dialogRef: MatDialogRef<AutoLogOutComponent>,
    private readonly store$: Store,
    private dialog: MatDialog,
  ) {}

  hide() {
    this.store$.dispatch(loginActions.startTrackUserActivity());
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.store$.dispatch(loginActions.stopTrackUserActivity());
    setInterval(() => {
      this.remainingTime$.next(this.remainingTime$.value - 1);
    }, 1000);
  }
}
