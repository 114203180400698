import { createActionGroup, props } from '@ngrx/store';
import { featureKey } from './selectors';
import { DialogData } from '../../../core/models/common-components.model';
import { MatDialogRef } from '@angular/material/dialog';

export const DialogsActions = createActionGroup({
  source: featureKey,
  events: {
    showDialog: props<{ data: DialogData; callback?: (...args: any[]) => void }>(),
    setRef: props<{ ref: MatDialogRef<any> | null }>(),
  },
});
