import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { AppAlertType, AppButtonType } from 'src/app/core/models/common-components.model';
import { CdkStepper, CdkStepperModule } from '@angular/cdk/stepper';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { SharedModule } from '../../../../shared/shared.module';
import { AuthModule } from '../../../auth/auth.module';
import { CommonModule } from '@angular/common';
import { NpiValidationComponent } from './npi-validation/npi-validation.component';
import { PersonalDetailsComponent } from './personal-details/personal-details.component';
import { OrganizationActions } from '../../store/actions';
import { LetDirective } from '../../../../core/utils/let-directive';
import { fromOrganizations } from '../../store/selectors';
import { formatPhoneToString } from '../../../../core/utils/formatPhoneToString';

@Component({
  selector: 'app-create-organization.component',
  templateUrl: './create-organization.component.html',
  styleUrl: './create-organization.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SharedModule,
    AuthModule,
    CdkStepperModule,
    CommonModule,
    NpiValidationComponent,
    PersonalDetailsComponent,
    LetDirective,
  ],
})
export class CreateOrganizationComponent {
  @ViewChild('cdkStepper') stepper!: CdkStepper;
  @ViewChild(NpiValidationComponent) npiValidationComponent!: NpiValidationComponent;
  @ViewChild(PersonalDetailsComponent) personalDetailsComponent!: PersonalDetailsComponent;
  public alertTypes = AppAlertType;
  public btnTypes = AppButtonType;
  registering$ = this.store$.select(fromOrganizations.selectRegistering);

  constructor(private readonly store$: Store) {}

  public checkValidity(selectedIndex: number, registering: boolean | null): boolean {
    switch (selectedIndex) {
      case 0: {
        return (
          !this.npiValidationComponent?.form ||
          (!this.isFormValid(this.npiValidationComponent?.form) &&
            this.npiValidationComponent?.form.enabled)
        );
      }
      case 1: {
        return (
          registering ||
          (!this.isFormValid(this.personalDetailsComponent?.form) &&
            !this.personalDetailsComponent?.accountWithoutPersonMode.value)
        );
      }
      default: {
        return false;
      }
    }
  }

  public cancel(): void {
    const pristine =
      this.npiValidationComponent?.form.pristine && this.personalDetailsComponent?.form.pristine;

    this.store$.dispatch(OrganizationActions.createOrganizationAccountCancel({ pristine }));
  }

  public back(): void {
    if (this.stepper?.selected) this.stepper.selected.completed = false;
    this.stepper.previous();
  }

  public next(selectedIndex: number): void {
    if (selectedIndex === 0) {
      this.npiValidationComponent.form.disable();
    }

    if (this.stepper?.selected) this.stepper.selected.completed = true;
    this.stepper.next();
  }

  public submit(): void {
    const organization = this.personalDetailsComponent.searchResult;

    const user = this.personalDetailsComponent.form.getRawValue();
    this.store$.dispatch(
      OrganizationActions.createOrganizationAccount({
        body: {
          user: this.personalDetailsComponent.accountWithoutPersonMode.value
            ? undefined
            : {
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                mobilePhoneNumber: formatPhoneToString(user.phoneNumber),
              },
          organization: {
            ...organization,
            phoneNumber: formatPhoneToString(organization.phoneNumber),
          },
        },
      }),
    );
  }

  private isFormValid(form?: FormGroup): boolean {
    if (!form) {
      return false;
    }
    return form.status !== 'PENDING' && form.valid;
  }
}
