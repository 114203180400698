<div class="app-dropdown">
  <div *ngIf="label" class="app-dropdown__label" [class.label-icon]="labelPostfixIcon">
    {{ label }}:
    <ng-container *ngIf="isRequired">*</ng-container>
    <mat-icon
      *ngIf="labelPostfixIcon"
      matSuffix
      class="material-icons-outlined pointer"
      [style.color]="labelPostfixIconColor"
      [matTooltip]="labelPostfixIconTooltip || ''"
      (click)="labelPostfixIconClick.emit()"
      >{{ labelPostfixIcon }}</mat-icon
    >
  </div>

  <mat-form-field class="app-dropdown__field">
    <mat-select
      [formControl]="control"
      value=""
      [placeholder]="mapperPlaceholder"
      (selectionChange)="selectedValue($event)"
    >
      <mat-select-trigger class="trigger">
        <mat-icon
          *ngIf="selectedData?.icon"
          [style.color]="selectedData?.iconColor"
          class="smallIcon"
          >{{ selectedData?.icon }}</mat-icon
        >{{ selectedData?.text
        }}<span *ngIf="selectedData?.optionLabel2"> {{ selectedData?.optionLabel2 }}</span>
        <span *ngIf="selectedData?.showAbbreviation"> ({{ selectedData?.showAbbreviation }}) </span>
      </mat-select-trigger>
      <mat-option
        *ngFor="let option of values"
        [value]="option[optionValue]"
        [disabled]="option[optionDisabled]"
        (click)="clickOption(option)"
        ><mat-icon *ngIf="optionIcon" [style.color]="option[optionIconColor]" class="smallIcon">{{
          option[optionIcon]
        }}</mat-icon>
        <div class="option-content">
          <div class="leftContent">
            {{ mapperProvided ? optionLabelFn(option) : option[optionLabel]
            }}<span *ngIf="optionLabel2"> {{ option[optionLabel2] }}</span>
            <span *ngIf="showAbbreviation"> ({{ option[showAbbreviation] }}) </span>
            <span *ngIf="optionLabelSecondLine" class="app-dropdown__field__secondLine">
              <br />
              {{ option[optionLabelSecondLine] }}</span
            >
          </div>

          <div *ngIf="option[optionLabelRightContent]" class="rightContent">
            {{ option[optionLabelRightContent] }}
          </div>
        </div>
      </mat-option>
      <mat-option
        *ngIf="displayExtraSection"
        class="app-dropdown__field__extraSection"
        (click)="selectedExtraSection()"
        ><ng-content></ng-content
      ></mat-option>
    </mat-select>
  </mat-form-field>

  <mat-error class="app-dropdown__error" *ngIf="control?.invalid && control?.touched">
    {{ getErrorMessage() }}
  </mat-error>
</div>
