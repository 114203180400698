import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { adminsListActions } from '../../store/actions/actions';
import { PERMISSIONS } from '../../consts/roles-and-permissions';
import { AppButtonType } from '../../models/common-components.model';
import { HasPermissionDirective } from '../../directives/has-permission.directive';
import { SharedModule } from '../../../shared/shared.module';
import { AdminsTableComponent } from './admins-table/admins-table.component';
import { MatDialog } from '@angular/material/dialog';
import { AddAdminComponent } from './add-admin/add-admin.component';

@Component({
  selector: 'app-admins-list',
  templateUrl: './admins-list.component.html',
  styleUrl: './admins-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [HasPermissionDirective, SharedModule, AdminsTableComponent],
})
export class AdminsListComponent implements OnInit {
  protected readonly PERMISSIONS = PERMISSIONS;
  protected readonly btnTypes = AppButtonType;
  constructor(
    private store: Store,
    private dialog: MatDialog,
  ) {}
  ngOnInit() {
    this.store.dispatch(adminsListActions.getAdminsList());
  }

  addAdmin(): void {
    this.dialog.open(AddAdminComponent, {
      autoFocus: false,
      width: '392px',
    });
  }
}
