<div *let="logout$ | async" class="app-dialog">
  <div automation-id="title" class="title">Session Timeout</div>
  <div automation-id="title" class="content">
    Your session is about to expire due to inactivity.<br />
    You will be logged out in {{ remainingTime$ | async }} s.
  </div>
  <div automation-id="actions" class="actions container-horizontal">
    <app-button
      automation-id="cancel-btn"
      [type]="btnTypes.Flat"
      [size]="btnSizes.FullHeight"
      label="Contunue session"
      (onClick)="hide()"
    ></app-button>
  </div>
</div>
