<div>
  <h1>CIPLA CONSUMER HEALTH DATA PRIVACY POLICY</h1>
  <p>
    <strong>OVERVIEW</strong>
  </p>
  <p>
    Cipla USA, Inc. and its North American affiliates (collectively, “Cipla”) is committed to
    respecting your privacy. This policy supplements the
    <a [href]="'https://www.cipla.com/privacy-policy'">Cipla Privacy Notice</a> and applies to
    personal information defined as “consumer health data” subject to applicable privacy laws,
    including the Washington My Health My Data Act.
  </p>

  <p>
    <strong>CONSUMER HEALTH DATA COLLECTED</strong>
  </p>
  <p>
    The consumer health data we collect depends on the context of your interaction with us. Examples
    of consumer health data we collect include information about your:
  </p>
  <ul>
    <li>health conditions, treatment, symptoms, or diagnoses;</li>
    <li>health-related appointments;</li>
    <li>
      information that could identify your attempt to seek health care services or information.
    </li>
  </ul>

  <p>
    <strong>SOURCES OF CONSUMER HEALTH DATA</strong>
  </p>
  <p>We collect consumer health data from the following sources:</p>
  <ul>
    <li>
      directly from you when you or your healthcare provider use our websites or applications,
      including LeuPROLogix;
    </li>
    <li>
      indirectly from you when you visit our websites through use of cookies and other tracking
      technologies;
    </li>
    <li>from our clinical trial partners or patient support providers;</li>
    <li>from your healthcare providers; and</li>
    <li>
      where you have provided your explicit consent for our collection and use of your health
      information.
    </li>
  </ul>
  <p>
    <strong> PURPOSE OF COLLECTION AND USE</strong>
  </p>

  <p>
    We collect consumer health data in order to provide you and your healthcare provider with
    appointment scheduling and patient support services.
  </p>

  <p>
    <strong> DISCLOSURE OF CONSUMER HEALTH DATA</strong>
  </p>

  <p>
    We disclose consumer health data to our service providers so that they may help us provide our
    products and services and otherwise operate our business. We also disclose consumer health data
    to regulatory bodies as required by law, rule, or regulation. We may also disclose consumer
    health data to other third parties with your consent to such disclosure.
  </p>

  <p>We may disclose consumer health data to affiliates [Cipla Ltd].</p>

  <p>
    We may also disclose consumer health data to the parties of a corporate transaction such as a
    merger or acquisition, or to government agencies or otherwise as necessary to prevent, detect,
    or investigate fraud or threats to health, safety, or legal rights.
  </p>

  <p>
    <strong>YOUR RIGHTS</strong>
  </p>

  <p>
    In addition to your privacy rights outlined in the Cipla Privacy Notice, Washington residents
    have the following rights with respect to their consumer health data, subject to certain
    exceptions:
  </p>

  <ul>
    <li>
      the right to confirm whether we collect, share, or sell your consumer health data, and to
      access and receive a copy of your consumer health data;
    </li>
    <li>
      the right to a list of all third parties and affiliates with whom we have shared or sold your
      consumer health data and an email address or other online mechanism that you can use to
      contact each third party;
    </li>
    <li>the right to delete your consumer health data; and</li>
    <li>
      the right to withdraw your consent to the collection, sharing, or selling of your consumer
      health data.
    </li>
  </ul>

  <p>
    You can submit a request to exercise these rights using any of the mechanisms listed in the
    Contact Information section below. Depending on the type of request, we may need to verify your
    request before we can fulfill it. Please note that certain exceptions may apply to these rights
    (e.g., we may be unable to honor a request to delete if it conflicts with our legal obligation
    to retain and report adverse event data).
  </p>

  <p>
    If you are located in the state of Washington, you also have the right to appeal any decisions
    we make with regard to your rights request. If your appeal is unsuccessful, you may raise a
    concern or lodge a complaint with the Washington State Attorney General at
    <a [href]="'https://www.atg.wa.gov/file-complaint'">www.atg.wa.gov/file-complaint</a>.
  </p>

  <p>
    <strong> CONTACT INFORMATION</strong>
  </p>

  <p>
    If you have questions about this policy or if you would like to exercise your privacy rights,
    please contact us at: <a [href]="mailToLink">{{ email }}</a
    >.
  </p>

  <p>
    <strong>CHANGES TO THIS POLICY</strong>
  </p>

  <p>
    We may update this policy at any time. Any changes to the policy will become effective upon the
    date of our posting of the revised policy on our websites.
  </p>

  <p class="empty-space"></p>

  <p>
    <strong>SUPPLEMENT TO CONSUMER HEALTH DATA PRIVACY POLICY FOR NEVADA CONSUMERS</strong>
  </p>

  <p>
    This Supplement to the Consumer Health Data Privacy Policy applies to Nevada consumers for
    purposes of providing additional disclosures required by Nevada’s consumer health data privacy
    law.
  </p>

  <p>
    The foregoing consumer health data disclosures and rights apply to Nevada residents. In
    addition, Nevada residents also have the right to review and amend any consumer health data we
    maintain about them. Nevada residents can escalate unsuccessful appeals of consumer rights
    requests to the Nevada Attorney General at
    <a [href]="'https://ag.nv.gov/Complaints/File_Complaint/'"
      >https://ag.nv.gov/Complaints/File_Complaint/</a
    >.
  </p>

  <p>
    We do not permit third parties to collect consumer health data over time and across our websites
    in Washington and Nevada. However, third parties may still be able to collect consumer health
    data from you over time and across different websites depending on your browser, browser
    add-ons, and permissions you set on your device used to access the websites. Such collection by
    third parties is not related to our collection of consumer health data from you. We encourage
    you to view the privacy notices of those third parties for more information about their data
    practices and your privacy choices with regard to those activities.
  </p>
</div>
<div class="buttons">
  <app-button label="Close" (onClick)="close()"></app-button>
</div>
