import {
  adminsListActions,
  badgesActions,
  mfaActions,
  requestCallAction,
  startLogoutAction,
} from './../actions/actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import {
  forgotPasswordActions,
  loggedInSuccessAction,
  loginActions,
  logoutAction,
  refreshTokenActions,
  registerActions,
  resendInviteActions,
} from 'src/app/core/store/actions/actions';
import {
  catchError,
  debounceTime,
  filter,
  fromEvent,
  interval,
  map,
  merge,
  mergeMap,
  of,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';
import { AuthApiService } from 'src/app/modules/auth/services/auth-api.service';
import { FirstLoginResModel, MfaLoginResDto } from 'src/app/core/models/auth.model';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store/models/state';
import {
  selectLoginFirstData,
  selectMfaData,
  selectOrdersNotificationsCount,
  selectTokens,
  selectUser,
} from 'src/app/core/store/selectors/selectors';
import { DialogsActions } from '../../../modules/dialogs/store/actions';
import { ROLES } from '../../consts/roles-and-permissions';
import { OrdersApiService } from 'src/app/modules/orders/services/orders-api.service';
import { AppointmentsApiService } from 'src/app/modules/inventory/services/appointments-api.service';
import { OrganizationOrdersApiService } from 'src/app/modules/organization-orders/services/organization-orders-api.service';
import { fromOrganizations } from 'src/app/modules/organizations/store/selectors';
import { customerData } from '../../consts/customer-data';
import { isPresent } from '../../utils/isPresent';
import { MatDialog } from '@angular/material/dialog';
import { ResetPasswordLinkExpiredComponent } from '../../../modules/auth/components/reset-password-link-expired/reset-password-link-expired.component';
import { waitWithLatestFrom } from '../../utils/wait-with-latest-from';
import { OrganizationEnrollmentActions } from 'src/app/modules/organizations/store/actions';
import { AppRoutes, AuthRoutes } from 'src/app/core/consts/navigation.const';
import { AutoLogOutComponent } from '../../components/auto-log-out/auto-log-out.component';
import { PasswordService } from 'src/app/modules/auth/services/password.service';

@Injectable()
export class Effects {
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginActions.login),
      mergeMap((props) =>
        this.authApiService.login$(props.body).pipe(
          map((res) => {
            if (res.challenge === 'SMS_MFA') {
              this.passwordService.setPassword(props.body.password);
              res = res as MfaLoginResDto;
              return loginActions.loginSuccess({ res });
            } else {
              res = res as FirstLoginResModel;
              return loginActions.loginFirstSuccess({ res });
            }
          }),
          catchError((error) => of(loginActions.loginFailure({ error }))),
        ),
      ),
    ),
  );

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginActions.loginSuccess),
        map((props) => {
          this.router.navigate([`${AppRoutes.LOGIN}/${AuthRoutes.MFA_VERIFICATION}`]);
        }),
      ),
    { dispatch: false },
  );

  loginFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginActions.loginFailure),
      map((error) => {
        if (error.error.status === 403) {
          return DialogsActions.showDialog({
            data: {
              actions: ['Ok'],
              content: `Your account is no longer active. Please contact customer support <a href="mailto:${customerData.email}">${customerData.email}</a> for assistance`,
            },
          });
        } else {
          if (error.error.status === 401 && error.error.error.code === 'UNAUTHORIZED') {
            this.router.navigate([`${AppRoutes.LOGIN}/${AuthRoutes.BLOCKED}`], {
              queryParams: {
                exp: error.error.error.metadata.expTime,
              },
            });
          }

          return loginActions.loginFailureClose();
        }
      }),
    ),
  );

  loginFirstSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginActions.loginFirstSuccess),
        map(() => this.router.navigate([`${AppRoutes.LOGIN}/${AuthRoutes.SET_PASSWORD}`])),
      ),
    { dispatch: false },
  );

  register$ = createEffect(() =>
    this.actions$.pipe(
      ofType(registerActions.register),
      mergeMap((props) =>
        this.authApiService.register$(props.body).pipe(
          map(() => registerActions.registerSuccess()),
          catchError((response) => {
            return of(
              registerActions.registerFail(),
              DialogsActions.showDialog({
                data: {
                  actions: ['Close'],
                  content: '',
                  title: response.error?.message || 'Registration API not available',
                },
              }),
            );
          }),
        ),
      ),
    ),
  );

  registerSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(registerActions.registerSuccess),
      map((props) => this.router.navigate([AppRoutes.LOGIN])),
      map((props) =>
        DialogsActions.showDialog({
          data: {
            actions: ['Close'],
            content:
              'An email has been sent to the registered email address, please review for next steps and additional information',
            title: 'Your request for an application has been submitted',
          },
        }),
      ),
    ),
  );

  registerCancel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(registerActions.registerCancel),
      map(({ pristine }) => {
        if (pristine) {
          this.router.navigate([AppRoutes.LOGIN]);

          return DialogsActions.setRef({ ref: null });
        } else {
          return DialogsActions.showDialog({
            data: {
              actions: ['Cancel', 'Ok'],
              content: '',
              title: 'The updates will not be saved',
            },
            callback: (action) => {
              if (action === 'Ok') {
                this.router.navigate([AppRoutes.LOGIN]);
              }
            },
          });
        }
      }),
    ),
  );

  registerChangePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(registerActions.registerConfirm),
      withLatestFrom(this.store.select(selectLoginFirstData)),
      mergeMap(([props, firstLoginData]) =>
        this.authApiService
          .registerConfirm$({
            newPassword: props.body.newPassword,
            session: firstLoginData?.session,
            cognitoId: firstLoginData?.metadata.cognitoId,
          })
          .pipe(
            map((res) => {
              this.passwordService.setPassword(props.body.newPassword);
              return registerActions.registerConfirmSuccess({ res });
            }),
            catchError((error) => of(registerActions.registerConfirmFailure({ error }))),
          ),
      ),
    ),
  );

  registerConfirmSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(registerActions.registerConfirmSuccess),
      map((props) =>
        DialogsActions.showDialog({
          data: {
            actions: ['Ok'],
            content: '',
            title: 'Thank you for registering!',
          },
          callback: () => {
            this.router.navigate([`${AppRoutes.LOGIN}/${AuthRoutes.MFA_VERIFICATION}`]);
          },
        }),
      ),
    ),
  );

  registerConfirmFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(registerActions.registerConfirmFailure),
      map((props) =>
        DialogsActions.showDialog({
          data: {
            actions: ['Ok'],
            content: `Please, restart login with temporary password`,
            title: 'Your session has expired',
          },
          callback: () => {
            this.router.navigate([AppRoutes.LOGIN]);
          },
        }),
      ),
    ),
  );

  refreshTokenSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(refreshTokenActions.refreshTokenSuccess),
        map((props) => {
          localStorage.setItem('refreshToken', props?.res?.tokens.refreshToken);
          return props;
        }),
      ),
    { dispatch: false },
  );

  autoRefreshTokenSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(refreshTokenActions.autoRefreshTokenSuccess),
        map((props) => {
          localStorage.setItem('refreshToken', props?.res?.tokens.refreshToken);
          return props;
        }),
      ),
    { dispatch: false },
  );

  refreshTokenFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(refreshTokenActions.refreshTokenFailure),
        map((error) => localStorage.removeItem('refreshToken')),
      ),
    { dispatch: false },
  );

  startLogout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(startLogoutAction),
        withLatestFrom(this.store.select(selectTokens)),
        switchMap(([_, tokens]) =>
          tokens?.accessToken ? this.authApiService.logout$(tokens.accessToken) : of(null),
        ),
        map(() => {
          window.location.href = `${AppRoutes.LOGIN}?logout=true`;
        }),
      ),
    { dispatch: false },
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logoutAction),
      switchMap(() => {
        localStorage.removeItem('refreshToken');
        return of(
          badgesActions.setOrdersNotificationsCount({ count: null }),
          badgesActions.setCiplaAdminOrdersNotificationsCount({ count: null }),
          badgesActions.setAppointmentsNotificationsCount({ count: null }),
          OrganizationEnrollmentActions.clearEnrollmentData(),
        );
      }),
    ),
  );

  forgotPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(forgotPasswordActions.forgotPassword),
      mergeMap((props) =>
        this.authApiService.forgotPass$({ email: props.email }).pipe(
          map(() => forgotPasswordActions.forgotPasswordSuccess({ email: props.email })),
          catchError((error) => {
            return of(forgotPasswordActions.forgotPasswordFailure(error));
          }),
        ),
      ),
    ),
  );

  forgotPasswordSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(forgotPasswordActions.forgotPasswordSuccess),
      map((props) =>
        DialogsActions.showDialog({
          data: {
            actions: ['Back to Login'],
            content: `Thank you. If the login email is correct, you will receive an email with instructions on how to reset your password.`,
            title: 'Password Recovery Email Sent',
            disableClose: true,
          },
          callback: (action) => {
            if (action === 'Back to Login') {
              this.router.navigate([AppRoutes.LOGIN]);
            }
          },
        }),
      ),
    ),
  );

  forgotPasswordFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(forgotPasswordActions.forgotPasswordFailure),
      map((error) => {
        return DialogsActions.showDialog({
          data: {
            actions: ['Close'],
            content: '',
            title: error.error?.message || 'Auth API not available',
          },
        });
      }),
    ),
  );

  forgotPasswordConfirm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(forgotPasswordActions.forgotPasswordConfirm),
      mergeMap((props) =>
        this.authApiService.forgotPassConfirm$(props.body).pipe(
          map(() => forgotPasswordActions.forgotPasswordConfirmSuccess()),
          catchError((error) => {
            this.dialog.open(ResetPasswordLinkExpiredComponent);
            return of(forgotPasswordActions.forgotPasswordConfirmFailure({ error }));
          }),
        ),
      ),
    ),
  );

  forgotPasswordConfirmSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(forgotPasswordActions.forgotPasswordConfirmSuccess),
      map((props) =>
        DialogsActions.showDialog({
          data: {
            actions: ['Ok'],
            content: `Your password has been updated successfully. Use the new password to log in`,
            title: 'Password updated!',
          },
          callback: () => {
            this.router.navigate([AppRoutes.LOGIN]);
          },
        }),
      ),
    ),
  );

  getCiplaAdminOrdersNotificationsCount$ = createEffect(() =>
    this.actions$.pipe(ofType(badgesActions.getCiplaAdminOrdersNotificationsCount)).pipe(
      waitWithLatestFrom(
        this.store.select(selectUser).pipe(
          filter(isPresent),
          filter((user) => user.role === ROLES.ADMIN || user.role === ROLES.SUPER_ADMIN),
        ),
      ),
      switchMap(() => {
        return this.ordersApiService
          .getCount$()
          .pipe(
            map((response) =>
              badgesActions.setCiplaAdminOrdersNotificationsCount({ count: response }),
            ),
          );
      }),
    ),
  );

  getOrdersNotificationsCount$ = createEffect(() =>
    this.actions$.pipe(ofType(badgesActions.getOrdersNotificationsCount)).pipe(
      waitWithLatestFrom(
        this.store.select(fromOrganizations.selectOrganization).pipe(filter(isPresent)),
        this.store.select(selectUser).pipe(
          filter(isPresent),
          filter((user) => user.role === ROLES.ORG_USER || user.role === ROLES.ORG_ADMIN),
        ),
      ),
      switchMap(([, organization]) => {
        return this.organizationOrdersApiService.getCount$(organization.id + '').pipe(
          map((response) => {
            return badgesActions.setOrdersNotificationsCount({ count: response });
          }),
        );
      }),
    ),
  );

  getAppointmentsNotificationsCount$ = createEffect(() =>
    this.actions$.pipe(ofType(badgesActions.getAppointmentsNotificationsCount)).pipe(
      waitWithLatestFrom(
        this.store.select(selectUser).pipe(
          filter(isPresent),
          filter((user) => Boolean(user.role === ROLES.ORG_ADMIN || user.role === ROLES.ORG_USER)),
        ),
      ),
      map(([, user]) => {
        return user.organizationId;
      }),
      filter(isPresent),
      switchMap((organizationId) => {
        return this.appointmentsApiService
          .countAppointments$(organizationId)
          .pipe(
            map((response) => badgesActions.setAppointmentsNotificationsCount({ count: response })),
          );
      }),
    ),
  );

  resendInvite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resendInviteActions.resendInvite),
      switchMap((props) =>
        this.authApiService.resendInvite$(props.email).pipe(
          map(() => resendInviteActions.resendInviteSuccess()),
          catchError((error) => of(resendInviteActions.resendInviteFailure({ error }))),
        ),
      ),
    ),
  );

  resendInviteSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resendInviteActions.resendInviteSuccess),
      map(() =>
        DialogsActions.showDialog({
          data: {
            actions: ['Ok'],
            content: `The invitation is sent to the user's email`,
          },
        }),
      ),
    ),
  );

  cancelInvite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resendInviteActions.cancelInvite),
      switchMap((props) =>
        this.authApiService.cancelInvite$(props.email).pipe(
          map(() => resendInviteActions.cancelInviteSuccess()),
          catchError((error) =>
            of(
              DialogsActions.showDialog({
                data: {
                  actions: ['Close'],
                  content: '',
                  title: error.error?.message || 'Auth API not available',
                },
              }),
              resendInviteActions.cancelInviteFailure({ error }),
            ),
          ),
        ),
      ),
    ),
  );

  pollRefreshToken$$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(refreshTokenActions.refreshTokenSuccess, mfaActions.verifyCodeSuccess),
        switchMap(() => interval(1000 * 60 * 20)),
        withLatestFrom(this.store.select(selectUser).pipe(filter(isPresent))),
        switchMap(() => this.authApiService.refreshToken$()),
      ),
    { dispatch: false },
  );
  updateBadgesPoll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshTokenActions.refreshTokenSuccess, mfaActions.verifyCodeSuccess),
      switchMap(() => interval(1000 * 30)),
      waitWithLatestFrom(this.store.select(selectUser).pipe(filter(isPresent))),
      map(() => badgesActions.updateAllBadges()),
    ),
  );

  updateBadgesOnLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshTokenActions.refreshTokenSuccess, mfaActions.verifyCodeSuccess),
      map(() => badgesActions.updateAllBadges()),
    ),
  );

  updateAllBadges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(badgesActions.updateAllBadges),
      switchMap(() => {
        return of(
          badgesActions.getAppointmentsNotificationsCount(),
          badgesActions.getOrdersNotificationsCount(),
          badgesActions.getCiplaAdminOrdersNotificationsCount(),
        );
      }),
    ),
  );

  showDeliveryConfirmationRequired$ = createEffect(() =>
    this.store
      .select(selectUser)
      .pipe(filter(isPresent))
      .pipe(
        filter((user) => {
          return user.role === ROLES.ORG_ADMIN || user.role === ROLES.ORG_USER;
        }),
        waitWithLatestFrom(
          this.store.select(selectOrdersNotificationsCount).pipe(filter(isPresent)),
        ),
        filter(([user, count]) => Boolean(count)),
        map(([user, count]) =>
          DialogsActions.showDialog({
            data: {
              title: 'Delivery Confirmation Required',
              actions: ['Cancel', 'Check out'],
              content: `You have orders that should have already been delivered. Please check it out and confirm the delivery`,
            },
            callback: (action) => {
              if (action === 'Check out') {
                this.router.navigate([`${AppRoutes.ORDERS}/${user.organizationId}`]);
              }
            },
          }),
        ),
      ),
  );

  trackActivity$ = createEffect(() =>
    this.store.select(selectUser).pipe(
      map((user) => {
        return user ? loginActions.startTrackUserActivity() : loginActions.stopTrackUserActivity();
      }),
    ),
  );

  runTracking$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginActions.startTrackUserActivity),
        switchMap((user) => {
          return merge(
            of(true),
            fromEvent(window, 'mousemove'),
            fromEvent(window, 'resize'),
            fromEvent(document, 'keydown'),
          ).pipe(takeUntil(this.actions$.pipe(ofType(loginActions.stopTrackUserActivity))));
        }),
        debounceTime(Number(localStorage.getItem('inactivityInterval')) || 24 * 60 * 60 * 1000),
        tap(() => {
          this.dialog.open(AutoLogOutComponent, { closeOnNavigation: false, disableClose: true });
        }),
      ),
    { dispatch: false },
  );

  verifyCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mfaActions.verifyCode),
      withLatestFrom(this.store.select(selectMfaData)),
      switchMap(([props, mfaData]) =>
        this.authApiService
          .mfaVerify$(props.code, mfaData?.session!, mfaData?.metadata.cognitoId!)
          .pipe(
            map((res) => mfaActions.verifyCodeSuccess({ res })),
            catchError((error) => of(mfaActions.verifyCodeFailure({ error }))),
          ),
      ),
    ),
  );

  verifyCodeSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(mfaActions.verifyCodeSuccess),
        map((props) => {
          this.passwordService.clearPassword();
          localStorage.setItem('refreshToken', props?.res?.tokens.refreshToken);
          return props;
        }),
        map((props) => {
          this.store.dispatch(loggedInSuccessAction());
          if (props.res.user.role === ROLES.ORG_ADMIN || props.res.user.role === ROLES.ORG_USER) {
            this.router.navigate([`${AppRoutes.ORGANIZATIONS}/${props.res.user.organizationId}`]);
          } else {
            this.router.navigate([AppRoutes.ORGANIZATIONS]);
          }
        }),
      ),
    { dispatch: false },
  );

  verifyCodeFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mfaActions.verifyCodeFailure),
      map((error) => {
        return DialogsActions.showDialog({
          data: {
            actions: ['Close'],
            content: '',
            title: error.error.error.message || 'Auth API not available',
          },
        });
      }),
    ),
  );

  resendCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mfaActions.resendCode),
      withLatestFrom(this.store.select(selectMfaData)),
      map(([_, mfaData]) =>
        loginActions.login({
          body: {
            email: mfaData?.metadata.email!,
            password: this.passwordService.getPassword() ?? '',
          },
        }),
      ),
    ),
  );

  requestCall$ = createEffect(() =>
    this.actions$.pipe(
      ofType(requestCallAction),
      switchMap((props) =>
        this.authApiService.requestCall$(props.body).pipe(
          map(() =>
            DialogsActions.showDialog({
              data: {
                actions: ['Close'],
                content: '',
                title: 'The request has been sent',
              },
            }),
          ),
          catchError((error) =>
            of(
              DialogsActions.showDialog({
                data: {
                  actions: ['Close'],
                  content: '',
                  title: error.error?.message ?? 'Support API is not available',
                },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  getAdminsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminsListActions.getAdminsList),
      switchMap(() =>
        this.authApiService.getAdminsList$().pipe(
          map((admins) => adminsListActions.getAdminsListSuccess({ admins })),
          catchError((error) =>
            of(
              DialogsActions.showDialog({
                data: {
                  actions: ['Close'],
                  content: '',
                  title: error.error?.message ?? 'Admins API is not available',
                },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  addCiplaAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminsListActions.addCiplaAdmin),
      switchMap((props) =>
        this.authApiService.addCiplaAdmin$(props.body).pipe(
          switchMap(() => [
            DialogsActions.showDialog({
              data: {
                actions: ['Ok'],
                content: 'The invitation and temporary password were sent to the specified email.',
                title: 'Cipla Admin account has been created',
              },
            }),
            adminsListActions.getAdminsList(),
          ]),
          catchError((error) =>
            of(
              DialogsActions.showDialog({
                data: {
                  actions: ['Ok'],
                  content: '',
                  title: error.error?.message ?? 'Admins API is not available',
                },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  deleteCiplaAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminsListActions.deleteCiplaAdmin),
      switchMap((props) =>
        this.authApiService.deleteCiplaAdmin$(props.id).pipe(
          switchMap(() => [
            DialogsActions.showDialog({
              data: {
                actions: ['Ok'],
                content: '',
                title: 'Cipla Admin account has been deleted',
              },
            }),
            adminsListActions.getAdminsList(),
          ]),
          catchError((error) =>
            of(
              DialogsActions.showDialog({
                data: {
                  actions: ['Ok'],
                  content: '',
                  title: error.error?.message ?? 'Admins API is not available',
                },
              }),
            ),
          ),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private authApiService: AuthApiService,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private store: Store<AppState>,
    private ordersApiService: OrdersApiService,
    private organizationOrdersApiService: OrganizationOrdersApiService,
    private appointmentsApiService: AppointmentsApiService,
    private passwordService: PasswordService,
  ) {}
}
