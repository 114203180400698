import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { map, Observable, take } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store/models/state';
import { selectUser } from 'src/app/core/store/selectors/selectors';
import { AppRoutes } from 'src/app/core/consts/navigation.const';

@Injectable({ providedIn: 'root' })
export class LoggedInGuard implements CanActivate {
  constructor(
    private store: Store<AppState>,
    private router: Router,
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (route.queryParams['logout']) {
      return true;
    }
    return this.store.select(selectUser).pipe(
      map((user) => {
        if (user) {
          this.router.navigate([AppRoutes.ORGANIZATIONS]);
          return false;
        } else {
          return true;
        }
      }),
      take(1),
    );
  }
}
