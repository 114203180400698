import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogModule,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AbstractControl, FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import * as CustomValidators from '../../../../core/utils/validators';
import { maxFileSize } from '../../../../core/utils/validators';
import { SharedModule } from '../../../../shared/shared.module';
import { allStates } from '../../../../core/consts/states';
import { AppDatepickerComponent } from '../../../../shared/components/app-datepicker/app-datepicker.component';
import { AppFileInputComponent } from '../../../../shared/components/app-file-input/app-file-input.component';
import {
  AppAlertType,
  AppButtonSize,
  AppButtonType,
  FileFormControlValue,
} from '../../../../core/models/common-components.model';
import { OrganizationActions } from '../../store/actions';
import { fromOrganizations } from '../../store/selectors';
import { AsyncPipe, CommonModule } from '@angular/common';
import { filter, switchMap, take, tap } from 'rxjs';
import { LetDirective } from '../../../../core/utils/let-directive';
import { Actions, ofType } from '@ngrx/effects';
import { markControlAsUsedAndValidate } from '../../../../core/utils/mark-control-as-used';
import {
  AddressStatusDto,
  OrganizationDto,
  ShippingAddressDto,
  ShippingAddressFormLicencesDto,
} from '../../../../core/models/organization.model';
import { PERMISSIONS } from '../../../../core/consts/roles-and-permissions';
import { HasPermissionDirective } from '../../../../core/directives/has-permission.directive';
import { isPresent } from '../../../../core/utils/isPresent';
import { FormUtilsService } from '../../../../core/services/form-utils.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DialogsActions } from '../../../dialogs/store/actions';
import {
  deaNumberMask,
  fedexMask,
  phoneNumberPrefix,
  phonePrefixMask,
  resaleCertificateMask,
  upcMask,
  zipCodeMask,
} from 'src/app/core/consts/masks';
import { ShippingAddressesApiService } from '../../../auth/services/shipping-addresses-api.service';

@UntilDestroy()
@Component({
  selector: 'app-add-new-shipping-address',
  templateUrl: './add-new-shipping-address.component.html',
  styleUrl: './add-new-shipping-address.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    ReactiveFormsModule,
    SharedModule,
    AppDatepickerComponent,
    AppFileInputComponent,
    AsyncPipe,
    LetDirective,
    HasPermissionDirective,
  ],
})
export class AddNewShippingAddressComponent implements OnInit, OnDestroy {
  public lastProcessedValues: { [key: string]: string } = {};

  AddressStatusDto = AddressStatusDto;

  enableTaxInformation = false;

  via = [
    { id: 'none', name: 'Not Specified', mask: fedexMask },
    { id: 'UPS', name: 'UPS', mask: upcMask },
    { id: 'FEDEX', name: 'FEDEX', mask: fedexMask },
  ];

  public prefix = phoneNumberPrefix;
  public phoneMask = phonePrefixMask;
  protected readonly alertTypes = AppAlertType;
  protected readonly zipCodeMask = zipCodeMask;
  protected readonly deaNumberMask = deaNumberMask;
  protected readonly resaleCertificateMask = resaleCertificateMask;
  protected readonly PERMISSIONS = PERMISSIONS;
  protected readonly btnSizes = AppButtonSize;

  public form = this.fb.group({
    shippingProvider: this.fb.control<string>('none', { nonNullable: true }),
    accountNumber: this.fb.control(
      { value: '', disabled: true },
      {
        nonNullable: true,
      },
    ),
    customerName: this.fb.control('', {
      validators: [Validators.required, Validators.maxLength(256)],
      nonNullable: true,
    }),
    street: this.fb.control('', {
      validators: [Validators.required, Validators.maxLength(256)],
      nonNullable: true,
    }),
    city: this.fb.control('', {
      validators: [Validators.required, Validators.maxLength(256)],
      nonNullable: true,
    }),
    state: this.fb.control('', { validators: [Validators.required], nonNullable: true }),
    zip: this.fb.control('', {
      validators: [Validators.required, Validators.minLength(5)],
      nonNullable: true,
    }),
    email: this.fb.control('', {
      validators: [
        Validators.required,
        Validators.maxLength(128),
        CustomValidators.emailFormatValidator(),
      ],
      nonNullable: true,
    }),
    phoneNumber: this.fb.control('', {
      validators: [Validators.required, Validators.minLength(10)],
      nonNullable: true,
    }),
    stateLicenceNumber: this.fb.control('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    stateLicenceType: this.fb.control('', { nonNullable: true }),
    stateLicenceExpDate: this.fb.control('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    stateLicenceFile: this.fb.control<FileFormControlValue | null>(null, {
      validators: [Validators.required, maxFileSize()],
      nonNullable: true,
    }),
    deaNumber: this.fb.control('', { validators: [Validators.required], nonNullable: true }),
    deaSchedule: this.fb.control('', { nonNullable: true }),
    deaExpDate: this.fb.control('', { validators: [Validators.required], nonNullable: true }),
    deaLicenceFile: this.fb.control<FileFormControlValue | null>(null, {
      validators: [Validators.required, maxFileSize()],
      nonNullable: true,
    }),

    taxNumber: this.fb.control('', { validators: [Validators.required], nonNullable: true }),
    taxExpDate: this.fb.control('', { validators: [Validators.required], nonNullable: true }),
    taxLicenceFile: this.fb.control<FileFormControlValue | null>(null, {
      validators: [Validators.required, maxFileSize()],
      nonNullable: true,
    }),
  });

  shippingProvider$ = this.form.controls.shippingProvider.valueChanges.pipe(
    tap((value) => {
      value === this.via[0].id
        ? this.disableAccountNumber()
        : this.form.controls.accountNumber.enable();
    }),
  );
  organization$ = this.store$.select(fromOrganizations.selectOrganization).pipe(
    filter(isPresent),
    tap((organization) => {
      if (this.form.controls.customerName.enabled) {
        this.form.controls.customerName.setValue(organization.name, { emitEvent: false });
      }
    }),
  );

  sewShippingAddressSaving$ = this.store$.select(fromOrganizations.selectNewShippingAddressSaving);

  get viaMask() {
    return (
      this.via.find((item) => item.id === this.form.controls.shippingProvider.value)?.mask ||
      fedexMask
    );
  }

  submit$ = this.actions$.pipe(
    ofType(
      OrganizationActions.createNewShippingAddressesSuccess,
      OrganizationActions.declineShippingAddressesSuccess,
      OrganizationActions.approveShippingAddressesSuccess,
      OrganizationActions.deleteShippingAddressesSuccess,
    ),
    tap(() => {
      this.form.markAsPristine();
      this.close('Close');
    }),
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ShippingAddressDto,
    private dialogRef: MatDialogRef<AddNewShippingAddressComponent>,
    private readonly store$: Store,
    private fb: FormBuilder,
    private readonly actions$: Actions,
    private formUtilsService: FormUtilsService,
    private shippingAddressesApiService: ShippingAddressesApiService,
  ) {
    if (!this.enableTaxInformation) {
      this.form.controls.taxNumber.disable();
      this.form.controls.taxExpDate.disable();
      this.form.controls.taxLicenceFile.disable();
    }
  }

  ngOnDestroy(): void {}

  public close(action: string): void {
    if (this.form.pristine) {
      this.dialogRef.close(action);
    } else {
      this.store$.dispatch(
        DialogsActions.showDialog({
          data: {
            actions: ['Cancel', 'Ok'],
            content: '',
            title: 'The updates will not be saved',
          },
          callback: (action) => {
            if (action === 'Ok') {
              this.dialogRef.close(action);
            }
          },
        }),
      );
    }
  }

  cancel() {
    this.close('Cancel');
  }

  submit(id: number, saving: boolean | null) {
    if (this.form.valid && !saving) {
      const formValue = this.form.getRawValue();
      this.store$.dispatch(
        OrganizationActions.createNewShippingAddresses({
          id,
          body: {
            ...formValue,
            taxLicenceFile: formValue.taxLicenceFile?.file,
            deaLicenceFile: formValue.deaLicenceFile?.file,
            stateLicenceFile: formValue.stateLicenceFile?.file,
          },
        }),
      );
    } else {
      markControlAsUsedAndValidate(this.form);
    }
  }
  protected readonly states = allStates;
  protected readonly btnTypes = AppButtonType;

  ngOnInit(): void {
    if (this.data) {
      let formValue: Partial<ShippingAddressFormLicencesDto> = {};

      const licences = this.data.licences || [];

      for (const licence of licences) {
        if (licence.name === 'STATE') {
          formValue.stateLicenceNumber = licence.stateNumber || '';
          formValue.stateLicenceType = licence.stateType || '';
          formValue.stateLicenceExpDate = licence.expirationDate || '';
          formValue.stateLicenceFile = {
            file: licence.file || '',
            id: licence.id,
          };
        }

        if (licence.name === 'DEA') {
          formValue.deaNumber = licence.deaNumber || '';
          formValue.deaSchedule = licence.deaSchedule || '';
          formValue.deaExpDate = licence.expirationDate || '';
          formValue.deaLicenceFile = {
            file: licence.file || '',
            id: licence.id,
          };
        }

        if (licence.name === 'TAX') {
          formValue.taxNumber = licence.taxCertNumber || '';
          formValue.taxExpDate = licence.expirationDate || '';
          formValue.taxLicenceFile = {
            file: licence.file || '',
            id: licence.id,
          };
        }
      }
      this.form.patchValue(this.data, { emitEvent: false });
      this.form.patchValue(formValue, { emitEvent: false });
      this.form.disable();
    }
    this.checkForSpaces();
  }

  decline(address: ShippingAddressDto, organization: OrganizationDto) {
    this.store$.dispatch(
      OrganizationActions.askDeclineShippingAddresses({
        id: organization.id,
        addressId: address.id,
      }),
    );
  }
  delete(address: ShippingAddressDto, organization: OrganizationDto) {
    this.store$.dispatch(
      OrganizationActions.askDeleteShippingAddresses({
        id: organization.id,
        addressId: address.id,
      }),
    );
  }

  approve(address: ShippingAddressDto, organization: OrganizationDto) {
    this.store$.dispatch(
      OrganizationActions.approveShippingAddresses({ id: organization.id, addressId: address.id }),
    );
  }

  downloadFile(value: FileFormControlValue): void {
    if (value.id && this.data.id) {
      this.store$
        .select(fromOrganizations.selectOrganization)
        .pipe(
          filter(isPresent),
          take(1),
          switchMap((organization) =>
            this.shippingAddressesApiService.getShippingFile$(
              organization.id,
              this.data.id,
              value.id!,
            ),
          ),
        )
        .subscribe((link) => {
          const a = document.createElement('a');
          a.href = link;
          a.download = value.file as string;
          a.click();
          window.URL.revokeObjectURL(link);
        });
    }
  }

  afterTodayFilter = (d: Date | null): boolean => {
    const now = new Date().setHours(0, 0, 0, 0);
    const targetDate = d?.getTime() || 0;
    return targetDate > new Date(now).getTime() - 1;
  };

  private checkForSpaces(): void {
    const emailControl = this.form.get('email') as AbstractControl;
    this.formUtilsService.setupControlSpaceCheck(
      emailControl,
      'email',
      this.lastProcessedValues,
      this,
    );
  }

  private disableAccountNumber() {
    this.form.controls.accountNumber.reset();
    this.form.controls.accountNumber.disable();
  }
}
