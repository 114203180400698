import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store/models/state';
import { selectUser } from 'src/app/core/store/selectors/selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ROLES } from 'src/app/core/consts/roles-and-permissions';
import { OrganizationStatusDto } from 'src/app/core/models/organization.model';
import { combineLatestWith } from 'rxjs';
import { selectOrganization } from 'src/app/modules/organizations/store/selectors';

@UntilDestroy()
@Directive({
  selector: '[appIsDeactivatedOrganizationUser]',
  standalone: true,
})
export class IsDeactivatedOrganizationUserDirective implements OnInit {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private store: Store<AppState>,
  ) {}

  ngOnInit() {
    this.store
      .select(selectUser)
      .pipe(combineLatestWith(this.store.select(selectOrganization)), untilDestroyed(this))
      .subscribe(([user, organization]) => {
        this.viewContainer.clear();
        if (user && (user.role === ROLES.ORG_ADMIN || user.role === ROLES.ORG_USER)) {
          if (user.organization && user.organization.status === OrganizationStatusDto.DEACTIVATED) {
            return;
          }
          if (organization && organization.status === OrganizationStatusDto.DEACTIVATED) {
            return;
          }
        }
        this.viewContainer.createEmbeddedView(this.templateRef);
      });
  }
}
