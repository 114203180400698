import { DropDownItem, DropDownItemNumber } from '../models/common-components.model';
import { OrderStatusDto } from '../models/order.model';
import { ShippingAddressDto } from '../models/organization.model';
import { convertFullAddress } from './convertFullAddress';

export const ALL_DROPDOWN_ITEM = { id: 'ALL', name: 'ALL' };

function getOrderStatusDropdownItems(): DropDownItem[] {
  const items = [
    { id: OrderStatusDto.SUBMITTED, name: OrderStatusDto.SUBMITTED },
    { id: OrderStatusDto.IN_PROCESS, name: OrderStatusDto.IN_PROCESS },
    { id: OrderStatusDto.DELIVERED, name: OrderStatusDto.DELIVERED },
    { id: OrderStatusDto.CANCELLED, name: OrderStatusDto.CANCELLED },
  ];

  return items;
}

function getOrderFailReasons(): DropDownItem[] {
  const items = [
    { id: '1', name: 'Damaged Units' },
    { id: '2', name: 'Missing Units' },
    { id: '3', name: 'Entire shipping package is missing' },
    { id: '4', name: 'Other' },
  ];

  return items;
}

function getOrderStatusDropdownItemsForFiltering(): DropDownItem[] {
  let items = [ALL_DROPDOWN_ITEM, ...getOrderStatusDropdownItems()];

  return items;
}

function getQuantityDropdownItems(
  max: number = 25,
  allLabelForMax: boolean = false,
  withZero: boolean = false,
): DropDownItemNumber[] {
  let items = [];
  for (let i = withZero ? 0 : 1; i <= max; i++) {
    items.push({
      label: getQuantityDropdownItemLabel(i, max, allLabelForMax),
      value: i,
    } as DropDownItemNumber);
  }

  if (allLabelForMax) {
    items = items.sort((item1, item2) => item2.value - item1.value);
  }

  return items;
}

function getQuantityDropdownItemLabel(index: number, max: number, allLabelForMax: boolean): string {
  let label = index + '';

  if (allLabelForMax && index === max) {
    label = label + ' (All)';
  }

  return label;
}

function getShippingAddressesLikeDropdownItems(addresses: ShippingAddressDto[]): DropDownItem[] {
  let items: DropDownItem[] = [];

  addresses.forEach((address: ShippingAddressDto) => {
    items.push({
      id: address.id + '',
      name: convertFullAddress({ ...address }),
    });
  });

  return items;
}

export {
  getOrderStatusDropdownItems,
  getOrderStatusDropdownItemsForFiltering,
  getQuantityDropdownItems,
  getShippingAddressesLikeDropdownItems,
  getOrderFailReasons,
};
