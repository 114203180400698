import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from 'src/app/modules/auth/components/login/login.component';
import { ResetComponent } from 'src/app/modules/auth/components/reset/reset.component';
import { RegistrationComponent } from 'src/app/modules/auth/components/registration/registration.component';
import { ChangePasswordComponent } from 'src/app/modules/auth/components/change-password/change-password.component';
import { AuthRoutes } from 'src/app/core/consts/navigation.const';
import { MfaVerificationComponent } from 'src/app/modules/auth/components/mfa-verification/mfa-verification.component';
import { MfaGuard } from 'src/app/core/guards/mfa.guard';
import { BlockedAccountComponent } from './components/blocked-account/blocked-account.component';

const routes: Routes = [
  {
    path: AuthRoutes.RESET_PASSWORD,
    component: ResetComponent,
  },
  {
    path: AuthRoutes.REGISTRATION,
    component: RegistrationComponent,
  },
  {
    path: AuthRoutes.SET_PASSWORD,
    component: ChangePasswordComponent,
  },
  {
    path: AuthRoutes.BLOCKED,
    component: BlockedAccountComponent,
  },
  {
    path: AuthRoutes.MFA_VERIFICATION,
    component: MfaVerificationComponent,
    canActivate: [MfaGuard],
  },
  {
    path: '',
    component: LoginComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
