import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { map, Observable } from 'rxjs';
import { DownloadOrdersRes, GetOrdersResponseDto } from 'src/app/core/models/order.model';
import { ApiService } from 'src/app/core/services/api.service';
import { ALL_DROPDOWN_ITEM } from 'src/app/core/utils/orders.util';

@Injectable({
  providedIn: 'root',
})
export class OrdersApiService extends ApiService {
  getShippingNotes$(): Observable<string> {
    return this.get('/orders/shipping-notes');
  }

  getCount$(): Observable<number> {
    return this.get('/orders/count');
  }

  getOrders$(
    page = 1,
    status: string[] | null,
    sortOrder: SortDirection = 'asc',
    orderBy: string | null,
    limit = 20,
    name: string | null,
  ): Observable<GetOrdersResponseDto> {
    const data = {
      page: page || 1,
      sortOrder: sortOrder || 'desc',
      orderBy: orderBy || 'id',
      limit,
    };

    let httpParams = new HttpParams();
    for (let key in data) {
      let k = key as keyof typeof data;
      httpParams = httpParams.append(key, data[k]);
    }

    if (name) {
      httpParams = httpParams.append('name', name);
    }

    if (status) {
      for (let s of status) {
        httpParams = httpParams.append('status', s);
      }
    }

    // @ts-ignore
    return this.get(`/orders`, httpParams);
  }

  downloadOrders$(
    ordersIds?: number[],
    status?: string[] | null,
    name?: string | null,
    orderBy?: string,
    sortOrder?: SortDirection,
  ): Observable<DownloadOrdersRes> {
    let params = new HttpParams();

    ordersIds?.forEach((id) => {
      params = params.append('ordersIds', id);
    });
    status
      ?.filter((item) => item !== ALL_DROPDOWN_ITEM.id)
      .forEach((label) => {
        params = params.append('status', label);
      });
    if (name) {
      params = params.append('name', name);
    }
    if (orderBy) {
      params = params.append('orderBy', orderBy);
    }
    if (sortOrder) {
      params = params.append('sortOrder', sortOrder);
    }
    return this.getFullResponse<Blob>('/orders/download', params, 'blob').pipe(
      map((response) => {
        const contentDisposition = response.headers.get('Content-Disposition');
        let matches = /filename="([^"]+)"/.exec(contentDisposition!);
        if (!matches) {
          matches = /filename=([^;]+)/.exec(contentDisposition!);
        }
        const filename = matches && matches[1] ? matches[1] : 'default-filename.pdf';

        return {
          blob: response.body,
          filename: filename,
        };
      }),
    );
  }
}
