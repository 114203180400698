import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LegalNoticeComponent } from '../../../modules/static-pages/components/legal-notice/legal-notice.component';
import { customerData } from '../../consts/customer-data';
import { GetSupportComponent } from '../get-support/get-support.component';
import { Router } from '@angular/router';
import { AppRoutes } from '../../consts/navigation.const';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  public customerData = customerData;
  public mailToLink = `mailto:${customerData.email}`;

  constructor(
    private dialog: MatDialog,
    private readonly router: Router,
  ) {}

  navigateToPrivacyPage() {
    this.router.navigate([AppRoutes.PRIVACY_POLICY]);
  }

  navigateToTermsOfUsePage() {
    this.router.navigate([AppRoutes.TERMS_OF_USE]);
  }

  getSupport() {
    this.dialog.open(GetSupportComponent, {
      autoFocus: false,
      width: '392px',
      position: {
        bottom: '24px',
        right: '24px',
      },
    });
  }

  navigateToLegalNoticePage() {
    this.dialog.open(LegalNoticeComponent, {
      autoFocus: false,
      maxHeight: '90vh',
    });
  }

  navigateToReturnPolicy() {
    this.router.navigate([AppRoutes.RETURN_GOODS_POLICY]);
  }
}
