<div class="app-reset-password-link-expired">
  <div automation-id="title" class="title">The link to reset password has expired.</div>
  <div automation-id="content" class="content">
    <a (click)="$event.preventDefault(); navigateToForgetPassword()">Click here to try again</a>
  </div>

  <div automation-id="actions" class="actions">
    <app-button
      [size]="btnSizes.FullWidth"
      [type]="btnTypes.Stroked"
      (click)="close()"
      [label]="'Back to Login'"
      class="app-dialog__action"
    ></app-button>
  </div>
</div>
