export enum Statuses {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  DISABLED = 'DISABLED',
  DEACTIVATED = 'DEACTIVATED',
  OPENED = 'OPENED',
  SUBMITTED = 'SUBMITTED',
  DELIVERED = 'DELIVERED',
  CANCELLED = 'CANCELLED',
  ENROLLMENT = 'ENROLLMENT',
  ONBOARDING = 'ONBOARDING',
  IN_PROCESS = 'IN_PROCESS',
  ON_HOLD = 'ON_HOLD',
  OPTED_IN = 'OPTED_IN',
  OPTED_OUT = 'OPTED_OUT',
}
