<div class="app-almost-there" [ngClass]="{ notitle: !data.title }">
  <div automation-id="title" class="title">You're almost there!</div>
  <div automation-id="content" class="content">
    Please complete your account enrollment by adding ship-to information and providing the
    necessary regulatory information
  </div>

  <div automation-id="content" class="documents">
    <li>State License</li>
    <li>DEA Certificate</li>
    <li>Resale/Tax Exempt Certificate</li>
  </div>

  <div automation-id="alert" class="alert">
    <app-alert [type]="alertTypes.Info">
      Incomplete and/or missing information may delay account creation
    </app-alert>
  </div>

  <div automation-id="actions" class="actions">
    <app-button
      [size]="btnSizes.FullWidth"
      [type]="item === 'Ok' ? btnTypes.Flat : btnTypes.Stroked"
      (click)="close(item)"
      *ngFor="let item of data.actions"
      [label]="item"
      class="app-dialog__action"
    ></app-button>
    <div style="display: none" cdkFocusInitial></div>
  </div>
</div>
