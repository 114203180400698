import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { LetDirective } from '../../../core/utils/let-directive';
import { MatInputModule } from '@angular/material/input';
import { NgxMaskDirective } from 'ngx-mask';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FileFormControlValue } from '../../../core/models/common-components.model';

@UntilDestroy()
@Component({
  selector: 'app-file-input',
  templateUrl: './app-file-input.component.html',
  styleUrl: './app-file-input.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatProgressBarModule,
    MatIconModule,
    LetDirective,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    NgxMaskDirective,
  ],
})
export class AppFileInputComponent {
  @Input() showErrors?: boolean = true;
  @Input() readonly = false;
  @Input() highlightAsError?: boolean = false;

  @Input() control!: FormControl;

  @Input()
  requiredFileType: string = '.pdf,.jpg,.gif,.png';
  @Input()
  requiredFileName = 'Required File Name';
  @Input() maxFileSizeInMB: number = 20;
  @Output() onDownload: EventEmitter<FileFormControlValue> =
    new EventEmitter<FileFormControlValue>();

  onFileSelected(event: any) {
    this.control.setValue({
      file: event.target.files[0],
    });
    this.control.markAsTouched();
    event.target.value = '';
  }

  deleteFile() {
    this.control.reset();
  }

  download() {
    const fileId = this.control.value?.id;
    if (fileId) {
      this.onDownload.emit(this.control.value);
    }
  }
}
