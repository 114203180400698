<ng-container *ngIf="control">
  <input
    type="file"
    class="file-input"
    [accept]="requiredFileType"
    (change)="onFileSelected($event)"
    #fileUpload
  />

  <div class="file-upload">
    @if (control.enabled) {
      <div class="file-title">
        @if (control.value) {
          <mat-icon>done</mat-icon>
          <div class="file-name">{{ control.value.name || requiredFileName }}</div>
          <mat-icon class="close" (click)="deleteFile()">close</mat-icon>
        } @else {
          <mat-icon>upload</mat-icon>
          Upload {{ requiredFileName }}
        }
      </div>
      <div class="browse-section"><a (click)="fileUpload.click()">Browse</a> your files</div>
      <div class="accept-section">
        Accepted PDF, JPG, GIF and PNG files with max size of {{ maxFileSizeInMB }}Mb
      </div>
    } @else {
      <div class="file-title">
        <a (click)="download()">{{ requiredFileName }}</a>
      </div>
    }
  </div>

  @if (control.invalid && control.touched) {
    @if (control.hasError('custom')) {
      <mat-error class="error" automation-id="size-field-error">
        Maximum file size exceeded
      </mat-error>
    }
    @if (control.hasError('required')) {
      <mat-error class="error" automation-id="required-field-error">
        Please fill in the required field(s)
      </mat-error>
    }
  }
</ng-container>
