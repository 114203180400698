import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { Observable, of } from 'rxjs';
import {
  ChangeStatusBodyDto,
  ConfirmOrderBodyDto,
  CreateOrderBodyDto,
  CreateOrderResponseDto,
  GetOrdersResponseDto,
  OrderDto,
} from 'src/app/core/models/order.model';
import { ApiService } from 'src/app/core/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class OrganizationOrdersApiService extends ApiService {
  createOrder$(orgId: string, body: CreateOrderBodyDto): Observable<CreateOrderResponseDto> {
    return this.post(`/organizations/${orgId}/orders`, body);
  }

  confirmOrder$(orgId: string, orderId: string, body: ConfirmOrderBodyDto): Observable<OrderDto> {
    return this.patch(`/organizations/${orgId}/orders/${orderId}/confirm`, body);
  }

  changeStatus$(orgId: string, orderId: string, body: ChangeStatusBodyDto): Observable<OrderDto> {
    return this.patch(`/organizations/${orgId}/orders/${orderId}/status`, body);
  }

  getCount$(orgId: string): Observable<number> {
    if (orgId === '') {
      return of(0);
    }

    return this.get(`/organizations/${orgId}/orders/count`);
  }

  getOrders$(
    id: number,
    page = 1,
    status: string[] | null,
    sortOrder: SortDirection = 'asc',
    orderBy: string | null,
    limit = 20,
  ): Observable<GetOrdersResponseDto> {
    const data = {
      page: page || 1,
      sortOrder: sortOrder || 'desc',
      orderBy: orderBy || 'id',
      limit,
    };

    let httpParams = new HttpParams();
    for (let key in data) {
      let k = key as keyof typeof data;
      httpParams = httpParams.append(key, data[k]);
    }

    if (status) {
      for (let s of status) {
        httpParams = httpParams.append('status', s);
      }
    }

    // @ts-ignore
    return this.get(`/organizations/${id}/orders`, httpParams);
  }
}
