<div class="app-patient-consent-form page">
  <div class="header">
    <span class="text">Health Information Opt-In Form</span>
  </div>
  <div class="content">
    By clicking “I consent” below, I agree that
    {{ decodedToken ? decodedToken.orgName : '[HEALTHCARE PROVIDER]' }} may share my personal
    information with Cipla LeuPRO Logix for the purposes of facilitating the scheduling and
    management of my medical treatments. I understand that my personal information, which may be
    defined as protected health information (PHI), will not be protected under the Health Insurance
    Portability and Accountability Act (HIPAA) once transferred Cipla. I understand that my personal
    information will be protected in accordance with
    <a (click)="openConsumerPolicy()" class="link"> Cipla Consumer Health Data Privacy Policy</a>,
    which I have reviewed. I further understand that I may opt out of the sharing of my personal
    information with Cipla at any time by contacting Cipla at globalprivacy&#64;cipla.com.
    <div class="buttons-block">
      <app-button
        [type]="btnTypes.Stroked"
        label="Cancel"
        (onClick)="cancel()"
        [disabled]="submitted"
      ></app-button>

      <app-button
        [type]="btnTypes.Flat"
        label="I consent"
        [disabled]="submitted"
        (onClick)="submit()"
      ></app-button>
    </div>
  </div>
</div>
