import { combineLatest, Observable, ObservableInput, ObservedValueOf } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

export function waitWithLatestFrom<T, O1 extends ObservableInput<any>>(
  ...waitObservables: [O1]
): (source: Observable<T>) => Observable<[T, ObservedValueOf<O1>]>;

export function waitWithLatestFrom<
  T,
  O1 extends ObservableInput<any>,
  O2 extends ObservableInput<any>,
>(
  ...waitObservables: [O1, O2]
): (source: Observable<T>) => Observable<[T, ObservedValueOf<O1>, ObservedValueOf<O2>]>;

export function waitWithLatestFrom<
  T,
  O1 extends ObservableInput<any>,
  O2 extends ObservableInput<any>,
  O3 extends ObservableInput<any>,
>(
  ...waitObservables: [O1, O2, O3]
): (
  source: Observable<T>,
) => Observable<[T, ObservedValueOf<O1>, ObservedValueOf<O2>, ObservedValueOf<O3>]>;

export function waitWithLatestFrom<
  T,
  O1 extends ObservableInput<any>,
  O2 extends ObservableInput<any>,
  O3 extends ObservableInput<any>,
  O4 extends ObservableInput<any>,
>(
  ...waitObservables: [O1, O2, O3, O4]
): (
  source: Observable<T>,
) => Observable<
  [T, ObservedValueOf<O1>, ObservedValueOf<O2>, ObservedValueOf<O3>, ObservedValueOf<O4>]
>;

export function waitWithLatestFrom<
  T,
  O1 extends ObservableInput<any>,
  O2 extends ObservableInput<any>,
  O3 extends ObservableInput<any>,
  O4 extends ObservableInput<any>,
  O5 extends ObservableInput<any>,
>(
  ...waitObservables: [O1, O2, O3, O4, O5]
): (
  source: Observable<T>,
) => Observable<
  [
    T,
    ObservedValueOf<O1>,
    ObservedValueOf<O2>,
    ObservedValueOf<O3>,
    ObservedValueOf<O4>,
    ObservedValueOf<O5>,
  ]
>;

export function waitWithLatestFrom<
  T,
  O1 extends ObservableInput<any>,
  O2 extends ObservableInput<any>,
  O3 extends ObservableInput<any>,
  O4 extends ObservableInput<any>,
  O5 extends ObservableInput<any>,
  O6 extends ObservableInput<any>,
>(
  ...waitObservables: [O1, O2, O3, O4, O5, O6]
): (
  source: Observable<T>,
) => Observable<
  [
    T,
    ObservedValueOf<O1>,
    ObservedValueOf<O2>,
    ObservedValueOf<O3>,
    ObservedValueOf<O4>,
    ObservedValueOf<O5>,
    ObservedValueOf<O6>,
  ]
>;

export function waitWithLatestFrom<T, O extends ObservableInput<any>>(
  ...waitObservables: O[]
): (source: Observable<T>) => Observable<[T, ...Array<ObservedValueOf<O>>]>;

export function waitWithLatestFrom<T, O1 extends ObservableInput<any>>(
  ...waitObservables: O1[]
): (source: Observable<T>) => Observable<[T, ...Array<ObservedValueOf<O1>>]> {
  return (source: Observable<T>) => {
    return source.pipe(
      switchMap((action) => {
        return combineLatest(waitObservables).pipe(
          first(),
          map((values) => {
            return [action, ...values] as [T, ...Array<ObservedValueOf<O1>>];
          }),
        );
      }),
    );
  };
}
