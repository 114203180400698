import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormArray, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import {
  accountNumberMaks,
  dunsNumberMask,
  faxMask,
  feinNumberMask,
  phoneMask,
} from 'src/app/core/consts/masks';
import { AppDatepickerComponent } from 'src/app/shared/components/app-datepicker/app-datepicker.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { naturesOfEntity } from 'src/app/modules/organizations/models/enrollment.model';

@UntilDestroy()
@Component({
  selector: 'app-enrollment-credit-application',
  templateUrl: './enrollment-credit-application.component.html',
  styleUrl: './enrollment-credit-application.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, SharedModule, AppDatepickerComponent],
})
export class EnrollmentCreditApplicationComponent implements OnInit {
  @Input() form: FormGroup | undefined;
  protected readonly dunsNumberMask = dunsNumberMask;
  protected readonly phoneMask = phoneMask;
  protected readonly faxMask = faxMask;
  protected readonly feinNumberMask = feinNumberMask;
  protected readonly accountNumberMaks = accountNumberMaks;
  public naturesOfEntity = naturesOfEntity;
  public yesNoRadio = [
    { id: true, name: 'Yes' },
    { id: false, name: 'No' },
  ];

  ngOnInit() {
    this.form
      ?.get('businessContactInformation.natureOfEntity')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((value) => {
        const specifyControl = this.form?.get('businessContactInformation.specifyOthers');
        if (value === this.naturesOfEntity[4].id) {
          specifyControl?.setValidators(Validators.required);
          specifyControl?.enable();
        } else {
          specifyControl?.removeValidators(Validators.required);
          specifyControl?.disable();
        }
      });
  }

  getFormGroup(
    key:
      | 'businessContactInformation'
      | 'businessCreditInformation'
      | 'bankReference'
      | 'businessReferenceInformation',
  ): FormGroup {
    return this.form?.get(key) as FormGroup;
  }

  public getFormArray(): FormArray {
    return this.form?.get('businessReferenceInformation.references') as FormArray;
  }
}
