import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import * as CustomValidators from '../../../../../core/utils/validators';
import { tap } from 'rxjs';
import { phoneNumberPrefix, phonePrefixMask } from 'src/app/core/consts/masks';

@Component({
  selector: 'app-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrl: './personal-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonalDetailsComponent {
  public form = this.fb.group({
    firstName: this.fb.control('', { validators: [Validators.required], nonNullable: true }),
    lastName: this.fb.control('', { validators: [Validators.required], nonNullable: true }),
    email: this.fb.control('', {
      validators: [
        Validators.required,
        Validators.maxLength(128),
        CustomValidators.emailFormatValidator(),
      ],
      nonNullable: true,
    }),
    phoneNumber: this.fb.control('', {
      nonNullable: true,
      validators: [Validators.minLength(10), Validators.required],
    }),
    message: this.fb.control('', { nonNullable: true }),
  });

  email$ = this.form.controls.email.valueChanges.pipe(
    tap((value) => {
      this.form.controls.email.setValue(value.replace(/\s/g, '').replace('..', '.'), {
        emitEvent: false,
      });
    }),
  );

  prefix = phoneNumberPrefix;
  phoneMask = phonePrefixMask;

  constructor(private fb: FormBuilder) {}
}
