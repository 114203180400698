import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FeatureState } from './state';
import { AddressStatusDto } from '../../../core/models/organization.model';

export const featureKey = 'organizationsFeature';

export const selectFeatureState = createFeatureSelector<FeatureState>(featureKey);

export const selectLastOrganizationSearchResult = createSelector(
  selectFeatureState,
  (state) => state.lastOrganizationSearch,
);

export const selectRegistering = createSelector(
  selectFeatureState,
  (state: FeatureState) => state.registering,
);

export const selectSearchName = createSelector(
  selectFeatureState,
  (state: FeatureState) => state.name,
);

export const selectSearchStatus = createSelector(
  selectFeatureState,
  (state: FeatureState) => state.status,
);
export const selectSearchPage = createSelector(
  selectFeatureState,
  (state: FeatureState) => state.page,
);

export const selectSearchOrderBy = createSelector(
  selectFeatureState,
  (state: FeatureState) => state.orderBy,
);

export const selectSearchSortOrder = createSelector(
  selectFeatureState,
  (state: FeatureState) => state.sortOrder,
);

export const selectSearchPageSize = createSelector(
  selectFeatureState,
  (state: FeatureState) => state.pageSize,
);

export const selectActionRequired = createSelector(
  selectFeatureState,
  (state: FeatureState) => state.actionRequired,
);

export const selectOrganizations = createSelector(
  selectFeatureState,
  (state: FeatureState) => state.organizations,
);

export const selectOrganizationsLoading = createSelector(
  selectFeatureState,
  (state: FeatureState) => state.loadingOrganizations,
);

export const selectPageSize = createSelector(
  selectFeatureState,
  (state: FeatureState) => state.pageSize,
);

export const selectTotal = createSelector(selectFeatureState, (state: FeatureState) => state.total);

export const selectPage = createSelector(selectFeatureState, (state: FeatureState) => state.page);

export const selectOrganization = createSelector(
  selectFeatureState,
  (state: FeatureState) => state.organization,
);

export const selectOrganizationUsers = createSelector(
  selectFeatureState,
  (state: FeatureState) => state.organizationUsers,
);

export const selectOrganizationShippingAddress = createSelector(
  selectFeatureState,
  (state: FeatureState) => state.organizationShippingAddress,
);

export const selectApprovedOrganizationShippingAddress = createSelector(
  selectFeatureState,
  (state: FeatureState) =>
    state.organizationShippingAddress?.filter(
      (address) =>
        address.status === AddressStatusDto.ACTIVE ||
        address.status === AddressStatusDto.DEACTIVATED,
    ),
);

export const selectReportsOrganizationShippingAddress = createSelector(
  selectFeatureState,
  (state: FeatureState) =>
    state.organizationShippingAddress?.filter(
      (address) =>
        address.status === AddressStatusDto.ACTIVE ||
        address.status === AddressStatusDto.DELETED ||
        address.status === AddressStatusDto.DEACTIVATED,
    ),
);
export const selectOrganizationLoading = createSelector(
  selectFeatureState,
  (state: FeatureState) => state.loadingOrganization,
);

export const selectLoadingAddresses = createSelector(
  selectFeatureState,
  (state: FeatureState) => state.loadingAddresses,
);

export const selectNewShippingAddressSaving = createSelector(
  selectFeatureState,
  (state: FeatureState) => state.shippingAddressSaving,
);

export const selectOrganizationEnrollment = createSelector(
  selectFeatureState,
  (state: FeatureState) => state.enrollment,
);

export const selectOrganizationEnrollmentShort = createSelector(
  selectFeatureState,
  (state: FeatureState) => state.enrollmentShortSummary,
);

export const fromOrganizations = {
  selectLastOrganizationSearchResult,
  selectRegistering,
  selectSearchSortOrder,
  selectSearchOrderBy,
  selectSearchPage,
  selectSearchName,
  selectSearchStatus,
  selectOrganizations,
  selectOrganizationsLoading,
  selectTotal,
  selectPageSize,
  selectPage,
  selectOrganization,
  selectOrganizationLoading,
  selectOrganizationUsers,
  selectOrganizationShippingAddress,
  selectLoadingAddresses,
  selectNewShippingAddressSaving,
  selectApprovedOrganizationShippingAddress,
  selectSearchPageSize,
  selectReportsOrganizationShippingAddress,
  selectActionRequired,
};
