<div class="app-dialog" [ngClass]="{ notitle: !data.title }">
  <h6 *ngIf="data.title" automation-id="title" mat-dialog-title class="app-dialog__title">
    <span [innerHTML]="data.title | safeHtml"></span>
  </h6>
  <div automation-id="content" mat-dialog-content class="app-dialog__content">
    <span [innerHTML]="data.content | safeHtml"></span>
  </div>
  <div automation-id="actions" mat-dialog-actions class="app-dialog__actions">
    <app-button
      [size]="btnSizes.FullWidth"
      [type]="item === 'Ok' ? btnTypes.Flat : btnTypes.Stroked"
      (click)="close(item)"
      *ngFor="let item of data.actions"
      [label]="item"
      class="app-dialog__action"
    ></app-button>
    <div style="display: none" cdkFocusInitial></div>
  </div>
</div>
