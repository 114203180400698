export class BaseFactory<T extends string = string> {
  protected readonly entities: { [key in T]?: { id: T; name: string } } = {};

  getById(id: string | T) {
    const item = { ...this.entities[id as T] };
    if (!item) {
      throw new Error(`Unknown id ${id} in ${this.constructor.name}`);
    }
    return item;
  }
}
