<div class="app-native-checkbox">
  <div class="app-native-checkbox__input-wrapper">
    <input
      type="checkbox"
      id="app-native-checkbox"
      class="app-native-checkbox__input"
      [disabled]="disabled"
      [checked]="checked"
      [indeterminate]="indeterminate"
      (click)="click($event)"
      (change)="change($event)"
    />
  </div>
  <label for="app-native-checkbox" class="app-native-checkbox__label">
    <ng-container *ngIf="label; else labelTemplate">{{ label }}</ng-container>
    <ng-template #labelTemplate>
      <span (click)="$event.preventDefault()"><ng-content></ng-content></span>
    </ng-template>
  </label>
</div>
