import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { OrganizationsApiService } from '../../../services/organizations-api.service';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, tap } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { OrganizationActions } from '../../../../organizations/store/actions';
import { createNpiValidator } from './npi-validator';
import { PrivacyPolicyComponent } from '../../../../static-pages/components/privacy-policy/privacy-policy.component';
import { MatDialog } from '@angular/material/dialog';
import { npiMask } from 'src/app/core/consts/masks';
import { Router } from '@angular/router';
import { TermsOfUseComponent } from '../../../../static-pages/components/terms-of-use/terms-of-use.component';

@Component({
  selector: 'app-npi-validation',
  templateUrl: './npi-validation.component.html',
  styleUrl: './npi-validation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NpiValidationComponent {
  public form = this.fb.group({
    npi: this.fb.control<string>('', {
      validators: [Validators.required, Validators.minLength(10)],
      asyncValidators: [createNpiValidator(this.apiService, this.store$)],
    }),
    termsConfirmation: this.fb.control<boolean>(false, {
      validators: [Validators.requiredTrue],
    }),
  });

  npiMask = npiMask;
  npiChanged$ = this.form.controls.npi.valueChanges.pipe(
    distinctUntilChanged(),
    tap((value) => {
      if (value?.length === 10) {
        this.form.controls.termsConfirmation.setValue(false, { emitEvent: false });
      }
    }),
  );

  searchResult$ = this.actions$.pipe(ofType(OrganizationActions.resetSearchResult)).pipe(
    tap(() => {
      this.cdr.detectChanges();
    }),
  );
  constructor(
    private fb: FormBuilder,
    private readonly apiService: OrganizationsApiService,
    private readonly store$: Store,
    private readonly actions$: Actions,
    private readonly cdr: ChangeDetectorRef,
    private readonly dialog: MatDialog,
    private readonly router: Router,
  ) {}

  navigateToPrivacyPage() {
    this.dialog.open(PrivacyPolicyComponent, {
      autoFocus: false,
      maxHeight: '90vh',
    });
  }

  navigateToTermsOfUsePage() {
    this.dialog.open(TermsOfUseComponent, {
      autoFocus: false,
      maxHeight: '90vh',
    });
  }
}
