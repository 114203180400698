@if (form) {
  <div class="app-enrollment-credit-application" [formGroup]="form">
    <div
      class="app-enrollment-credit-application__block"
      [formGroup]="getFormGroup('businessContactInformation')"
    >
      <div class="app-enrollment-credit-application__title">Business Contact Information</div>
      <div class="app-enrollment-credit-application__control">
        <app-input label="Company Name" formControlName="companyName" [maxLength]="256"></app-input>
      </div>
      <div class="app-enrollment-credit-application__row">
        <div class="app-enrollment-credit-application__control">
          <app-input label="DUNS No." formControlName="dunsNo" [mask]="dunsNumberMask"></app-input>
        </div>
        <div class="app-enrollment-credit-application__control">
          <app-datepicker
            label="Registered Since"
            formControlName="registeredSince"
          ></app-datepicker>
        </div>
      </div>
      <div class="app-enrollment-credit-application__row">
        <div class="app-enrollment-credit-application__control">
          <app-input
            label="Registered Address"
            formControlName="registeredAddress"
            [maxLength]="256"
          ></app-input>
        </div>
        <div class="app-enrollment-credit-application__control">
          <app-input
            label="City, State, Zip Code"
            formControlName="cityStateZip"
            [maxLength]="256"
          ></app-input>
        </div>
      </div>
      <div class="app-enrollment-credit-application__row">
        <div class="app-enrollment-credit-application__control">
          <app-input
            label="Account Receivable Email"
            formControlName="accountReceivableEmail"
            [maxLength]="128"
          ></app-input>
        </div>
        <div class="app-enrollment-credit-application__control">
          <app-input label="Phone" formControlName="phone" [mask]="phoneMask"></app-input>
        </div>
        <div class="app-enrollment-credit-application__control">
          <app-input label="Fax" formControlName="fax" [mask]="faxMask"></app-input>
        </div>
      </div>
      <div class="app-enrollment-credit-application__row">
        <div class="app-enrollment-credit-application__control">
          <app-input
            label="Nature of Business"
            formControlName="natureOfBusiness"
            [maxLength]="256"
          ></app-input>
        </div>
        <div class="app-enrollment-credit-application__control">
          <app-input label="F-EIN No." formControlName="fEinNo" [mask]="feinNumberMask"></app-input>
        </div>
      </div>
      <div class="app-enrollment-credit-application__control">
        <app-radio
          [values]="naturesOfEntity"
          formControlName="natureOfEntity"
          label="Nature of Entity"
        ></app-radio>
        <div class="app-enrollment-credit-application__control-specify">
          <app-input label="Specify" formControlName="specifyOthers" [maxLength]="256"></app-input>
        </div>
      </div>
    </div>
    <div
      class="app-enrollment-credit-application__block"
      [formGroup]="getFormGroup('businessCreditInformation')"
    >
      <div class="app-enrollment-credit-application__title">Business Credit Information</div>
      <div class="app-enrollment-credit-application__row">
        <div class="app-enrollment-credit-application__control">
          <app-input
            label="Registered Address"
            formControlName="registeredAddressBusiness"
            [maxLength]="256"
          ></app-input>
        </div>
        <div class="app-enrollment-credit-application__control">
          <app-input
            label="City, State, Zip Code"
            formControlName="cityStateZipBusiness"
            [maxLength]="256"
          ></app-input>
        </div>
      </div>
      <div class="app-enrollment-credit-application__control">
        <app-radio
          [values]="yesNoRadio"
          formControlName="isBankruptcyInitiated"
          label="Have you or your Officers or Affiliates ever filed a petition in bankruptcy?"
        ></app-radio>
      </div>
    </div>
    <div
      class="app-enrollment-credit-application__block"
      [formGroup]="getFormGroup('bankReference')"
    >
      <div class="app-enrollment-credit-application__title">Bank Reference</div>
      <div class="app-enrollment-credit-application__control">
        <app-input label="Bank Name" formControlName="bankName" [maxLength]="256"></app-input>
      </div>
      <div class="app-enrollment-credit-application__control">
        <app-input label="Bank Address" formControlName="bankAddress" [maxLength]="256"></app-input>
      </div>
      <div class="app-enrollment-credit-application__row">
        <div class="app-enrollment-credit-application__control">
          <app-input
            label="Type of Account"
            formControlName="typeOfAccount"
            [maxLength]="256"
          ></app-input>
        </div>
        <div class="app-enrollment-credit-application__control">
          <app-input
            label="Account No."
            formControlName="accountNo"
            [mask]="accountNumberMaks"
          ></app-input>
        </div>
      </div>
      <div class="app-enrollment-credit-application__row">
        <div class="app-enrollment-credit-application__control">
          <app-input
            label="Bank Contact Person"
            formControlName="bankContactPerson"
            [maxLength]="256"
          ></app-input>
        </div>
        <div class="app-enrollment-credit-application__control">
          <app-input label="Phone" formControlName="bankPhone" [mask]="phoneMask"></app-input>
        </div>
      </div>
    </div>
    <div
      class="app-enrollment-credit-application__block"
      [formGroup]="getFormGroup('businessReferenceInformation')"
    >
      <div formArrayName="references">
        <div class="app-enrollment-credit-application__title">Business Reference Information</div>
        <div class="app-enrollment-credit-application__subtitle">Please provide 3 references</div>
        @for (group of getFormArray().controls; track group; let i = $index) {
          <div class="app-enrollment-credit-application__small-block" [formGroupName]="i">
            <div class="app-enrollment-credit-application__small-block-title">
              Reference {{ i + 1 }}
            </div>
            <div class="app-enrollment-credit-application__row">
              <div class="app-enrollment-credit-application__control">
                <app-input
                  label="Company Name"
                  formControlName="companyName"
                  [maxLength]="256"
                ></app-input>
              </div>
              <div class="app-enrollment-credit-application__control">
                <app-input
                  label="Account No."
                  formControlName="accountNo"
                  [mask]="accountNumberMaks"
                ></app-input>
              </div>
            </div>
            <div class="app-enrollment-credit-application__row">
              <div class="app-enrollment-credit-application__control">
                <app-input
                  label="Bank Address"
                  formControlName="bankAddress"
                  [maxLength]="256"
                ></app-input>
              </div>
              <div class="app-enrollment-credit-application__control">
                <app-input
                  label="City, State, Zip Code"
                  formControlName="cityStateZip"
                  [maxLength]="256"
                ></app-input>
              </div>
            </div>
            <div class="app-enrollment-credit-application__row">
              <div class="app-enrollment-credit-application__control">
                <app-input
                  label="Customer Service Email"
                  formControlName="customerServiceEmail"
                  [maxLength]="128"
                ></app-input>
              </div>
              <div class="app-enrollment-credit-application__control">
                <app-input label="Phone" formControlName="phone" [mask]="phoneMask"></app-input>
              </div>
              <div class="app-enrollment-credit-application__control">
                <app-input label="Fax" formControlName="fax" [mask]="faxMask"></app-input>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
    <div class="app-enrollment-credit-application__control">
      <app-checkbox formControlName="confirmation">
        I certify that the information I have provided is accurate and I am electronically signing
        and authorizing Cipla to process the information on the attached form. By submitting this
        application, I authorize Cipla USA Inc. to make inquiries to my Credit History, Financial
        Institution and Business/Trade references.
      </app-checkbox>
    </div>
  </div>
}
