import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RoleToLabelPipe } from '../../../pipes/role-to-label.pipe';
import { SharedModule } from '../../../../shared/shared.module';
import { Store } from '@ngrx/store';
import { selectCiplaAdmins } from '../../../store/selectors/selectors';
import { formatStringToPhone } from '../../../utils/formatStringToPhone';
import { adminsListActions } from '../../../store/actions/actions';

@Component({
  selector: 'app-admins-table',
  templateUrl: './admins-table.component.html',
  styleUrl: './admins-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    MatFormFieldModule,
    MatIconModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    RoleToLabelPipe,
    SharedModule,
  ],
})
export class AdminsTableComponent {
  public displayedColumns: string[] = ['id', 'name', 'email', 'phone', 'action'];
  public admins$ = this.store.select(selectCiplaAdmins);

  constructor(private store: Store) {}

  protected readonly formatStringToPhone = formatStringToPhone;

  deleteAdmin(id: number): void {
    this.store.dispatch(adminsListActions.deleteCiplaAdmin({ id }));
  }
}
