import { NgModule } from '@angular/core';
import { OrganizationsComponent } from './components/organizations/organizations.component';
import { OrganizationsRoutingModule } from 'src/app/modules/organizations/organizations-routing.module';
import { OrganizationsStoreModule } from './store/module';
import { SharedModule } from '../../shared/shared.module';
import { HasPermissionDirective } from '../../core/directives/has-permission.directive';
import { ReactiveFormsModule } from '@angular/forms';
import { LetDirective } from '../../core/utils/let-directive';
import { CommonModule } from '@angular/common';
import { OrganizationsListComponent } from './components/organizations-list/organizations-list.component';

@NgModule({
  declarations: [OrganizationsComponent],
  imports: [
    OrganizationsRoutingModule,
    OrganizationsStoreModule,
    SharedModule,
    HasPermissionDirective,
    ReactiveFormsModule,
    LetDirective,
    CommonModule,
    OrganizationsListComponent,
  ],
})
export class OrganizationsModule {}
