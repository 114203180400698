<div class="app-left-side-menu" *ngIf="user" automation-id="left-menu">
  <ng-container *ngFor="let item of menuItems; let i = index">
    <div automation-id="menu-item"
      *ngIf="!item.roles?.length || (item.roles?.length && item.roles?.includes(user.role))"
      class="app-left-side-menu__item"
      [ngClass]="{ _active: i === selectedItemIndex }"
      (click)="selectItem(i)"
    >
      <div class="app-left-side-menu__item-icon-container">
        <mat-icon class="app-left-side-menu__item-icon material-icons-outlined">{{
          item.icon
        }}</mat-icon>
      </div>
      @if (!!item.counter) {
        <span
          class="app-left-side-menu__item-label"
          [matBadge]="item.counter"
          matBadgePosition="after"
          matBadgeColor="warn"
          >{{ item.label }}</span
        >
      } @else {
        <span class="app-left-side-menu__item-label">{{ item.label }}</span>
      }
    </div>
  </ng-container>
</div>
