<div>
  <h1>SUPPLIER'S RETURN POLICY</h1>
  <p><strong>Cipla USA Inc. – Standard Return Goods Policy</strong></p>
  <p>
    This Return Goods Policy of Supplier, a wholly owned subsidiary of Cipla Limited, utilizes a 3rd
    Party Returns Processing Agent for processing returns of Products. Supplier reserves the right
    to deny credit and does not assume responsibility for charges incurred by Participants using
    return companies other than the 3rd Party Processing Agent listed below.
  </p>
  <p><strong>Designated Return Processing Agent for Supplier</strong></p>
  <p>
    All eligible Supplier returns should be sent to the 3rd Party Logistics Company referenced
    below. In order to receive reimbursement, all returned Product must include an invoice/debit
    memo and shipped prepaid to: INMAR – South Dock c/o Cipla USA 4332 Empire Road Fort Worth, TX
    76155 1-800-967-5952 (Customer Service toll free)
  </p>
  <p><strong>Terms of Return Policy</strong></p>
  <p>
    All Products purchased from Supplier or its authorized wholesalers must be returned to a
    Supplier return processing agent. Supplier reserves the right to refuse credit if Product is
    destroyed and not returned in accordance with this policy.If a Product is returned to Supplier
    (through its returns processing agent), Supplier and/or its return processing agent may destroy,
    with or without payment or other recourse, any returned Product. Supplier will determine if the
    returned Product qualifies for credit or refund in accordance with this policy.Direct purchases
    are eligible for credit to be applied against outstanding account activity. Indirect purchases
    by Participants who have active indirect purchasing contracts with Supplier will receive refunds
    via their wholesaler. The refund or credit value of the returned Product will be in the amount
    of the price purchased less credits and adjustment or the current active contract price for the
    product, whichever is lower.Credit or refund will be processed directly to the Participant
    within 60 days after receipt of an approved return. Unauthorized deductions will not be
    accepted.Supplier will not pay or reimburse any service fees to Participants or third party
    return processors. All Products must be returned to a Supplier return processing agent for
    processing and destruction.Transportation charges including prepaid freight and insurances will
    be the responsibility of the Participant unless returned due to defect, recall, damage or
    Supplier error.Supplier may request proof of purchase on all Product returned for credit or
    refund.
  </p>
  <p><strong>Returnable Product for Credit or Refund</strong></p>
  <p>A Product qualifies for credit or refund if it meets the following criteria:</p>
  <ul>
    <li>Product was purchased directly from Supplier under this Agreement</li>
    <li>Product was purchased from an authorized wholesaler</li>
    <li>Product is returned to a designated Supplier return processing agent (per above)</li>
    <li>
      Product is Supplier labeled Product and is returned undamaged in full, unopened, and sealed
      containers (unless returned due to defect, recall, damage or Supplier error)
    </li>
    <li>
      Product will expire in 6 months or less but does not exceed beyond 12 months of expiration
    </li>
    <li>Concealed damage claims made within 5 days of receipt</li>
    <li>
      Product is recalled. Recalled Product must be returned separately and in accordance with the
      Supplier recall notification.
    </li>
  </ul>
  <p><strong>Non-Returnable Product</strong></p>
  <ul>
    <li>Outside the acceptable expiration range</li>
    <li>Product label is missing or unreadable</li>
    <li>Product purchased at sacrifice, fire, or bankruptcy sales</li>
    <li>Sold on a promotion or on a non-returnable basis</li>
    <li>Overstock items not within the terms of this policy</li>
    <li>Product with invalid and/or missing batch/lot number</li>
    <li>Product that has been relabeled, repacked, or adulterated in any way</li>
    <li>Product previously dispensed to a patient</li>
    <li>
      Partial product except where mandated by state statute, i.e. Georgia, North Carolina, and
      Mississippi.
    </li>
    <li>
      Product destroyed by a 3rd party returns processing agent that is not a Supplier returns
      processing agent
    </li>
    <li>Concealed damage claims more than 5 days after receipt</li>
    <li>Product discontinued for more than 1 year</li>
    <li>
      Product damaged to insurable causes such as fire or natural disasters or if
      damaged/deteriorated due to improper handling or storage by the Participant
    </li>
    <li>Product purchased or distributed contrary to federal, state, or local laws</li>
    <li>
      Product sold to any City, County, State, and/or Federal entity for the purpose of stock piling
      directly by Supplier or through an authorized wholesaler
    </li>
  </ul>
  <p>
    <strong>Disclaimer:</strong> Supplier reserves the rights to make changes to this policy at its
    sole discretion and will notify changes of this policy in writing. This statement of policy
    shall supersede any previous version or statement(s) regarding Supplier’s return policy to
    Participants whether in writing, oral, or established through the course of dealing with
    Supplier.Policy Effective as of 8/1/2018
  </p>
</div>
