import { Injectable } from '@angular/core';
import { OrganizationStatusDto } from '../models/organization.model';
import { BaseFactory } from './base.factory';

@Injectable({
  providedIn: 'root',
})
export class OrganizationStatusFactory extends BaseFactory<OrganizationStatusDto> {
  override readonly entities = {
    [OrganizationStatusDto.ACTIVE]: {
      id: OrganizationStatusDto.ACTIVE,
      name: 'Active',
      icon: 'circle',
      iconColor: '#71CE62',
      canChangeTo: [OrganizationStatusDto.DEACTIVATED],
    },
    [OrganizationStatusDto.PENDING]: {
      id: OrganizationStatusDto.PENDING,
      name: 'In review',
      icon: 'circle',
      iconColor: '#255CA3',
      canChangeTo: [OrganizationStatusDto.ONBOARDING, OrganizationStatusDto.DEACTIVATED],
    },
    [OrganizationStatusDto.ONBOARDING]: {
      id: OrganizationStatusDto.ONBOARDING,
      name: 'Onboarding',
      icon: 'circle',
      iconColor: '#2F80ED',
      canChangeTo: [OrganizationStatusDto.DEACTIVATED],
    },
    [OrganizationStatusDto.ENROLLMENT]: {
      id: OrganizationStatusDto.ENROLLMENT,
      name: 'Enrollment',
      icon: 'circle',
      iconColor: '#2F80ED',
      canChangeTo: [OrganizationStatusDto.DEACTIVATED],
    },
    [OrganizationStatusDto.DEACTIVATED]: {
      id: OrganizationStatusDto.DEACTIVATED,
      name: 'Deactivated',
      icon: 'circle',
      iconColor: '#AAAAAA',
      canChangeTo: [
        OrganizationStatusDto.ACTIVE,
        OrganizationStatusDto.ONBOARDING,
        OrganizationStatusDto.ENROLLMENT,
      ],
    },
  };

  override getById(id: string | OrganizationStatusDto) {
    const item = { ...this.entities[id as OrganizationStatusDto] };
    if (!item) {
      throw new Error(`Unknown id ${id} in ${this.constructor.name}`);
    }
    return item;
  }

  getAllItemsAsArray() {
    return Object.values(this.entities);
  }
}
