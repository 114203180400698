import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  FirstLoginResModel,
  ForgotPassConfirmReqDto,
  LoginReqModel,
  LoginResDto,
  MfaLoginResDto,
  RegisterConfirmReqDto,
  RegistrationBodyDto,
  UserDto,
} from 'src/app/core/models/auth.model';
import { HttpErrorResponse } from '@angular/common/http';
import { RequestCallReqDto } from '../../models/common-components.model';
import { AddUserReqDto } from '../../models/staff.model';

export const loginActions = createActionGroup({
  source: 'Auth',
  events: {
    Login: props<{ body: LoginReqModel }>(),
    'Login Success': props<{ res: MfaLoginResDto }>(),
    'Login Failure': props<{ error: HttpErrorResponse }>(),
    'Login Failure Close': emptyProps(),
    'Login First Success': props<{ res: FirstLoginResModel }>(),
    startTrackUserActivity: emptyProps(),
    stopTrackUserActivity: emptyProps(),
  },
});

export const registerActions = createActionGroup({
  source: 'Auth',
  events: {
    Register: props<{ body: RegistrationBodyDto }>(),
    'Register Success': emptyProps(),
    'Register Fail': emptyProps(),
    'Register Confirm': props<{ body: RegisterConfirmReqDto }>(),
    'Register Confirm Success': props<{ res: MfaLoginResDto }>(),
    'Register Confirm Failure': props<{ error: HttpErrorResponse }>(),
    'Register Cancel': props<{ pristine: boolean }>(),
  },
});

export const refreshTokenActions = createActionGroup({
  source: 'Auth',
  events: {
    'Refresh Token Success': props<{ res: LoginResDto }>(),
    'Refresh Token Failure': props<{ error: HttpErrorResponse }>(),
    'Auto Refresh Token Success': props<{ res: LoginResDto }>(),
  },
});

export const startLogoutAction = createAction('Start Auth Logout');
export const logoutAction = createAction('Auth Logout');

export const forgotPasswordActions = createActionGroup({
  source: 'Auth',
  events: {
    'Forgot Password': props<{ email: string }>(),
    'Forgot Password Success': props<{ email: string }>(),
    'Forgot Password Failure': props<{ error: HttpErrorResponse }>(),
    'Forgot Password Confirm': props<{ body: ForgotPassConfirmReqDto }>(),
    'Forgot Password Confirm Success': emptyProps(),
    'Forgot Password Confirm Failure': props<{ error: HttpErrorResponse }>(),
  },
});

export const loggedInSuccessAction = createAction('Auth Logged In Success');

export const badgesActions = createActionGroup({
  source: 'Auth',
  events: {
    updateAllBadges: emptyProps(),
    getOrdersNotificationsCount: emptyProps(),
    setOrdersNotificationsCount: props<{ count: number | null }>(),
    getCiplaAdminOrdersNotificationsCount: emptyProps(),
    setCiplaAdminOrdersNotificationsCount: props<{ count: number | null }>(),
    getAppointmentsNotificationsCount: emptyProps(),
    setAppointmentsNotificationsCount: props<{ count: number | null }>(),
    showDeliveryConfirmationRequired: emptyProps(),
  },
});

export const resendInviteActions = createActionGroup({
  source: 'Auth',
  events: {
    'Resend Invite': props<{ email: string }>(),
    'Resend Invite Success': emptyProps(),
    'Resend Invite Failure': props<{ error: HttpErrorResponse }>(),
    'Cancel Invite': props<{ email: string }>(),
    'Cancel Invite Success': emptyProps(),
    'Cancel Invite Failure': props<{ error: HttpErrorResponse }>(),
  },
});

export const mfaActions = createActionGroup({
  source: 'Auth MFA',
  events: {
    'Resend Code': emptyProps(),
    'Verify Code': props<{ code: string }>(),
    'Verify Code Success': props<{ res: LoginResDto }>(),
    'Verify Code Failure': props<{ error: HttpErrorResponse }>(),
  },
});

export const requestCallAction = createAction(
  'Auth Request Call',
  props<{ body: RequestCallReqDto }>(),
);

export const adminsListActions = createActionGroup({
  source: 'Auth Admins List',
  events: {
    'Get Admins List': emptyProps(),
    'Get Admins List Success': props<{ admins: UserDto[] }>(),
    'Add Cipla Admin': props<{ body: AddUserReqDto }>(),
    'Delete Cipla Admin': props<{ id: number }>(),
  },
});
