<div class="app-datepicker">
  <mat-label *ngIf="label" class="app-datepicker__label"
    >{{ label }}:
    <ng-container *ngIf="isRequired">*</ng-container>
  </mat-label>
  <mat-form-field appearance="outline">
    <input
      matInput
      [matDatepicker]="picker"
      [formControl]="control"
      [matDatepickerFilter]="filter"
      [readonly]="readonly"
      [placeholder]="placeholder"
    />
    <mat-hint>{{ placeholder }}</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <ng-container *ngIf="control?.invalid && control?.touched">
    @if (this.control.hasError('required')) {
      <mat-error class="error" automation-id="required-field-error"
        >Please fill in the required field(s)</mat-error
      >
    }
  </ng-container>
</div>
