import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AppButtonSize, AppButtonType } from 'src/app/core/models/common-components.model';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { combineLatestWith, filter, map, tap } from 'rxjs';
import {
  EnrollmentShortDto,
  EnrollmentStatusDto,
  OrganizationDto,
  OrganizationStatusDto,
  ShippingAddressDto,
} from '../../../../core/models/organization.model';
import { fromOrganizations, selectOrganizationEnrollmentShort } from '../../store/selectors';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../../shared/shared.module';
import { OrganizationActions } from '../../store/actions';
import { convertFullAddress } from '../../../../core/utils/convertFullAddress';
import { LetDirective } from '../../../../core/utils/let-directive';
import { formatStringToPhone } from '../../../../core/utils/formatStringToPhone';
import { HasPermissionDirective } from '../../../../core/directives/has-permission.directive';
import { PERMISSIONS } from 'src/app/core/consts/roles-and-permissions';
import { OrganizationStatusFactory } from '../../../../core/factories/organization-status.factory';
import { PermissionsService } from '../../../../core/services/permissions.service';
import { selectUser } from '../../../../core/store/selectors/selectors';
import { isPresent } from '../../../../core/utils/isPresent';

@Component({
  selector: 'app-organization-summary',
  templateUrl: './organization-summary.component.html',
  styleUrl: './organization-summary.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, SharedModule, LetDirective, HasPermissionDirective],
})
export class OrganizationSummaryComponent {
  public PERMISSIONS = PERMISSIONS;

  items = this.organizationStatusFactory.getAllItemsAsArray();

  users$ = this.store$.select(fromOrganizations.selectOrganizationUsers);

  user$ = this.store$.select(selectUser).pipe(
    filter(isPresent),
    tap((user) => {
      this.permissionsService.hasPermission(
        PERMISSIONS.organizationAccount.canChangeAdmin,
        user.role,
      )
        ? this.form.enable()
        : this.form.disable();
    }),
  );

  loading$ = this.store$.select(fromOrganizations.selectOrganizationLoading);

  public form = this.fb.group({
    status: new FormControl<string>(this.items[0].id, {
      validators: Validators.required,
      nonNullable: true,
    }),

    adminId: new FormControl<number | undefined>(undefined, {
      nonNullable: true,
    }),
  });

  searchResult$ = this.store$.select(fromOrganizations.selectOrganization).pipe(
    filter((value) => Boolean(value)),
    combineLatestWith(
      this.store$.select(fromOrganizations.selectOrganizationShippingAddress),
      this.store$.select(selectOrganizationEnrollmentShort),
    ),
    map(([value, addresses, enrollmentShort]) => {
      if (value) {
        this.setItems(value.status, addresses, enrollmentShort);
        this.form.controls.status.setValue(value.status, { emitEvent: false });
        this.form.controls.adminId.setValue(value.adminId, { emitEvent: false });
      }
      return value;
    }),
  );

  organizationDataMockValue(searchResult: OrganizationDto) {
    return {
      status: searchResult.status,
      name: searchResult.name,
      address: convertFullAddress({ ...searchResult }),
      phone: formatStringToPhone(searchResult.phoneNumber),
    };
  }

  public cancel(): void {
    this.store$.dispatch(
      OrganizationActions.createOrganizationAccountCancel({ pristine: this.form.pristine }),
    );
  }

  public save(searchResult: OrganizationDto): void {
    this.form.markAsPristine();
    this.store$.dispatch(
      OrganizationActions.askUpdateOrganizationAccount({
        id: searchResult.id,
        status: this.form.controls.status.value,
        orgAdminId: this.form.controls.adminId.value,
      }),
    );
  }

  constructor(
    private fb: FormBuilder,
    private readonly store$: Store,
    readonly organizationStatusFactory: OrganizationStatusFactory,
    private permissionsService: PermissionsService,
  ) {}

  protected readonly btnTypes = AppButtonType;
  protected readonly btnSizes = AppButtonSize;

  getStatusName(status: string) {
    return this.organizationStatusFactory.getById(status).name;
  }

  setItems(
    status: OrganizationStatusDto,
    addresses: ShippingAddressDto[] | null,
    enrollment: EnrollmentShortDto | null,
  ) {
    this.items = [
      this.organizationStatusFactory.getById(status),
      ...this.organizationStatusFactory
        .getById(status)
        .canChangeTo?.map((item) => this.organizationStatusFactory.getById(item))
        .filter((item) => {
          if (
            status === OrganizationStatusDto.DEACTIVATED &&
            enrollment &&
            enrollment.status !== EnrollmentStatusDto.APPROVED
          ) {
            return item.id === OrganizationStatusDto.ENROLLMENT;
          } else if (
            status === OrganizationStatusDto.DEACTIVATED &&
            addresses?.length &&
            enrollment
          ) {
            return item.id === OrganizationStatusDto.ACTIVE;
          } else if (status === OrganizationStatusDto.DEACTIVATED && !addresses?.length) {
            return item.id === OrganizationStatusDto.ONBOARDING;
          }
          return item;
        }),
    ];
  }
}
