import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from 'src/app/app.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { Effects } from 'src/app/core/store/effects/effects';
import { reducer } from 'src/app/core/store/reducers/reducer';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { environment } from 'src/environment/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NotificationsModule } from './modules/notifications/notifications.module';
import { DialogsModule } from './modules/dialogs/dialogs.module';
import { AuthApiService } from 'src/app/modules/auth/services/auth-api.service';
import { firstValueFrom } from 'rxjs';
import { JwtInterceptor } from 'src/app/core/interceptors/jwt.interceptor';
import { HeaderComponent } from './core/components/header/header.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { OrganizationsModule } from './modules/organizations/organizations.module';
import { LeftSideMenuComponent } from './core/components/left-side-menu/left-side-menu.component';
import { TopMenuComponent } from './core/components/top-menu/top-menu.component';
import { HasPermissionDirective } from 'src/app/core/directives/has-permission.directive';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatBadgeModule } from '@angular/material/badge';
import { FooterComponent } from './core/components/footer/footer.component';
import { SpinnerInterceptor } from 'src/app/core/interceptors/spinner.interceptor';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LetDirective } from 'src/app/core/utils/let-directive';
import { BreadcrumbsComponent } from './core/components/breadcrumbs/breadcrumbs.component';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { RoleToLabelPipe } from 'src/app/core/pipes/role-to-label.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ForbiddenInterceptor } from 'src/app/core/interceptors/forbidden.interceptor';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';

export function initializeApp(authApiService: AuthApiService): () => Promise<any> {
  return async (): Promise<any> => {
    try {
      return firstValueFrom(authApiService.refresh$());
    } catch (error) {
      console.error('Initialization error:', error);
    }
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LeftSideMenuComponent,
    TopMenuComponent,
    FooterComponent,
    BreadcrumbsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule,
    NotificationsModule,
    DialogsModule,
    StoreModule.forRoot({ rootState: reducer, router: routerReducer }),
    EffectsModule.forRoot([Effects]),
    HttpClientModule,
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    MatMenuModule,
    MatIconModule,
    OrganizationsModule,
    HasPermissionDirective,
    MatDatepickerModule,
    MatNativeDateModule,
    MatBadgeModule,
    MatProgressSpinnerModule,
    LetDirective,
    RoleToLabelPipe,
    MatTooltipModule,
    StoreRouterConnectingModule.forRoot(),
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AuthApiService], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ForbiddenInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
