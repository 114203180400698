import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { PermissionGuard } from 'src/app/core/guards/permission.guard';
import { PERMISSIONS } from 'src/app/core/consts/roles-and-permissions';
import { LoggedInGuard } from 'src/app/core/guards/logged-in.guard';
import { OrganizationStatusGuard } from 'src/app/core/guards/organization-status.guard';
import { AppRoutes } from 'src/app/core/consts/navigation.const';
import { PatientConsentFormComponent } from 'src/app/core/components/patient-consent-form/patient-consent-form.component';
import { ReturnPolicyComponent } from './modules/static-pages/components/return-policy/return-policy.component';
import { ConsumerHealthPrivacyPolicyComponent } from './modules/static-pages/components/consumer-health-privacy-policy/consumer-health-privacy-policy.component';
import { StandardPurchaseTermsComponent } from './modules/static-pages/components/standard-purchase-terms/standard-purchase-terms.component';
import { TermsOfUseComponent } from './modules/static-pages/components/terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './modules/static-pages/components/privacy-policy/privacy-policy.component';
import { AdminsListComponent } from './core/components/admins-list/admins-list.component';

export enum routeParams {
  organizationId = 'organizationId',
  userId = 'userId',
  patientId = 'patientId',
}

const routes: Routes = [
  {
    path: AppRoutes.LOGIN,
    loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
    data: { breadcrumb: { skip: true } },
    canActivate: [LoggedInGuard],
  },
  {
    path: AppRoutes.ORGANIZATIONS,
    loadChildren: () =>
      import('./modules/organizations/organizations.module').then((m) => m.OrganizationsModule),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: { label: 'Organizations', icon: 'corporate_fare' },
    },
  },
  {
    path: `${AppRoutes.ORDERS}/:${routeParams.organizationId}`,
    loadChildren: () =>
      import('./modules/organization-orders/organization-orders.module').then(
        (m) => m.OrganizationOrdersModule,
      ),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: { label: 'Orders', icon: 'shopping_basket' },
    },
  },
  {
    path: AppRoutes.ORGANIZATIONS_ORDERS,
    loadChildren: () => import('./modules/orders/orders.module').then((m) => m.OrdersModule),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: { label: 'Orders', icon: 'shopping_basket' },
    },
  },
  {
    path: AppRoutes.ORGANIZATIONS_INVENTORY,
    loadChildren: () =>
      import('./modules/organizations-inventory/organizations-inventory.module').then(
        (m) => m.OrganizationsInventoryModule,
      ),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: { label: 'Inventory', icon: 'inventory' },
    },
  },
  {
    path: AppRoutes.INVENTORY,
    loadChildren: () =>
      import('./modules/inventory/inventory.module').then((m) => m.InventoryModule),
    canActivate: [AuthGuard, OrganizationStatusGuard],
    data: {
      breadcrumb: { label: 'Inventory', icon: 'inventory' },
    },
  },
  {
    path: `${AppRoutes.STAFF}/:${routeParams.organizationId}`,
    loadChildren: () => import('./modules/staff/staff.module').then((m) => m.StaffModule),
    canActivate: [AuthGuard, OrganizationStatusGuard],
    data: {
      breadcrumb: { label: 'Staff', icon: 'people' },
    },
  },
  {
    path: AppRoutes.SUPPORT,
    loadChildren: () => import('./modules/support/support.module').then((m) => m.SupportModule),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: { label: 'Support', icon: 'help_outline' },
    },
  },
  {
    path: `${AppRoutes.REPORTS}/:${routeParams.organizationId}`,
    loadChildren: () => import('./modules/reports/reports.module').then((m) => m.ReportsModule),
    canActivate: [AuthGuard, PermissionGuard, OrganizationStatusGuard],
    data: {
      breadcrumb: { label: 'Reports', icon: 'report' },
      requiredPermission: PERMISSIONS.organizationReports.canView,
    },
  },
  {
    path: `${AppRoutes.PATIENTS}/:${routeParams.organizationId}`,
    loadChildren: () => import('./modules/patients/patients.module').then((m) => m.PatientsModule),
    canActivate: [AuthGuard, OrganizationStatusGuard, PermissionGuard],
    data: {
      breadcrumb: { label: 'Patients', icon: 'personal_injury' },
      requiredPermission: PERMISSIONS.patients.canView,
    },
  },
  {
    path: AppRoutes.CONSENT_FORM,
    component: PatientConsentFormComponent,
    data: { breadcrumb: { skip: true } },
  },
  {
    path: AppRoutes.RETURN_GOODS_POLICY,
    component: ReturnPolicyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: AppRoutes.STANDARD_PURCHASE_TERMS,
    component: StandardPurchaseTermsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: AppRoutes.TERMS_OF_USE,
    component: TermsOfUseComponent,
  },
  {
    path: AppRoutes.CONSUMER_HEALTH_PRIVACY_POLICY,
    component: ConsumerHealthPrivacyPolicyComponent,
  },
  {
    path: AppRoutes.PRIVACY_POLICY,
    component: PrivacyPolicyComponent,
  },
  {
    path: AppRoutes.ADMINS_LIST,
    component: AdminsListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      breadcrumb: { label: 'Cipla Admins List', icon: 'admin_panel_settings' },
      requiredPermission: PERMISSIONS.adminsList,
    },
  },
  { path: '', redirectTo: AppRoutes.LOGIN, pathMatch: 'full' },
  { path: '**', redirectTo: AppRoutes.LOGIN },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
