import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AppAlertType } from 'src/app/core/models/common-components.model';

@Component({
  selector: 'app-alert',
  templateUrl: './app-alert.component.html',
  styleUrl: './app-alert.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppAlertComponent {
  @Input() type: AppAlertType = AppAlertType.Info;
  @Input() showIcon?: boolean = true;
}
