<div class="app-top-menu" automation-id="top-menu-page">
  @for (item of menuItems; track item; let i = $index) {
    @if (isItemShown(item)) {
      <div
        *let="isItemDisabled(item) as disabled"
        class="app-top-menu__item"
        automation-id="top-menu-item"
        [ngClass]="{ _active: i == selectedItemIndex }"
        [class.disabled]="disabled"
        (click)="selectItem(i, disabled)"
        [matTooltip]="disabled ? 'Organization account is not Active' : ''"
      >
        <div class="app-top-menu__item-icon-container">
          <mat-icon class="app-top-menu__item-icon material-icons-outlined">{{
            item.icon
          }}</mat-icon>
        </div>
        @if (!!item.counter) {
          <span
            class="app-top-menu__item-label"
            [matBadge]="item.counter"
            matBadgePosition="after"
            matBadgeColor="warn"
            >{{ item.label }}</span
          >
        } @else {
          <span class="app-top-menu__item-label">{{ item.label }}</span>
        }
      </div>
    }
  }
</div>
