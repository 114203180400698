import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { AppButtonSize, AppButtonType } from 'src/app/core/models/common-components.model';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store/models/state';
import { forgotPasswordActions } from 'src/app/core/store/actions/actions';
import * as CustomValidators from 'src/app/core/utils/validators';
import { Title } from '@angular/platform-browser';
import { FormUtilsService } from 'src/app/core/services/form-utils.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrl: './reset.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetComponent implements OnInit, OnDestroy {
  public btnTypes = AppButtonType;
  public btnSizes = AppButtonSize;
  public form = this.fb.group({
    email: [null, [Validators.required, CustomValidators.emailFormatValidator()]],
  });
  public lastProcessedValues: { [key: string]: string } = {};

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private titleService: Title,
    private formUtilsService: FormUtilsService,
  ) {}

  ngOnInit() {
    this.titleService.setTitle('LeuPRO Logix | Reset Password');
    this.checkForSpaces();
  }

  public submit(): void {
    this.store.dispatch(forgotPasswordActions.forgotPassword({ email: this.form.value.email! }));
  }

  private checkForSpaces(): void {
    const emailControl = this.form.get('email') as AbstractControl;
    this.formUtilsService.setupControlSpaceCheck(
      emailControl,
      'email',
      this.lastProcessedValues,
      this,
    );
  }

  ngOnDestroy() {}
}
