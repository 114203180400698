import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { CommonModule } from '@angular/common';
import { allStates } from '../../../../../core/consts/states';
import { faxMask, phoneMask, zipCodeMask } from 'src/app/core/consts/masks';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { selectOrganization } from 'src/app/modules/organizations/store/selectors';
import { filter } from 'rxjs';
import { isPresent } from 'src/app/core/utils/isPresent';
import { OrganizationDto } from 'src/app/core/models/organization.model';
import { LetDirective } from '../../../../../core/utils/let-directive';

@UntilDestroy()
@Component({
  selector: 'app-enrollment-bill-to',
  templateUrl: './enrollment-bill-to.component.html',
  styleUrl: './enrollment-bill-to.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule, FormsModule, ReactiveFormsModule, CommonModule, LetDirective],
})
export class EnrollmentBillToComponent implements OnInit {
  @Input() form!: FormGroup | undefined;
  protected readonly states = allStates;
  protected readonly zipCodeMask = zipCodeMask;
  protected readonly phoneMask = phoneMask;
  protected readonly faxMask = faxMask;

  private organization: OrganizationDto | undefined;

  constructor(private store: Store) {}

  resetSameAsOrganization() {
    if (this.form?.get('sameAsOrganization')?.value) {
      this.form?.get('sameAsOrganization')?.setValue(false, { emitEvent: false });
    }
  }

  ngOnInit() {
    this.store
      .select(selectOrganization)
      .pipe(filter(isPresent), untilDestroyed(this))
      .subscribe((organization) => {
        this.organization = organization;
      });
    this.form
      ?.get('sameAsOrganization')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((value) => {
        if (value) {
          const fromOrganizationData = {
            customerName: this.organization?.name,
            street: this.organization?.street,
            city: this.organization?.city,
            state: this.organization?.state,
            zip: this.organization?.zip,
            phoneNumber: this.organization?.phoneNumber,
          };
          this.form?.patchValue(fromOrganizationData, { emitEvent: false });
        } else {
          this.form?.reset(null, { emitEvent: false });
        }
      });

    const orgFields = ['customerName', 'street', 'city', 'state', 'zip', 'phoneNumber'];

    orgFields.forEach(
      (field) =>
        this.form?.get(field)?.valueChanges.subscribe(() => this.resetSameAsOrganization()),
    );
  }
}
