<div class="app-personal-details" automation-id="app-personal-details">
  <div class="app-personal-details__form">
    <app-key-value-block
      *ngIf="searchResult$ | async as searchResult"
      automation-id="key-value-block"
      class="app-personal-details__form-single-row"
      title="Organization Details"
      [source]="organizationDataMockValue(searchResult)"
      [labels]="labelsDataMock"
    ></app-key-value-block>
  </div>

  <app-checkbox
    class="app-personal-details__mode-checkbox"
    automation-id="mode-checkbox"
    [formControl]="accountWithoutPersonMode"
  >
    Create Organization Account without Contact Person
  </app-checkbox>

  @if (!accountWithoutPersonMode.value) {
    <div class="app-personal-details__form" [formGroup]="form" automation-id="contact-person-form">
      <div automation-id="title" class="mat-subtitle-2">Contact Person</div>
      <div class="app-personal-details__form-row">
        <app-input
          automation-id="first-name-field"
          class="app-personal-details__form-control"
          label="First Name"
          formControlName="firstName"
        ></app-input>
        <app-input
          automation-id="last-name-field"
          class="app-personal-details__form-control"
          label="Last Name"
          formControlName="lastName"
        ></app-input>
      </div>
      <div class="app-personal-details__form-row">
        <app-input
          automation-id="email-field"
          class="app-personal-details__form-control"
          label="Email"
          formControlName="email"
          [maxLength]="128"
        ></app-input>
        <app-input
          automation-id="phone-field"
          class="app-personal-details__form-control"
          label="Mobile Phone Number"
          formControlName="phoneNumber"
          [mask]="phoneMask"
          [prefix]="prefix"
        ></app-input>
      </div>
    </div>
  }
</div>
