import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AppLabelColors } from 'src/app/core/models/common-components.model';

@Component({
  selector: 'app-label',
  templateUrl: './app-label.component.html',
  styleUrl: './app-label.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppLabelComponent {
  @Input() text: string | undefined;
  @Input() color: string | undefined;
  @Input() type: AppLabelColors = AppLabelColors.GREY;
}
