import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { catchError, debounceTime, filter, map, of, switchMap, throttleTime } from 'rxjs';
import { DialogsActions } from '../../dialogs/store/actions';
import { Router } from '@angular/router';
import { OrganizationActions } from './actions';
import { ShippingAddressesApiService } from '../../auth/services/shipping-addresses-api.service';
import { Store } from '@ngrx/store';
import { AppRoutes } from 'src/app/core/consts/navigation.const';
import { waitWithLatestFrom } from '../../../core/utils/wait-with-latest-from';
import { selectRouteParam } from '../../../core/store/selectors/selectors';
import { routeParams } from '../../../app-routing.module';
import { isPresent } from '../../../core/utils/isPresent';

@Injectable()
export class ShippingAddressEffects {
  getOrganizationAddresses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OrganizationActions.getOrganization,
        OrganizationActions.defaultShippingAddressesSuccess,
        OrganizationActions.approveShippingAddressesSuccess,
        OrganizationActions.declineShippingAddressesSuccess,
        OrganizationActions.deleteShippingAddressesSuccess,
        OrganizationActions.createNewShippingAddressesSuccess,
        OrganizationActions.deactivateShippingAddressesSuccess,
        OrganizationActions.activateShippingAddressesSuccess,
      ),
      map(() => {
        return OrganizationActions.loadOrganizationAddresses();
      }),
    ),
  );

  loadOrganizationAddresses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.loadOrganizationAddresses),
      waitWithLatestFrom(
        this.store$.select(selectRouteParam(routeParams.organizationId)).pipe(filter(isPresent)),
      ),
      debounceTime(1000),
      switchMap(([, id]) => {
        return this.shippingAddressesApiService.getOrganizationAddresses$(id).pipe(
          map((response) =>
            OrganizationActions.getOrganizationShippingAddressesSuccess({ response }),
          ),
          catchError((response) => {
            return of(
              OrganizationActions.getOrganizationShippingAddressesFail(),
              DialogsActions.showDialog({
                data: {
                  actions: ['Close'],
                  content: '',
                  title: response.error?.message || 'Organizations API not available',
                },
              }),
            );
          }),
        );
      }),
    ),
  );

  createOrganizationAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.createNewShippingAddresses),
      switchMap(({ id, body }) => {
        return this.shippingAddressesApiService.createOrganizationAddress$(id, body).pipe(
          map((response) => OrganizationActions.createNewShippingAddressesSuccess({ id })),
          catchError((response) => {
            return of(
              OrganizationActions.createNewShippingAddressesFail(),
              DialogsActions.showDialog({
                data: {
                  actions: ['Close'],
                  content: '',
                  title: response.error?.message || 'Organizations API not available',
                },
              }),
            );
          }),
        );
      }),
    ),
  );

  createNewShippingAddressesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.createNewShippingAddressesSuccess),
      map(() => {
        return DialogsActions.showDialog({
          data: {
            actions: ['Close'],
            content: '',
            title:
              'You have successfully submitted a request to add a new Ship-To. Cipla USA Generics will promptly review and process your request',
          },
        });
      }),
    ),
  );

  approveShippingAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.approveShippingAddresses),
      throttleTime(500),
      switchMap(({ id, addressId }) => {
        return this.shippingAddressesApiService.approveOrganizationAddress$(id, addressId).pipe(
          map((response) => OrganizationActions.approveShippingAddressesSuccess()),
          catchError((response) => {
            return of(
              OrganizationActions.approveShippingAddressesFail(),
              DialogsActions.showDialog({
                data: {
                  actions: ['Close'],
                  content: '',
                  title: response.error?.message || 'Organizations API not available',
                },
              }),
            );
          }),
        );
      }),
    ),
  );

  defaultShippingAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.defaultShippingAddresses),
      switchMap(({ id, addressId }) => {
        return this.shippingAddressesApiService.defaultOrganizationAddress$(id, addressId).pipe(
          map((response) => OrganizationActions.defaultShippingAddressesSuccess({ id })),
          catchError((response) => {
            return of(
              OrganizationActions.defaultShippingAddressesFail(),
              DialogsActions.showDialog({
                data: {
                  actions: ['Close'],
                  content: '',
                  title: response.error?.message || 'Organizations API not available',
                },
              }),
            );
          }),
        );
      }),
    ),
  );

  askDeleteShippingAddresses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.askDeleteShippingAddresses),
      map(({ id, addressId }) => {
        return DialogsActions.showDialog({
          data: {
            actions: ['Close', 'Yes, Delete'],
            content: 'You are about to remove this Ship-To from your address book',
            title: 'Are you sure you want to continue?',
          },

          callback: (action) => {
            if (action === 'Yes, Delete') {
              this.store$.dispatch(OrganizationActions.deleteShippingAddresses({ id, addressId }));
            }
          },
        });
      }),
    ),
  );

  deleteShippingAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.deleteShippingAddresses),
      switchMap(({ id, addressId }) => {
        return this.shippingAddressesApiService.deleteOrganizationAddress$(id, addressId).pipe(
          map((response) => OrganizationActions.deleteShippingAddressesSuccess({ id })),
          catchError((response) => {
            return of(
              OrganizationActions.deleteShippingAddressesFail(),
              DialogsActions.showDialog({
                data: {
                  actions: ['Close'],
                  content: '',
                  title: response.error?.message || 'Organizations API not available',
                },
              }),
            );
          }),
        );
      }),
    ),
  );

  askDeclineShippingAddresses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.askDeclineShippingAddresses),
      map(({ id, addressId }) => {
        return DialogsActions.showDialog({
          data: {
            actions: ['Close', 'Yes, Decline'],
            content:
              'The declined Ship-To will be removed from the address book. Are you sure you want to continue?',
            title: 'You are about to decline the addition/inclusion of this Ship-To',
          },
          callback: (action) => {
            if (action === 'Yes, Decline') {
              this.store$.dispatch(OrganizationActions.declineShippingAddresses({ id, addressId }));
            }
          },
        });
      }),
    ),
  );

  declineShippingAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.declineShippingAddresses),
      switchMap(({ id, addressId }) => {
        return this.shippingAddressesApiService.declineOrganizationAddress$(id, addressId).pipe(
          map((response) => OrganizationActions.declineShippingAddressesSuccess({ id })),
          catchError((response) => {
            return of(
              OrganizationActions.declineShippingAddressesFail(),
              DialogsActions.showDialog({
                data: {
                  actions: ['Close'],
                  content: '',
                  title: response.error?.message || 'Organizations API not available',
                },
              }),
            );
          }),
        );
      }),
    ),
  );

  declineShippingAddressesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.declineShippingAddressesSuccess),
      switchMap(({ id }) => {
        this.router.navigate([`${AppRoutes.ORGANIZATIONS}/${id}`]);

        return of(
          DialogsActions.showDialog({
            data: {
              actions: ['Close'],
              content: '',
              title: 'Shipping address has been declined',
            },
          }),
        );
      }),
    ),
  );

  askDeactivateShippingAddresses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.askDeactivateShippingAddresses),
      map(({ id, addressId }) => {
        return DialogsActions.showDialog({
          data: {
            actions: ['Close', 'Yes, Deactivate'],
            content:
              'After deactivation, placing orders for this destination will no longer be possible.',
            title: 'Are you sure you want to continue?',
          },

          callback: (action) => {
            if (action === 'Yes, Deactivate') {
              this.store$.dispatch(
                OrganizationActions.deactivateShippingAddresses({ id, addressId }),
              );
            }
          },
        });
      }),
    ),
  );

  deactivateShippingAddresses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.deactivateShippingAddresses),
      switchMap(({ id, addressId }) => {
        return this.shippingAddressesApiService.deactivateOrganizationAddress$(id, addressId).pipe(
          map((response) => OrganizationActions.deactivateShippingAddressesSuccess({ id })),
          catchError((response) => {
            return of(
              OrganizationActions.deactivateShippingAddressesFail(),
              DialogsActions.showDialog({
                data: {
                  actions: ['Close'],
                  content: '',
                  title: response.error?.message || 'Organizations API not available',
                },
              }),
            );
          }),
        );
      }),
    ),
  );

  deactivateShippingAddressesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.deactivateShippingAddressesSuccess),
      switchMap(({ id }) => {
        this.router.navigate([`${AppRoutes.ORGANIZATIONS}/${id}`]);

        return of(
          DialogsActions.showDialog({
            data: {
              actions: ['Close'],
              content: '',
              title: 'Shipping address has been deactivated',
            },
          }),
        );
      }),
    ),
  );

  askActivateShippingAddresses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.askActivateShippingAddresses),
      map(({ id, addressId }) => {
        return DialogsActions.showDialog({
          data: {
            actions: ['Close', 'Ok'],
            content:
              'Once activated, it will be available as a valid destination for placing orders.',
            title: 'You are about to activate the Ship-To address.',
          },

          callback: (action) => {
            if (action === 'Ok') {
              this.store$.dispatch(
                OrganizationActions.activateShippingAddresses({ id, addressId }),
              );
            }
          },
        });
      }),
    ),
  );

  activateShippingAddresses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.activateShippingAddresses),
      switchMap(({ id, addressId }) => {
        return this.shippingAddressesApiService.activateOrganizationAddress$(id, addressId).pipe(
          map((response) => OrganizationActions.activateShippingAddressesSuccess({ id })),
          catchError((response) => {
            return of(
              OrganizationActions.activateShippingAddressesFail(),
              DialogsActions.showDialog({
                data: {
                  actions: ['Close'],
                  content: '',
                  title: response.error?.message || 'Organizations API not available',
                },
              }),
            );
          }),
        );
      }),
    ),
  );

  activateShippingAddressesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.activateShippingAddressesSuccess),
      switchMap(({ id }) => {
        this.router.navigate([`${AppRoutes.ORGANIZATIONS}/${id}`]);

        return of(
          DialogsActions.showDialog({
            data: {
              actions: ['Close'],
              content: '',
              title: 'Shipping address has been activated',
            },
          }),
        );
      }),
    ),
  );
  constructor(
    private actions$: Actions,
    private shippingAddressesApiService: ShippingAddressesApiService,
    private readonly router: Router,
    private readonly store$: Store,
  ) {}
}
