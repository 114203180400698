import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OrganizationsComponent } from 'src/app/modules/organizations/components/organizations/organizations.component';
import { CreateOrganizationComponent } from './components/create-organization/create-organization.component';
import { OrganizationAccountComponent } from './components/organization-account/organization-account.component';
import { PermissionGuard } from 'src/app/core/guards/permission.guard';
import { PERMISSIONS } from 'src/app/core/consts/roles-and-permissions';
import { EnrollmentComponent } from 'src/app/modules/organizations/components/enrollment/enrollment.component';
import { CanDeactivateEnrollmentGuard } from 'src/app/modules/organizations/guards/deactivate-enrollment.guard';
import { OrganizationsRoutes } from 'src/app/core/consts/navigation.const';
import { routeParams } from '../../app-routing.module';

const routes: Routes = [
  {
    path: '',
    component: OrganizationsComponent,
    canActivate: [PermissionGuard],
    data: {
      requiredPermission: PERMISSIONS.organizations.canView,
    },
  },
  {
    path: OrganizationsRoutes.CREATE,
    component: CreateOrganizationComponent,
    data: {
      breadcrumb: { label: 'Create New Organization Account' },
    },
  },
  {
    path: `:${routeParams.organizationId}`,
    component: OrganizationAccountComponent,
    canActivate: [PermissionGuard],
    data: {
      breadcrumb: { label: 'Organization Account', icon: 'home' },
      requiredPermission: PERMISSIONS.organizationAccount.canView,
    },
  },
  {
    path: `:${routeParams.organizationId}/${OrganizationsRoutes.ENROLLMENT}`,
    component: EnrollmentComponent,
    canDeactivate: [CanDeactivateEnrollmentGuard],
    canActivate: [PermissionGuard],
    data: {
      breadcrumb: { label: 'Enrollment' },
      requiredPermission: PERMISSIONS.enrollment.canView,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OrganizationsRoutingModule {}
