<div class="app-footer">
  <div class="app-footer__block">
    <div class="app-footer__block-label">Cipla USA, Inc</div>
    <div class="app-footer__block-info">
      10 Independence Boulevard Suite 300 | Warren, NJ 07059 ciplausa.com
    </div>
  </div>
  <div class="app-footer__block">
    <div class="app-footer__block-label">Contact us at:</div>
    <div class="app-footer__block-info">{{ customerData.phone }}</div>
    <div class="app-footer__block-info">
      <a [href]="mailToLink">{{ customerData.email }}</a>
    </div>
  </div>
  <div class="app-footer__block">
    <div class="app-footer__block-info">
      <a (click)="navigateToTermsOfUsePage()">Terms of Use</a>
    </div>
    <div class="app-footer__block-info">
      <a (click)="navigateToPrivacyPage()">Privacy Notice</a>
    </div>
    <div class="app-footer__block-info">
      <a (click)="navigateToReturnPolicy()">Return Goods Policy</a>
    </div>
  </div>
  <div class="app-footer__block">
    <div class="app-footer__block-info">
      <a (click)="getSupport()" class="app-footer__block-get-support">
        <mat-icon class="material-icons-outlined">help_outline</mat-icon>
        Get Support
      </a>
    </div>
  </div>
</div>
