@if (form) {
  <div class="app-enrollment-ship-to" [formGroup]="form">
    <div class="app-enrollment-ship-to__alert">
      <app-alert [type]="alertTypes.Info">
        Cipla will ship out all product via UPS Ground for Ambient and UPS Next Day Air for Cold
        Chain. If you would like to ship via FedEx, please indicate your selection in the form and
        provide your account number
      </app-alert>
    </div>
    <div class="app-enrollment-ship-to__row">
      <div class="app-enrollment-ship-to__control">
        <app-dropdown
          label="Ship via"
          formControlName="shippingProvider"
          optionValue="id"
          [values]="shipViaValue"
        ></app-dropdown>
      </div>
      <div class="app-enrollment-ship-to__control">
        <app-input
          label="Account Number"
          formControlName="accountNumber"
          [mask]="getAccountNumberMask()"
        ></app-input>
      </div>
    </div>
    <div class="app-enrollment-ship-to__control">
      <app-input label="Customer Name" formControlName="customerName" [maxLength]="256"></app-input>
    </div>
    <div class="app-enrollment-ship-to__control">
      <app-input label="Street Name" formControlName="street" [maxLength]="256"></app-input>
    </div>
    <div class="app-enrollment-ship-to__row">
      <div class="app-enrollment-ship-to__control">
        <app-input label="City" formControlName="city" [maxLength]="256"></app-input>
      </div>
      <div class="app-enrollment-ship-to__control">
        <app-dropdown
          label="State"
          formControlName="state"
          showAbbreviation="id"
          optionValue="id"
          [values]="states"
        ></app-dropdown>
      </div>
      <div class="app-enrollment-ship-to__control">
        <app-input label="Zip Code" formControlName="zip" [mask]="zipCodeMask"></app-input>
      </div>
    </div>
    <div class="app-enrollment-ship-to__row">
      <div class="app-enrollment-ship-to__control">
        <app-input
          label="Account Payable Email"
          formControlName="email"
          [maxLength]="128"
        ></app-input>
      </div>
      <div class="app-enrollment-ship-to__control">
        <app-input label="Phone" formControlName="phoneNumber" [mask]="phoneMask"></app-input>
      </div>
      <div class="app-enrollment-ship-to__control">
        <app-input label="Fax" formControlName="fax" [mask]="faxMask"></app-input>
      </div>
    </div>
  </div>
}
