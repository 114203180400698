import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { BehaviorSubject, catchError, filter, Observable, switchMap, throwError } from 'rxjs';
import { AuthApiService } from '../../modules/auth/services/auth-api.service';
import { Store } from '@ngrx/store';
import { selectIsLoggedIn } from '../store/selectors/selectors';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  isRefreshing = new BehaviorSubject(false);
  isLoggedIn = false;
  // add URL if no need to show spinner
  private urlsToIgnore: string[] = ['auth/password/forgot', 'auth/login', 'auth/refresh'];
  constructor(
    private readonly store: Store,
    private authApiService: AuthApiService,
  ) {
    this.store.select(selectIsLoggedIn).subscribe((value) => (this.isLoggedIn = value));
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          !this.urlsToIgnore.some((url) => req.url.includes(url)) &&
          error.status === 401
        ) {
          return this.handle401Error(req, next);
        }

        return throwError(() => error);
      }),
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing.value) {
      this.isRefreshing.next(true);

      if (this.isLoggedIn) {
        return this.authApiService.refreshToken$().pipe(
          switchMap(() => {
            this.isRefreshing.next(false);

            return next.handle(request);
          }),
          catchError((error) => {
            this.isRefreshing.next(false);

            return throwError(() => error);
          }),
        );
      }
    }
    return this.isRefreshing.pipe(
      filter((isRefreshing) => {
        return !isRefreshing;
      }),
      switchMap(() => {
        return next.handle(request);
      }),
    );
  }
}
