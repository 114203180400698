import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { SafeHtmlPipe } from '../../../../core/pipes/safe-html.pipe';
import { SharedModule } from '../../../../shared/shared.module';
import {
  AppAlertType,
  AppButtonSize,
  AppButtonType,
  DialogData,
} from '../../../../core/models/common-components.model';

@UntilDestroy()
@Component({
  selector: 'app-almost-there',
  templateUrl: './almost-there.component.html',
  styleUrl: './almost-there.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle,
    SafeHtmlPipe,
    SharedModule,
  ],
})
export class AlmostThereComponent {
  public btnTypes = AppButtonType;
  public btnSizes = AppButtonSize;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogRef: MatDialogRef<AlmostThereComponent>,
  ) {}

  public close(action: string): void {
    this.dialogRef.close(action);
  }

  protected readonly alertTypes = AppAlertType;
}
