<div>
  <h1>
    <p>STANDARD PURCHASE TERMS</p>

    <p>
      BY PLACING AN ORDER WITH CIPLA USA, INC., THE BUYER EXPRESSLY AGREES WITH THE TERMS OF THIS
      AGREEMENT
    </p>
  </h1>

  <p>
    The following Standard Purchase Terms (“Agreement”) govern the non-exclusive purchase and sale
    of pharmaceutical products from Cipla USA, Inc. (“Cipla”) by the purchasing Buyer (“Buyer”) and
    become effective immediately upon order placement.
  </p>

  <p>
    1. <strong>Product and Pricing. </strong>Available Product(s) and Pricing is established
    directly through Buyer and Cipla. Cipla is responsible for providing Buyer with current
    Product(s) and Pricing as well as changes related to Product(s) and Pricing.
  </p>
  <p>
    2. <strong>Purchase Orders, Shipping. </strong>Buyer will submit all purchase orders with Cipla
    via its e-commerce platform or in writing by submitting an e-mail to
    <a [href]="mailToLink">{{ email }}</a
    >. Upon acceptance of Purchase Order, Cipla shall use commercially reasonable efforts to deliver
    the Products ordered under a Purchase Order within six (6) business days after the receipt of
    the Purchase Order. Buyer agrees that each purchase order should, at the minimum, (i) be US$ 250
    in value; and (ii) meet the Minimum Order Quantity (MOQ) of the Product as determined by Cipla
    from time-to-time. Cipla reserves the right to reject a Purchase Order, including, if (iii)
    Purchase Order does not meet the above requirements, (iv) if Buyer has exceeded its established
    credit limit, (v) is not current with its payment obligations, or (vi) if Buyer informs or if
    Cipla becomes aware that Buyer is no longer a member of a contracted group purchasing
    organization as of the date the Purchase Order is placed. Cipla will use its preferred carrier
    for the shipment of the Product to the Buyer. Shipment shall be FOB Buyer’s location (which site
    shall be designated by Buyer at the time of the order and must appear as an authorized “ship to”
    location). Title to and risk of loss of Products sold hereunder shall pass to Buyer upon
    delivery to the specified location. Cipla shall determine the method of transportation, routing
    of all shipments and shall pay freight charges subject to 2.i and 2.ii listed above. Should
    Buyer require upgraded shipping (next day air, two (2) day air or three (3) day air), Buyer must
    select preferred shipping option within the e-commerce platform and before submitting final
    order. they must notify Cipla in writing of this request prior to submitting their order.
    Delivery of all quantities of Products subject to an order confirmation provided by Cipla shall
    be deemed to have been made in full unless within five (5) business days from the date of
    receipt, Buyer informs Cipla, either by phone, (844-247-5287) – Option #1, or e-mail,
    <a [href]="mailToLink">{{ email }}</a> of any incorrect shipments, damaged Products, or
    shortages in such quantities of Products.
  </p>
  <p>
    3. <strong>Shelf Life. </strong>Products delivered by Cipla will not have less than a twelve
    (12) month interval between a Product’s date of delivery by Cipla and its expiration date.
  </p>
  <p>
    4. <strong>Payment Terms. </strong>Payments are due net thirty (30) days from the invoice date.
    Preferred payment method is ACH. If available, payments can also be made via credit card. Cipla
    reserves the right to pursue any past due amounts of Buyer to the fullest extent permitted by
    law, including use of a collection agency and pursuit of legal remedies. Buyer shall be
    responsible to reimburse Cipla for all fees and costs associated with collections agency
    services in seeking payment of Buyer's past due amounts.
  </p>
  <p>
    5. <strong>Return of Products. </strong>Products purchased by Buyer shall be returned for
    replacement, or at Cipla's sole discretion for credit, in accordance with Cipla’s then-current
    Standard Return Goods Policy in effect at time replacement request is made (copies of which can
    be obtained by contacting Cipla Customer Service at 844-247-5284 – Option #1). Buyer shall not
    bill or obtain payment from any third party for Products returned to Cipla for credit or
    replacement‬.
  </p>
  <p>
    6. <strong>Product Recalls. </strong>In the event of a general recall or a limited recall by the
    U.S. Food and Drug Administration, Cipla shall use commercially reasonable efforts to promptly
    notify Buyer of such recall. In connection with any such recalls, Buyer will provide the
    services and assistance requested in writing by Cipla in connection with such recall. Cipla
    shall reimburse Buyer for those services and assistance requested by Cipla at the time of the
    recall announcement according to Healthcare Distribution Management Association guidelines.
  </p>
  <p>
    7. <strong>Warranty, Defect & Limitation. </strong>Cipla‪ represents and warrants that the
    Products shall (a) conform to the specifications, drawings and samples furnished by Cipla and
    shall be safe for their intended use, (b) be merchantable; and (c) be free and clear of all
    liens, security interests, or other encumbrances. ‬‬‬‬Any applicable statute of limitations runs
    from the date of Buyer’s discovery of the noncompliance of the Products with the foregoing
    warranties. If any Products are found to be in non-compliance of the warranties of Cipla under
    this Section 7 and a claim is made by the Buyer on account of such defect, Cipla shall, as its
    sole liability, either replace the defective Products with substantially similar products or
    credit the Buyer. This Section and the obligations contained herein shall survive the expiration
    or earlier termination of this Agreement. The remedies set forth in this Section are not in
    addition to, and are a limitation on, any other rights or remedies that may be available against
    Cipla. EXCEPT AS EXPRESSLY PROVIDED HEREIN, CIPLA DOES NOT MAKE ANY WARRANTY OF ANY KIND,
    EXPRESS, STATUTORY, IMPLIED OR OTHERWISE, INCLUDING WITHOUT LIMITATION, ANY WARRANTY OF
    MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. NO ORAL OR WRITTEN INFORMATION OR ADVICE
    GIVEN BY AN EMPLOYEE OR REPRESENTATIVE OF CIPLA SHALL CREATE A WARRANTY FOR ANY PRODUCTS.‬‬‬
  </p>
  <p>
    8. <strong>Compliance with Law. </strong>Each party represents and warrants that to the best of
    its knowledge, after due inquiry, it is, and for the Term shall be, in compliance with all
    federal and state laws, ordinances and regulations that are material to the operation of its
    business and the performance of its obligations under this Agreement (“Legal Requirements”),
    including, but not limited to, Legal Requirements pertaining to the safety of the Products,
    occupational health and safety, environmental protection, nondiscrimination, antitrust, health
    care regulation, the Health Insurance Portability and Accountability Act (“HIPAA”) and equal
    employment opportunity.
  </p>
  <p>
    9. <strong>Pharmacovigilance. </strong>Buyer shall forward to Cipla all reports of any Product
    complaints or Adverse Event (as defined below) made through any means to Buyer within the
    earlier of (a) one (1) business day: or (b) three (3) calendar days of receipt of the report.
    Buyer shall also use no less than commercially reasonable efforts, to notify Cipla’s Drug Safety
    Department in accordance with this section as soon as possible upon receipt of notice of a
    Product complaint or Adverse Event for a Product listed under this Agreement. Buyer shall
    forward to Cipla any additional medical updates on each Adverse Event report that Purchaser
    receives within one (1) business day or three (3) calendar days of receipt by Buyer, whichever
    is earlier. Buyer shall cooperate with Cipla’s reasonable requests for additional information
    related to any Adverse Event report. Buyer shall maintain all records of Adverse Events as
    required by federal law. Buyer shall capture patient information (subject to HIPAA and
    applicable law), reporter information (if other than patient), Product information (including
    the GTIN) and details of the Adverse Event or Product complaint. All such information shall be
    forwarded to Cipla as directed immediately below mail: Cipla USA, Inc., Attention: Drug Safety
    Department, 10 Independence Blvd., Suite 300, Warren, NJ 07059 or via e-mail:
    <a [href]="mailToLink">{{ email }}</a
    >.
  </p>
</div>
