import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import * as CustomValidators from '../../../../../core/utils/validators';
import { SharedModule } from '../../../../../shared/shared.module';
import { CommonModule } from '@angular/common';
import { AppKeyValueLabels } from '../../../../../core/models/common-components.model';
import { fromOrganizations } from '../../../store/selectors';
import { filter, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { OrganizationDataDto } from '../../../../../core/models/organization.model';
import { LetDirective } from '../../../../../core/utils/let-directive';
import { formatStringToPhone } from '../../../../../core/utils/formatStringToPhone';
import { isPresent } from '../../../../../core/utils/isPresent';
import { convertFullAddress } from '../../../../../core/utils/convertFullAddress';
import { phoneNumberPrefix, phonePrefixMask } from 'src/app/core/consts/masks';

@Component({
  selector: 'app-personal-details-create-organization',
  templateUrl: './personal-details.component.html',
  styleUrl: './personal-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule, ReactiveFormsModule, CommonModule, LetDirective],
})
export class PersonalDetailsComponent {
  public accountWithoutPersonMode = this.fb.control(true);

  public form = this.fb.group({
    firstName: this.fb.control('', { validators: [Validators.required], nonNullable: true }),
    lastName: this.fb.control('', { validators: [Validators.required], nonNullable: true }),
    email: this.fb.control('', {
      validators: [
        Validators.required,
        Validators.maxLength(128),
        CustomValidators.emailFormatValidator(),
      ],
      nonNullable: true,
    }),
    phoneNumber: this.fb.control('', {
      validators: [Validators.minLength(10), Validators.required],
      nonNullable: true,
    }),
  });

  prefix = phoneNumberPrefix;
  phoneMask = phonePrefixMask;

  public labelsDataMock: AppKeyValueLabels[] = [
    {
      label: 'NPI Number',
      field: 'npi',
    },
    {
      label: 'Organization Name',
      field: 'name',
    },
    {
      label: 'Address',
      field: 'address',
    },
    {
      label: 'Phone',
      field: 'phone',
    },
  ];

  searchResult!: OrganizationDataDto;

  searchResult$ = this.store$.select(fromOrganizations.selectLastOrganizationSearchResult).pipe(
    filter(isPresent),
    tap((value) => {
      this.searchResult = value;
    }),
  );

  organizationDataMockValue(searchResult: OrganizationDataDto) {
    return {
      npi: searchResult.npi,
      name: searchResult.name,
      address: convertFullAddress({ ...searchResult }),
      phone: formatStringToPhone(searchResult.phoneNumber),
    };
  }

  constructor(
    private fb: FormBuilder,
    private readonly store$: Store,
  ) {}
}
