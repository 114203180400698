import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { faxMask, phoneMask } from 'src/app/core/consts/masks';
import { EnrollmentContactType } from 'src/app/modules/organizations/models/enrollment.model';

@Component({
  selector: 'app-enrollment-key-contacts',
  templateUrl: './enrollment-key-contacts.component.html',
  styleUrl: './enrollment-key-contacts.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, SharedModule],
})
export class EnrollmentKeyContactsComponent {
  @Input() form: FormGroup | undefined;
  protected readonly phoneMask = phoneMask;
  protected readonly faxMask = faxMask;

  public getFormArray(): FormArray {
    return this.form?.get('contactsArray') as FormArray;
  }

  public getContactTitle(group: AbstractControl): string {
    const contactType = group.get('contactType')?.value;
    switch (contactType) {
      case EnrollmentContactType.PURCHASING: {
        return 'Purchasing Contact';
      }
      case EnrollmentContactType.ACCOUNTS_PAYABLE: {
        return 'Account Payable Contact';
      }
    }
    return 'Purchasing Contact';
  }
}
