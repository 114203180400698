import { AppState } from 'src/app/core/store/models/state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getRouterSelectors } from '@ngrx/router-store';

export const selectRootState = createFeatureSelector<AppState>('rootState');

export const selectUser = createSelector(selectRootState, (state: AppState) => state.user);
export const selectTokens = createSelector(selectRootState, (state: AppState) => state.tokens);
export const selectRegistering = createSelector(
  selectRootState,
  (state: AppState) => state.registering,
);
export const selectLoginError = createSelector(
  selectRootState,
  (state: AppState) => state.loginError,
);
export const selectLoginFirstData = createSelector(
  selectRootState,
  (state: AppState) => state.firstLoginData,
);

export const selectIsLoggedIn = createSelector(
  selectRootState,
  (state: AppState) => state.isLoggedIn,
);

export const selectCiplaAdminOrdersNotificationsCount = createSelector(
  selectRootState,
  (state: AppState) => state.ciplaAdminOrdersNotificationsCount,
);

export const selectOrdersNotificationsCount = createSelector(
  selectRootState,
  (state: AppState) => state.organizationOrdersNotificationsCount,
);

export const selectAppointmentsNotificationsCount = createSelector(
  selectRootState,
  (state: AppState) => state.organizationAppointmentsNotificationsCount,
);

export const selectMfaData = createSelector(selectRootState, (state: AppState) => state.loginData);

export const selectCiplaAdmins = createSelector(selectRootState, (state: AppState) => state.admins);

export const { selectRouteParam, selectRouteParams } = getRouterSelectors();
