<div automation-id="app-npi-validation" class="app-npi-validation">
  <div class="app-npi-validation__form" [formGroup]="form">
    <div *let="npiChanged$ | async" automation-id="title" class="mat-subtitle-2">
      Please, provide your Practice NPI number
    </div>
    <div class="app-npi-validation__form-npi">
      <app-input
        *let="searchResult$ | async"
        automation-id="npi-field"
        formControlName="npi"
        label="NPI Number"
        [mask]="npiMask"
      ></app-input>

      @if (this.form.controls.npi.hasError('custom')) {
        <mat-error class="npi-error" automation-id="npi-field-error">{{
          this.form.controls.npi.getError('custom')
        }}</mat-error>
      }
    </div>

    <app-checkbox automation-id="terms-checkbox" formControlName="termsConfirmation">
      I confirm that I'm a healthcare professional and agree to the
      <a
        automation-id="terms-link"
        (click)="
          $event.preventDefault(); $event.stopImmediatePropagation(); navigateToTermsOfUsePage()
        "
        >Terms of Use</a
      >
      and
      <a
        automation-id="policy-link"
        (click)="
          $event.preventDefault(); $event.stopImmediatePropagation(); navigateToPrivacyPage()
        "
        >Privacy Notice</a
      >.
    </app-checkbox>
  </div>
</div>
