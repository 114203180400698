<section class="app-stepper">
  <header>
    <ol class="app-stepper__header">
      <ng-container *ngFor="let step of steps; let i = index;">
        <li [ngClass]="{'active': selectedIndex === i, 'completed': step.completed}" class="app-stepper__step-header">
          <div (click)="selectedIndex = i">
            <ng-container *ngIf="step.stepLabel; else showLabelText" [ngTemplateOutlet]="step.stepLabel.template">
            </ng-container>
            <ng-template #showLabelText>
              {{ step.label }}
            </ng-template>
          </div>
        </li>
      </ng-container>
    </ol>
  </header>
  <div class="app-stepper__content">
    <div [ngTemplateOutlet]="selectedContent"></div>
  </div>
  <!--<div class="app-stepper__footer">
    <ng-container *ngIf=""></ng-container>
  </div>-->

</section>
