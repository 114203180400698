import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import {
  catchError,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  of,
  switchMap,
} from 'rxjs';
import { DialogsActions } from '../../dialogs/store/actions';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../../core/store/models/state';
import { OrganizationActions, OrganizationEnrollmentActions } from './actions';
import { OrganizationsApiService } from '../../auth/services/organizations-api.service';
import {
  fromOrganizations,
  selectActionRequired,
  selectOrganizationEnrollmentShort,
  selectSearchName,
  selectSearchOrderBy,
  selectSearchPage,
  selectSearchPageSize,
  selectSearchSortOrder,
  selectSearchStatus,
} from './selectors';
import { ROLES } from '../../../core/consts/roles-and-permissions';
import { isPresent } from '../../../core/utils/isPresent';
import { waitWithLatestFrom } from '../../../core/utils/wait-with-latest-from';
import { AppRoutes } from 'src/app/core/consts/navigation.const';
import { routeParams } from '../../../app-routing.module';
import { selectRouteParam } from '../../../core/store/selectors/selectors';

@Injectable()
export class FeatureEffects {
  getOrganizations$ = createEffect(() =>
    combineLatest([
      this.actions$.pipe(ofType(OrganizationActions.getOrganizations)),
      this.store.select(selectSearchPage),
      this.store.select(selectSearchName),
      this.store.select(selectSearchStatus),
      this.store.select(selectSearchOrderBy),
      this.store.select(selectSearchSortOrder),
      this.store.select(selectSearchPageSize),
      this.store.select(selectActionRequired),
    ]).pipe(
      map(([_, page, name, status, orderBy, sortOrder, pageSize, actionRequired]) => {
        return OrganizationActions.loadOrganizations({
          page,
          name,
          orderBy,
          sortOrder,
          status,
          pageSize,
          actionRequired,
        });
      }),
    ),
  );

  loadOrganizations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.loadOrganizations),
      debounceTime(500),
      switchMap(({ page, name, orderBy, sortOrder, status, pageSize, actionRequired }) => {
        return this.organizationsApiService
          .getOrganizations$(page, name, sortOrder, orderBy, status, pageSize, actionRequired)
          .pipe(
            map((response) => OrganizationActions.setOrganizations({ response })),
            catchError((response) => {
              return of(
                OrganizationActions.getOrganizationsFail(),
                DialogsActions.showDialog({
                  data: {
                    actions: ['Close'],
                    content: '',
                    title: response.error?.message || 'Organizations API not available',
                  },
                }),
              );
            }),
          );
      }),
    ),
  );

  loadOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OrganizationActions.loadOrganization,
        OrganizationActions.updateOrganizationAccountSuccess,
        OrganizationActions.approveShippingAddressesSuccess,
      ),
      // тут нужен дебаунсер так как читаем id из queryParams в двух местах
      debounceTime(500),
      map(() => {
        return OrganizationActions.getOrganization();
      }),
    ),
  );

  getOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.getOrganization),
      waitWithLatestFrom(
        this.store.select(selectRouteParam(routeParams.organizationId)).pipe(filter(isPresent)),
      ),
      switchMap(([, id]) => {
        return this.organizationsApiService.getOrganization$(id).pipe(
          map((response) => {
            localStorage.setItem('lastOrganizationId', response.id.toString());
            return OrganizationActions.setOrganization({ response });
          }),
          catchError((response) => {
            return of(
              OrganizationActions.getOrganizationFail(),
              DialogsActions.showDialog({
                data: {
                  actions: ['Close'],
                  content: '',
                  title: response.error?.message || 'Organizations API not available',
                },
              }),
            );
          }),
        );
      }),
    ),
  );

  loadOrganizationUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.getOrganization),
      waitWithLatestFrom(
        this.store.select(selectRouteParam(routeParams.organizationId)).pipe(filter(isPresent)),
      ),
      switchMap(([, id]) => {
        return this.organizationsApiService
          .getOrganizationUsers$({
            id,
            roles: [ROLES.ORG_USER, ROLES.SUPER_ADMIN, ROLES.ORG_ADMIN, ROLES.ADMIN],
            statuses: ['ACTIVE'],
          })
          .pipe(
            map((response) => OrganizationActions.setOrganizationUsers({ response })),
            catchError((response) => {
              return of(
                OrganizationActions.getOrganizationUsersFail(),
                DialogsActions.showDialog({
                  data: {
                    actions: ['Close'],
                    content: '',
                    title: response.error?.message || 'Organizations API not available',
                  },
                }),
              );
            }),
          );
      }),
    ),
  );

  createOrganizationAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.createOrganizationAccount),
      mergeMap((props) =>
        this.organizationsApiService.createAccount$(props.body).pipe(
          map(({ orgId }) => OrganizationActions.createOrganizationAccountSuccess({ orgId })),
          catchError((response) => {
            return of(
              OrganizationActions.createOrganizationAccountFail(),
              DialogsActions.showDialog({
                data: {
                  actions: ['Close'],
                  content: '',
                  title: response.error?.message || 'Registration API not available',
                },
              }),
            );
          }),
        ),
      ),
    ),
  );

  askUpdateOrganizationAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.askUpdateOrganizationAccount),
      map(({ id, status, orgAdminId }) => {
        if (status === 'DEACTIVATED') {
          return DialogsActions.showDialog({
            data: {
              actions: ['Cancel', 'Ok'],
              content: 'You are about to deactivate this Organization Account',
              title: 'Are you sure you want to continue?',
            },
            callback: (action) => {
              if (action === 'Ok') {
                this.store.dispatch(
                  OrganizationActions.updateOrganizationAccount({ id, status, orgAdminId }),
                );
              }
            },
          });
        } else {
          return OrganizationActions.updateOrganizationAccount({ id, status, orgAdminId });
        }
      }),
    ),
  );

  updateOrganizationAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.updateOrganizationAccount),
      mergeMap(({ id, status, orgAdminId }) =>
        this.organizationsApiService
          .updateAccount$(id, { status: status, orgAdminId: orgAdminId })
          .pipe(
            map(() => OrganizationActions.updateOrganizationAccountSuccess()),
            catchError((response) => {
              return of(
                OrganizationActions.updateOrganizationAccountFail(),
                DialogsActions.showDialog({
                  data: {
                    actions: ['Close'],
                    content: '',
                    title: response.error?.message || 'Registration API not available',
                  },
                }),
              );
            }),
          ),
      ),
    ),
  );

  createOrganizationAccountSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.createOrganizationAccountSuccess),
      map((props) =>
        DialogsActions.showDialog({
          data: {
            actions: ['View account', 'Close'],
            content: '',
            title: 'The organization account has been created.',
          },
          callback: (action) => {
            if (action === 'View account') {
              // TODO: медот создания не возвращает id организации - переход на нее невозможен
              this.router.navigate([`${AppRoutes.ORGANIZATIONS}/${props.orgId}`]);
            } else {
              this.router.navigate([AppRoutes.ORGANIZATIONS]);
            }
          },
        }),
      ),
    ),
  );

  createOrganizationAccountCancel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.createOrganizationAccountCancel),
      map(({ pristine }) => {
        if (pristine) {
          this.router.navigate([AppRoutes.ORGANIZATIONS]);

          return DialogsActions.setRef({ ref: null });
        } else {
          return DialogsActions.showDialog({
            data: {
              actions: ['Cancel', 'Ok'],
              content: '',
              title: 'The updates will not be saved',
            },
            callback: (action) => {
              if (action === 'Ok') {
                this.router.navigate([AppRoutes.ORGANIZATIONS]);
              }
            },
          });
        }
      }),
    ),
  );

  getOrganizationIdFromParams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.getOrganizationIdFromParams),
      switchMap(({ routeFn, routerFn }) => {
        return routerFn()
          .events.pipe(filter((e) => e instanceof NavigationEnd))
          .pipe(
            switchMap(() => {
              return this.store.select(selectRouteParam(routeParams.organizationId));
            }),
            distinctUntilChanged(),
          );
      }),
      map((organizationId) => {
        return organizationId
          ? OrganizationActions.loadOrganization()
          : OrganizationActions.setOrganization({ response: null });
      }),
    ),
  );

  getOrganizationEnrollment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationEnrollmentActions.getOrganizationEnrollment),
      waitWithLatestFrom(
        this.store.select(selectRouteParam(routeParams.organizationId)).pipe(filter(isPresent)),
      ),
      switchMap(([, organizationId]) => {
        return this.organizationsApiService.getOrganizationEnrollment$(organizationId).pipe(
          switchMap((enrollments) => {
            return enrollments && enrollments?.length > 0
              ? [
                  OrganizationEnrollmentActions.getOrganizationEnrollmentSuccess({
                    enrollment: enrollments[0],
                  }),
                  OrganizationEnrollmentActions.getOrganizationEnrollmentById({
                    enrollmentId: enrollments[0].id,
                  }),
                ]
              : [];
          }),
        );
      }),
    ),
  );

  getOrganizationEnrollmentShort$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationEnrollmentActions.getOrganizationEnrollmentShort),
      waitWithLatestFrom(
        this.store.select(selectRouteParam(routeParams.organizationId)).pipe(filter(isPresent)),
      ),
      switchMap(([, organizationId]) =>
        this.organizationsApiService.getOrganizationEnrollment$(organizationId).pipe(
          switchMap((enrollments) => [
            OrganizationEnrollmentActions.getOrganizationEnrollmentSuccess({
              enrollment: (enrollments && enrollments[0]) ?? null,
            }),
          ]),
        ),
      ),
    ),
  );

  getOrganizationEnrollmentById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationEnrollmentActions.getOrganizationEnrollmentById),
      waitWithLatestFrom(
        this.store.select(fromOrganizations.selectOrganization).pipe(filter(isPresent)),
      ),
      switchMap(([props, organization]) =>
        this.organizationsApiService
          .getOrganizationEnrollmentById$(organization.id, props.enrollmentId)
          .pipe(
            switchMap((enrollment) => [
              OrganizationEnrollmentActions.getOrganizationEnrollmentByIdSuccess({ enrollment }),
            ]),
          ),
      ),
    ),
  );

  createOrganizationEnrollmentAndUpdateBillTo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationEnrollmentActions.createOrganizationEnrollmentAndUpdateBillTo),
      waitWithLatestFrom(
        this.store.select(fromOrganizations.selectOrganization).pipe(filter(isPresent)),
        this.store.select(selectOrganizationEnrollmentShort),
      ),
      switchMap(([props, organization, enrollmentShort]) =>
        !enrollmentShort
          ? this.organizationsApiService
              .createOrganizationEnrollment$(organization.id!)
              .pipe(
                switchMap((enrollment) => [
                  OrganizationEnrollmentActions.createOrganizationEnrollmentSuccess({ enrollment }),
                  OrganizationActions.loadOrganization(),
                  OrganizationEnrollmentActions.updateEnrollmentBillTo({ billTo: props.billTo }),
                ]),
              )
          : [OrganizationEnrollmentActions.updateEnrollmentBillTo({ billTo: props.billTo })],
      ),
    ),
  );

  updateOrganizationEnrollmentBillTo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationEnrollmentActions.updateEnrollmentBillTo),
      waitWithLatestFrom(
        this.store.select(fromOrganizations.selectOrganization).pipe(filter(isPresent)),
        this.store.select(selectOrganizationEnrollmentShort).pipe(filter(isPresent)),
      ),
      switchMap(([props, organization, enrollment]) =>
        this.organizationsApiService
          .updateOrganizationEnrollmentBillTo$(organization.id!, enrollment.id, props.billTo)
          .pipe(map(() => OrganizationEnrollmentActions.updateEnrollmentSuccess())),
      ),
    ),
  );
  updateOrganizationEnrollmentShipTo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationEnrollmentActions.updateEnrollmentShipTo),
      waitWithLatestFrom(
        this.store.select(fromOrganizations.selectOrganization).pipe(filter(isPresent)),
        this.store.select(selectOrganizationEnrollmentShort).pipe(filter(isPresent)),
      ),
      switchMap(([props, organization, enrollment]) =>
        this.organizationsApiService
          .updateOrganizationEnrollmentShipTo$(organization.id, enrollment.id, props.shipTo)
          .pipe(map(() => OrganizationEnrollmentActions.updateEnrollmentSuccess())),
      ),
    ),
  );
  updateOrganizationEnrollmentContacts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationEnrollmentActions.updateEnrollmentContacts),
      waitWithLatestFrom(
        this.store.select(fromOrganizations.selectOrganization).pipe(filter(isPresent)),
        this.store.select(selectOrganizationEnrollmentShort).pipe(filter(isPresent)),
      ),
      switchMap(([props, organization, enrollment]) =>
        this.organizationsApiService
          .updateOrganizationEnrollmentContacts$(organization.id, enrollment.id, props.contacts)
          .pipe(map(() => OrganizationEnrollmentActions.updateEnrollmentSuccess())),
      ),
    ),
  );

  updateOrganizationEnrollmentLicences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationEnrollmentActions.updateEnrollmentLicences),
      waitWithLatestFrom(
        this.store.select(fromOrganizations.selectOrganization).pipe(filter(isPresent)),
        this.store.select(selectOrganizationEnrollmentShort).pipe(filter(isPresent)),
      ),
      switchMap(([props, organization, enrollment]) =>
        this.organizationsApiService
          .updateOrganizationEnrollmentLicences$(organization.id, enrollment.id, props.licences)
          .pipe(map(() => OrganizationEnrollmentActions.updateEnrollmentSuccess())),
      ),
    ),
  );

  updateOrganizationEnrollmentCreditData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationEnrollmentActions.updateEnrollmentCreditData),
      waitWithLatestFrom(
        this.store.select(fromOrganizations.selectOrganization).pipe(filter(isPresent)),
        this.store.select(selectOrganizationEnrollmentShort).pipe(filter(isPresent)),
      ),
      switchMap(([props, organization, enrollment]) =>
        this.organizationsApiService
          .updateOrganizationEnrollmentCreditData$(organization.id, enrollment.id, props.creditData)
          .pipe(
            switchMap(() => [
              OrganizationEnrollmentActions.updateEnrollmentSuccess(),
              OrganizationEnrollmentActions.submitEnrollment(),
            ]),
          ),
      ),
    ),
  );

  submitOrganizationEnrollment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationEnrollmentActions.submitEnrollment),
      waitWithLatestFrom(
        this.store.select(fromOrganizations.selectOrganization).pipe(filter(isPresent)),
        this.store.select(selectOrganizationEnrollmentShort).pipe(filter(isPresent)),
      ),
      switchMap(([_, organization, enrollment]) =>
        this.organizationsApiService
          .submitOrganizationEnrollment$(organization.id, enrollment.id!)
          .pipe(
            switchMap((enrollment) => [
              OrganizationEnrollmentActions.submitEnrollmentSuccess({ enrollment }),
              OrganizationEnrollmentActions.getOrganizationEnrollmentShort(),
              DialogsActions.showDialog({
                data: {
                  actions: ['View', 'Close'],
                  content: '',
                  title: 'The enrollment form has been submitted',
                },
                callback: (action) => {
                  if (action === 'Close') {
                    this.router.navigate([`${AppRoutes.ORGANIZATIONS}/${organization.id}`]);
                  } else {
                    this.store.dispatch(OrganizationEnrollmentActions.getOrganizationEnrollment());
                  }
                },
              }),
            ]),
          ),
      ),
    ),
  );

  approveEnrollment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationEnrollmentActions.approveEnrollment),
      waitWithLatestFrom(
        this.store.select(fromOrganizations.selectOrganization).pipe(filter(isPresent)),
        this.store.select(selectOrganizationEnrollmentShort).pipe(filter(isPresent)),
      ),
      switchMap(([_, organization, enrollment]) =>
        this.organizationsApiService
          .approveOrganizationEnrollment$(organization.id, enrollment.id)
          .pipe(
            map(() =>
              DialogsActions.showDialog({
                data: {
                  actions: ['Ok'],
                  content: '',
                  title: 'The Enrollment Form has been successfully approved',
                },
                callback: () => {
                  this.router.navigate([`${AppRoutes.ORGANIZATIONS}/${organization.id}`]);
                },
              }),
            ),
          ),
      ),
    ),
  );

  declineEnrollment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationEnrollmentActions.declineEnrollment),
      waitWithLatestFrom(
        this.store.select(fromOrganizations.selectOrganization).pipe(filter(isPresent)),
        this.store.select(selectOrganizationEnrollmentShort).pipe(filter(isPresent)),
      ),
      switchMap(([_, organization, enrollment]) =>
        this.organizationsApiService
          .declineOrganizationEnrollment$(organization.id, enrollment.id)
          .pipe(
            map(() =>
              DialogsActions.showDialog({
                data: {
                  actions: ['Ok'],
                  content: '',
                  title: 'Enrollment Form has been declined',
                },
                callback: () => {
                  this.router.navigate([`${AppRoutes.ORGANIZATIONS}/${organization.id}`]);
                },
              }),
            ),
          ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private organizationsApiService: OrganizationsApiService,
    private readonly router: Router,
    private store: Store<AppState>,
  ) {}
}
