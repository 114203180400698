import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store/models/state';
import { map, Observable, take } from 'rxjs';
import { selectUser } from 'src/app/core/store/selectors/selectors';
import { ROLES } from 'src/app/core/consts/roles-and-permissions';
import { OrganizationStatusDto } from 'src/app/core/models/organization.model';
import { combineLatest } from 'rxjs';
import { selectOrganization } from 'src/app/modules/organizations/store/selectors';
import { AppRoutes } from 'src/app/core/consts/navigation.const';

@Injectable({ providedIn: 'root' })
export class OrganizationStatusGuard implements CanActivate {
  constructor(
    private store: Store<AppState>,
    private router: Router,
  ) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return combineLatest([
      this.store.select(selectUser),
      this.store.select(selectOrganization),
    ]).pipe(
      map(([user, organization]) => {
        if (user && (user.role === ROLES.ORG_ADMIN || user.role === ROLES.ORG_USER)) {
          if (
            user.organization &&
            (user.organization.status === OrganizationStatusDto.ONBOARDING ||
              user.organization.status === OrganizationStatusDto.PENDING ||
              user.organization.status === OrganizationStatusDto.ENROLLMENT)
          ) {
            this.router.navigate([AppRoutes.LOGIN]);
            return false;
          }
          if (
            organization &&
            (organization.status === OrganizationStatusDto.ONBOARDING ||
              organization.status === OrganizationStatusDto.PENDING ||
              organization.status === OrganizationStatusDto.ENROLLMENT)
          ) {
            this.router.navigate([AppRoutes.LOGIN]);
            return false;
          }
        }
        return true;
      }),
      take(1),
    );
  }
}
