import { CanDeactivate } from '@angular/router';
import { map } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AppDialogComponent } from 'src/app/shared/components/app-dialog/app-dialog.component';
import { Injectable } from '@angular/core';
import { EnrollmentComponent } from 'src/app/modules/organizations/components/enrollment/enrollment.component';
import { ROLES } from '../../../core/consts/roles-and-permissions';

@Injectable({
  providedIn: 'root',
})
export class CanDeactivateEnrollmentGuard implements CanDeactivate<EnrollmentComponent> {
  constructor(public dialog: MatDialog) {}
  canDeactivate(component: EnrollmentComponent) {
    const canDeactivateRes = component.canDeactivateEnrollment();
    const user = component.user;
    const form = component.form;

    if (user?.role === ROLES.ADMIN || user?.role === ROLES.SUPER_ADMIN || form.disabled) {
      return true;
    }

    if (canDeactivateRes) {
      const data = {
        title:
          'Your progress on the enrollment form has been saved as a draft. Feel free to return anytime to pick up where you left off.',
        content: '',
        actions: ['Back to edit', 'Leave'],
      };
      const ref = this.dialog.open(AppDialogComponent, {
        data,
      });
      return ref.afterClosed().pipe(map((result) => result === 'Leave'));
    } else {
      const data = {
        title: 'Unsaved updates are detected',
        content:
          "Click 'Next' to validate and save your progress for each step of the form. Ensure all mandatory fields are filled in before proceeding",
        actions: ['Back to edit', 'Leave'],
      };
      const ref = this.dialog.open(AppDialogComponent, {
        data,
      });
      return ref.afterClosed().pipe(map((result) => result === 'Leave'));
    }
  }
}
