<div class="app-checkbox">
  <div class="app-checkbox__input-wrapper">
    <input type="checkbox" id="app-checkbox" class="app-checkbox__input" [formControl]="control" />
  </div>
  <label
    for="app-checkbox"
    class="app-checkbox__label"
    [ngClass]="{ _normal: fontSize === 'normal' }"
  >
    <ng-container *ngIf="label; else labelTemplate">{{ label }}</ng-container>
    <ng-template #labelTemplate>
      <span (click)="$event.preventDefault()"><ng-content></ng-content></span>
    </ng-template>
  </label>
</div>
