<div class="app-breadcrumbs">
  @for (breadcrumb of breadcrumbs; track breadcrumb.label; let last = $last) {
    <div class="app-breadcrumbs__item">
      <div (click)="goTo(breadcrumb)" class="app-breadcrumbs__item-clickable">
        <mat-icon *ngIf="breadcrumb.icon" class="material-icons-outlined app-breadcrumbs__item-icon"
          >{{ breadcrumb.icon }}
        </mat-icon>
        <span class="app-breadcrumbs__item-label">{{ breadcrumb.label }}</span>
      </div>
      <span *ngIf="!last" class="app-breadcrumbs__item-arrow">></span>
    </div>
  }
</div>
