import { Pipe, PipeTransform } from '@angular/core';
import { ROLES } from 'src/app/core/consts/roles-and-permissions';

@Pipe({
  name: 'roleToLabel',
  standalone: true,
})
export class RoleToLabelPipe implements PipeTransform {
  transform(role: ROLES): string {
    switch (role) {
      case ROLES.ADMIN:
        return 'System Admin';
      case ROLES.ORG_ADMIN:
        return 'Organization Admin';
      case ROLES.ORG_USER:
        return 'Organization User';
    }
    return 'Admin';
  }
}
