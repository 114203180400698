import { AbstractControl, FormArray, FormGroup } from '@angular/forms';

export function markControlAsUsedAndValidate(control: AbstractControl, emitEvent: boolean = true) {
  markControlAs(control, (cont: AbstractControl) => {
    cont.markAsTouched();
    cont.markAsDirty();
    cont.updateValueAndValidity({ emitEvent });
  });
}

export function markControlAs(control: AbstractControl, markAs: (cont: AbstractControl) => void) {
  if (control instanceof FormArray) {
    control.controls.forEach((nestedControl) => {
      markControlAs(nestedControl, markAs);
    });

    return;
  }

  if (control instanceof FormGroup) {
    const { controls } = control;

    for (const name in controls) {
      // eslint-disable-next-line no-prototype-builtins
      if (controls.hasOwnProperty(name)) {
        markControlAs(controls[name], markAs);
      }
    }

    return;
  }

  markAs(control);
}
