<div
  class="app-status"
  [ngClass]="{
    _green: isGreen,
    _blue: isBlue,
    _lightBlue: isLightBlue,
    _gray: isGray,
    _red: isRed
  }"
>
  {{ statusText }}
</div>
