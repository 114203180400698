<div class="common-table">
  <table
    mat-table
    [dataSource]="(admins$ | async) || []"
    matSort
    matSortActive="firstName"
    matSortDirection="asc"
  >
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>ID</th>
      <td mat-cell *matCellDef="let row">{{ row.id }}</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Full Name</th>
      <td mat-cell *matCellDef="let row">{{ row.firstName }} {{ row.lastName }}</td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>Email</th>
      <td mat-cell *matCellDef="let row">{{ row.email }}</td>
    </ng-container>

    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef>Mobile Phone</th>
      <td mat-cell *matCellDef="let row">{{ formatStringToPhone(row.mobilePhoneNumber) }}</td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let row">
        <mat-icon
          matSuffix
          class="table-action-icon material-icons-outlined"
          (click)="deleteAdmin(row.id)"
          matTooltip="Delete Cipla Admin"
          >{{ 'delete_outline' }}</mat-icon
        >
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr *matNoDataRow>
      <td colspan="7">
        <div class="no-results-row">
          <div class="no-results-content">
            <mat-icon matPrefix>search_off</mat-icon> No cipla admins found
          </div>
        </div>
      </td>
    </tr>
  </table>
</div>
