import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ROLES } from 'src/app/core/consts/roles-and-permissions';
import { NavigationEnd, Router } from '@angular/router';
import { AppState } from 'src/app/core/store/models/state';
import { Store } from '@ngrx/store';
import {
  selectAppointmentsNotificationsCount,
  selectOrdersNotificationsCount,
  selectUser,
} from 'src/app/core/store/selectors/selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, filter } from 'rxjs';
import { isPresent } from 'src/app/core/utils/isPresent';
import { selectOrganization } from 'src/app/modules/organizations/store/selectors';
import { UserDto } from '../../models/auth.model';
import { OrganizationDto, OrganizationStatusDto } from '../../models/organization.model';
import { AppRoutes, OrganizationOrdersRoutes } from 'src/app/core/consts/navigation.const';

interface MenuItem {
  icon: string;
  label: string;
  roles?: string[];
  route: string;
  counter?: number;
  disabledForStatuses?: string[];
}

@UntilDestroy()
@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrl: './top-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopMenuComponent implements OnInit {
  public menuItems: MenuItem[] = [];
  public selectedItemIndex: number | undefined;
  public user: UserDto | undefined;
  public organization: OrganizationDto | undefined | null;

  private ordersMenuLabel: string = 'Orders';
  private inventoryMenuLabel: string = 'Inventory';
  private staffMenuLabel: string = 'Staff';
  private reportsMenuLabel: string = 'Reports';
  private patientsMenuLabel: string = 'Patients';
  private orgAccountMenuLabel: string = 'Organization Account';

  private ordersCreateUrl: string = `/${AppRoutes.ORDERS}/${OrganizationOrdersRoutes.CREATE}`;

  public ordersMenuIndex: number = 0;
  public inventoryMenuIndex: number = 0;
  public staffMenuIndex: number = 0;
  public reportsMenuIndex: number = 0;
  public patientsMenuIndex: number = 0;
  public orgAccountMenuIndex: number = 0;

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        untilDestroyed(this),
      )
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.checkSelectedItem(event.url);
        }
      });
    combineLatest([
      this.store.select(selectUser).pipe(filter(isPresent)),
      this.store.select(selectOrganization),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([user, organization]) => {
        this.user = user;
        this.organization = organization || this.user?.organization;
        this.initMenuItems(user, organization);
        this.fetchData();
        if (user && user.organizationId) {
          this.menuItems[this.ordersMenuIndex].route =
            `/${AppRoutes.ORDERS}/${user.organizationId}`;
          this.menuItems[this.staffMenuIndex].route = `/${AppRoutes.STAFF}/${user.organizationId}`;
          this.menuItems[this.reportsMenuIndex].route =
            `/${AppRoutes.REPORTS}/${user.organizationId}`;
          this.menuItems[this.inventoryMenuIndex].route =
            `/${AppRoutes.INVENTORY}/${user.organizationId}`;
          this.menuItems[this.patientsMenuIndex].route =
            `/${AppRoutes.PATIENTS}/${user.organizationId}`;
        } else if (organization && organization.id) {
          this.menuItems[this.ordersMenuIndex].route = `/${AppRoutes.ORDERS}/${organization.id}`;
          this.menuItems[this.staffMenuIndex].route = `/${AppRoutes.STAFF}/${organization.id}`;
          this.menuItems[this.reportsMenuIndex].route = `/${AppRoutes.REPORTS}/${organization.id}`;
          this.menuItems[this.inventoryMenuIndex].route =
            `/${AppRoutes.INVENTORY}/${organization.id}`;
          this.menuItems[this.patientsMenuIndex].route =
            `/${AppRoutes.PATIENTS}/${organization.id}`;
        }
        this.checkSelectedItem(this.router.url);
      });
  }

  public isItemShown(item: MenuItem): boolean {
    return this.user ? !!item.roles?.includes(this.user?.role) : true;
  }

  public selectItem(i: number, disabled: boolean | null): void {
    if (disabled) {
      return;
    }
    this.selectedItemIndex = i;
    if (this.menuItems[i].route) {
      this.router.navigate([this.menuItems[i].route]);
    }
  }

  public isItemDisabled(item: MenuItem): boolean {
    if (this.organization && this.user) {
      if (item.disabledForStatuses?.includes(this.organization?.status)) {
        return this.user.role === ROLES.ORG_USER || this.user.role === ROLES.ORG_ADMIN;
      }
    }
    return false;
  }

  private checkSelectedItem(url: string): void {
    this.selectedItemIndex = this.menuItems.findIndex((item) => url.startsWith(item.route));
    if (this.selectedItemIndex === -1) {
      this.processNestedUrls(url);
    }
    this.cdr.markForCheck();
  }

  private processNestedUrls(url: string): void {
    switch (url) {
      case this.ordersCreateUrl: {
        this.selectedItemIndex = this.ordersMenuIndex;
        break;
      }
    }
  }

  private fetchData(): void {
    this.store
      .select(selectOrdersNotificationsCount)
      .pipe(
        filter(isPresent),
        filter(() => this.user?.role !== ROLES.ADMIN && this.user?.role !== ROLES.SUPER_ADMIN),
        untilDestroyed(this),
      )
      .subscribe((count) => {
        this.menuItems[this.ordersMenuIndex].counter = count;
        this.cdr.markForCheck();
      });

    this.store
      .select(selectAppointmentsNotificationsCount)
      .pipe(
        filter(isPresent),
        filter(() => this.user?.role !== ROLES.ADMIN && this.user?.role !== ROLES.SUPER_ADMIN),
        untilDestroyed(this),
      )
      .subscribe((count) => {
        this.menuItems[1].counter = count;
        this.cdr.markForCheck();
      });
  }

  private initMenuItems(user: UserDto, organization: OrganizationDto | null): void {
    const orgAccountMenuItem: MenuItem = {
      icon: 'home',
      label: this.orgAccountMenuLabel,
      roles: [ROLES.SUPER_ADMIN, ROLES.ADMIN],
      route: !organization ? '' : `/${AppRoutes.ORGANIZATIONS}/${organization?.id}`,
      disabledForStatuses: [
        OrganizationStatusDto.PENDING,
        OrganizationStatusDto.ONBOARDING,
        OrganizationStatusDto.ENROLLMENT,
      ],
    };

    this.menuItems = [
      {
        icon: 'shopping_basket',
        label: this.ordersMenuLabel,
        roles: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ORG_ADMIN, ROLES.ORG_USER],
        route: AppRoutes.ORDERS,
        disabledForStatuses: [
          OrganizationStatusDto.PENDING,
          OrganizationStatusDto.ONBOARDING,
          OrganizationStatusDto.ENROLLMENT,
        ],
      },
      {
        icon: 'inventory',
        label: this.inventoryMenuLabel,
        roles: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ORG_ADMIN, ROLES.ORG_USER],
        route: AppRoutes.INVENTORY,
        disabledForStatuses: [
          OrganizationStatusDto.PENDING,
          OrganizationStatusDto.ONBOARDING,
          OrganizationStatusDto.ENROLLMENT,
        ],
      },
      {
        icon: 'people',
        label: this.staffMenuLabel,
        roles: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ORG_ADMIN, ROLES.ORG_USER],
        route: AppRoutes.STAFF,
        disabledForStatuses: [
          OrganizationStatusDto.PENDING,
          OrganizationStatusDto.ONBOARDING,
          OrganizationStatusDto.ENROLLMENT,
        ],
      },
      {
        icon: 'report',
        label: this.reportsMenuLabel,
        roles: [ROLES.SUPER_ADMIN, ROLES.ORG_ADMIN],
        route: AppRoutes.REPORTS,
        disabledForStatuses: [
          OrganizationStatusDto.PENDING,
          OrganizationStatusDto.ONBOARDING,
          OrganizationStatusDto.DEACTIVATED,
          OrganizationStatusDto.ENROLLMENT,
        ],
      },
      {
        icon: 'personal_injury',
        label: this.patientsMenuLabel,
        roles: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.ORG_ADMIN, ROLES.ORG_USER],
        route: AppRoutes.PATIENTS,
        disabledForStatuses: [
          OrganizationStatusDto.PENDING,
          OrganizationStatusDto.ONBOARDING,
          OrganizationStatusDto.ENROLLMENT,
        ],
      },
    ];

    if (user?.role === ROLES.ADMIN || user?.role === ROLES.SUPER_ADMIN) {
      this.menuItems = [orgAccountMenuItem, ...this.menuItems];
    }

    this.ordersMenuIndex = this.menuItems.findIndex((item) => item.label === this.ordersMenuLabel);
    this.inventoryMenuIndex = this.menuItems.findIndex(
      (item) => item.label === this.inventoryMenuLabel,
    );
    this.staffMenuIndex = this.menuItems.findIndex((item) => item.label === this.staffMenuLabel);
    this.reportsMenuIndex = this.menuItems.findIndex(
      (item) => item.label === this.reportsMenuLabel,
    );
    this.patientsMenuIndex = this.menuItems.findIndex(
      (item) => item.label === this.patientsMenuLabel,
    );
    this.orgAccountMenuIndex = this.menuItems.findIndex(
      (item) => item.label === this.orgAccountMenuLabel,
    );
  }
}
