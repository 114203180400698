import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AppState } from 'src/app/core/store/models/state';
import { Store } from '@ngrx/store';
import { UserDto } from 'src/app/core/models/auth.model';
import { startLogoutAction } from 'src/app/core/store/actions/actions';
import { Router } from '@angular/router';
import { ROLES } from 'src/app/core/consts/roles-and-permissions';
import { selectUser } from '../../store/selectors/selectors';
import { selectOrganization } from '../../../modules/organizations/store/selectors';
import { filter, Observable } from 'rxjs';
import { isPresent } from '../../utils/isPresent';
import { OrganizationDto, OrganizationStatusDto } from '../../models/organization.model';
import { AppRoutes, StaffRoutes } from 'src/app/core/consts/navigation.const';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  public user$ = this.store.select(selectUser);
  public organization$: Observable<OrganizationDto> = this.store
    .select(selectOrganization)
    .pipe(filter(isPresent));
  protected readonly ROLES = ROLES;
  OrganizationStatusDto = OrganizationStatusDto;
  constructor(
    private store: Store<AppState>,
    private router: Router,
  ) {}

  logout(): void {
    this.store.dispatch(startLogoutAction());
  }

  navigateToProfile(user: UserDto) {
    this.router.navigate([
      `${AppRoutes.STAFF}/${user.organizationId}/${StaffRoutes.VIEW}/${user.id}`,
    ]);
  }

  toHomePage(user: UserDto): void {
    if (user.role === ROLES.ORG_ADMIN || user.role === ROLES.ORG_USER) {
      this.router.navigate([`${AppRoutes.ORGANIZATIONS}/${user.organizationId}`]);
    } else {
      this.router.navigate([AppRoutes.ORGANIZATIONS]);
    }
  }

  showProfileBtn(user: UserDto, organization: OrganizationDto | null) {
    return (
      user.organizationId &&
      (organization?.status === OrganizationStatusDto.ACTIVE ||
        organization?.status === OrganizationStatusDto.DEACTIVATED)
    );
  }
}
