import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NpiSearchResponseDto } from '../../../shared/models/organization/npi-search-response.dto';
import {
  CreateOrganizationBodyDto,
  CreateOrganizationResponseDto,
  EnrollmentBillingAddressDto,
  EnrollmentContactDto,
  EnrollmentCreditDataDto,
  EnrollmentDto,
  EnrollmentLicencesReqDto,
  EnrollmentShippingAddressDto,
  EnrollmentShortDto,
  EnrollmentStatusDto,
  GetOrganizationsResponseDto,
  GetOrganizationsUsersResponseDto,
  OrganizationDto,
  UpdateOrganizationBodyDto,
} from '../../../core/models/organization.model';
import { SortDirection } from '@angular/material/sort';
import { ROLES } from '../../../core/consts/roles-and-permissions';

@Injectable({
  providedIn: 'root',
})
export class OrganizationsApiService extends ApiService {
  searchByNpi(npi: string): Observable<NpiSearchResponseDto> {
    // TODO: добавить обертку над new HttpParams() в базовый сервис
    return this.get('/organizations/search', new HttpParams().set('npi', npi));
  }

  createAccount$(body: CreateOrganizationBodyDto): Observable<CreateOrganizationResponseDto> {
    return this.post('/organizations', body);
  }

  updateAccount$(
    id: number,
    body: UpdateOrganizationBodyDto,
  ): Observable<CreateOrganizationResponseDto> {
    return this.patch(`/organizations/${id}`, body);
  }

  getOrganizations$(
    page = 1,
    name: string | null,
    sortOrder: SortDirection = 'asc',
    orderBy: string | null,
    statuses: string[] | null,
    limit: number,
    actionRequired: boolean,
  ): Observable<GetOrganizationsResponseDto> {
    const data = {
      page,
      sortOrder,
      name,
      orderBy,
      status,
      limit,
    };
    for (let key in data) {
      let k = key as keyof typeof data;
      if (!data[k]) {
        delete data[k];
      }
    }

    // @ts-ignore
    let httpParams = new HttpParams({ fromObject: data });
    if (statuses) {
      for (let status of statuses) {
        if (status) {
          httpParams = httpParams.append('status', status);
        }
      }
    }

    if (actionRequired) {
      httpParams = httpParams.append('actionRequired', `true`);
    }

    return this.get('/organizations', httpParams);
  }

  getOrganization$(id: string): Observable<OrganizationDto> {
    return this.get(`/organizations/${id}`);
  }

  getOrganizationUsers$(config: {
    id: string;
    page?: number;
    sortOrder?: SortDirection;
    orderBy?: string | null;
    roles?: ROLES[];
    statuses?: string[];
  }): Observable<GetOrganizationsUsersResponseDto> {
    const data = {
      page: config.page || 1,
      sortOrder: config.sortOrder || 'desc',
      orderBy: config.orderBy || 'id',
      status: 'ACTIVE',
    };

    let httpParams = new HttpParams();

    for (let key in data) {
      let k = key as keyof typeof data;
      httpParams = httpParams.append(key, data[k]);
    }
    if (config.roles) {
      for (let role of config.roles) {
        httpParams = httpParams.append('role', role);
      }
    }

    if (config.statuses) {
      for (let status of config.statuses) {
        httpParams = httpParams.append('status', status);
      }
    }

    // @ts-ignore
    return this.get(`/organizations/${config.id}/users`, httpParams);
  }

  getOrganizationEnrollment$(
    organizationId: string,
    statuses: EnrollmentStatusDto[] = [
      EnrollmentStatusDto.IN_PROGRESS,
      EnrollmentStatusDto.IN_REVIEW,
      EnrollmentStatusDto.APPROVED,
    ],
  ): Observable<EnrollmentShortDto[]> {
    let httpParams = new HttpParams();
    if (statuses?.length) {
      for (let status of statuses) {
        httpParams = httpParams.append('status', status);
      }
    }
    return this.get(`/organizations/${organizationId}/enrollments`, httpParams);
  }

  getOrganizationEnrollmentById$(
    organizationId: number,
    enrollmentId: number,
  ): Observable<EnrollmentDto> {
    return this.get(`/organizations/${organizationId}/enrollments/${enrollmentId}`);
  }

  createOrganizationEnrollment$(organizationId: number): Observable<EnrollmentShortDto> {
    return this.post(`/organizations/${organizationId}/enrollments`);
  }

  updateOrganizationEnrollmentBillTo$(
    organizationId: number,
    enrollmentId: number,
    body: EnrollmentBillingAddressDto,
  ): Observable<void> {
    return this.put(
      `/organizations/${organizationId}/enrollments/${enrollmentId}/billing-address`,
      body,
    );
  }
  updateOrganizationEnrollmentShipTo$(
    organizationId: number,
    enrollmentId: number,
    body: EnrollmentShippingAddressDto,
  ): Observable<void> {
    return this.put(
      `/organizations/${organizationId}/enrollments/${enrollmentId}/shipping-address`,
      body,
    );
  }
  updateOrganizationEnrollmentContacts$(
    organizationId: number,
    enrollmentId: number,
    body: EnrollmentContactDto[],
  ): Observable<void> {
    return this.put(`/organizations/${organizationId}/enrollments/${enrollmentId}/contacts`, body);
  }
  updateOrganizationEnrollmentCreditData$(
    organizationId: number,
    enrollmentId: number,
    body: EnrollmentCreditDataDto,
  ): Observable<void> {
    return this.put(
      `/organizations/${organizationId}/enrollments/${enrollmentId}/credit-data`,
      body,
    );
  }

  updateOrganizationEnrollmentLicences$(
    organizationId: number,
    enrollmentId: number,
    licences: EnrollmentLicencesReqDto,
  ): Observable<void> {
    const formData = new FormData();
    let key: keyof typeof licences;

    for (key in licences) {
      const value = licences[key];
      if (value) {
        formData.append(key, value);
      }
    }
    return this.putFormData(
      `/organizations/${organizationId}/enrollments/${enrollmentId}/licences`,
      formData,
    );
  }

  submitOrganizationEnrollment$(
    organizationId: number,
    enrollmentId: number,
  ): Observable<EnrollmentDto> {
    return this.put(`/organizations/${organizationId}/enrollments/${enrollmentId}/submit`);
  }

  approveOrganizationEnrollment$(organizationId: number, enrollmentId: number): Observable<void> {
    return this.patch(`/organizations/${organizationId}/enrollments/${enrollmentId}/approve`);
  }

  declineOrganizationEnrollment$(organizationId: number, enrollmentId: number): Observable<void> {
    return this.patch(`/organizations/${organizationId}/enrollments/${enrollmentId}/decline`);
  }

  getEnrollmentLicenceFile$(
    organizationId: number,
    enrollmentId: number,
    fileId: number,
  ): Observable<string> {
    return this.getText(
      `/organizations/${organizationId}/enrollments/${enrollmentId}/licences/${fileId}`,
    );
  }
}
